import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {SearchStateService} from '../../services/search-state.service';
import {SearchStatePaginator} from '../../utils/paginator/search-state-paginator';
import {SearchCompany} from '../../model/search-company';
import {Company} from 'api/models/asset/company';
import {Router} from '@angular/router';
import {combineLatest, Subscription} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {ScreenService} from '../../services/screen.service';
import {PointResult} from '../../model/search-response';

@Component({
    selector: 'app-search-companies',
    templateUrl: './search-companies.component.html'
})
export class SearchCompaniesComponent implements OnInit, OnDestroy {
    private allSearchPoints: PointResult[] = [];
    private subscriptions: Subscription[] = [];
    gridView$ = this.searchStateService.gridView$;
    isOnMobile = false;
    paginator = new SearchStatePaginator<SearchCompany>(
        this.searchStateService,
        (page, pageSize, query, favorite, sort, companyFilter) => {
            return this.apiService.filter<SearchCompany>('company', '', query, favorite, sort, page, pageSize, companyFilter);
        }
    );
    isLoading$ = this.searchStateService.loadingSearchResults$;
    allSearchedCompaniesPaginator = new SearchStatePaginator<Company>(
        this.searchStateService,
        (page, pageSize, query, favorite, sort, companiesFilter) => {
            return this.apiService.filter<SearchCompany>('project', '', query, favorite, sort, page, 1000, companiesFilter);
        }
    );
    allResultsCompaniesPaginator = new SearchStatePaginator<Company>(
        this.searchStateService,
        (page, pageSize, query, favorite, sort, companyFilter) => {
            return this.apiService.allCompanies$;
        }
    );

    constructor(
        private searchStateService: SearchStateService,
        private apiService: ApiService,
        private router: Router,
        private screenService: ScreenService
    ) {
    }

    openCompanyDetailPage(company: Company) {
        this.router.navigate(['companies', company.kvw_nummer]);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.screenService.screen$.subscribe(screen => {
                this.isOnMobile = screen === 'mobile';
            })
        );
        this.subscriptions.push(
            this.screenService.screen$.pipe(
                filter(screen => screen === 'desktop'),
                switchMap(() => combineLatest([
                    this.allResultsCompaniesPaginator.responseWithParams$,
                    this.allSearchedCompaniesPaginator.responseWithParams$,
                    this.paginator.content$
                ]))
            ).subscribe(([allResults, allSearchedCompaniesPaginator, pagedResults]) => {
                const resultPoints = pagedResults
                    .filter(result => result.geog !== null)
                    .map(item => ({...item, title: item.naam, id: item.kvw_nummer, type: 'activeCompany'} as PointResult));

                this.allSearchPoints = allResults.value
                    .filter(result => result.geog !== null)
                    .map(item => ({...item, title: item.naam, id: item.kvw_nummer, type: 'company'} as PointResult));

                const searchedPoints = allSearchedCompaniesPaginator.value
                    .filter(result => result.geog !== null)
                    .map(item => ({...item, title: item.naam, id: item.kvw_nummer, type: 'company'} as PointResult));

                const searchPoints = pagedResults.length === 0 ?
                    this.allSearchPoints :
                    searchedPoints;

                const allPoints = searchPoints
                    .filter(result => pagedResults.every(it => result.id !== it.kvw_nummer))
                    .concat(resultPoints);

                this.searchStateService.pointResults$.next(allPoints);
            })
        );
    }
}
