import {FillExtrusionLayer, VectorSource} from 'mapbox-gl';
import {environment} from '../../../../environments/environment';

export const pandSource: VectorSource = {
    type: 'vector',
    minzoom: 12,
    maxzoom: 18,
    tiles: [
        // eslint-disable-next-line max-len
        `${environment.geoserverUrl}/Digibase/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=Digibase:pand_vectortiles_view&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`
    ],
};

export const pandLayer: FillExtrusionLayer = {
    id: 'pand',
    type: 'fill-extrusion',
    source: 'pand',
    'source-layer': 'pand_vectortiles_view',
    minzoom: 12,
    layout: {},
    paint: {
        'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            13, 0,
            14,
            ['case',
                ['has', 'h_pand'],
                ['get', 'h_pand'],
                0
            ],
            16,0
        ]
    }
};

export const pandLayerExtrusionColor = [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    '#32A3C9',
    '#B0B5BC'
];

export const pandLayerEnergyClassExtrusionColor = [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    '#3a88bf',
    [
        'match',
        ['get', 'energy_class'],
        ['A++++'],
        '#009947',
        ['A+++'],
        '#009947',
        ['A++'],
        '#009947',
        ['A+'],
        '#009947',
        ['A'],
        '#009947',
        ['B'],
        '#35a036',
        ['C'],
        '#9fc61b',
        ['D'],
        '#fef804',
        ['E'],
        '#feb818',
        ['F'],
        '#ea6017',
        ['G'],
        '#e91521',
        '#dbd8d5'
    ]
];

export const pandLayerBouwjaarExtrusionColor = [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    '#3a88bf',
    [
        'interpolate',
        ['linear'],
        ['get', 'oorspronkelijk_bouwjaar'],
        1005,
        '#444444',
        1700,
        '#430719',
        1750,
        '#740320',
        1800,
        '#a50026',
        1850,
        '#d73027',
        1900,
        '#f46d43',
        1925,
        '#fdae61',
        1950,
        '#FEE090',
        1975,
        '#84c7db',
        2000,
        '#5096c2',
        2015,
        '#1d66aa',
        2021,
        '#117c3c',
        9999,
        '#444444'
    ]
];


export const pandLayerEnergyClass: FillExtrusionLayer = {
    ...pandLayer,
    paint: {
        ...pandLayer.paint,
        'fill-extrusion-color': [
            'case',
            ['boolean', ['feature-state', 'selected'], false],
            '#3a88bf',
            [
                'match',
                ['get', 'energy_class'],
                ['A++++'],
                '#009947',
                ['A+++'],
                '#009947',
                ['A++'],
                '#009947',
                ['A+'],
                '#009947',
                ['A'],
                '#009947',
                ['B'],
                '#35a036',
                ['C'],
                '#9fc61b',
                ['D'],
                '#fef804',
                ['E'],
                '#feb818',
                ['F'],
                '#ea6017',
                ['G'],
                '#e91521',
                '#dbd8d5'
            ]
        ]
    }
};

export const pandLayerBouwjaar: FillExtrusionLayer = {
    ...pandLayer,
    paint: {
        ...pandLayer.paint,
        'fill-extrusion-color': [
            'case',
            ['boolean', ['feature-state', 'selected'], false],
            '#3a88bf',
            [
                'interpolate',
                ['linear'],
                ['get', 'oorspronkelijk_bouwjaar'],
                1005,
                '#444444',
                1700,
                '#430719',
                1750,
                '#740320',
                1800,
                '#a50026',
                1850,
                '#d73027',
                1900,
                '#f46d43',
                1925,
                '#fdae61',
                1950,
                '#FEE090',
                1975,
                '#84c7db',
                2000,
                '#5096c2',
                2015,
                '#1d66aa',
                2021,
                '#117c3c',
                9999,
                '#444444'
            ]
        ]
    }
};
