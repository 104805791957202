<div class="relative">
    <div class="pb-4 flex items-center">
        <app-export-dropdown *ngIf="!isOnMobile" titleText="Resultaten" table="company">
        </app-export-dropdown>
        <app-pagination-sort *ngIf="gridView$|async;" class="ml-auto" [paginator]="paginator"
                             [sortOptions]="companySortOptions" [setSortFromQueryParams]="true" [startOption]="'recent'"></app-pagination-sort>
        <app-results-view-switch class="mobile:hidden" [class.ml-auto]="(gridView$|async) === false"></app-results-view-switch>
        <app-pagination class="mobile:hidden" [paginator]="paginator"></app-pagination>
    </div>
    <ng-container *ngIf="paginator.content$|async as companies">
        <app-company-tile-grid *ngIf="(gridView$|async) || isOnMobile; else listView" [companies]="companies">
        </app-company-tile-grid>
        <ng-template #listView>
            <app-company-list [items]="companies"></app-company-list>
        </ng-template>
    </ng-container>

    <span *ngIf="paginator.isLoading" class="absolute top-24 left-1/2 transform -translate-x-1/2"
          inlineSVG="/assets/icons/loader.svg"></span>
    <app-empty-state *ngIf="paginator.isLoading === false && paginator.isEmpty"
                     style="padding-top: calc(50vh - 248px - 112px)">
    </app-empty-state>
    <div class="mt-4 flex items-end flex-col">
        <app-pagination [paginator]="paginator"></app-pagination>
    </div>
</div>
