<ng-container *ngIf="(currentSortLabel$|async) as currentSortLabel">
    <div class="rounded px-2 py-0.5" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        <span (click)="sortService.updateState(true);" class="text-neutral-1 rounded p-0.5 hover:bg-neutral-4 cursor-pointer"
              [class.hover:bg-neutral-4]="currentSortLabel !== distanceLabel"
              [class.opacity-50]="currentSortLabel === distanceLabel"
              [class.cursor-default]="currentSortLabel === distanceLabel"
              [inlineSVG]="(sortService.currentSort$|async) === 2 ? '/assets/icons/icon-24-sort-down.svg' : '/assets/icons/icon-24-sort-up.svg'"></span>
        <button (click)="currentSortLabel === distanceLabel || onDropdownOpenClick()" class="px-2 py-1 rounded"       type="button"
                 [class.hover:bg-neutral-4]="currentSortLabel !== distanceLabel"
                 [class.opacity-50]="currentSortLabel === distanceLabel"
                 [class.cursor-default]="currentSortLabel === distanceLabel">
        {{currentSortLabel}}
    </button>
    </div>
    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="dropdownOpen"
        [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
        (overlayOutsideClick)="onOutsideOverlayClick()"
        (detach)="onOutsideOverlayClick()">
        <div class="bg-white border border-neutral-3 rounded p-2 w-56 shadow-overlay">
            <div class="w-full px-4 py-2 text-neutral-1 text-xs font-medium">Toon</div>
            <button class="w-full px-4 py-2 rounded hover:bg-neutral-4 text-left" *ngFor="let option of sortOptions"
                    (click)="onOptionClick(option.key)">
                {{option.label}}
            </button>
        </div>
    </ng-template>
</ng-container>
