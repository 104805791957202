import {Observable} from 'rxjs';
import {SearchFilterResponse} from '../../model/search-response';
import {AbstractPaginator, PaginatorLoadParams} from './abstract-paginator';

export class CallbackPaginator<Item, Sort = any, Filter = any> extends AbstractPaginator<Item, Sort, Filter> {
    constructor(
        loadParams: Observable<PaginatorLoadParams<Sort, Filter>>,
        loadFunction: (
            page: number,
            pageSize: number,
            query: string,
            favorite: boolean,
            sort: Sort,
            filter: Filter
        ) => Observable<SearchFilterResponse<Item>>,
        private setPageFunction: (page: number) => void,
        private setPageSizeFunction: (pageSize: number) => void,
        private setSortFunction: (sort: Sort) => void
    ) {
        super(loadParams, loadFunction);
    }

    next(): void {
        if (this.hasNext()) {
            this.setPageFunction(this.currentPage + 1);
        }
    }

    previous(): void {
        if (this.hasPrevious()) {
            this.setPageFunction(this.currentPage - 1);
        }
    }

    setPageSize(pageSize: number) {
        this.setPageSizeFunction(pageSize);
    }

    setSort(sort: Sort) {
        this.setSortFunction(sort);
    }
}
