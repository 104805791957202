import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-collapse',
    templateUrl: './collapse.component.html'
})
export class CollapseComponent {
    @Input() collapseTitle = '';
    @Input() leftIndent = true;
    @Input() open = true;
}
