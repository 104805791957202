import {CallbackPaginator} from './callback-paginator';
import {SearchStateService} from '../../services/search-state.service';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SearchFilterResponse} from '../../model/search-response';

export class SearchStatePaginator<Item> extends CallbackPaginator<Item, string, {[key: string]: string[]}> {
    constructor(
        searchStateService: SearchStateService,
        loadFunction: (
            page: number,
            pageSize: number,
            query: string,
            favorite: boolean,
            sort: string,
            filter: {[key: string]: string[]}
        ) => Observable<SearchFilterResponse<Item>>,
    ) {
        super(
            searchStateService.searchState$.pipe(
                map(({page, pageSize, query, favorite, sort, filter}) => {
                    return ({page, pageSize, query, favorite, sort, filter});
                }),
                distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
            ),
            loadFunction,
            page => searchStateService.setPage(page),
            pageSize => searchStateService.setPageSize(pageSize),
            sort => searchStateService.setTableSort({
                field: sort, direction: 'ASC'
            })
        );
    }
}
