import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SidebarService {
    private pageWidthSubject = new BehaviorSubject(600);
    pageWidth$ = this.pageWidthSubject.asObservable();

    setPageWidth(newSize: number) {
        this.pageWidthSubject.next(newSize);
    }
}
