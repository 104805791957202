<button class="flex flex-col w-full h-full rounded bg-white overflow-hidden desktop:hover:shadow-tile relative group
        ring-2 ring-transparent focus:ring-primary focus:outline-none border border-neutral-3 px-6 relative mobile:shadow"
        (click)="onItemClicked($event, relation, 'relation')">

    <div class="w-screen -ml-6 border-b"></div>
    <div class="block border-top desktop:py-4 mobile:py-2 text-left">
        <div class="font-medium">
            {{ relation.name }}
        </div>
        <div class="leading-6 mt-3 desktop:min-h-18">
            {{ relation.branche_address }}<br>
            {{ relation.branche_postal_code }}<br>
            {{ relation.branche_city }}<br>
            {{ relation.branche_country }}
        </div>
        <div class="text-primary font-medium leading-6 flex flex-wrap w-full pt-2">
            <a *ngIf="relation.main_phone" class="font-medium flex items-center space-x-1 mb-2 mr-2"
               [ngClass]="{'border-r border-neutral-4 pr-2': relation.email}"
               [href]="renderPhoneNumber(relation.main_phone)">
                <span inlineSVG="/assets/icons/icon-16-phone.svg"></span>
            </a>
            <a *ngIf="relation.email" class="font-medium flex items-center space-x-1 mb-2"
               [href]="'mailto:' + relation.email" tabindex="-1">
                <span inlineSVG="/assets/icons/icon-16-mail.svg"></span>
            </a>
        </div>
    </div>
</button>
