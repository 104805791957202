<button class="block w-full h-full relative rounded mobile:rounded-lg bg-neutral-4 overflow-hidden desktop:hover:shadow-tile
    ring-2 ring-transparent focus:ring-primary focus:outline-none border border-neutral-3 group mobile:shadow"
    (click)="onItemClicked($event, project, 'project')"
    [ngClass]="selectionService.projectIsSelected(project) ? 'mobile:ring-primary' : 'mobile:ring-transparent'"
>
    <ng-container *ngIf="project | projectImage; then projectImage else defaultImage"></ng-container>
    <ng-template #projectImage>
        <img appImageFade *ngIf="project | projectImage; else defaultImage" [src]="project | projectImage"
             class="absolute inset-0 w-full h-full object-cover">
        <div class="absolute inset-x-0 bottom-0 px-6 pb-4 flex flex-col justify-end text-left
             h-24 bg-gradient-to-b from-transparent to-dark">
            <div class="flex items-center">
                <span *ngIf="!hasCommercialProjectName(project)" class="text-base mobile:text-sm font-medium text-white">{{project.naam}}</span>
                <span *ngIf="hasCommercialProjectName(project)">
                    <span class="text-base mobile:text-sm font-medium text-white">{{project.commercial_project_name}}</span>&nbsp;
                    <span [tooltip]="getProjectNameTooltipText(project)" class="mr-1 text-white" inlineSVG="/assets/icons/icon-info.svg"></span>
                </span>
                <app-is-favorite [project]="project"></app-is-favorite>
            </div>
            <span class="text-xs mobile:text-s font-medium text-white text-opacity-80 truncate">{{project.bedrijf}}</span>
        </div>
    </ng-template>
    <ng-template #defaultImage>
        <div class="absolute inset-0 bottom-18 bg-neutral-4">
            <span class="top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2 object-contain"
                inlineSVG="/assets/icons/thumbnail.svg"></span>
        </div>
        <div class="absolute inset-x-0 bottom-0 px-6 pb-4 flex flex-col justify-end text-left h-18 bg-white">
            <div class="flex items-center">
                <span *ngIf="!hasCommercialProjectName(project)" class="text-base font-medium text-dark truncate mobile:line-clamp-2">{{project.naam}}</span>
                <span *ngIf="hasCommercialProjectName(project)">
                    <span class="text-base font-medium text-dark truncate mobile:line-clamp-2">{{project.commercial_project_name}}</span>&nbsp;
                    <span [tooltip]="getProjectNameTooltipText(project)" class="mr-1 text-dark" inlineSVG="/assets/icons/icon-info.svg"></span>
                </span>
                <app-is-favorite [project]="project"></app-is-favorite>
            </div>
            <span class="text-xs font-medium text-neutral-1 truncate">{{project.bedrijf}}</span>
        </div>
    </ng-template>
    <app-is-selected *ngIf="(selectionMode$|async) === 'project'" class="mobile:hidden absolute top-2 left-2 group-hover:visible" [project]="project"
                     [ngClass]="selectionService.projectIsSelected(project) ? 'visible' : 'invisible'">
        <div unchecked class="cursor-pointer px-1 bg-white hover:bg-neutral-3 rounded"
             (click)="addProjectToSelection($event, (project))">
            <span inlineSVG="/assets/icons/checkbox.svg"></span>
        </div>
        <div checked class="cursor-pointer bg-primary text-white rounded"
             (click)="removeProjectFromSelection($event, (project))">
            <span inlineSVG="/assets/icons/checkbox.svg" class="px-1"></span>
        </div>
    </app-is-selected>
    <app-project-tile-context-menu class="absolute top-2 right-2" [project]="project"></app-project-tile-context-menu>
</button>
