<app-search-result-container [isLoading]="isLoading$|async" [isEmpty]="paginator.isEmpty"
    emptyTitle="Geen bedrijven gevonden" emptyDescription="Er zijn geen bedrijven gevonden, probeer het opnieuw via de zoekbalk"
>
    <div class="relative">
        <div class="pb-4 flex items-center">
            <app-export-dropdown *ngIf="!isOnMobile" titleText="Resultaten" table="project">
            </app-export-dropdown>
            <a class="ml-auto text-primary font-medium text-right"
               routerLink="/companies/search" queryParamsHandling="preserve">
                Geavanceerd zoeken
            </a>
            <app-results-view-switch class="mobile:hidden">
            </app-results-view-switch>
            <app-pagination class="mobile:hidden ml-2" [paginator]="paginator"></app-pagination>
        </div>
        <ng-container *ngIf="paginator.content$|async as companies">
            <app-company-tile-grid
                *ngIf="gridView$|async; else listView"
                [companies]="companies"
            ></app-company-tile-grid>
            <ng-template #listView>
                <app-company-list [items]="companies"></app-company-list>
            </ng-template>
        </ng-container>
        <div class="mt-4 flex items-end flex-col">
            <app-pagination [paginator]="paginator"></app-pagination>
        </div>
    </div>
</app-search-result-container>
