import {Component, OnDestroy, OnInit} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef, VwuiModalService} from '@recognizebv/vwui-angular';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {SearchState} from '../../services/search-state.service';
import {ExportPreferenceService} from '../../services/export-preference.service';
import {ExportPreference} from 'api/models/export-preference';
import {DeleteSavedFilterModalComponent} from '../delete-saved-filter-modal/delete-saved-filter-modal.component';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {SaveFilterModalComponent} from '../save-filter-modal/save-filter-modal.component';
import {switchMap} from 'rxjs/operators';
import {ExportProjectErrorModalComponent} from '../export-project-error-modal/export-project-error-modal.component';

@Component({
    selector: 'app-export-project-modal',
    templateUrl: './export-project-modal.component.html'
})
export class ExportProjectModalComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    parameters = [
        {
            title: 'ID',
            key: 'id'
        },
        {
            title: 'Naam',
            key: 'naam'
        },
        {
            title: 'Onderneming',
            key: 'bedrijf'
        },
        {
            title: 'Cluster regionaam',
            key: 'cluster_regionaam'
        },
        {
            title: 'Opdrachtgever',
            key: 'opdrachtgever'
        },
        {
            title: 'Pandref',
            key: 'pandref'
        },
        {
            title: 'Project type',
            key: 'project_type'
        },
        {
            title: 'Startdatum',
            key: 'startdatum'
        },
        {
            title: 'Einddatum',
            key: 'einddatum'
        },
        {
            title: 'Plaats',
            key: 'plaats'
        },
        {
            title: 'Huisnummer',
            key: 'huisnummer'
        },
        {
            title: 'Postcode',
            key: 'postcode'
        },
        {
            title: 'Straat',
            key: 'straat'
        },
        {
            title: 'AfbeeldingURL',
            key: 'afbeeldingURL'
        },
        {
            title: 'Gebouwclassificatie',
            key: 'gebouwclassificatie'
        },
        {
            title: 'Gebouwsubclassificatie',
            key: 'gebouwsubclassificatie'
        },
        {
            title: 'VWconcept',
            key: 'vwconcept'
        },
        {
            title: 'Duurzaamheid classificatie',
            key: 'duurzaamheidsclassificatie'
        },
        {
            title: 'Bedrijf code',
            key: 'bedrijf_code'
        },
        {
            title: 'Company code',
            key: 'companyCode'
        },
        {
            title: 'KVW-nummer',
            key: 'kvw_nummer'
        },
        {
            title: 'Aangemaakt op',
            key: 'aangemaakt_op'
        },
        {
            title: 'Operationele status',
            key: 'operationele_projectstatus'
        },
        {
            title: 'Naam lowercase',
            key: 'naam_lowercase'
        },
        {
            title: 'Heeft PRS',
            key: 'hasPrs'
        },
        {
            title: 'PRS',
            key: 'prs'
        },
        {
            title: 'Projectleider',
            key: 'projectleider'
        },
        {
            title: 'Werkvoorbereider',
            key: 'werkvoorbereider'
        },
        {
            title: 'Inkoper',
            key: 'inkoper'
        },
        {
            title: 'Uitvoerder',
            key: 'uitvoerder'
        },
        {
            title: 'Calculator',
            key: 'calculator'
        },
    ];

    form = new FormGroup({});
    total = null;
    searchState: SearchState;
    selectedSearchFilters: string[] = [];
    refresh$ = new BehaviorSubject(null);
    filters$ = this.refresh$.pipe(switchMap(() => this.exportPreferenceService.getExportPreferences()));

    constructor(
        private vwuiModalRef: VwuiModalRef,
        private modalConfig: VwuiModalConfig,
        private modalService: VwuiModalService,
        private exportPreferenceService: ExportPreferenceService,
        private toastrService: ToastrService,
    ) {
    }

    ngOnInit(): void {
        this.total = this.modalConfig.data.total;
        this.searchState = this.modalConfig.data.searchState;
        if (this.modalConfig.data.searchState.filter) {
            this.selectedSearchFilters = Object.keys(this.modalConfig.data.searchState.filter);
        }
        this.parameters.forEach(parameter => {
            if (this.searchState.filter && this.selectedSearchFilters.includes(parameter.key)) {
                this.form.addControl(parameter.key, new FormControl({value: true, disabled: true}));
            } else {
                this.form.addControl(parameter.key, new FormControl(true));
            }
        });
    }

    asFormControl(control: AbstractControl) {
        return control as FormControl;
    }

    get checked() {
        return Object.values(this.form.value).filter(it => it === false).length === 0;
    }

    get indeterminate() {
        return Object.values(this.form.value).filter(it => it === false).length > 0
            && Object.values(this.form.value).filter(it => it === false).length < this.parameters.length;
    }

    toggleCheck(checked) {
        this.parameters.forEach(parameter => {
            const formItem = this.form.get(parameter.key);
            if (!formItem.disabled) {
                formItem.setValue(checked.detail.checked);
            }
        });
    }

    activateExportPreference(savedFilter: ExportPreference) {
        const filterMatch = savedFilter.filter.filter(filter => this.selectedSearchFilters.includes(filter));
        if (filterMatch.length !== this.selectedSearchFilters.length) {
            this.modalService.open(ExportProjectErrorModalComponent, {
                data: {
                    selectedSearchFilters: this.selectedSearchFilters
                }
            });
        } else {
            this.parameters.forEach(parameter => {
                if (!this.form.get(parameter.key).disabled) {
                    this.form.get(parameter.key).setValue(false);
                }
            });
            savedFilter.filter.forEach(filter => {
                const formItem = this.form.get(filter);
                if (formItem) {
                    formItem.setValue(true);
                }
            });
        }
    }

    close() {
        this.vwuiModalRef.close();
    }

    save() {
        const exportParams = Object.keys(this.form.getRawValue()).filter(key => this.form.getRawValue()[key] === true);
        this.vwuiModalRef.close(exportParams);
    }

    openSaveExportParameterModal() {
        const modal = this.modalService.open(SaveFilterModalComponent);
        this.subscriptions.push(modal.afterClosed.subscribe(async (data) => {
            if (data) {
                try {
                    await this.exportPreferenceService.postExportPreference({
                        name: data,
                        filter: Object.keys(this.form.getRawValue()).filter(key => this.form.getRawValue()[key] === true)
                    }).toPromise();
                    this.toastrService.success('Export filter succesvol opgeslagen');
                    this.refresh$.next(null);
                } catch (e) {
                    switch (e.status) {
                        case 409:
                            this.toastrService.error('Export filter is al in gebruik');
                            break;
                        default:
                            this.toastrService.error('Fout bij het opslaan van export filter');
                    }
                }
            }
        }));
    }

    deleteExportPreference(event: Event, savedFilter: ExportPreference) {
        event.stopPropagation();
        const modal = this.modalService.open(DeleteSavedFilterModalComponent, {
            data: {
                filter: savedFilter
            }
        });
        this.subscriptions.push(modal.afterClosed.subscribe(async (exportPreference: ExportPreference) => {
            if (exportPreference) {
                try {
                    await this.exportPreferenceService.deleteExportPreference(exportPreference.id).toPromise();
                    this.toastrService.success('Export filter succesvol verwijderd');
                    this.refresh$.next(null);
                } catch (e) {
                    this.toastrService.error('Fout bij verwijderen van export filter');
                }
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
