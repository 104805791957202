<button class="mr-auto rounded desktop:group-hover:visible mobile:visible bg-white border border-neutral-3 desktop:hover:bg-neutral-4 h-6 w-6"
        [class.invisible]="!dropdownOpen"
        cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="onClick($event)"
        inlineSVG="/assets/icons/icon-16-more-horizontal.svg">
</button>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="dropdownOpen"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    [cdkConnectedOverlayPositions]="connectedPositions"
    (overlayOutsideClick)="onOutsideOverlayClick()"
    (detach)="onOutsideOverlayClick()">
    <div class="bg-white border-neutral-3 border rounded z-10 mt-1">
        <div class="px-2 py-4">
            <div class="text-xs mb-2 ml-4 text-neutral-1">
                Acties
            </div>
            <div>
                <app-is-favorite [project]="project" [company]="company">
                    <div starOpen (click)="onAddFavorite($event, (project || company))"
                         class="leading-6 text-dark py-2 px-4 cursor-pointer hover:bg-neutral-4 rounded">
                        <span inlineSVG="/assets/icons/icon-16-star.svg" class="mr-2"></span>
                        Toevoegen aan favorieten
                    </div>
                    <div starFilled (click)="onRemoveFavorite($event, (project || company))"
                         class="leading-6 text-dark py-2 px-4 cursor-pointer hover:bg-neutral-4 rounded">
                        <span inlineSVG="/assets/icons/icon-16-star-filled.svg" class="mr-2"></span>
                        Verwijderen uit favorieten
                    </div>
                </app-is-favorite>
                <app-is-selected [project]="project" [company]="company">
                    <div unchecked (click)="toggleSelected($event, (project || company))"
                         class="leading-6 text-dark py-2 px-4 cursor-pointer hover:bg-neutral-4 rounded">
                        <span inlineSVG="/assets/icons/checkbox.svg" class="cursor-pointer px-1 bg-white hover:bg-neutral-3 rounded mr-2"></span>
                        Selecteer
                    </div>
                    <div checked (click)="toggleSelected($event, (project || company))"
                         class=" leading-6 text-dark py-2 px-4 cursor-pointer hover:bg-neutral-4 rounded">
                        <span inlineSVG="/assets/icons/checkbox.svg" class="cursor-pointer px-1 bg-primary text-white rounded mr-2"></span>
                        Deselecteer
                    </div>
                </app-is-selected>
            </div>
        </div>
    </div>
</ng-template>
