import {Component} from '@angular/core';
import {LngLat, Popup} from 'mapbox-gl';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-claim-pand-menu',
    templateUrl: './claim-pand-menu.component.html'
})
export class ClaimPandMenuComponent {
    protected readonly projectenPortaalBaseUrl= environment.projectenPortaalBaseUrl;
    public popupModel: Popup;
    public lngLat: LngLat;
    public mapboxService: MapboxService;

    constructor() {
    }

    close() {
        this.mapboxService.actionMenu.remove();
    }
}
