import {Component, Input,} from '@angular/core';
import {ProjectRelation} from 'api/models/prs';

@Component({
    selector: 'app-project-relation-card',
    templateUrl: './project-relation-card.component.html'
})
export class ProjectRelationCardComponent{
    @Input() projectRelation: ProjectRelation;
}
