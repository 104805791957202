import {SearchMode} from '../../model/search-result-type';
import {PaddingOptions} from 'mapbox-gl';

export function searchModePaddingOptions(mode: SearchMode, tableWidth: number): PaddingOptions {
    return {
        top: 0,
        left: searchModePadding(mode, tableWidth),
        right: 0,
        bottom: 0
    };
}

export function searchModePadding(mode: SearchMode, tableWidth: number): number {
    if (!mode || mode === 'collapsed') {
        return 0;
    } else if (mode === 'search' || mode === 'result') {
        return 500;
    }

    return tableWidth;
}
