<app-search-result-container [isLoading]="isLoading$|async" [isEmpty]="(totalResults$|async) === 0">
    <ng-container *ngIf="results$ | async as searchResponse" class="relative">
        <div *ngIf="searchResponse.project.count > 0" class="relative mb-6">
            <div class="mb-4 flex items-center justify-between">
                <div appTitle>Projecten</div>
                <a class="text-primary font-medium"
                   routerLink="../projects" queryParamsHandling="preserve">
                    Alle {{ searchResponse.project.count }} projecten
                </a>
            </div>
            <ng-container *ngIf="searchResponse.project.value as projects">
                <app-project-list *ngIf="!isOnMobile" [projects]="projects"></app-project-list>
                <app-project-tile-grid *ngIf="isOnMobile" [projects]="projects"></app-project-tile-grid>
            </ng-container>
        </div>
        <div *ngIf="searchResponse.company.count > 0" class="relative mb-6">
            <div class="mb-4 flex items-center justify-between">
                <div appTitle>Bedrijven</div>
                <a class="text-primary font-medium"
                   routerLink="../companies" queryParamsHandling="preserve">
                    Alle {{ searchResponse.company.count }} bedrijven
                </a>
            </div>
            <app-company-list *ngIf="!isOnMobile" [items]="searchResponse.company.value"></app-company-list>
            <app-company-tile-grid *ngIf="isOnMobile" [companies]="searchResponse.company.value"></app-company-tile-grid>
        </div>
        <div *ngIf="searchResponse.verblijfsobject.count > 0" class="relative mb-6">
            <div class="mb-4 flex items-center justify-between">
                <div appTitle>Adressen</div>
                <a class="text-primary font-medium"
                   routerLink="../adressen" queryParamsHandling="preserve">
                    Alle {{ searchResponse.verblijfsobject.count }} adressen
                </a>
            </div>
            <app-verblijfsobject-list [items]="searchResponse.verblijfsobject.value" [addEmptyStateRow]="true">
            </app-verblijfsobject-list>
        </div>
        <div *ngIf="searchResponse.relation.count > 0" class="relative mb-6">
            <div class="mb-4 flex items-center justify-between">
                <div appTitle>Leveranciers</div>
                <a class="text-primary font-medium"
                   routerLink="../relations" queryParamsHandling="preserve">
                    Alle {{ searchResponse.relation.count }} leveranciers
                </a>
            </div>
            <app-relation-list *ngIf="!isOnMobile" [items]="searchResponse.relation.value"></app-relation-list>
        </div>
        <div *ngIf="searchResponse.woonplaats.count > 0" class="relative mb-6">
            <div class="mb-4 flex items-center justify-between">
                <div appTitle>Plaatsen</div>
                <a *ngIf="searchResponse.woonplaats.count > 3" class="text-primary font-medium"
                   routerLink="../plaatsen" queryParamsHandling="preserve">
                    Alle {{ searchResponse.woonplaats.count }} plaatsen
                </a>
            </div>
            <app-woonplaats-list *ngIf="searchResponse.woonplaats.count > 0" [items]="searchResponse.woonplaats.value" [addEmptyStateRow]="true">
            </app-woonplaats-list>
        </div>
    </ng-container>
</app-search-result-container>
