<table class="w-full rounded-table-neutral-3 text-left">
    <tr class="text-neutral-1">
        <th *ngIf="(selectionMode$|async) === 'project'" class="w-12"></th>
        <th class="py-3 px-6"></th>
        <ng-container *ngFor="let column of columns.value">
            <th class="py-3 whitespace-nowrap" *ngIf="column.enabled">
                <button (click)="sortService.sortBy(column.sort, false)"
                        class="-ml-2 py-1 px-2 font-medium hover:bg-neutral-4 inline rounded">{{ column.name }}
                    <span *ngIf="((sortService.currentState$|async) === column.sort || (sortService.currentState$|async) === '-' + column.sort) && column.enabled"
                          class="inline-block transform" [class.rotate-180]="(sortService.currentSort$|async) === 2"
                          [class.invisible]="(sortService.currentSort$|async) === 0"
                          [class.visible]="(sortService.currentState$|async) === column.sort || (sortService.currentState$|async) === '-' + column.sort"
                          inlineSVG="/assets/icons/icon-arrow-up.svg"></span>
                </button>
            </th>
        </ng-container>
        <th class="px-6 w-px">
            <button
                class="w-6 h-6 rounded-lg hover:bg-neutral-3"
                (click)="onColumnPickerClick()"
                cdkOverlayOrigin #trigger="cdkOverlayOrigin">
                <span inlineSVG="/assets/icons/icon-16-columns.svg"></span>
            </button>
        </th>
    </tr>
    <tr *ngIf="!projects || projects.length === 0">
        <td class="py-3 px-6 text-center" [attr.colspan]="(selectionMode$|async) === 'project' ? 3 : 2">
            Geen projecten gevonden.
        </td>
    </tr>
    <tr *ngFor="let project of projects" class="hover:bg-neutral-4 cursor-pointer group"
        (click)="onItemClicked($event, project, 'project')">
        <td *ngIf="(selectionMode$|async) === 'project'" class="py-3 pl-6" (click)="$event.stopPropagation()">
            <div class="flex items-center">
                <app-is-selected [project]="project">
                    <div unchecked
                         class="cursor-pointer px-1 border-primary text-white border rounded hover:bg-neutral-3"
                         (click)="addProjectToSelection($event, (project))">
                        <span class="" inlineSVG="/assets/icons/checkbox.svg"></span>
                    </div>
                    <div checked class="cursor-pointer bg-primary border rounded border-primary rounded"
                         (click)="removeProjectFromSelection($event, (project))">
                        <span inlineSVG="/assets/icons/checkbox.svg" class="px-1"></span>
                    </div>
                </app-is-selected>
            </div>
        </td>
        <td class="py-3 px-6">
            <div class="flex align-center flex-nowrap items-center">
                <div class="relative w-8 h-8 rounded flex-shrink-0 flex items-center justify-center"
                     [class.bg-primary]="!(project | projectImage)">
                    <!--
                        We _should_ be using thumbnail images here, but for unknown reasons suffixing ?size=thumbnail
                        no longer works. We use full size images instead, which definitely works, but is obviously
                        not ideal in terms of performance and bandwith used.
                    -->
                    <img *ngIf="project | projectImage; else defaultImage" alt=""
                         [src]="project | projectImage"
                         class="absolute inset-0 w-full object-cover rounded">
                    <ng-template #defaultImage>
                        <span class="absolute left-auto right-auto text-white"
                              inlineSVG="/assets/icons/icon-project.svg" [setSVGAttributes]="{width: 16, height: 16}">
                        </span>
                    </ng-template>
                </div>
		        <app-is-favorite [project]="project"></app-is-favorite>
            </div>
        </td>
        <td class="relative" *ngIf="columns.value[0].enabled">
            {{ project.id }}
        </td>
        <td class="relative min-w-14" *ngIf="columns.value[1].enabled">
            {{ hasCommercialProjectName(project) ? project.naam + ", " + project.commercial_project_name : project.naam }}
        </td>
        <td class="relative min-w-14" *ngIf="columns.value[2].enabled">
            {{ project.bedrijf }}
        </td>
        <td class="relative min-w-14" *ngIf="columns.value[3].enabled">
            {{ project.plaats }}
        </td>
	 <td class="relative min-w-14" *ngIf="columns.value[4].enabled">
            {{ project.operationele_projectstatus }}
        </td>
        <td class="relative min-w-14" *ngIf="columns.value[5].enabled">
            {{ project.opdrachtgever }}
        </td>
        <td class="relative" (click)="$event.stopPropagation()">
            <app-project-tile-context-menu
                class="absolute top-1/2 transform -translate-y-1/2 right-4"
                [project]="project"
                variant="list"
            ></app-project-tile-context-menu>
        </td>
    </tr>
</table>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="dropdownOpen"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    (overlayOutsideClick)="onOutsideOverlayClick()"
    (detach)="onOutsideOverlayClick()">
    <div class="bg-white border-neutral-3 border rounded z-10 px-2 py-2">
        <label
            *ngFor="let column of columns.controls"
            [formGroup]="column" class="custom-checkbox grey-checkbox px-4 py-2 rounded hover:bg-neutral-4 cursor-pointer select-none">
            <input [id]="'checkbox-' + column.value.name" type="checkbox" formControlName="enabled" (change)="onColumnPickerUpdate()">
            <label [for]="'checkbox-' + column.value.name">
                <span inlineSVG="/assets/icons/icon-check.svg"></span>
                {{column.value.name}}
            </label>
        </label>
    </div>
</ng-template>
