import {SearchPoint} from './searchpoint';

export interface Company {
    type: 'company';
    identificatie: string;
    kvw_nummer: string; // ID
    kvk_nummer: string;
    kvk_vestigingsnummer: string;
    naam: string;
    handelsnamen: string;
    is_hoofdvestiging: boolean;
    divisie_naam: string;
    domainnaam: string;
    email: string;
    hoofd_telefoonnummer: string;

    vestiging_straat: string;
    vestiging_huisnummer: string;
    vestiging_huisnummertoevoeging: string;
    vestiging_postcode: string;
    vestiging_plaats: string;
    correspondentie_straat: string;
    correspondentie_huisnummer: string;
    correspondentie_huisnummertoevoeging: string;
    correspondentie_postcode: string;
    correspondentie_plaats: string;

    entiteit_logo_url: string;
    entiteit_thumbnail_url: string;

    datum_laatst_bijgewerkt: string;
    geog: SearchPoint;
}

export function companyVestigingAddressString(company: Company): string {
    if (!company.vestiging_postcode) {
        return '';
    } else if (!company.vestiging_straat) {
        return `${company.vestiging_postcode} ${company.vestiging_plaats}`;
    } else {
        return `${company.vestiging_straat}${company.vestiging_huisnummer}${company.vestiging_huisnummertoevoeging ?? ''}, ${company.vestiging_postcode} ${company.vestiging_plaats}`;
    }
}

export function companyCorrespondenceAddressString(company: Company): string {
    if (!company.correspondentie_postcode) {
        return '';
    } else if (!company.correspondentie_straat) {
        return `${company.correspondentie_postcode} ${company.correspondentie_plaats}`;
    } else {
        return `${company.correspondentie_straat}${company.correspondentie_huisnummer}${company.correspondentie_huisnummertoevoeging ?? ''}, ${company.correspondentie_postcode} ${company.correspondentie_plaats}`;
    }
}
