import {Component, Input, OnDestroy} from '@angular/core';
import {SelectionService} from '../../services/selection.service';
import {UserSelected} from 'api/models/user-selected';
import {map, shareReplay} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {ModalService} from '../../services/modal.service';
import {Subscription} from 'rxjs';
import {Project, projectImage} from 'api/models/asset/project';
import {ApiService} from '../../services/api.service';
import {Company} from 'api/models/asset/company';

@Component({
    selector: 'app-selection-list',
    templateUrl: './selection-list.component.html'
})
export class SelectionListComponent implements OnDestroy {
    @Input() icon: string;

    private subscriptions: Subscription[] = [];

    selectionMode$ = this.selectionService.selectionMode$;
    selection$ = this.selectionService.selection$.pipe(shareReplay(1));
    selectionCount$ = this.selection$.pipe(
        map((selection) => selection.length)
    );

    exporting = false;
    favoritizing = false;
    selectingAll = false;
    exportType = 'xlsx';
    isLoadingModal = false;

    constructor(
        private modalService: ModalService,
        private selectionService: SelectionService,
        private toastrService: ToastrService,
        private apiService: ApiService
    ) {
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions = [];
    }

    removeSelected(event: MouseEvent, selected: UserSelected) {
        event.stopPropagation();
        this.selectionService.removeSelected(selected);
    }

    async clearSelection() {
        await this.selectionService.clearSelection();
    }

    async exportSelection() {
        this.exporting = true;
        try {
            await this.selectionService.exportSelection(this.exportType);
        } catch(e) {
            console.error(e.message);
            this.toastrService.error('Kan selectie niet exporteren');
        } finally {
            this.exporting = false;
        }
    }

    async favoritizeSelection() {
        this.favoritizing = true;
        try {
            await this.selectionService.favoritizeSelection();
        } catch (e) {
            console.error(e.message);
            this.toastrService.error('Selectie favoriet maken mislukt.');
        } finally {
            this.favoritizing = false;
        }
    }

    async selectAllProjects() {
        this.selectingAll = true;
        try {
            await this.clearSelection();
            const projects = await this.apiService.allProjects$.toPromise();
            projects.value.forEach((project: Project) => {
                this.selectionService.addSelected({
                    id: project.id,
                    type: 'project',
                    icon: projectImage(project)
                });
            });
        } catch (e) {
            console.error(e.message);
            this.toastrService.error('Alle projecten toevoegen mislukt.');
        } finally {
            this.selectingAll = false;
        }
    }

    async selectAllCompanies() {
        this.selectingAll = true;
        try {
            await this.clearSelection();
            const companies = await this.apiService.allCompanies$.toPromise();
            companies.value.forEach((company: Company) => {
                this.selectionService.addSelected({
                    id: company.kvw_nummer,
                    type: 'company',
                    icon: company.entiteit_logo_url
                });
            });
        } catch (e) {
            console.error(e.message);
            this.toastrService.error('Alle bedrijven toevoegen mislukt.');
        } finally {
            this.selectingAll = false;
        }
    }

    addToCollection(selectionCount) {
        if (selectionCount > 20) {
            this.toastrService.error('Er mogen maar 20 projecten aan een collectie worden toegevoegd.');
        } else {
            this.isLoadingModal = true;
            this.modalService.openCollectionModal();
            this.subscriptions.push(
                this.modalService.vwuiModalRef.afterClosed.subscribe(() => {
                    this.isLoadingModal = false;
                })
            );
        }
    }
}
