<app-card *ngIf="projectTeamMember">
    <div class="font-medium">{{projectTeamMember.name}} ({{projectTeamMember.role}})</div>
    <div>{{projectTeamMember.companyName}}</div>

    <div cardFooter>
        <a *ngIf="projectTeamMember?.businessPhones?.length > 0" href="tel:{{projectTeamMember.businessPhones[0]}}">
            <button class="text-primary font-medium py-2 pr-3 border-r mr-3">
                <span class="mr-1 transform inline-block w-4 h-4" inlineSVG="/assets/icons/icon-16-phone.svg"></span>
                Bellen
            </button>
        </a>
        <a *ngIf="projectTeamMember.upn" href="mailto:{{projectTeamMember.upn}}">
            <button class="text-primary font-medium py-2 pr-3 border-r mr-3">
                <span class="mr-1 transform inline-block w-4 h-4" inlineSVG="/assets/icons/icon-mail.svg"></span>
                Mailen
            </button>
        </a>
        <a *ngIf="projectTeamMember.upn" href="https://teams.microsoft.com/l/chat/0/0?users={{projectTeamMember.upn}}">
            <button class="text-primary font-medium py-2 pr-3 border-r mr-3">
                <span class="mr-1 transform inline-block w-4 h-4" inlineSVG="/assets/icons/icon-16-teams-chat.svg"></span>
                Teams Chat
            </button>
        </a>
        <a *ngIf="projectTeamMember.upn" (click)="gotoTeamMemberPage(projectTeamMember.upn)">
            <button class="text-primary font-medium py-2">
                <span class="mr-1 transform inline-block w-4 h-4" inlineSVG="/assets/icons/icon-project.svg"
                      [setSVGAttributes]="{width: 16, height: 16}"
                ></span>
                Projects
            </button>
        </a>
    </div>
</app-card>

