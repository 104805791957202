<ng-container *ngIf="relation$ | async as relation; else empty">
        <div class="text-base font-medium text-dark truncate">
            <div class="ml-6 py-8 w-5 h-5">
                <span class="bg-white rounded mr-2 cursor-pointer" inlineSVG="/assets/icons/icon-arrow-left.svg"
                      (click)="goBack()"></span>
               {{relation.name}}
            </div>
        </div>

    <div class="mx-6 mt-8 mobile:mt-0">
        <app-relation-tile class="block desktop:w-80 mb-4" [relation]="relation"></app-relation-tile>
    </div>
    <div class="mx-6 mt-8 mobile:mt-0" *ngIf="relation.projectIds.length > 0 && (projects$|async) as projects; else projectsSpinner">

        <div class="flex items-center pt-3 pb-4 cursor-pointer border-t border-neutral-3"
             *ngIf="projects.count > 0">
            <div class="flex cursor-pointer" (click)="projectsExpanded = !projectsExpanded">
                <div class="transform origin-center"
                     [ngClass]="projectsExpanded ? 'rotate-90' : '-rotate-90'"
                     inlineSVG="/assets/icons/navigation-arrow-icon.svg"
                ></div>
                <span class="font-medium text-base ml-4">Betrokken Projecten</span>
            </div>
            <span class="font-medium text-base text-primary ml-auto" (click)="goToProjects(relation.projectIds)">Alle {{projects.count}}
                projecten
            </span>
        </div>
        <div [hidden]="!projectsExpanded">
            <div *ngIf="projects.value.length === 0">Niet beschikbaar</div>
            <div *ngFor="let project of projects.value" class="flex my-1 cursor-pointer"
                 [routerLink]="['/projects/', project.id]"
                 queryParamsHandling="preserve">
                <div class="flex align-center flex-nowrap items-center">
                    <div class="relative w-8 h-8 rounded flex items-center justify-center"
                         [class.bg-primary]="!(project | projectImage)">
                        <img *ngIf="project | projectImage; else defaultImage" alt=""
                             [src]="project | projectImage"
                             class="absolute inset-0 w-full object-cover rounded">
                        <!--
                            We _should_ be using thumbnail images here, but for unknown reasons suffixing ?size=thumbnail
                            no longer works. We use full size images instead, which definitely works, but is obviously
                            not ideal in terms of performance and bandwith used.
                        -->
                        <ng-template #defaultImage>
                                    <span class="absolute left-auto right-auto text-white"
                                          inlineSVG="/assets/icons/icon-project.svg" [setSVGAttributes]="{width: 16, height: 16}">
                                    </span>
                        </ng-template>
                    </div>
                    <span class="ml-4">{{ project.naam }}</span>
                    <app-is-favorite [project]="project"></app-is-favorite>
                </div>
            </div>
        </div>
        <app-collapse collapseTitle="Details">
            <app-information-item label="KVK-nummer" [value]="relation.kvk_nummer"></app-information-item>
            <app-information-item label="KVK-vestigingsnummer" [value]="relation.kvk_vestigingsnummer"></app-information-item>
            <app-information-item label="Is hoofdvestiging" [value]="getIsHoofvestiging(relation.root_name)"></app-information-item>
        </app-collapse>
    </div>
</ng-container>
<ng-template #projectsSpinner>
    <div *ngIf="loadingProjects" class="m-6 flex justify-center">
        <vwui-spinner></vwui-spinner>
    </div>
</ng-template>
<ng-template #empty>
    <div *ngIf="!loading" class="text-base font-medium text-dark truncate">
        <div class="ml-6 py-8 w-5 h-5">
            <span class="bg-white rounded mr-2 cursor-pointer" inlineSVG="/assets/icons/icon-arrow-left.svg"
                  (click)="goBack()"></span>
            Leverancier niet gevonden
        </div>
    </div>
    <div *ngIf="loading" class="m-6 flex justify-center">
        <vwui-spinner></vwui-spinner>
    </div>

</ng-template>

