import {Component, Input} from '@angular/core';
import {SearchVerblijfsobject} from '../../model/search-verblijfsobject';
import {Router} from '@angular/router';
import {Verblijfsobject} from 'api/models/asset/verblijfsobject';

@Component({
    selector: 'app-verblijfsobject-list',
    templateUrl: './verblijfsobject-list.component.html'
})
export class VerblijfsobjectListComponent {
    @Input() items: SearchVerblijfsobject[] = [];
    @Input() addEmptyStateRow = false;

    constructor(public router: Router) {
    }

    onItemClicked(item: Verblijfsobject) {
        const pandRef = item.pandref.replace('NL.IMBAG.Pand.', '');
        this.router.navigate(['/verblijfsobject', item.identificatie], {
            queryParamsHandling: 'merge',
            queryParams: {
                id: pandRef,
                type: 'pand'
            }
        });
    }
}
