import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RadioButtonOption} from 'src/app/model/radio-button-option';

@Component({
    selector: 'app-radio-button-group',
    templateUrl: './radio-button-group.component.html'
})
export class RadioButtonGroupComponent {
    @Input() selectedValue: string;
    @Input() options: RadioButtonOption[];
    @Input() disabled = false;
    @Output() selectValue = new EventEmitter<any>();

    handleClick(option) {
        if (this.disabled) {
            return;
        }
        this.selectValue.emit(option.value);
    }
}
