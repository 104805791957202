<ng-container *ngIf="(metadata$ | async) as metadata; else empty">
    <ng-container *ngIf="!loading && metadata.asset as pand">
        <div class="text-base font-medium text-dark truncate">
            <div class="ml-6 py-8 w-5 h-5">
                <span class="bg-white rounded mr-2 cursor-pointer" inlineSVG="/assets/icons/icon-arrow-left.svg"
                      (click)="this.location.back()"></span>
                {{ getTitle(pand.verblijfsobjecten) }}
            </div>
        </div>

        <div class="p-6 border-b border-neutral-3">
            <span class="font-medium text-base">Publieke informatie</span>

            <app-collapse collapseTitle="Eigenschappen pand">
                <div class="flex align-center items-center mt-1">
                    <span class="flex-half text-neutral-1 font-medium leading-6">Status</span>
                    <span>{{ pand.status }}</span>
                </div>
                <div class="flex align-center items-center mt-1">
                    <span class="flex-half text-neutral-1 font-medium leading-6">Bouwjaar</span>
                    <span>{{ pand.oorspronkelijkBouwjaar }}</span>
                </div>
                <div class="flex align-center items-center mt-1">
                    <span class="flex-half text-neutral-1 font-medium leading-6">Aantal adressen</span>
                    <span>{{ pand.verblijfsobjecten.length }}</span>
                </div>
                <div class="flex align-center items-center mt-1">
                    <span class="flex-half text-neutral-1 font-medium leading-6">BAG-id</span>
                    <span>{{ pand.identificatie.replace('NL.IMBAG.Pand.', '') }}</span>
                </div>
            </app-collapse>
            <app-collapse [collapseTitle]="'Adressen (' + pand.verblijfsobjecten.length + ')'">
                <div *ngFor="let verblijfsobject of pand.verblijfsobjecten"
                     class="flex align-center items-center mt-1 font-medium text-primary cursor-pointer">
                    <a (click)="onVerblijfsObjectClicked(verblijfsobject, pand.identificatie)">
                        {{ verblijfsobject.openbareruimteNaam }} {{ verblijfsobject.huisnummer }}  {{ verblijfsobject.huisletter }} {{ verblijfsobject.huisnummertoevoeging }}
                    </a>
                </div>
            </app-collapse>
        </div>
    </ng-container>
</ng-container>

<ng-template #empty>
    <div *ngIf="!loading" class="text-base font-medium text-dark truncate">
        <div class="ml-6 py-8 w-5 h-5">
            <span class="bg-white rounded mr-2 cursor-pointer" inlineSVG="/assets/icons/icon-arrow-left.svg"
                  (click)="this.location.back()"></span>
            Pand niet gevonden
        </div>
    </div>
</ng-template>

<div *ngIf="loading" class="m-6 flex justify-center">
    <vwui-spinner></vwui-spinner>
</div>
