
<ng-container *ngIf="selectionMode$|async as selectionMode">
    <ng-container *ngIf="selectionCount$ | async as selectionCount">
        <div *ngIf="selectionMode !== 'disabled' && selectionCount > 0" class="z-20 fixed bottom-20 inset-x-8 flex flex-col gap-2">
            <button class="bg-primary text-white px-2 py-1 rounded shadow" (click)="toggleContextMenu()">
                <ng-container *ngIf="selectionMode === 'project'">
                    <ng-container *ngIf="selectionCount === 1">1 project geselecteerd</ng-container>
                    <ng-container *ngIf="selectionCount > 1">{{ selectionCount }} projecten geselecteerd</ng-container>
                </ng-container>
                <ng-container *ngIf="selectionMode === 'company'">
                    <ng-container *ngIf="selectionCount === 1">1 bedrijf geselecteerd</ng-container>
                    <ng-container *ngIf="selectionCount > 1">{{ selectionCount }} bedrijven geselecteerd</ng-container>
                </ng-container>
            </button>

            <div *ngIf="showContextMenu" class="flex flex-col gap-2 bg-white border-primary border rounded py-2">
                <button *ngIf="selectionMode === 'project'" [disabled]="selectingAll" (click)="selectAllProjects()">
                    Selecteer alle projecten
                </button>
                <button *ngIf="selectionMode === 'company'" [disabled]="selectingAll" (click)="selectAllCompanies()">
                    Selecteer alle bedrijven
                </button>
                <button [disabled]="favoritizing" (click)="favoritizeSelection()">
                    Maak selectie favorieten
                </button>
                <button [disabled]="isLoadingModal || selectionMode !== 'project'" (click)="addToCollection(selectionCount)">
                    Voeg toe aan collectie
                </button>
                <button [disabled]="exporting" (click)="exportSelection()">
                    Exporteer selectie
                </button>
                <button (click)="clearSelection()">
                    Alles deselecteren
                </button>
            </div>
        </div>
    </ng-container>
</ng-container>
