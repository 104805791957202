<div class="relative">
    <div class="pb-4 flex items-center">
        <app-export-dropdown *ngIf="!isOnMobile" [total]="totalProjectItems" titleText="Resultaten" table="project">
        </app-export-dropdown>
        <app-pagination-sort *ngIf="gridView$|async;" class="ml-auto" [paginator]="paginator"
                             [sortOptions]="projectSortOptions" [setSortFromQueryParams]="true" [startOption]="'recent'">
        </app-pagination-sort>
        <app-results-view-switch class="mobile:hidden" [class.ml-auto]="(gridView$|async) === false"></app-results-view-switch>
        <app-pagination class="mobile:hidden"  [paginator]="paginator"></app-pagination>
    </div>
    <ng-container *ngIf="paginator.content$|async as projects">
        <ng-container *ngIf="(gridView$|async) || isOnMobile; else listView">
            <app-project-tile-grid
                [projects]="projects"
                [isLoading]="paginator.isLoading"
            ></app-project-tile-grid>
        </ng-container>
        <ng-template #listView>
            <app-project-list [projects]="projects"></app-project-list>
        </ng-template>
    </ng-container>
    <span *ngIf="paginator.isLoading" class="absolute top-24 left-1/2 transform -translate-x-1/2"
          inlineSVG="/assets/icons/loader.svg"></span>
    <app-empty-state *ngIf="paginator.isLoading === false && paginator.isEmpty"
                     style="padding-top: calc(50vh - 248px - 112px)">
    </app-empty-state>
    <div class="mt-4 flex items-end flex-col">
        <app-pagination [paginator]="paginator"></app-pagination>
    </div>
</div>
