<div
    class="bottom-10 left-1/2 absolute transform items-center -translate-y-1/2 flex justify-between bg-white px-6 py-4 rounded shadow gap-10"
    [ngClass]="(pinnedLocationService.windowLocation$|async) === 'center' ? '-translate-x-1/2' : 'translate-x-1/4'">
    <div>
        <ng-container *ngIf="!loading && verblijfsObject$|async as verblijfsObject; else loadingspinner">
            <p class="whitespace-nowrap">{{ verblijfsObject | verblijfsobjectAddress }}, {{ verblijfsObject.woonplaatsNaam }}</p>
            <p (click)="copyToClipboard()"
               class="cursor-pointer text-neutral">{{(pinnedLocationService.coordinates$|async) | lngLatToString}}</p>
        </ng-container>
        <ng-template #loadingspinner>
            <div class="flex justify-center">
                <vwui-spinner></vwui-spinner>
            </div>
        </ng-template>
    </div>
    <div>
        <p (click)="searchNearby()" class="text-primary font-medium cursor-pointer">In de buurt zoeken</p>
    </div>
    <div>
        <button class="mr-auto rounded group-hover:visible bg-white border border-neutral-3 hover:bg-neutral-4 h-8 w-8"
                inlineSVG="/assets/icons/icon-16-more-horizontal.svg"
                (click)="onDropdownOpenClick()"
                cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        </button>
    </div>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="dropdownOpen"
    [cdkConnectedOverlayPositions]="connectedPositions"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    (overlayOutsideClick)="onOutsideOverlayClick()"
    (detach)="onOutsideOverlayClick()">
    <div class="px-2 pt-1 pb-2 mb-1 bg-white shadow-overlay">
        <div class="pt-2 px-4 text-neutral-1 font-medium leading-6">Acties</div>

        <div class="rounded py-2 px-4 text-dark text-sm hover:bg-neutral-4 cursor-pointer">
            <a (click)="emailLocation()">Locatie versturen</a>
        </div>
    </div>
</ng-template>
