// @ts-nocheck
import { massaModelsData } from './gltfmodels.test';
import { featureServices } from './feature-services';
import * as configcat from 'configcat-js';

export const environment = {
    production: true,
    hmr: false,
    azureApiKey: 'pj37PZ3H47u76prChBNUoN9j8nKXhqwZtbYfuf0H0pg',
    arcgisApiKey: 'AAPK2fe9be6fd2d14cb8afae28445dfd9ea9hyX0X5tl_JPhSjT2IS-jaOixbaTvKyKUNXcDx0xzswA8qSc5ISl4kaxsb05rhAsG',
    arcgisBasemapStyleUrl: 'https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles',
    authClientId: 'cf3e2dba-a442-4343-a3ba-809bda27ea20',
    authAuthority: 'https://login.microsoftonline.com/0ef544fb-3d5c-4c60-932e-735bcef00405/',
    authScopes: [],
    azureBaseUrl: 'https://vwsearch.tst.volkerwessels.dev/api',
    trimbleApiBaseUrl: 'https://app21.connect.trimble.com/tc/api/2.0',
    trimbleOAuthBaseUrl: 'https://id.trimble.com',
    trimbleOAuthClientId: 'd51fc498-58c1-4f9c-a8de-05ab95c17680',
    trimbleOAuthRedirectUri: 'https://vwsearch.tst.volkerwessels.dev/trimble/oauth',
    trimbleOAuthScope: 'openid%20VWMapstst',
    searchInstrumentationKey: '9c2e7b52-9986-4def-ad96-0d8bba1d80be',
    searchServiceName: 'bvgo-we-asp-t-3ddp-mi',
    vectorTilesBlobUrl: 'https://bvgowesat3ddpvectortiles.blob.core.windows.net',
    geoserverUrl: 'https://bvgo-we-as-t-3ddp-geoserver.azurewebsites.net/geoserver',
    mapproxyBaseUrl: 'https://vwsearch.tst.volkerwessels.dev/mapproxy',
    massamodels: massaModelsData,
    prsBaseUrl: 'https://prs.tst.volkerwessels.dev/',
    baseUrl: 'https://vwsearch.tst.volkerwessels.dev/',
    detectAdblock: true,
    projectenPortaalBaseUrl: 'https://projectenportaal.tst.volkerwessels.dev/',
    configCatKey: 'configcat-sdk-1/cqTbCOChCEONKYa815sJNg/-_ucUfCwXUSiDQ4aQHdGsQ',
    configCatLogLevel: configcat.LogLevel.Info,
    featureServices
};
