<div class="app-legend" [class.app-legend--collapsed]="currentTabIndex === -1">
    <div class="app-legend__tabs">
        <button class="app-legend__tab" *ngFor="let tab of tabs; index as tabIndex" (click)="onTabClick(tabIndex)"
                [class.app-legend__tab--active]="tabIndex === currentTabIndex" [class.hidden]="!tab.visible">
            <span *ngIf="tab.visible"
                  [inlineSVG]="'/assets/layericons/' + tab.layerIcon + '.svg'"
                  [ngClass]="tab.layerIconClasses">
            </span>
        </button>
    </div>
    <div class="app-legend__content" *ngFor="let tab of tabs; index as tabIndex"
         [class.hidden]="tabIndex !== currentTabIndex">
        <div *ngFor="let group of tab.groups; index as groupIndex; first as isFirst; last as isLast">
            <div class="app-legend__checkbox mb-5 border-neutral-3"
                 [class.pt-4]="!isFirst" [class.mt-4]="!isFirst" [class.border-t]="!isFirst">
                <input [id]="group.label" checked type="checkbox" [style.--color]="'#a6afca'"
                       [style.--b]="group.type === 'static' ? '#a6afca': null"
                       [checked]="group.selectState !== 'none'"
                       [style.--checkbox-scale]="group.selectState === 'none' ? 0: 1"
                       [formControl]="getGroupFormControl(tabIndex, groupIndex)"
                       [tooltip]="group.selectState === 'none' ? 'Alles selecteren' : 'Alles deselecteren'">
                <label [for]="group.label" class="app-legend__label head">
                    <span *ngIf="group.type !== 'static'" class="app-legend__checkbox"
                          [style.--checkbox-scale]="group.selectState === 'none' ? 0: 1"
                          [style.--checkbox-height]="group.selectState === 'some' ? '0.5rem' : '0.25rem'"
                          [inlineSVG]="group.selectState === 'some' ? '/assets/icons/icon-checkbox-line.svg' : '/assets/icons/icon-check.svg'"></span>
                    {{ group.label }}
                </label>
            </div>
            <div class="app-legend__group-items" [style.--rowcount]="ceil(group.items.length / 2)">
                <div class="app-legend__checkbox" *ngFor="let item of group.items">
                    <input [id]="item.label" checked type="checkbox"
                           [style.--color]="item.color ? item.color : ('url(\'/assets/legend/'+item.pattern+'.png\')')"
                           [style.--b]="group.type === 'static' ? item.color : null"
                           [style.--bc]="item.pattern ? '#000' : null"
                           [formControl]="getFormControl(tabIndex, groupIndex, item.value)">
                    <label [for]="item.label" class="app-legend__label parent"
                           [style.--checkbox-height]="'0.25rem'"
                           [style.--stroke]="getCheckboxColor(item.color || '#000')">
                        <span *ngIf="group.type !== 'static'" class="app-legend__checkmark"
                              inlineSVG="/assets/icons/icon-check.svg"></span>
                        {{ item.label }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
