import { Component } from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {SearchPreference} from 'api/models/search-preference';

@Component({
  selector: 'app-delete-saved-filter-modal',
  templateUrl: './delete-saved-filter-modal.component.html'
})
export class DeleteSavedFilterModalComponent {
    loading = false;

    constructor(
        private modalRef: VwuiModalRef,
        public modalConfig: VwuiModalConfig<{filter: SearchPreference}>
    ) {
    }

    close() {
        this.modalRef.close();
    }

    async delete() {
        this.modalRef.close(this.modalConfig.data.filter);
    }
}
