import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {v4 as uuid} from 'uuid';
import {FacetSelectOption} from '../search-filter/search-filter.component';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {debounceTime, map, shareReplay, startWith} from 'rxjs/operators';

@Component({
    selector: 'app-search-filter-select-checkbox',
    templateUrl: './search-filter-select-checkbox.component.html',
    styleUrls: ['./search-filter-select-checkbox.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchFilterSelectCheckboxComponent),
            multi: true
        }
    ]
})
export class SearchFilterSelectCheckboxComponent implements OnInit, ControlValueAccessor {
    @Input() set filterKey(value: string) {
        this.filterControl.setValue(value);
    }
    @Input() set options(value: FacetSelectOption[]) {
        this.optionsSubject.next(value ?? []);
    }
    @Input() set listOpened(value: boolean) {
        this.filterListOpenedSubject.next(value);
    }
    @Output() filterKeyChange = new EventEmitter<string>();
    @Output() listOpenedChange = new EventEmitter<boolean>();
    private subscriptions: Subscription[] = [];
    private onChange: (value: string[]) => void;
    private onTouched: () => void;
    private optionsSubject = new BehaviorSubject<FacetSelectOption[]>([]);
    private enabledOptionsSubject = new BehaviorSubject<string[]>([]);
    private initializeValues = false;

    readonly MINIMUM_OPTIONS = 5; // Amount of options shown when list is collapsed

    filterListOpenedSubject = new BehaviorSubject<boolean>(false);
    filterControl = new FormControl('');
    inputIdPrefix = uuid();
    options$ = combineLatest([
        this.optionsSubject,
        this.enabledOptionsSubject
    ]).pipe(
        map(([options, enabledOptions]) => options.map(it => ({
            ...it,
            enabled: enabledOptions.includes(it.value)
        }))),
        map(it => {
            const list = [...it];
            list.sort((x, y) => (x.enabled === y.enabled) ? 0 : x.enabled ? -1 : 1);
            return list;
        })
    );
    filteredOptions = combineLatest([
        this.options$,
        this.filterControl.valueChanges.pipe(startWith('')),
    ]).pipe(
        map(([options, filter]) => filter.trim() !== ''
            ? options.filter(it => new RegExp(filter, 'gi').test(it.label) || it.enabled)
            : options
        ),
        shareReplay(1)
    );
    visibleOptions$ = combineLatest([
        this.filteredOptions,
        this.filterListOpenedSubject
    ]).pipe(
        map(([options, filterListOpened]) => {
            const totalEnabledOptions = this.enabledOptionsSubject.value.length;
            return filterListOpened ? options : [
                ...(totalEnabledOptions < this.MINIMUM_OPTIONS
                        ? options.slice(0, this.MINIMUM_OPTIONS)
                        : options.filter(it => it.enabled)
                )
            ];
        })
    );
    collapsable$ = combineLatest([
        this.options$,
        this.enabledOptionsSubject
    ]).pipe(debounceTime(50))
        .pipe(map(([options, enabledOptions]) => options.length > this.MINIMUM_OPTIONS && options.length !== enabledOptions.length)
    );

    constructor() {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.enabledOptionsSubject.asObservable().pipe(debounceTime(200)).subscribe(enabledOptions => {
                if (!this.initializeValues && this.onChange) {
                    this.onChange(enabledOptions);
                }
                if (this.onTouched) {
                    this.onTouched();
                }
                this.initializeValues = false;
            }),
            this.filterListOpenedSubject.subscribe(filterListOpened => {
                if (!filterListOpened) {
                    this.filterControl.setValue('');
                }
                if (this.listOpenedChange.observers.length > 0) {
                    this.listOpenedChange.emit(filterListOpened);
                }
            }),
            this.filterControl.valueChanges.subscribe(it => {
                if (this.filterKeyChange.observers.length > 0) {
                    this.filterKeyChange.emit(it);
                }
            })
        );
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    toggleOption(option: FacetSelectOption, e?: Event) {
        e?.stopPropagation();
        const enabledOptions = this.enabledOptionsSubject.value;
        if (enabledOptions.includes(option.value)) {
            this.enabledOptionsSubject.next(enabledOptions.filter(it => it !== option.value));
        } else {
            this.enabledOptionsSubject.next([...enabledOptions, option.value]);
        }
    }

    toggleFilterList() {
        this.filterListOpenedSubject.next(!this.filterListOpenedSubject.value);
    }

    writeValue(value: string[]): void {
        this.initializeValues = true;
        this.enabledOptionsSubject.next(value);
    }
}
