<div class="px-6 mobile:pt-20">
    <div class="pt-8 h-24 mobile:hidden">
        <h1 appTitle="lg" class="m-0">Mijn Collecties</h1>
    </div>
    <div class="flex gap-2 border-t border-b border-neutral-3 py-4 relative
        mobile:fixed mobile:top-0 mobile:inset-x-0 mobile:z-20 mobile:bg-white mobile:px-4">
        <app-collections-search-input
            class="flex-grow"
            placeholder="Collecties doorzoeken"
            [formControl]="queryControl"
            [showBackButton]="true"
            [suggestions]="suggestions$|async"
            (clickedOnSuggestion)="navigateToCollection($event)"
            (submit)="handleSearch($event)"
        ></app-collections-search-input>
    </div>

    <div class="py-4">
        <div class="pb-4 flex items-center">
            <span class="font-medium text-base mr-auto rounded py-1">Mijn Collecties</span>
            <app-results-view-switch class="ml-auto"></app-results-view-switch>
        </div>
        <ng-container *ngIf="collectionsPaginator.content$|async as collections; else loadingSpinner">
            <ng-container *ngIf="gridView$|async; else listView">
                <div class="grid grid-cols-searchGrid gap-4">
                    <app-collection-tile *ngFor="let collection of collections" [collection]="collection"></app-collection-tile>
                    <div *ngIf="collections.length < 2"></div>
                    <div *ngIf="collections.length < 3"></div>
                </div>
            </ng-container>
            <ng-template #listView>
                <app-collection-list [collections]="collections"></app-collection-list>
            </ng-template>
            <ng-container *ngIf="collections.length === 0">
                <div class="text-center flex items-center flex-col mt-16">
                    <span inlineSVG="/assets/icons/box-illustration.svg"></span>
                    <span class="mt-6 font-medium text-lg text-darkBlue_2">
                        {{queryControl.value ? 'Geen resultaten' : 'Geen collecties'}}
                    </span>
                    <span class="mt-4 text-neutral-1 w-1/2">
                        {{
                            queryControl.value
                            ? 'Er zijn geen resultaten gevonden'
                            : 'Er zijn nog geen collecties aangemaakt, selecteer projecten om een collectie aan te maken.'
                        }}
                    </span>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #loadingSpinner>
            <span class="fixed top-96 left-1/2 transform -translate-x-1/2" inlineSVG="/assets/icons/loader.svg"></span>
        </ng-template>
    </div>
</div>
