<table *ngIf="collections.length" class="table-auto w-full border rounded border-neutral-3 border-separate" cellspacing="0">
    <thead>
        <tr class="text-neutral-1 text-left">
            <th class="px-3 py-4">Naam</th>
            <th class="px-3 py-4">Projecten in de collectie</th>
            <th></th>
        </tr>
    </thead>
    <tr *ngFor="let collection of collections"
        class="hover:bg-neutral-4 cursor-pointer group"
        (click)="navigateToCollection(collection)"
    >
        <td class="border-t p-3">{{collection.name}}</td>
        <td class="border-t p-3">
            <app-collection-projects-icons [collection]="collection"></app-collection-projects-icons>
        </td>
        <td class="border-t p-3 text-right">
            <app-collections-tile-context-menu
                [collection]="collection"
                variant="list"
            ></app-collections-tile-context-menu>
        </td>
    </tr>
</table>
