export enum VWConcept {
    None = 'None',
    ClimateReady = 'ClimateReady',
    MorgenWonen = 'MorgenWonen',
    NieuwWonen = 'NieuwWonen',
    Plusrenoveren = 'Plusrenoveren',
    PluswonenAppartementen = 'PluswonenAppartementen',
    PluswonenGrondgebonden = 'PluswonenGrondgebonden',
    ZuiverWonen = 'ZuiverWonen',
    PuurWonen = 'PuurWonen',
    Other = 'Other',
}

export const vwConceptTranslation = {
    None: 'Geen',
    ClimateReady: 'Climate Ready',
    MorgenWonen: 'MorgenWonen',
    NieuwWonen: 'NieuwWonen',
    Plusrenoveren: 'Plusrenoveren',
    PluswonenAppartementen: 'Pluswonen Appartementen',
    PluswonenGrondgebonden: 'Pluswonen Grondgebonden',
    ZuiverWonen: 'ZuiverWonen',
    PuurWonen: 'Puur wonen',
    Other: 'Anders',
};
