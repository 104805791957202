import { SearchFacet } from '../model/search-facet';

type SearchFacetCollection = { [key: string]: SearchFacet[] };

const mergeFacets = (first: SearchFacet[] | undefined, second: SearchFacet[] | undefined): SearchFacet[] => {
    if (first == null || second == null) {
        return (first || second) ?? [];
    }
    const countsByValue: { [key: string]: number[] } = {};
    for (const { count, value } of [...first, ...second]) {
        countsByValue[value] ||= [];
        countsByValue[value].push(count);
    }
    return Object.entries(countsByValue).map(([value, count]) => ({
        value,
        count: count.reduce((sum, current) => current === 0 ? sum : current, 0)
    }));
};

/**
 * Deep merges objects containing search facets.
 * When the same search facet is found in both objects, their counts are summed.
 */
export const mergeFacetCollection = (first: SearchFacetCollection, second: SearchFacetCollection): SearchFacetCollection => {
    const keys = new Set([...Object.keys(first), ...Object.keys(second)]);
    return [...keys].reduce((result, key) => {
        result[key] = mergeFacets(first[key], second[key]);
        return result;
    }, {});
};
