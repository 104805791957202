<vwui-modal-header>
    Filter verwijderen
</vwui-modal-header>
<vwui-modal-body>
    <p>Weet u zeker dat u het filter "{{ modalConfig.data.filter.name }}" wilt verwijderen?</p>
    <div class="flex mt-14">
        <button (click)="close()" class="ml-auto px-6 py-3 text-neutral-1 font-medium">Annuleren</button>
        <button (click)="delete()" class="px-6 py-3 text-white font-medium rounded
            bg-gradient-to-r from-primary to-primary-light">
            Verwijderen
            <span inlineSVG="/assets/icons/spinner.svg" [class.d-none]="!loading"></span>
        </button>
    </div>
</vwui-modal-body>
