<div class="p-6">
    <div class="flex items-center">
        <span class="text-darkBlue_2 text-xl font-medium">Locatie versturen</span>
        <button class="ml-auto text-neutral-1 w-5 h-5" inlineSVG="/assets/icons/icon-24-close.svg"
                (click)="modalRef.close()"></button>
    </div>

    <form [formGroup]="form" (submit)="sendLocation()">
        <div class="mb-4">
            <label for="email-field" class="block font-medium pb-3 mt-2 text-neutral">E-mailadres</label>
            <input id="email-field" type="email" placeholder="E-mailadres" formControlName="email"
                   class="border rounded w-full h-12 px-3 text-darkBlue focus:border-primary">
        </div>
    </form>
    <div class="flex items-center mt-14">
        <button class="ml-auto font-medium text-neutral" (click)="modalRef.close()">Annuleren</button>
        <button class="ml-6 py-3 px-6 text-white rounded bg-gradient-to-r from-primary to-primary-light"
                [disabled]="sendingLocation"
                (click)="sendLocation()"
                [class.opacity-50]="form.invalid">
            Verzenden
            <span inlineSVG="/assets/icons/spinner.svg" [hidden]="!sendingLocation"></span>
        </button>
    </div>
</div>
