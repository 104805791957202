import {SymbolLayer} from 'mapbox-gl';

export const poiSymbol: SymbolLayer = {
    id: 'azure-poi',
    type: 'symbol',
    source: 'azure-poi',
    maxzoom: 18,
    layout: {
        'icon-allow-overlap': true,
        'icon-anchor': 'bottom',
        'icon-image': ['get', 'icon'],
        'icon-size': 0.75
    }
};
