import { GeoJSONSourceRaw, SymbolLayer } from 'mapbox-gl';
import { PointResult } from '../../../model/search-response';
import * as GeoJSON from 'geojson';

export const searchResultTypeIcons = {
    project: 'projects',
    company: 'companies',
    relation: 'companies',
    activeProject: 'active-project',
    activeCompany: 'active-company'
};

export function searchPointsToSourceData(pointResults: PointResult[]): GeoJSON.FeatureCollection {
    return {
        type: 'FeatureCollection',
        features: pointResults.filter(it => it?.geog?.coordinates).map(result => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: result.geog.coordinates
            },
            properties: {
                id: result?.id,
                title: result.title,
                icon: searchResultTypeIcons[result.type],
                searchType: result.type,
                coordinates: result.geog.coordinates
            }
        }))
    };
}

export function getSearchResultsSource(pointResults: PointResult[]): GeoJSONSourceRaw {
    return {
        type: 'geojson',
        data: searchPointsToSourceData(pointResults),
        cluster: true,
        clusterRadius: 50,
        clusterProperties: {
            has_project: ['any', ['==', ['get', 'searchType'], 'project'], 'false'],
            has_company: ['any', ['==', ['get', 'searchType'], 'company'], 'false'],
            has_verblijfsobject: ['any', ['==', ['get', 'searchType'], 'verblijfsobject'], 'false'],
            has_relation: ['any', ['==', ['get', 'searchType'], 'relation'], 'false'],
            is_active_project: ['any', ['==', ['get', 'searchType'], 'activeProject'], 'false'],
            is_active_company: ['any', ['==', ['get', 'searchType'], 'activeCompany'], 'false'],
        }
    } as GeoJSONSourceRaw;
}

export const searchResultsLayer: SymbolLayer = {
    id: 'search-results',
    type: 'symbol',
    source: 'search-results',
    filter: ['!', ['has', 'point_count']],
    layout: {
        'icon-allow-overlap': true,
        'icon-anchor': 'bottom',
        'icon-image': ['get', 'icon'],
        'icon-size': 0.75,
        'text-allow-overlap': true,
        'text-anchor': 'bottom',
        'text-field': ['get', 'title'],
        'text-font': ['Arial Unicode MS Regular'],
        'text-letter-spacing': 0.1,
        'text-offset': [0, -4],
        'text-padding': 0,
        'text-size': 14,
    },
    paint: {
        'text-color': 'rgb(33, 33, 33)',
        'text-halo-color': 'rgb(255, 255, 255)',
        'text-halo-width': 1,
        'text-halo-blur': 0.5,
        'text-opacity': {
            stops: [
                [10.9, 0],
                [11, 1]
            ]
        }
    }
};

export const searchResultClusterLayer: SymbolLayer = {
    id: 'search-result-cluster',
    type: 'symbol',
    source: 'search-results',
    filter: ['has', 'point_count'],
    layout: {
        'icon-allow-overlap': true,
        'icon-anchor': 'bottom',
        'icon-image': ['case',
            ['==', ['get', 'is_active_company'], true], 'mixed-cluster',
            ['==', ['get', 'is_active_project'], true], 'mixed-cluster',
            'cluster'
        ],
        'icon-size': 0.75,
        'text-allow-overlap': true,
        'text-anchor': 'bottom',
        'text-field': '{point_count_abbreviated}',
        'text-font': ['Arial Unicode MS Regular'],
        'text-letter-spacing': 0.1,
        'text-offset': [0, -1.6],
        'text-padding': 0,
        'text-size': 15
    },
    paint: {
        'text-color': 'rgb(255, 255, 255)'
    }
};
