<div class="flex gap-2 flex-wrap overflow-hidden mb-4" #filterGrid [class.mb-4]="(filters$|async)?.length > 0"
     [class.max-h-12]="!filtersExpanded" [class.max-h-screen]="filtersExpanded">
    <div class="group relative flex items-center px-3 py-2 border border-neutral-3 rounded text-m truncate
        cursor-pointer hover:shadow-button"
         *ngFor="let savedFilter of filters$|async" (click)="activateFilter(savedFilter)">
        <div class="text-primary cursor-pointer mr-2"
             inlineSVG="/assets/icons/icon-16-filter.svg">
        </div>
        <div class="relative flex-1 truncate pr-6">
            {{ savedFilter.name }}
            <div class="absolute right-0 inset-y-0 cursor-pointer text-neutral-1 hover:text-dark invisible group-hover:visible mobile:visible bg-white"
                    (click)="openDeleteFilterModal($event, savedFilter)"
                    inlineSVG="/assets/icons/icon-16-bin.svg">
            </div>
        </div>
    </div>
</div>
<button class="h-10 px-3 py-2 border border-neutral-3 hover:bg-neutral-3 rounded text-primary font-medium
        whitespace-nowrap" [class.hidden]="!(filtersExpanded || filterGrid.scrollHeight > filterGrid.clientHeight)"
        (click)="filtersExpanded = !filtersExpanded">
   {{filtersExpanded ? 'Toon minder' : 'Toon meer'}}
</button>
