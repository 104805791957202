import {Component, Input} from '@angular/core';
import {Company} from 'api/models/asset/company';
import {SelectionHelper} from '../is-selected/selection-helper';
import {SelectionService} from '../../services/selection.service';
import {Router} from '@angular/router';
import {SortService} from '../../services/sort.service';

@Component({
    selector: 'app-company-list',
    templateUrl: './company-list.component.html'
})
export class CompanyListComponent extends SelectionHelper {
    @Input() items: Company[] = [];
    selectionMode$ = this.selectionService.selectionMode$;
    constructor(
        protected selectionService: SelectionService,
        protected router: Router,
        public sortService: SortService) {
        super(selectionService, router);
    }

    goToDomain(event: MouseEvent, domain: string) {
        event.stopPropagation();
        window.open('https://' + domain, '_blank');
    }
}
