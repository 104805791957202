<app-modal-header>Veelgestelde vragen</app-modal-header>
<vwui-modal-body>
    <ng-container *ngIf="(data$|async) as data">
        <div class="faq" *ngIf="data && activeQuestion !== null">
            <div *ngFor="let section of data.sections">
                <ng-container *ngFor="let questionAnswerPair of section.questionAnswerPairs;">
                    <div class="border-b border-neutral-2 pt-4" [class.pb-4]="activeQuestion.question !== questionAnswerPair.question" >
                        <div class="cursor-pointer flex items-center font-medium" (click)="activeQuestion = questionAnswerPair">
                            <span class="desktop:ml-4 mr-2 mobile:mr-4 transform" inlineSVG="/assets/icons/navigation-arrow-icon.svg"
                                  [ngClass]="activeQuestion.question === questionAnswerPair.question ? '-rotate-90' : 'rotate-90'"
                            ></span>
                            <span [innerHTML]="questionAnswerPair.question"></span>
                        </div>
                        <div class="ml-11 mobile:ml-8 pt-1 pb-4" [hidden]="activeQuestion.question !== questionAnswerPair.question">
                            <span [innerHTML]="questionAnswerPair.answer"></span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</vwui-modal-body>
<vwui-modal-footer>
    <button class="mobile:hidden" (click)="close()">Sluiten</button>
</vwui-modal-footer>
