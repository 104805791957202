import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';

import {PointResult, RecentFilterResponse, SearchFilterResponse, SearchResponse, SearchSuggestResponse} from '../model/search-response';
import {combineLatest, EMPTY, Observable, of} from 'rxjs';
import {SearchResultType} from '../model/search-result-type';
import {CreateKaartenserviceRequest} from '../model/create-kaartenservice-request';
import {Disclaimer} from '../model/disclaimer';
import {Asset, AssetMetadata} from 'api/models/asset/metadata';
import {Faq} from 'api/models/faq';
import {PageResponse} from 'api/models/page-response';
import {UserFavorites} from 'api/models/user-favorite';
import {CreateSearchPreference, SearchPreference} from 'api/models/search-preference';
import {UserRecentVisit} from 'api/models/user-recent-visit';
import {expand, map, pluck, scan, shareReplay} from 'rxjs/operators';
import {Woonplaats} from 'api/models/woonplaats';
import {ShareLocationRequest} from 'api/models/share-location-request';
import {Collection, CreateCollection, ShareCollection} from 'api/models/collection';
import {Project, ProjectApplication, ProjectRelation, ProjectTeam, ProjectTeamMember} from 'api/models/asset/project';
import {CustomHttpParamEncoder} from '../utils/custom-http-param-encoder';
import {Glb} from 'api/models/glb';
import {SearchProject} from '../model/search-project';
import {SearchCompany} from '../model/search-company';
import KvwNumbers from 'api/models/kvw-numbers';
import {cleanObject} from '../utils/clean-object';
import KeyValueText from 'api/models/key-value-text';
import {ProjectBuildingClassification} from 'api/models/asset/project-building-classification';
import {Pand} from 'api/models/asset/pand';
import {Company} from 'api/models/asset/company';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    allProjects$ = this.fetchProjects().pipe(
        expand(([response, page]) => response.value.length ? this.fetchProjects(page + 1) : EMPTY),
        map(([response, page]) => response),
        scan((acc, response) => ({...response, value: [...acc.value, ...response.value]})),
        shareReplay(1)
    );
    allCompanies$ = this.fetchCompanies().pipe(
        expand(([response, page]) => response.value.length ? this.fetchCompanies(page + 1) : EMPTY),
        map(([response, page]) => response),
        scan((acc, response) => ({...response, value: [...acc.value, ...response.value]})),
        shareReplay(1)
    );

    allCompaniesAndProjectsPoints$ = combineLatest([
        this.allProjects$,
        this.allCompanies$
    ]).pipe(
        map(([projects, companies]) => [
            ...companies.value.map((item: SearchCompany) => ({
                ...item, title: item.naam, id: item.kvw_nummer
            } as PointResult)),
            ...projects.value.map((item: SearchProject) => ({
                ...item, title: item.naam, id: item.id
            } as PointResult))])
    );

    kvwNumbers$ = this.getKvwNumbers().pipe(shareReplay(1));

    constructor(private httpClient: HttpClient) {
    }

    getKvwNumbers(): Observable<KvwNumbers> {
        return this.httpClient.get<KvwNumbers>(`${environment.azureBaseUrl}/kvw-numbers`);
    }

    getMyCompanies(): Observable<Company[]> {
        return this.httpClient.get<Company[]>(`${environment.azureBaseUrl}/kvw-numbers-me`);
    }

    getMetadata<AssetType extends Asset>(type: AssetType['type'], id: string) {
        return this.httpClient.get<AssetMetadata<AssetType>>(`${environment.azureBaseUrl}/asset/${id}/metadata`, {
            params: {type}
        });
    }

    suggest(search: string, type: SearchResultType) {
        const params: { [key: string]: any } = {search};
        if (type) {
            params.type = type;
        }

        return this.httpClient.get<SearchSuggestResponse>(
            `${environment.azureBaseUrl}/search/suggest`, {params}
        );
    }

    search(search: string, center: string, favorite: boolean): Observable<SearchResponse> {
        const params: { [key: string]: any } = {search};
        if (center) {
            params.center = center;
        }
        if (favorite) {
            params.favorite = favorite;
        }

        return this.httpClient.get<SearchResponse>(
            `${environment.azureBaseUrl}/search`, {params}
        );
    }

    getRecentResults<T = any>(
        type: SearchResultType,
        favorite: boolean,
        amount: number
    ) {
        return this.httpClient.get<RecentFilterResponse<T>>(
            `${environment.azureBaseUrl}/search/filter`,
            {
                params: new HttpParams({
                    fromObject: {
                        type,
                        size: amount.toString(),
                        ...(favorite && {favorite: 'true'})
                    }
                })
            }
        );
    }

    filter<T extends { type: SearchResultType }>(
        type: SearchResultType,
        center: string | null,
        search: string,
        favorite: boolean,
        sort: string,
        page: number,
        pageSize: number,
        filter: { [p: string]: string[] }) {

        return this.httpClient.get<SearchFilterResponse<T>>(
            `${environment.azureBaseUrl}/search/filter`,
            {
                params: new HttpParams({
                    encoder: new CustomHttpParamEncoder(),
                    fromObject: {
                        search: search || '',
                        type,
                        sort: sort || '',
                        skip: (page * pageSize).toString(),
                        size: pageSize.toString(),
                        ...filter,
                        ...(favorite && {favorite: 'true'}),
                        ...(center !== null && {center})
                    }
                })
            }
        ).pipe(
            map(response => {
                response.value.forEach(item => item.type = type);
                return response;
            })
        );
    }

    export(
        type: string,
        fileType: string,
        search: string,
        favorite: boolean,
        sort: string,
        filter: { [key: string]: string[] },
        exportParameters?: string[]
    ): Observable<Blob> {
        return this.httpClient.get(`${environment.azureBaseUrl}/export`, {
            params: cleanObject({
                fileType,
                search: search || '',
                favorite: `${favorite || ''}`,
                type,
                sort: sort || '',
                ...filter,
                exportParameters
            }),
            responseType: 'blob'
        });
    }

    postKaartenserviceRequest(request: CreateKaartenserviceRequest) {
        return this.httpClient.post(`${environment.azureBaseUrl}/kaartenservice/request`, request);
    }

    getDisclaimer() {
        return this.httpClient.get<Disclaimer>(`${environment.azureBaseUrl}/disclaimer`);
    }

    getFavorites() {
        return this.httpClient.get<UserFavorites>(`${environment.azureBaseUrl}/user-favorite`);
    }

    postFavorites(favorites: UserFavorites) {
        return this.httpClient.post<void>(`${environment.azureBaseUrl}/user-favorite`, favorites, {
            observe: 'response'
        });
    }

    getSearchPreferences(type: SearchResultType) {
        return this.httpClient.get<SearchPreference[]>(`${environment.azureBaseUrl}/search-preferences`, {
            params: {table: type || '%'}
        });
    }

    postSearchPreference(searchPreference: CreateSearchPreference) {
        return this.httpClient.post<any>(`${environment.azureBaseUrl}/search-preferences`, searchPreference);
    }

    deleteSearchPreference(id: number) {
        return this.httpClient.delete<void>(`${environment.azureBaseUrl}/search-preferences/${id}`);
    }

    getFaq() {
        return this.httpClient.get<Faq>(`${environment.azureBaseUrl}/faq`);
    }

    getPandWithPolygon(identificatie: string): Observable<Pand> {
        return this.httpClient.get<Pand>(`${environment.azureBaseUrl}/pand/${identificatie}`);
    }

    getImportMetadata(layerKey: string): Observable<string> {
        return this.httpClient.get<KeyValueText>(`${environment.azureBaseUrl}/import-metadata`).pipe(
            map(importMetaData => {
                const date = importMetaData[layerKey + '-import-date'];
                return date ? `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}` : '-';
            }));
    }

    getWoonplaats(id: number | string): Observable<Woonplaats> {
        return this.httpClient.get<Woonplaats>(`${environment.azureBaseUrl}/woonplaats/${id}`);
    }

    postShareLocation(shareLocationRequest: ShareLocationRequest) {
        return this.httpClient.post<ShareLocationRequest>(`${environment.azureBaseUrl}/share-location`, shareLocationRequest);
    }

    getUserRecentVisits(type: SearchResultType) {
        const params: any = {};
        if (type) {
            params.type = type;
        }

        return this.httpClient.get<UserRecentVisit[]>(`${environment.azureBaseUrl}/user-recent-visits`, {params});
    }

    getCollections(page: number, size: number = 10): Observable<PageResponse<Collection>> {
        return this.httpClient.get<PageResponse<Collection>>(`${environment.azureBaseUrl}/collections`, {
            params: {page: page.toString(), size: size.toString()}
        });
    }

    getCollection(id: number | string): Observable<Collection> {
        return this.httpClient.get<Collection>(`${environment.azureBaseUrl}/collection/${id}`);
    }

    getCollectionsBySearch(page: number, searchTerm: string, size: number = 10): Observable<PageResponse<Collection>> {
        return this.httpClient.get<PageResponse<Collection>>(`${environment.azureBaseUrl}/collections-byName`, {
            params: {page: page.toString(), size: size.toString(), name: searchTerm}
        });
    }

    getCollectionsBySuggestions(searchTerm: string): Observable<Collection[]> {
        return this.httpClient.get<Collection[]>(`${environment.azureBaseUrl}/collections-suggestions`, {
            params: {name: searchTerm}
        });
    }

    postCollection(collection: CreateCollection) {
        return this.httpClient.post<Collection>(`${environment.azureBaseUrl}/collection`, collection);
    }

    deleteCollection(collection: Collection) {
        return this.httpClient.delete<void>(`${environment.azureBaseUrl}/collection/` + collection.id);
    }

    postCollectionShareEmail(shareCollection: ShareCollection) {
        return this.httpClient.post<Collection>(`${environment.azureBaseUrl}/collection/share`, shareCollection);
    }

    getProjects(projectIds: string[]): Observable<Project[]> {
        return this.filter<Project>(
            'project', '', '',
            false, '', 0, projectIds.length, {id: projectIds}
        ).pipe(
            pluck('value')
        );
    }

    getProjectTeam(projectId: number | string): Observable<ProjectTeam> {
        return this.httpClient.get<ProjectTeam>(`${environment.azureBaseUrl}/projects/${projectId}/team`);
    }

    getProjectRelations(projectId: string) {
        return this.httpClient.get<ProjectRelation[]>(`${environment.azureBaseUrl}/projects/${projectId}/relations`);
    }

    getTeamMemberProjects(upn: string): Observable<ProjectTeamMember[]> {
        return this.httpClient.get<ProjectTeamMember[]>(`${environment.azureBaseUrl}/project-team-member/${upn}`);
    }

    getProjectGlb(projectId: number | string): Observable<Glb> {

        return this.httpClient.get<Glb>(`${environment.azureBaseUrl}/project-glbs/${projectId}`);
    }

    getProjectApplications(projectId: number): Observable<ProjectApplication[]> {
        return this.httpClient.get<ProjectApplication[]>(`${environment.azureBaseUrl}/projects/${projectId}/applications`);
    }

    getProjectTrimbleApplications(projectId: number): Observable<ProjectApplication[]> {
        return this.httpClient.get<ProjectApplication[]>(`${environment.azureBaseUrl}/projects/${projectId}/trimble-applications`);
    }

    getBuildingProjectClassification(projectId: number | string): Observable<ProjectBuildingClassification> {
        return this.httpClient.get<ProjectBuildingClassification>(
            `${environment.azureBaseUrl}/projects/${projectId}/building-classification`);
    }

    private fetchProjects(page = 0) {
        return combineLatest([
            this.filter<SearchProject>('project', '', '', false, '', page, 1000, {}),
            of(page)
        ]);
    }

    private fetchCompanies(page = 0) {
        return combineLatest([
            this.filter<SearchCompany>('company', '', '', false, '', page, 1000, {}),
            of(page)
        ]);
    }
}
