<button #trigger="cdkOverlayOrigin"
        (click)="onClick($event)" cdkOverlayOrigin
        class="mr-auto rounded group-hover:visible bg-white border border-neutral-3 hover:bg-neutral-4 h-8 w-8"
        inlineSVG="/assets/icons/icon-16-more-horizontal.svg">
</button>

<ng-template
    (detach)="onOutsideOverlayClick()"
    (overlayOutsideClick)="onOutsideOverlayClick()"
    [cdkConnectedOverlayOpen]="dropdownOpen"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayPositions]="connectedPositions"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    cdkConnectedOverlay>
    <div class="bg-white border-neutral-3 border rounded z-10 mt-1">
        <div class="px-2 py-4">
            <div class="text-xs mb-2 ml-4 text-neutral-1">
                Acties
            </div>
            <div>
                <app-is-favorite [project]="project">
                    <div (click)="onAddFavorite($event, project)"
                         class="leading-6 text-dark py-2 px-4 cursor-pointer hover:bg-neutral-4 rounded"
                         starOpen>
                        <span class="mr-2" inlineSVG="/assets/icons/icon-16-star.svg"></span>
                        Toevoegen aan favorieten
                    </div>
                    <div (click)="onRemoveFavorite($event, project)"
                         class="leading-6 text-dark py-2 px-4 cursor-pointer hover:bg-neutral-4 rounded"
                         starFilled>
                        <span class="mr-2" inlineSVG="/assets/icons/icon-16-star-filled.svg"></span>
                        Verwijderen uit favorieten
                    </div>
                </app-is-favorite>
            </div>
            <div *ngIf="project && project.prs" (click)="addToCollection($event, project)"
                 class="leading-6 text-dark py-2 px-4 cursor-pointer hover:bg-neutral-4 rounded mobile:hidden">
                <span class="mr-2" inlineSVG="/assets/icons/icon-16-collection.svg"></span>
                Toevoegen aan collectie
            </div>
            <div (click)="emailLocation($event, project)"
                 class="leading-6 text-dark py-2 px-4 cursor-pointer hover:bg-neutral-4 rounded">
                Locatie versturen
            </div>
        </div>
    </div>
</ng-template>
