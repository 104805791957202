import {projectTypeTranslation} from 'api/models/asset/project';
import {vwConceptTranslation} from 'api/models/asset/vw-concept';
import {sustainabilityTranslation} from 'api/models/asset/sustainability';
import KvwNumbers from 'api/models/kvw-numbers';

export function facetOptionLabel(key: string, value: string, kvwNumbers: KvwNumbers): string {
    switch (key) {
        case 'project_type':
            return projectTypeTranslation[value] ?? value;
        case 'vwconcept':
            return vwConceptTranslation[value] ?? value;
        case 'duurzaamheidsclassificatie':
            return sustainabilityTranslation[value] ?? value;
        case 'hasPrs':
            return 'Project sheet aanwezig';
        case 'hasMca':
            return 'MCA sheet aanwezig';
        case 'kvw_nummer':
            return kvwNumbers[value] ?? value;
        default:
            return value;
    }
}

export function facetGroup(key: string) {
    switch (key) {
        case 'building_type':
        case 'client_name':
        case 'company_name':
        case 'kvw_nummer':
        case 'woonplaatsNaam':
        case 'gebruiksdoel':
        case 'project_type':
        case 'project_status':
        case 'cluster_regionaam':
        case 'vwconcept':
        case 'gebouwclassificatie':
        case 'gebouwsubclassificatie':
        case 'duurzaamheidsclassificatie':
        case 'startdatum':
        case 'einddatum':
            return 'Algemeen';
        case 'prs_links_type':
            return 'Documenten';
        case 'prs_attributes_urbanDensity':
        case 'prs_attributes_energyLabel':
        case 'prs_attributes_riskManagement':
            return 'Kenmerken';
        case 'prs_contractAttributes_contractScope':
        case 'prs_contractAttributes_implementation':
        case 'prs_contractAttributes_buyerParticipation':
        case 'prs_contractAttributes_realizationPosition':
        case 'prs_contractAttributes_contractPrecondition':
        case 'prs_contractAttributes_methodOfAcquisition':
        case 'prs_contractAttributes_contractPrice':
        case 'prs_contractAttributes_additionalWork':
            return 'Contract';
        case 'hasPrs':
        case 'hasMca':
            return 'Top';
        default:
            return 'Algemeen';
    }
}

export function facetLabel(key: string) {
    switch (key) {
        case 'building_type':
            return 'Gebouwtype';
        case 'client_name':
            return 'Opdrachtgever';
        case 'company_name':
            return 'Onderneming';
        case 'kvw_nummer':
            return 'Onderneming';
        case 'woonplaatsNaam':
            return 'Woonplaats';
        case 'gebruiksdoel':
            return 'Gebruiksdoel';
        case 'project_status':
            return 'Status';
        case 'cluster_regionaam':
            return 'Regio';
        case 'operationele_projectstatus':
            return 'Operationele projectstatus';
        case 'vwconcept':
            return 'VolkerWessels Concept';
        case 'duurzaamheidsclassificatie':
            return 'Duurzaamheid classificatie';
        case 'gebouwclassificatie':
            return 'Gebruiksfunctie';
        case 'gebouwsubclassificatie':
            return 'Sub-Gebruiksfunctie';
        case 'project_type':
            return 'Werksoort';
        case 'hasPrs':
            return 'Project referentie sheet';
        case 'startdatum':
            return 'Start bouw';
        case 'einddatum':
            return 'Opleverdatum';
        case 'prs_attributes_surfaceBVO':
            return 'Oppervlakte BVO';
        case 'prs_attributes_surfaceVVO':
            return 'Oppervlakte VVO';
        case 'prs_attributes_surfaceFacade':
            return 'Oppervlakte gevel';
        case 'prs_attributes_locationSize':
            return 'Locatie omvang';
        case 'prs_attributes_buildingLayersAboveGround':
            return 'Aantal bouwlagen bovengronds';
        case 'prs_attributes_buildingLayersUnderGround':
            return 'Aantal bouwlagen ondergronds';
        case 'prs_attributes_demolitionSize':
            return 'Omvang sloop';
        case 'prs_attributes_remediateSize':
            return 'Omvang sanering';
        case 'prs_attributes_readyForConstructionSize':
            return 'Omvang bouwrijp maken';
        case 'prs_attributes_readyToLiveSize':
            return 'Omvang woonrijp maken';
        case 'prs_attributes_maintanceContractYears':
            return 'Onderhoudscontract aantal jaar';
        case 'prs_attributes_urbanDensity':
            return 'Stedelijkheid';
        case 'prs_attributes_energyLabel':
            return 'Energielabel';
        case 'prs_attributes_riskManagement':
            return 'Initiële Quickscan-classificatie';
        case 'prs_contractAttributes_contractScope':
            return 'Contractscope / aard van het werk';
        case 'prs_contractAttributes_implementation':
            return 'Vorm van de realisatie';
        case 'prs_contractAttributes_buyerParticipation':
            return 'Kopersparticipatie';
        case 'prs_contractAttributes_realizationPosition':
            return 'Positie binnen de realisatie';
        case 'prs_contractAttributes_contractPrecondition':
            return 'Randvoorwaarden contractvorm';
        case 'prs_contractAttributes_methodOfAcquisition':
            return 'Wijze van acquisitie';
        case 'prs_contractAttributes_contractPrice':
            return 'Aanneemsom';
        case 'prs_contractAttributes_additionalWork':
            return 'Meer- en minderwerk';
        default:
            return key.charAt(0).toUpperCase() + key.slice(1);
    }
}
