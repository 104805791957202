import {Component, OnDestroy, OnInit} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {SelectionService} from '../../services/selection.service';
import {CollectionsService} from '../../services/collections.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Project} from 'api/models/asset/project';
import {filter, withLatestFrom} from 'rxjs/operators';
import {ApiService} from '../../services/api.service';
import {InfiniteScrollPaginator} from '../../utils/infinite-scroll-paginator';
import {Collection, CreateCollection} from 'api/models/collection';
import {SaveStates} from '../../model/save-states';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
    selector: 'app-collections-modal',
    templateUrl: './collections-modal.component.html'
})
export class CollectionsModalComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    projectsWithoutPrs$ = new BehaviorSubject<Project[]>(undefined);
    saving$ = new BehaviorSubject<void>(null);
    modalState$ = new BehaviorSubject<'incompatibleProjects' | 'compatibleProjects'>(undefined);
    compatibleProjects: Project[] = [];
    compatibleProjectsCount = 0;
    restrictedProjectsCount$ = new BehaviorSubject<number>(0);
    projectsCount = 0;
    collections = new InfiniteScrollPaginator(() => this.apiService.getCollections(0));
    selectedCollections: Collection[] = [];
    saveState$ = new BehaviorSubject<SaveStates>(SaveStates.INITIAL);
    newCollectionForm: UntypedFormGroup = new UntypedFormGroup({
        name: new UntypedFormControl(this.modalConfig.data.newCollectionName,
            [Validators.minLength(1),
                Validators.maxLength(255),
                Validators.required])
    });
    pendingSubmit = false;

    constructor(public vwuiModalRef: VwuiModalRef,
                public modalConfig: VwuiModalConfig<{ newCollectionName: string }>,
                public router: Router,
                protected selectionService: SelectionService,
                protected collectionsService: CollectionsService,
                protected apiService: ApiService
    ) {
    }

    ngOnInit(): void {
        this.collectionsService.checkSelectedProjects();
        this.subscriptions.push(this.collectionsService.projectsWithoutPrs$.pipe(
            filter(incompatibleProjects => incompatibleProjects !== undefined)).subscribe
            ((incompatibleProjects) => {
                this.modalState$.next(incompatibleProjects.length === 0 ? 'compatibleProjects' : 'incompatibleProjects');
                this.projectsWithoutPrs$.next(incompatibleProjects);
                this.compatibleProjectsCount = (this.selectionService.getSelectionIds().length - incompatibleProjects.length);
            }),
            this.collectionsService.compatibleProjects$.pipe(
                filter(compatibleProjects => compatibleProjects !== undefined)).subscribe
            (compatibleProjects => {
                this.compatibleProjects = compatibleProjects;
            }),
            this.collectionsService.projectCount$.pipe(
                withLatestFrom(this.collectionsService.sharedProjectsCount$)).subscribe((
                [projectsCount, sharedProjectsCount]) => {
                this.projectsCount = projectsCount;
                this.restrictedProjectsCount$.next(sharedProjectsCount - projectsCount);

            }),
            this.saving$.pipe(withLatestFrom(this.saveState$),
                filter(([_, saveState]) => this.selectedCollections.length > 0 && saveState === SaveStates.INITIAL)).subscribe(
                () => {
                    this.saveState$.next(SaveStates.SAVING);
                }));
    }

    createCollection() {
        try {
            this.newCollectionForm.markAllAsTouched();
            if (this.newCollectionForm && !this.newCollectionForm.valid && !this.pendingSubmit) {
                return;
            }
            this.pendingSubmit = true;
            const collection: CreateCollection = {
                ...this.newCollectionForm.value,
                items: this.compatibleProjects.map(project => project.id)
            };
            this.apiService.postCollection(collection).toPromise().then(() => {
                this.collections.refresh();
            });
        } catch (e) {
            console.error('Create Collection failed', e.message);
        } finally {
            this.pendingSubmit = false;
            this.newCollectionForm.reset();
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    toggleSelection(collection: Collection, selected: boolean) {
        if (selected) {
            this.selectCollection(collection);
        } else {
            this.unselectCollection(collection);
        }
    }

    selectCollection(collection: Collection) {
        collection.selected = true;
        this.selectedCollections.push(collection);
    }

    unselectCollection(collection: Collection) {
        collection.selected = false;
        const index = this.selectedCollections.findIndex(it => it.id === collection.id);
        if (index !== -1) {
            this.selectedCollections.splice(index, 1);
        }
    }

    async addToCollections(): Promise<void> {
        return Promise.all((this.selectedCollections.map(collection => {
                collection.items = collection.items.concat(this.compatibleProjects.map(project => project.id).filter((item) =>
                    collection.items.indexOf(item) < 0));
                return this.apiService.postCollection(collection).toPromise();
            }))
        ).then(() => {
            this.vwuiModalRef.close();
            this.selectionService.clearSelection();
            this.collectionsService.refreshCollections$.next();
            this.router.navigate(['collections']);
        }).catch(e => {
            console.error('Saving could not happen', e.message);
            this.saveState$.next(SaveStates.INITIAL);
        });
    }

    continueToCollection() {
        this.selectionService.removeSelectedIds(this.projectsWithoutPrs$.value.map(project => project.id));
        this.collectionsService.checkSelectedProjects();
        this.modalState$.next('compatibleProjects');
    }
}
