<table class="w-full rounded-table-neutral-3 text-left">
    <tr class="text-neutral-1 whitespace-nowrap">
        <th class="py-3 px-6"><button (click)="sortService.sortBy('name', false)"
                                      class="py-1 px-2 -ml-2 font-medium hover:bg-neutral-4 inline rounded">Leverancier
            <span *ngIf="(sortService.currentState$|async) === 'name' || (sortService.currentState$|async) === '-name'"
                  class="inline-block transform"
                  [class.rotate-180]="(sortService.currentSort$|async) === 2"
                  [class.invisible]="(sortService.currentSort$|async) ===  0"
                  [class.visible]="(sortService.currentState$|async) === 'name'"
                  inlineSVG="/assets/icons/icon-arrow-up.svg"></span></button></th>
        <th class="py-3 px-6">
            <button (click)="sortService.sortBy('branche_city', false)"
                    class="py-1 px-2 -ml-4 font-medium hover:bg-neutral-4 rounded"
            >Plaats
                <span *ngIf="(sortService.currentState$|async) === 'branche_city' || (sortService.currentState$|async) === '-branche_city'"
                      class="inline-block transform"
                      [class.rotate-180]="(sortService.currentSort$|async) ===  2"
                      [class.invisible]="(sortService.currentSort$|async) ===  0"
                      inlineSVG="/assets/icons/icon-arrow-up.svg"></span></button>
        </th>
        <th class="py-3 px-6">
            <button (click)="sortService.sortBy('branche_city', false)" class="py-1 px-2 -ml-4 font-medium hover:bg-neutral-4 rounded"
            >Telefoonnummer
                <span *ngIf="(sortService.currentState$|async) === 'main_phone' || (sortService.currentState$|async) === '-main_phone'"
                      class="inline-block transform"
                      [class.rotate-180]="(sortService.currentSort$|async) === 2"
                      [class.invisible]="(sortService.currentSort$|async) ===  0"
                      inlineSVG="/assets/icons/icon-arrow-up.svg">
                </span></button>
        </th>
    </tr>

    <tr *ngIf="addEmptyStateRow && (!items || items.length === 0)">
        <td class="py-3 px-6 text-center">
            Geen leveranciers gevonden.
        </td>
    </tr>
    <tr *ngFor="let item of items" class="hover:bg-neutral-4 cursor-pointer"
        (click)="onItemClicked(item)">
        <td class="py-3 px-6">
            <div class="flex align-center flex-nowrap items-center">
                <div class="relative w-8 h-8 rounded flex items-center justify-center bg-primary">
                    <span class="absolute left-auto right-auto text-white"
                          inlineSVG="/assets/icons/icon-company.svg" [setSVGAttributes]="{width: 16, height: 16}">
                    </span>
                </div>
                <span class="ml-4">
                    {{ item.name }}
                </span>
            </div>
        </td>
        <td class="px-4">{{ item.branche_city }}</td>
        <td class="px-4">{{ item.main_phone }}</td>
    </tr>
</table>
