export enum Sustainability {
    None = 'None',
    Breeam = 'Breeam',
    NOM = 'NOM',
    EPV = 'EPV',
    EPC0 = 'EPC0',
    Energienotaloos = 'Energienotaloos',
    Well = 'Well',
    Gasloos = 'Gasloos',
    GPR = 'GPR',
    Other = 'Other',
}

export const sustainabilityTranslation = {
    None: 'Geen',
    Breeam: 'Breeam',
    NOM: 'NOM',
    EPV: 'EPV',
    EPC0: 'EPC=0,0',
    Energienotaloos: 'Energienotaloos',
    Well: 'Well',
    Gasloos: 'Gasloos',
    GPR: 'GPR',
    Other: 'Anders',
};
