<div class="relative">
    <div
        class="relative flex gap-4 mobile:gap-1 items-center px-4 py-3 border border-neutral-3 h-12 rounded transition-all
            overflow-hidden focus-within:border-primary"
        [class.pl-12]="currentValue || (filterActive$|async)" (click)="query.focus()">
        <button type="button" class="absolute text-dark left-4 top-1/2 transition-all transform -translate-y-1/2"
                [class.-translate-x-16]="!(currentValue || (filterActive$|async))"
                (click)="clearAndCommit()"
                inlineSVG="/assets/icons/icon-16-arrow-left.svg">
        </button>
        <div class="flex-grow">
            <input #query class="w-full mobile:h-9 text-m focus-visible:shadow-none" type="text" [placeholder]="placeholder"
                   [formControl]="inputControl" (keydown.enter)="commitValue()"
                   (focus)="showSuggestions = true" (blur)="showSuggestions = false">
        </div>
        <div *ngIf="loading" class="text-primary">
            <span inlineSVG="/assets/icons/spinner.svg" class="w-4 ml-auto"></span>
        </div>
        <button type="button" class="text-primary"
                *ngIf="(!isOnMobile && inputControl.value) || (isOnMobile && inputControl.value && !loading)"
                (click)="clear()"
                inlineSVG="/assets/icons/icon-24-close.svg"
                [setSVGAttributes]="{width: '20', height: '20'}">
        </button>
        <button type="button" [ngClass]="inputControl.value ? 'text-primary' : 'text-neutral-1'"
                *ngIf="!isOnMobile || (isOnMobile && !inputControl.value && !loading)"
                (click)="commitValue()"
                inlineSVG="/assets/icons/icon-24-search.svg"
                [setSVGAttributes]="{width: '20', height: '20'}">
        </button>
    </div>

    <div *ngIf="showSuggestions"
         class="absolute top-full inset-x-0 mt-2 pt-2 pb-4 px-4 bg-white border border-neutral-3 rounded z-10 shadow-md"
         (mousedown)="$event.preventDefault()">

        <ng-container *ngIf="inputControl.value && inputControl.value.trim() !== '' && suggestions">
            <div *ngIf="!loading && suggestions.suggestions.length === 0 && suggestions.autocomplete.length === 0" class="mt-2">
                Geen resultaten gevonden.
            </div>

            <ng-container *ngIf="suggestions.suggestions">
                <app-search-suggestion
                    *ngFor="let suggestion of suggestions.suggestions"
                    (click)="suggestionClick(suggestion)"
                    [searchTerm]="inputControl.value"
                    [icon]="suggestion.type | assetTypeIcon"
                    [label]="suggestion['@search.text']"
                    [identification]="suggestion.id"
                    [favorite]="suggestion.favorite">
                </app-search-suggestion>
            </ng-container>

            <ng-container *ngIf="suggestions.autocomplete as suggestions">
                <h2 class="my-2 ml-1" *ngIf="suggestions.length > 0">Suggesties</h2>
                <app-search-suggestion
                    *ngFor="let suggestion of suggestions"
                    (click)="search(suggestion.queryPlusText)"
                    icon="/assets/icons/icon-24-search.svg"
                    [searchTerm]="inputControl.value"
                    [label]="suggestion.queryPlusText"
                    [identification]="null"
                    [favorite]="false"
                ></app-search-suggestion>
            </ng-container>

        </ng-container>

        <ng-container *ngIf="recentVisits.length > 0 && !inputControl.value" (mousedown)="$event.preventDefault()">
            <h2 class="my-2 ml-1">Recent bekeken</h2>
            <app-search-suggestion
                *ngFor="let recentVisit of recentVisits"
                [routerLink]="[recentVisit.type | assetTypeRoute, recentVisit.identification]"
                [searchTerm]="inputControl.value"
                [icon]="recentVisit.type | assetTypeIcon"
                [label]="recentVisit.name"
                [identification]="recentVisit.identification"
                [favorite]="recentVisit.favorite"
            ></app-search-suggestion>
        </ng-container>
    </div>
</div>
