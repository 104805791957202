import {BehaviorSubject, Observable} from 'rxjs';
import {concatMap, map, retry, scan, switchMap, takeWhile, tap} from 'rxjs/operators';
import {PageResponse} from 'api/models/page-response';

export class InfiniteScrollPaginator<T> {
    private loadMoreSubject = new BehaviorSubject<void>(null);
    private refreshSubject = new BehaviorSubject<void>(null);
    hasMore$ =  new BehaviorSubject<boolean>(true);
    loading$ =  new BehaviorSubject<boolean>(true);
    content$: Observable<T[]> = this.refreshSubject.pipe(
        switchMap(() => this.loadMoreSubject.pipe(
            scan<void, number>((previousPage) => {
                this.loading$.next(true);
                // Calculate page number by sum of previous plus current pagenumber
                // If previousPage is -1 then start with page 0
                return previousPage === -1 ? 0 : previousPage + 1;
            }, -1),
            concatMap<number, Observable<PageResponse<T>>>((page) => this.loadFunction(page)),
            retry(3), // Retry retrieving at most 3 times
            takeWhile<PageResponse<T>>(pageResponse => {
                // Take data while last page hasn't been reached yet
                this.hasMore$.next(pageResponse.currentPage < (pageResponse.totalPages - 1));
                return pageResponse.currentPage === 0 || pageResponse.currentPage < pageResponse.totalPages;
            }),
            // Only return items, not the other paginated properties
            map<PageResponse<T>, T[]>((it) => it.content),
            scan<T[], T[]>((acc, items) => [...acc, ...items], []),
            tap(() => this.loading$.next(false))
        ))
    );

    constructor(private loadFunction: (page: number) => Observable<PageResponse<T>>) {
    }

    refresh() {
        this.refreshSubject.next();
    }

    loadMore() {
        this.loadMoreSubject.next();
    }
}
