import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-ui-page-content',
    templateUrl: './ui-page-content.component.html',
    styleUrls: ['./ui-page-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiPageContentComponent {
}
