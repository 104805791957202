import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {map} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {getRoleName, Role} from 'api/models/role';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {
    constructor(private authService: AuthService,
                private router: Router,
                private toastrService: ToastrService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if(!route.data.role) {
            throw Error('role must be set in route data when using RoleGuard');
        }
        return this.authService.hasRole$(route.data.role).pipe(
            map(hasRole => hasRole ? true : this.accessDenied(route.data.fallbackUrl, route.data.role))
        );
    }

    accessDenied(fallbackUrl, role: Role) {
        this.toastrService.info(`Je hebt de benodigde rol '${getRoleName(role)}' niet`);
        return this.router.createUrlTree(fallbackUrl || ['/']);
    }
}
