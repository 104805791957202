<div
    class="shadow cursor-pointer my-4 mx-6 w-8 h-8 flex justify-center items-center bg-white rounded"
    inlineSVG="/assets/icons/icon-arrow-left.svg"
    [setSVGAttributes]="{width: 16, height: 16}"
    (click)="goBack()"
></div>
<ng-container *ngIf="teamMember$|async as teamMember; else empty">
    <div *ngIf="teamMember; else empty"  class="m-6" >
        <app-card>
            <div class="font-medium">{{teamMember.name}}</div>
            <div>{{teamMember.companyName}}</div>

            <div cardFooter>
                <a *ngIf="teamMember?.businessPhones?.length > 0" href="tel:{{teamMember.businessPhones[0]}}">
                    <button class="text-primary font-medium py-2 pr-3 border-r mr-3">
                    <span class="mr-1 transform inline-block w-4 h-4"
                          inlineSVG="/assets/icons/icon-16-phone.svg"></span>
                        Bellen
                    </button>
                </a>
                <a *ngIf="teamMember.upn" href="mailto:{{teamMember.upn}}">
                    <button class="text-primary font-medium py-2 pr-3 border-r mr-3">
                    <span class="mr-1 transform inline-block w-4 h-4"
                          inlineSVG="/assets/icons/icon-mail.svg"></span>
                        Mailen
                    </button>
                </a>
                <a *ngIf="teamMember.upn" href="https://teams.microsoft.com/l/chat/0/0?users={{teamMember.upn}}">
                    <button class="text-primary font-medium py-2">
                    <span class="mr-1 transform inline-block w-4 h-4"
                          inlineSVG="/assets/icons/icon-16-teams-chat.svg"></span>
                        Teams Chat
                    </button>
                </a>
            </div>
        </app-card>

        <app-collapse collapseTitle="Betrokken projecten">
            <ng-container *ngIf="projects$|async as projectSearchFilterResponse; else emptyProjects" >
                <div *ngFor="let project of projectSearchFilterResponse.value" class="flex mb-2 cursor-pointer"
                     [routerLink]="['/projects/', project.id]"
                     queryParamsHandling="preserve">
                    <div class="flex flex-nowrap">
                        <div class="flex-shrink-0 relative w-8 h-8 rounded flex items-center justify-center"
                             [class.bg-primary]="!(project | projectImage)">
                            <img *ngIf="project | projectImage; else defaultImage" alt=""
                                 [src]="project | projectImage"
                                 class="absolute inset-0 w-full object-cover rounded">
                            <ng-template #defaultImage>
                                <span class="absolute left-auto right-auto text-white"
                                      inlineSVG="/assets/icons/icon-project.svg" [setSVGAttributes]="{width: 16, height: 16}">
                                </span>
                            </ng-template>
                        </div>
                        <span class="ml-4">{{ project.naam }}<br> {{ getRoleByProjectId(project.id, teamMemberProjects$ | async)}}</span>
                    </div>
                </div>
            </ng-container>
        </app-collapse>
    </div>
</ng-container>

<ng-template #empty>
    <div *ngIf="loading.teamMember" class="m-6 flex justify-center">
        <vwui-spinner></vwui-spinner>
    </div>
    <div *ngIf="!loading.teamMember" class="pt-20 px-4">
        <app-empty-state
            title="Geen teamlid gevonden"
            message="Er kon geen teamlid gevonden worden."
        ></app-empty-state>
    </div>
</ng-template>

<ng-template #emptyProjects>
    <div *ngIf="loading.projects" class="m-4 flex justify-center">
        <vwui-spinner></vwui-spinner>
    </div>
    <div *ngIf="!loading.projects" class="pt-20 px-4">
        <app-empty-state
            title="Geen projecten gevonden"
            message="Er zijn projecten gevonden waar dit teamlid aan gekoppeld is"
        ></app-empty-state>
    </div>
</ng-template>

