import {Component, Input} from '@angular/core';
import {Collection} from 'api/models/collection';
import {ApiService} from '../../services/api.service';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {fadeInAnimation} from '../../animations/fade.animation';

@Component({
    selector: 'app-collection-tile',
    templateUrl: './collection-tile.component.html',
    animations: [fadeInAnimation]
})
export class CollectionTileComponent {
    @Input() set collection(value: Collection) {
        this.theCollection = value;
        this.projectIdsSubject.next(value.items);
    };

    theCollection: Collection|null = null;
    projectIdsSubject = new BehaviorSubject<string[]>([]);
    projects$ = this.projectIdsSubject.pipe(
        switchMap(ids => this.apiService.getProjects(ids))
    );

    constructor(
        protected apiService: ApiService,
        private router: Router
    ) {}

    onTileClicked() {
        this.router.navigate(
            ['/collections/', this.theCollection.id],
            {queryParamsHandling: 'preserve'}
        );
    }
}
