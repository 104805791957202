import {Component, isDevMode, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, forkJoin, of, Subscription, timer} from 'rxjs';
import {NgSelectConfig} from '@ng-select/ng-select';
import {UserPreferencesService} from './services/user-preferences.service';
import {map, switchMap, takeWhile, withLatestFrom} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {TrimbleService} from './services/trimble.service';
import {MapboxService} from './services/mapbox/mapbox.service';
import {AuthenticationStatus, AuthService} from './services/auth.service';
import {RouterHistoryService} from './services/router-history.service';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {ScreenService} from './services/screen.service';
import {Role} from 'api/models/role';
import {FeatureFlagService} from './modules/shared/feature-flag/feature-flag.service';
import {AppStoreItemService} from './services/app-store-item.service';
import {ProjectClassificationService} from './services/project-classification.service';

declare let BUILD_NUMBER: string;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    showUserUnauthorized$ = this.authService.status$.pipe(
        switchMap(status => status === AuthenticationStatus.Authenticated
            ? this.authService.hasRole$(Role.Gebruiker).pipe(map(it => !it))
            : of(false)
        )
    );
    buildNumber = BUILD_NUMBER;

    authSubscription: Subscription;

    constructor(
        public featureFlagService: FeatureFlagService,
        private authService: AuthService,
        private appStoreItemService: AppStoreItemService,
        private projectClassificationService: ProjectClassificationService,
        private modalService: VwuiModalService,
        private userPreferencesService: UserPreferencesService,
        private router: Router,
        private route: ActivatedRoute,
        private mapbox: MapboxService,
        private trimbleService: TrimbleService,
        private screenService: ScreenService,
        private routerHistory: RouterHistoryService, // Necessary because Angular loads services lazy, this makes sure
                                                     // the service is loaded from the beginning so that history is
                                                     // accurate
        ngSelectConfig: NgSelectConfig
    ) {
        ngSelectConfig.notFoundText = 'Geen resultaten';
        ngSelectConfig.loadingText = 'Laden...';
        ngSelectConfig.addTagText = 'Toevoegen';
        ngSelectConfig.typeToSearchText = 'Type om te zoeken';
        console.log('VWSearch version: ' + this.buildNumber);

        this.authSubscription = combineLatest([
            this.authService.me$,
            this.authService.meMemberOf$,
            this.authService.roles$,
            this.authService.status$
        ]).pipe(
            takeWhile((values) => values[3] === AuthenticationStatus.Authenticated),
        ).subscribe(([user, memberOf, roles]) => {
            this.featureFlagService.initFeatureFlagUser({
                id: user.id,
                email: user.mail,
                custom: {
                    domain: user.mail.split('@')[1],
                    memberOf: memberOf.value
                        .filter(group => !!group.displayName)
                        .map(group => group.displayName),
                    roles: Array.from(roles).map(role => role)
                }
            });
        });
    }

    async ngOnInit(): Promise<void> {
        this.authService.init();
        this.trimbleService.tryRefreshAccessToken();
        this.userPreferencesService.init();
        this.appStoreItemService.init();
        this.projectClassificationService.init();

        forkJoin([
            timer(isDevMode() ? 0 : 1000),
            this.userPreferencesService.asObservable().pipe(
                withLatestFrom(this.screenService.screen$, (userPreferences, screen) => ({userPreferences, screen})),
                takeWhile(preferences => preferences === null),
            )
        ]).subscribe(() => {
        }, () => {
        }, () => {
            document.getElementById('init-splash')?.remove();
        });
    }

    ngOnDestroy() {
        if (this.authSubscription) {
            this.authSubscription.unsubscribe();
        }
    }
}

