import mapboxgl, {AnyLayer} from 'mapbox-gl';
import FeatureService from 'mapbox-gl-arcgis-featureserver';
import { environment } from '../../../../environments/environment';

const baseUrl = environment.featureServices.baseUrl;

export const lotSourceKey = 'lot';

export const initLotFeatureService = (map: mapboxgl.Map): FeatureService => {
    return new FeatureService(lotSourceKey, map, {
        url: `${baseUrl}/${environment.featureServices.lot.featureServerUrlPath}`,
        useStaticZoomLevel: false,
        minZoom: 7,
        projectionEndpoint: `${baseUrl}/${environment.featureServices.lot.projectionEndpointPath}`,
    }) as FeatureService;
};

export const lotLayers: AnyLayer[] = [
    {
        id: 'lot-fill',
        type: 'fill',
        source: lotSourceKey,
        minzoom: 15,
        layout: {},
        paint: {
            'fill-color': 'hsl(195, 100%, 36%)',
            'fill-outline-color': 'hsl(195, 100%, 36%)',
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                0.5,
                0
            ],
        }
    },
    {
        id: 'lot-border',
        type: 'line',
        source: lotSourceKey,
        minzoom: 15,
        layout: {},
        paint: {
            'line-color':
                [
                    'case',
                    ['boolean', ['feature-state', 'selected'], false],
                    '#008AB8',
                    '#fe3816'
                ]
        }
    },
    {
        id: 'lot-label',
        type: 'symbol',
        source: lotSourceKey,
        minzoom: 15,
        layout: {
            'text-field': ['to-string', ['get', 'perceelnummer']],
            'text-font': ['Arial Regular'],
            'text-letter-spacing': 0.1,
            'text-offset': [0, 0.1],
            'text-size': 11
        },
        paint: {
            'text-halo-color': '#fff',
            'text-halo-blur': 0.5,
            'text-halo-width': 1,
            'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                16,
                0,
                16.5,
                1,
                22,
                1
            ],
            'text-color': '#000'
        }
    },
];
