import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InformationBarService {
    informationBarClosed$ = new BehaviorSubject(false);
    overLayOpened = false;

    toggleInfoBar(closed: boolean) {
        this.informationBarClosed$.next(closed);
    }

    toggleOverLayState(closed: boolean) {
        this.overLayOpened = closed;
    }
}
