import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {AuthenticationStatus, AuthService} from '../services/auth.service';
import {filter, first, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.status$.pipe(
            filter(status => status !== AuthenticationStatus.Pending),
            map(status => status === AuthenticationStatus.Authenticated),
            first(),
        );
    }
}
