import {Bim} from 'common/models/asset/bim';
import {RcIndoor} from 'common/models/asset/rc-indoor';
import {RcOutdoor} from 'common/models/asset/rc-outdoor';
import {Prs} from 'api/models/prs';
import {ProjectApplication} from 'api/models/asset/project';
import {View} from './trimble/view';

export type SelectableMetadataItemTypes = 'bim'|'rcIndoor'|'rcOutdoor'|'prs'|'projectApplication'|'trimbleView'|'VWSearchIFrame';
export enum SelectableMetadataItemType {
    BIM = 'bim',
    ERP = 'erp',
    RC_INDOOR = 'rcIndoor',
    RC_OUTDOOR = 'rcOutdoor',
    PRS = 'prs',
    PRS_COLLECTION = 'prsCollection',
    PROJECT_APPLICATION = 'projectApplication',
    TRIMBLE_VIEW = 'trimbleView',
    VW_SEARCH_IFRAME = 'VWSearchIFrame'
}

export interface SelectableMetadataItem {
    type: SelectableMetadataItemType|SelectableMetadataItemTypes;
    item: Bim | RcIndoor | RcOutdoor | Prs | number[] | ProjectApplication | View;
}
