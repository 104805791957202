<app-search-result-container [isLoading]="isLoading$|async" [isEmpty]="paginator.isEmpty"
    emptyTitle="Geen adressen gevonden" emptyDescription="Er zijn geen adressen gevonden, probeer het opnieuw via de zoekbalk"
>
    <div class="relative">
        <div class="pb-4 flex items-center">
            <div class="font-medium text-base mr-auto rounded px-2 py-1">
                Adressen
            </div>
            <app-pagination class="ml-auto" [paginator]="paginator"></app-pagination>
        </div>
        <app-verblijfsobject-list [items]="paginator.content$|async">
        </app-verblijfsobject-list>
        <div class="mt-4 flex items-end flex-col">
            <app-pagination [paginator]="paginator"></app-pagination>
        </div>
    </div>
</app-search-result-container>
