<table class="w-full rounded-table-neutral-3 text-left">
    <tr *ngIf="addEmptyStateRow && (!items || items.length === 0)">
        <td class="py-3 px-6 text-center">
            Geen locaties gevonden.
        </td>
    </tr>
    <tr *ngFor="let item of items" class="hover:bg-neutral-4 cursor-pointer"
        (click)="onItemClicked(item)">
        <td class="py-3 px-6">
            <div class="flex align-center flex-nowrap items-center">
                <div class="relative w-8 h-8 rounded flex items-center justify-center bg-primary">
                    <span class="absolute left-auto right-auto text-white"
                          inlineSVG="/assets/icons/icon-24-pin.svg" [setSVGAttributes]="{width: 16, height: 16}">
                    </span>
                </div>
                <span class="ml-4">
                    {{ item | verblijfsobjectAddress }}, {{ item.postcode }} {{ item.woonplaatsNaam }}
                </span>
            </div>
        </td>
    </tr>
</table>
