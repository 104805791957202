import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Collection} from 'api/models/collection';
import {Router} from '@angular/router';
import {RouterHistoryService} from '../../services/router-history.service';
import {detailPageGoBack} from '../../utils/navigation';
import {generatePrsCollectionLink} from '../../utils/prs-helper';
import {map} from 'rxjs/operators';
import {ApiService} from '../../services/api.service';

@Component({
    selector: 'app-collection-header',
    templateUrl: './collection-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionHeaderComponent {
    @Input() collection: Collection;

    goBack = detailPageGoBack(this.router, this.routerHistory, ['collections']);

    constructor(
        private apiService: ApiService,
        private router: Router,
        private routerHistory: RouterHistoryService
    ) {}

    async navigateToPrsPresentation() {
        const prsIds = await this.apiService.getProjects(this.collection.items).pipe(
            map(projects => projects.filter(p => p.prs !== null).map(p => p.prs_id))
        ).toPromise();
        window.location.href = generatePrsCollectionLink(prsIds);
    }
}
