import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {Overlay} from '@angular/cdk/overlay';
import {AbstractPaginator} from '../../utils/paginator/abstract-paginator';
import {map} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {isLatLng} from 'api/utils/lat-lng';
import {SortService} from '../../services/sort.service';

@Component({
    selector: 'app-pagination-sort',
    templateUrl: './pagination-sort.component.html'
})
export class PaginationSortComponent<Sort> implements OnChanges, OnDestroy {
    @Input() paginator: AbstractPaginator<any, Sort>;
    @Input() sortOptions: {
        key: Sort;
        label: string;
    }[] = [];
    @Input() setSortFromQueryParams = false;
    @Input() startOption?: string;
    private routeSubscription: Subscription = null;
    dropdownOpen = false;
    scrollStrategy = this.overlay.scrollStrategies.close();
    currentSortLabel$: Observable<string>;
    readonly distanceLabel = 'Afstand';

    constructor(
        private overlay: Overlay,
        private route: ActivatedRoute,
        public sortService: SortService
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.paginator && changes.paginator.currentValue !== changes.paginator.previousValue) {
            this.currentSortLabel$ = this.paginator.loadParams$.pipe(
                map(loadParams => {
                    if (isLatLng(loadParams.query)) {
                        return this.distanceLabel;
                    }
                    let currentOption = this.sortOptions.find(option => option.key === loadParams.sort);
                    if (currentOption === undefined && loadParams.sort !== undefined) {
                        currentOption = this.sortOptions.find(option => {
                            return '-' + option.key.toString() === loadParams.sort.toString();
                        });
                    }
                    if (loadParams.sort !== undefined) {
                        this.sortService.currentState$.next(loadParams.sort);
                    }
                    if (currentOption === undefined) {
                        currentOption = this.startOption === 'recent' ?
                            this.sortOptions.find(option => option.label === 'Aanmaakdatum') :
                            this.sortOptions.find(option => option.label === 'Naam');
                        this.paginator.setSort(undefined);
                        this.sortService.currentSort$.next(1);
                    }

                    return currentOption?.label;
                })
            );
        }
        if (changes.setSortFromQueryParams
            && changes.setSortFromQueryParams.currentValue !== changes.setSortFromQueryParams.previousValue
        ) {
            if (this.routeSubscription) {
                this.routeSubscription.unsubscribe();
            }
            if (this.setSortFromQueryParams) {
                this.routeSubscription = this.route.queryParamMap.subscribe(queryParamMap => {
                    if (queryParamMap.has('sort')) {
                        const sort = queryParamMap.get('sort') as any;
                        if (this.sortOptions.some(option => option.key === sort)) {
                            this.sortService.currentState$.next(sort.toString());
                            if (sort.toString() !== this.sortService.defaultState) {
                                this.sortService.currentSort$.next(1);
                            }
                            if (sort.toString().includes('-') && sort.toString() !== this.sortService.defaultState) {
                                this.sortService.currentSort$.next(2);
                            }
                            this.onOptionClick(sort);
                            this.paginator.setSort(sort);
                        }
                    }
                });
            }
        }
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
        this.routeSubscription = null;
    }

    onDropdownOpenClick() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    onOutsideOverlayClick() {
        // Schedule so closing dropdown occurs after potential openClick event
        setTimeout(() => this.dropdownOpen = false, 0);
    }

    onOptionClick(sort: Sort) {
        this.paginator.setSort(sort);
        this.sortService.currentState$.next(sort.toString());
        this.dropdownOpen = false;
    }
}
