import {Component, Input, OnInit} from '@angular/core';
import {Project} from 'api/models/asset/project';
import {SelectionService} from '../../services/selection.service';
import {Router} from '@angular/router';
import {SelectionHelper} from '../is-selected/selection-helper';
import {Overlay} from '@angular/cdk/overlay';
import {UntypedFormBuilder} from '@angular/forms';
import {ProjectColumnConfigService} from 'src/app/services/project-column-config.service';
import {SortService} from '../../services/sort.service';
import {hasCommercialProjectName} from '../../utils/project-name';

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html'
})
export class ProjectListComponent<Sort> extends SelectionHelper implements OnInit {
    @Input() projects: Project[];
    dropdownOpen = false;
    scrollStrategy = this.overlay.scrollStrategies.close();
    selectionMode$ = this.selectionService.selectionMode$;
    columns = this.formBuilder.array([
        this.formBuilder.group({name: 'Project ID', sort: 'id', enabled: true}),
        this.formBuilder.group({name: 'Projectnaam', sort: 'naam', enabled: true}),
        this.formBuilder.group({name: 'Onderneming', sort: 'bedrijf', enabled: true}),
        this.formBuilder.group({name: 'Plaats', sort: 'plaats', enabled: true}),
        this.formBuilder.group({name: 'Status', sort: 'operationele_projectstatus', enabled: true}),
        this.formBuilder.group({name: 'Opdrachtgever', sort: 'opdrachtgever', enabled: true}),
        // Add soft-hyphenation character to facilitate word-wrapping in column header
    ]);
    hasCommercialProjectName = hasCommercialProjectName;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private projectColumnConfig: ProjectColumnConfigService,
        private overlay: Overlay,
        protected selectionService: SelectionService,
        protected router: Router,
        public sortService: SortService) {
        super(selectionService, router);
    }

    private get currentProjectColumnConfig(): { name: string; enabled: boolean }[] {
        return this.columns.value;
    }

    ngOnInit(): void {
        if (this.projectColumnConfig.isConfigApplicable(this.currentProjectColumnConfig)) {
            this.columns.patchValue(this.projectColumnConfig.config);
        }
    }

    /**
     * Adds a trailing space after comma's that didn't have one
     * */
    padCommas(input?: string): string | undefined {
        return input?.replace(/,(?=\w)/g, ', ');
    }

    onColumnPickerUpdate(): void {
        this.projectColumnConfig.config = this.currentProjectColumnConfig;
    }

    onColumnPickerClick(): void {
        this.dropdownOpen = !this.dropdownOpen;
    }

    onOutsideOverlayClick() {
        // Schedule so closing dropdown occurs after potential onDropdownOpenClick event
        setTimeout(() => this.dropdownOpen = false, 0);
    }
}
