import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    catchError,
    filter,
    map,
    mergeAll,
    mergeMap,
    pluck,
    retry,
    shareReplay,
    switchMap,
    take,
    tap, withLatestFrom
} from 'rxjs/operators';
import {Observable, of, Subscription} from 'rxjs';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Project, ProjectApplication, ProjectRelation, ProjectTeam} from 'api/models/asset/project';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {SearchStateService} from '../../services/search-state.service';
import {AssetMetadata} from 'api/models/asset/metadata';
import {SelectableMetadataItem, SelectableMetadataItemTypes} from '../../model/metadata';
import {InformationBarService} from '../../services/information-bar.service';
import {MobileService} from '../../services/mobile.service';
import {detailPageGoBack} from '../../utils/navigation';
import {RouterHistoryService} from '../../services/router-history.service';
import {TrimbleService} from '../../services/trimble.service';
import {PointResult} from '../../model/search-response';
import {stabuChapters} from 'src/app/model/stabu-chapters';
import {ModalService} from '../../services/modal.service';
import {Company} from 'api/models/asset/company';
import {ProjectAppStoreItemService} from '../../services/project-app-store-item.service';
import {ProjectAppStoreItem} from 'api/models/asset/project-app-store-item';
import {ProjectClassification} from 'api/models/asset/project-classification';
import {ProjectClassificationService} from '../../services/project-classification.service';

interface ProjectRelationChapter {
    chapter: string;
    label: string;
    projectRelations: ProjectRelation[];
}

@Component({
    selector: 'app-projects-detail',
    templateUrl: './projects-detail.component.html'
})
export class ProjectsDetailComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    project: Project;
    companies: Observable<string[]>;
    metadata: AssetMetadata;
    currentDate = new Date();
    activeTab: 'project' | 'project_dossier' | 'project_referentie_sheet' = 'project';

    projectClassificationsToShow: string[] = [
        'TAOPS00', // Operationele projectstatus
        'DUFCD00', // Duurzaamheid classificatie
        'HRWKS00', // Werksoort
        'WRVWC00', // VolkerWessels Concept
        'WRGBFU00', // Gebruiksfunctie
        'WRGBFS00', // Sub-Gebruiksfunctie
    ];

    projectClassificationValuesToShow$: Observable<ProjectClassification[]> = this.projectClassificationService
            .projectClassifications$.pipe(
        withLatestFrom(of(this.projectClassificationsToShow)),
        map(([projectClassifications, projectClassificationDefinitionsToShow]) => {
            return projectClassificationDefinitionsToShow.map(projectClassificationCode => {
                return projectClassifications.find(projectClassification =>
                    projectClassification.code === projectClassificationCode);
            });
        }),
    );

    metadata$ = this.activatedRoute.paramMap.pipe(
        tap(() => this.loading = true),
        switchMap(paramMap => this.apiService.getMetadata<Project>('project', paramMap.get('projectID'))),
        tap(() => this.loading = false, () => this.loading = false),
        retry(2),
        catchError(_ => of(null)),
        filter(it => !!it)
    );
    companies$ = this.metadata$.pipe(
        switchMap(metadata => {
            return this.apiService.filter<Company>(
                'company', '', '', false, '', 0, 10,
                {kvw_nummer: [...new Set([metadata.asset.kvw_nummer, ...metadata.asset.bouwcombinatie_kvw_numbers])]}
            ).pipe(
                map(response => {
                    const companies = response.value.map(company => `${company.kvw_nummer} - ${company.naam}`);
                    return companies.sort((a, b) =>
                        a.includes(metadata.asset.kvw_nummer) ? -1 : b.includes(metadata.asset.kvw_nummer) ? 1 : 0);
                })
            );
        }
    ));
    projectRelationsChapters$: Observable<ProjectRelationChapter[]> = this.activatedRoute.paramMap.pipe(
        switchMap(paramMap => this.apiService.getProjectRelations(paramMap.get('projectID'))),
        retry(1),
        map(projectRelations => {
            return projectRelations.reduce((result, item) => {
                const chapter = item.stabu_code_largest_stabu.substring(0, 2);

                const existingChapter = result.find(it => it.chapter === chapter);
                if (existingChapter) {
                    existingChapter.projectRelations.push(item);
                } else {
                    result.push({chapter, projectRelations: [item], label: `${chapter} ${stabuChapters[chapter]}`});
                }

                return result;
            }, []);
        }),
        shareReplay(1),
        catchError(_ => {
            return of(null);
        })
    );
    projectTeam$: Observable<ProjectTeam> = this.activatedRoute.paramMap.pipe(
        switchMap(paramMap => this.apiService.getProjectTeam(paramMap.get('projectID'))),
        retry(1),
        shareReplay(1),
        catchError(_ => {
            return of(null);
        })
    );

    trimbleApplications$: Observable<ProjectApplication[]> = this.activatedRoute.paramMap.pipe(
        tap(() => this.loadingTrimbleApplications = true),
        switchMap(paramMap => this.apiService.getProjectTrimbleApplications(+paramMap.get('projectID'))),
        retry(1),
        map(projectApplications => projectApplications.filter(projectApplication => projectApplication.applicationNumber !== '')),
        shareReplay(1),
        catchError(_ => {
            return of([]);
        }),
        tap(() => this.loadingTrimbleApplications = false,
            () => this.loadingTrimbleApplications = false
        ),
    );

    trimbleViews$= this.trimbleApplications$.pipe(
        mergeAll(),
        mergeMap(projectApplication => this.trimbleService.getViews(projectApplication.applicationNumber)),
        tap(() => this.loadingTrimbleViews = true),
        shareReplay(1),
        catchError(_ => {
            this.trimbleViewAccesDenied = true;
            return of([]);
        }),
        map(views => views.filter(view => view.name.toLowerCase() === 'situatie')),
        tap(() => this.loadingTrimbleViews = false,
            () => this.loadingTrimbleViews = false
        ),
    );

    projectBuildingClassification$: Observable<string[]> = this.activatedRoute.paramMap.pipe(
        switchMap(paramMap => this.apiService.getBuildingProjectClassification(paramMap.get('projectID'))),
        retry(1),
        map(projectBuildingClassification => {
            return Object.keys(projectBuildingClassification).map(key => {
                switch (key) {
                    case 'plotNumber':
                        return `${projectBuildingClassification[key]} woningen`;
                    case 'bvo':
                        return `${projectBuildingClassification[key].toFixed(2)}m2 BVO`;
                    default:
                        return '';
                }
            });
        }),
        shareReplay(1),
        catchError(_ => {
            return of(['']);
        })
    );

    projectAppStoreItems$: Observable<ProjectAppStoreItem[]> = this.activatedRoute.paramMap.pipe(
        switchMap(paramMap => this.projectAppStoreItemService.getByProjectId(+paramMap.get('projectID'))),
        retry(1),
        shareReplay(1),
        catchError(_ => {
            return of([]);
        })
    );

    projectProjectClassifications$ = this.activatedRoute.paramMap.pipe(
        switchMap(paramMap => this.projectClassificationService
            .getProjectProjectClassificationsByProjectId(+paramMap.get('projectID'))),
        retry(1),
        shareReplay(1),
        catchError(_ => {
            return of([]);
        })
    );

    isOnMobile = false;
    loading = true;
    loadingProjectApplications = false;
    loadingTrimbleApplications = false;
    loadingTrimbleViews = false;
    trimbleAuthenticated: boolean;
    trimbleViewAccesDenied: boolean;

    goBack = detailPageGoBack(this.router, this.routerHistory, ['projects']);

    constructor(
        private apiService: ApiService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private routerHistory: RouterHistoryService,
        private mapboxService: MapboxService,
        private mobileService: MobileService,
        private searchStateService: SearchStateService,
        private infoBarService: InformationBarService,
        private trimbleService: TrimbleService,
        private modalService: ModalService,
        private projectAppStoreItemService: ProjectAppStoreItemService,
        private projectClassificationService: ProjectClassificationService
    ) {
    }


    ngOnDestroy(): void {
        this.mobileService.navbarEnabled$.next(true);
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
        this.infoBarService.toggleOverLayState(false);
        // Remove additional point results on closing of page
        this.searchStateService.pointResults$.next([]);
    }

    ngOnInit(): void {
        this.mobileService.navbarEnabled$.next(false);
        this.trimbleAuthenticated = this.trimbleService.isAuthenticated();
        this.subscriptions.push(
            this.activatedRoute.paramMap.subscribe(((paramMap) => {
                    this.activeTab = paramMap.get('tab') as 'project' | 'project_dossier' ?? 'project';
                })
            ),
            this.metadata$.subscribe((metadata) => {
                this.project = metadata.asset;
                this.metadata = metadata;

                this.loadMap().then(async () => {
                    if (this.project.geog !== null) {
                        this.mapboxService.map.flyTo({
                            center: [this.project.geog.coordinates[0], this.project.geog.coordinates[1]],
                            zoom: 17,
                            pitch: 50,
                            bearing: 0
                        });

                        const allPoints = this.searchStateService.pointResults$.value.length === 0
                            ? await this.apiService.allProjects$.pipe(
                                pluck('value'),
                                map(points => points.map(
                                    item => ({...item, title: item.naam, id: item.id, type: 'project'} as PointResult)))
                            ).toPromise()
                            : this.searchStateService.pointResults$.value;

                        this.searchStateService.pointResults$.next(
                            allPoints.map(
                                point => point.id === this.project.id
                                    ? {...point, type: 'activeProject'}
                                    : {...point, type: point.type === 'activeProject' ? 'project' : point.type}
                            )
                        );
                    }
                });
            }),
            this.mobileService.isOnMobile$.subscribe(isOnMobile => this.isOnMobile = isOnMobile)
        );
    }

    getProjectPercentage(): number {
        if (this.project.startdatum === null && this.project.einddatum === null) {
            return null;
        }
        if (this.project.einddatum) {
            const endDate = new Date(this.project.einddatum);
            if (endDate < this.currentDate) {
                return 100;
            }
            if (this.project.startdatum) {
                const startDate = new Date(this.project.startdatum);
                const projectDuration = endDate.getTime() - startDate.getTime();
                const elapsedDuration = this.currentDate.getTime() - startDate.getTime();
                return Math.max(0, elapsedDuration / projectDuration * 100);
            }
        }
        return 50;
    }

    getProjectLocation(project: Project) {
        return `${project.straat || ''} ${project.huisnummer || ''}, ${project.plaats || ''}`;
    }

    async loadMap() {
        if (!this.mapboxService.mapLoaded$.value) {
            await this.mapboxService.mapLoaded$.pipe(filter(it => it), take(1)).toPromise();
        }
    }

    openOverlay(item, type: SelectableMetadataItemTypes) {
        this.searchStateService.setSelectedItem({
            item,
            type
        } as SelectableMetadataItem);
    }

    openMCAModal(mcaMatrixUrl: string) {
        this.modalService.openMCAMatrixModal(mcaMatrixUrl);
    }

    openTrimbleLogin(projectId) {
        this.trimbleService.redirectToTrimbleLogin(['/projects/', projectId, 'project_dossier']);
    }

    async getProjectLeiderName(): Promise<string> {
        const projectTeam = await this.projectTeam$.toPromise();
        return projectTeam.team.find(projectTeamMember => projectTeamMember.role === 'Projectleider')[0].name || '';
    }

    filterDuplicatesByRelationName(projectRelations: ProjectRelation[]) {
        return projectRelations.filter((projectRelation, index, self) =>
            index === self.findIndex((t) => (
                t.relation_name === projectRelation.relation_name
            ))
        );
    }

    goToRelation(relationId: string) {
        this.router.navigate(['/relations/', relationId]);
    }

    getProjectClassificationValuesByDefinition(projectClassificationDefinition: string): Observable<string> {
        return this.projectProjectClassifications$.pipe(
            map(projectProjectClassifications => {
                return projectProjectClassifications.filter(projectProjectClassification => {
                    return projectProjectClassification.projectClassification.kenmerk === projectClassificationDefinition;
                });
            }),
            map(projectProjectClassifications => {
                return projectProjectClassifications.map(projectProjectClassification => {
                    return projectProjectClassification.projectClassificationValue.waarde;
                }).join(', ');
            })
        );
    }
}
