import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {MapEvent} from './map-event.types';

@Injectable({
    providedIn: 'root'
})
export class MapEventService {
    readonly mapEvent$ = new Subject<MapEvent>();

    constructor() {}

    triggerEvent(event: MapEvent): void {
        this.mapEvent$.next(event);
    }
}
