<div class="px-6 mobile:px-4 mobile:pt-20" [formGroup]="form">
    <div class="h-24 flex items-center mobile:hidden">
        <h1 appTitle="lg" class="m-0">VolkerWessels projecten</h1>
        <button class="ml-auto text-neutral-1">
            <span class="mr-1" inlineSVG="/assets/icons/icon-info.svg"></span>
            Hulp nodig?
        </button>
    </div>
    <ng-container *ngIf="kvwNumbers$ | async as kvwNumbers">
        <div class="mobile:fixed mobile:top-0 mobile:inset-x-0 mobile:z-20 mobile:bg-white mobile:px-4">
            <div class="desktop:hidden h-4" (click)="scrollToTop()"></div>
            <div class="flex gap-2 border-t border-b border-neutral-3 desktop:pt-4 pb-4 relative">
                <app-search-input formControlName="query" class="flex-grow" placeholder="Zoeken door projecten" type="project" (inputCleared)="onSearchClear()"></app-search-input>
                <app-search-filter [kvwNumbers]="kvwNumbers" type="project" formControlName="filter"></app-search-filter>
            </div>
        </div>

        <div class="py-4">
            <app-active-filter-list [kvwNumbers]="kvwNumbers" formControlName="filter"></app-active-filter-list>
            <router-outlet></router-outlet>
        </div>
    </ng-container>
</div>
