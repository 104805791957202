<app-search-result-container [isLoading]="isLoading$|async" [isEmpty]="paginator.isEmpty"
    emptyTitle="Geen plaatsen gevonden" emptyDescription="Er zijn geen plaatsen gevonden, probeer het opnieuw via de zoekbalk"
>
    <div class="relative">
        <div class="pb-4 flex items-center">
            <div class="font-medium text-base mr-auto rounded px-2 py-1">
                Plaatsen
            </div>
            <app-pagination class="ml-auto" [paginator]="paginator"></app-pagination>
        </div>
        <app-woonplaats-list [items]="paginator.content$|async">
        </app-woonplaats-list>
        <div class="mt-4 flex items-end flex-col">
            <app-pagination [paginator]="paginator"></app-pagination>
        </div>
    </div>
</app-search-result-container>
