import {UserRecentVisit} from 'api/models/user-recent-visit';
import {UserFavorite} from 'api/models/user-favorite';
import {SearchSuggest} from '../model/search-suggest';

export function recentVisitIsFavorite(favorites: UserFavorite[], recentVisit: UserRecentVisit) {
    return favorites.some(it => it.type === recentVisit.type && it.id === recentVisit.identification);
}

export function searchSuggestionIsFavorite(favorites: UserFavorite[], suggestion: SearchSuggest) {
    return favorites.some(it => it.type === suggestion.type && it.id === suggestion.id);
}
