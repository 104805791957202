export interface MapStyle {
    style: string;
    label: string;
    tileJsonName: string;
}

export const mapStyles: MapStyle[] = [
    {
        style: 'arcgis-streets-relief',
        label: 'Standaard',
        tileJsonName: 'arcgis/streets'
    },
    {
        style: 'arcgis-topographic',
        label: 'Topografisch',
        tileJsonName: 'arcgis/outdoor'
    },
    {
        style: 'osm-hybrid',
        label: 'Satelliet',
        tileJsonName: 'osm/hybrid'
    },
    {
        style: 'osm-standard-relief',
        label: 'OpenStreetMap',
        tileJsonName: 'osm/standard'
    },
    {
        style: 'arcgis-dark-gray',
        label: 'Donkergrijs',
        tileJsonName: 'arcgis/dark-gray'
    },
    {
        style: 'arcgis-navigation-night',
        label: 'Nacht navigatie',
        tileJsonName: 'arcgis/navigation-night'
    },
];
