<div class="px-6 pt-8 mobile:px-4 mobile:pt-4">
    <h1 appTitle="lg" class="m-0 mb-8 mobile:hidden">Account en meer</h1>
    <div class="w-full border-neutral-3 rounded border mb-2 shadow-button">
        <div class="my-7 mx-6 flex gap-4 items-center flex-wrap">
            <div
                class="bg-primary text-white uppercase text-base
                font-medium rounded-full flex justify-center items-center
                w-10 h-10 min-w-10 min-h-10">
                <span>{{ initials$ | async }}</span></div>
            <div>
            <span class="block font-medium">
                {{userName}}
            </span>
                <span class="text-neutral">
                {{email$|async}}
            </span>
            </div>
            <button (click)="this.logOut()" class="ml-auto font-medium text-primary">
                Uitloggen
            </button>
        </div>
    </div>
    <div *ngFor="let navigationListItem of navigationListItems">
        <div (click)="navigateTo(navigationListItem.linkedPage)"
             class="flex px-1 py-5 border-b border-neutral-3 items-center cursor-pointer hover:bg-neutral-4">
            <div>
                <span class="font-medium block">{{navigationListItem.title}}</span>
                <span class="text-neutral mt-1">{{navigationListItem.description}}</span>
            </div>
            <span class="ml-auto" inlineSVG="/assets/icons/navigation-arrow-icon.svg"></span>
        </div>
    </div>
</div>
