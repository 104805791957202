import '../utils/flat-map';

export enum Role {
    KaartenService = "KaartenService",
    PRS_Editor = "PRS_Editor",
    PRS_Admins = "PRS_Admins",
    Alle_publieke_projecten = "Alle_publieke_projecten",
    Alle_projecten_van_bedrijf = "Alle_projecten_van_bedrijf",
    Alle_projecten = "Alle_projecten",
    Super_Admin = "Super_Admin",
    Bedrijfsadmin = "Bedrijfsadmin",
    Gebruiker = "Gebruiker" // Dit is geen app-reg rol
}

export enum LegacyRole {
    ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN",
    ROLE_READ_ALL_DIR = "ROLE_READ_ALL_DIR",
    ROLE_READ_ALL_MDW = "ROLE_READ_ALL_MDW",
    ROLE_READ = "ROLE_READ",
    ROLE_KAARTENSERVICE = "ROLE_KAARTENSERVICE",
    ROLE_PRS_ADMINS = "ROLE_PRS_ADMINS",
    ROLE_PRS_EDITOR = "ROLE_PRS_EDITOR",
}

export const legacyRoleToRoleMapping = {
    [LegacyRole.ROLE_SUPER_ADMIN]: Role.Super_Admin,
    [LegacyRole.ROLE_READ_ALL_DIR]: Role.Alle_projecten,
    [LegacyRole.ROLE_READ_ALL_MDW]: Role.Alle_publieke_projecten,
    [LegacyRole.ROLE_READ]: Role.Alle_projecten_van_bedrijf,
    [LegacyRole.ROLE_KAARTENSERVICE]: Role.KaartenService,
    [LegacyRole.ROLE_PRS_EDITOR]: Role.PRS_Editor,
    [LegacyRole.ROLE_PRS_ADMINS]: Role.PRS_Admins
}

export const roleInheritance: {[key in keyof typeof Role]: Role[]} = {
    Super_Admin: [Role.Alle_projecten, Role.Bedrijfsadmin, Role.KaartenService, Role.PRS_Admins],
    Alle_projecten: [Role.Alle_publieke_projecten],
    Alle_publieke_projecten: [Role.Gebruiker],
    Alle_projecten_van_bedrijf: [Role.Gebruiker],
    KaartenService: [],
    PRS_Admins: [],
    PRS_Editor: [],
    Bedrijfsadmin: [],
    Gebruiker: []
}

export function getInheritedRoles(roles: Role[]): Set<Role> {
    return new Set(roles.flatMap(role => getRoleInheritedRoles(role)))
}
function getRoleInheritedRoles(role: Role): Role[] {
    return role in roleInheritance
        ? [role, ...roleInheritance[role].flatMap(role => getRoleInheritedRoles(role))]
        : [role];
}


export function getRoleName(role: Role | LegacyRole) {
    switch (role) {
        case Role.KaartenService:
        case LegacyRole.ROLE_KAARTENSERVICE:
            return 'Kaartenservice';
        case LegacyRole.ROLE_PRS_ADMINS:
            return 'PRS Administrator';
        case Role.PRS_Editor:
        case LegacyRole.ROLE_PRS_EDITOR:
            return 'PRS Editor';
        case Role.Alle_projecten_van_bedrijf:
        case LegacyRole.ROLE_READ:
            return 'Lezen';
        case Role.Alle_projecten:
        case LegacyRole.ROLE_READ_ALL_DIR:
            return 'Alles lezen directie';
        case Role.Alle_publieke_projecten:
        case LegacyRole.ROLE_READ_ALL_MDW:
            return 'Alles lezen medewerker';
        case Role.Super_Admin:
        case LegacyRole.ROLE_SUPER_ADMIN:
            return 'Administrator';
        case Role.Gebruiker:
            return 'Gebruiker';
        case Role.Bedrijfsadmin:
            return 'Bedrijfsadmin';
    }
}

export function isAssignableRole(role: string): boolean {
    return ([Role.Alle_projecten_van_bedrijf, Role.KaartenService, Role.PRS_Editor] as string[]).includes(role);
}
