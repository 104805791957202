import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-active-filter',
    templateUrl: './active-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActiveFilterComponent {
    @Output() removeOption = new EventEmitter<void>();
}
