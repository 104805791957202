import {Component, OnInit} from '@angular/core';
import {VwuiModalConfig, VwuiModalRef} from '@recognizebv/vwui-angular';
import {facetLabel} from '../../utils/facet-translations';

@Component({
    selector: 'app-export-project-error-modal',
    templateUrl: './export-project-error-modal.component.html'
})
export class ExportProjectErrorModalComponent implements OnInit {

    constructor(
        public modalConfig: VwuiModalConfig<{ selectedSearchFilters: string[] }>,
        private vwuiModalRef: VwuiModalRef
    ) {
    }

    ngOnInit(): void {
    }

    facetLabel(key: string) {
        return facetLabel(key);
    }

    close() {
        this.vwuiModalRef.close();
    }

}
