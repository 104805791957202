<div class="text-neutral-1 ml-2">
    <button *ngIf="hasGridView" class="w-8 h-8 rounded"
            [class.bg-highlight]="gridView$|async"
            [class.text-primary]="gridView$|async"
            (click)="onOptionClick('grid')"
            inlineSVG="/assets/icons/icon-grid-view.svg">
    </button>
    <button *ngIf="hasListView" class="w-8 h-8 rounded"
            [class.bg-highlight]="(gridView$|async) === false"
            [class.text-primary]="(gridView$|async) === false"
            (click)="onOptionClick('list')"
            inlineSVG="/assets/icons/icon-list-view.svg">
    </button>
</div>
