import {DisplayTypeSortOrder, ProjectAppStoreListItem} from '../project-app-store-list-item/project-app-store-list-item.types';

export const defaultAppStoreListItem: ProjectAppStoreListItem = {
    id: 1,
    title: 'Projecten portaal',
    description: 'Projectenportaal voor het beheren van projecten.',
    url: '',
    subtitle: 'Informatiesysteem',
    displayType: 'primary',
    sortOrder: DisplayTypeSortOrder.primary,
    activationType: '',
    appStoreItemId: 0,
    applicationNumber: '',
    contractorId: 0,
    contractorName: '',
    copyFromProject: 0,
    copyProjectSettings: false,
    copyUsers: false,
    createdAt: '',
    defaultActive: false,
    dtype: '',
    email: '',
    esbError: '',
    existingApplication: false,
    iconId: '',
    internalTitle: '',
    navAdministration: '',
    navApplicationId: 0,
    navisionAppId: 0,
    ownerId: 0,
    portfolioId: '',
    projectAdmin: '',
    projectId: 0,
    requestId: '',
    secondaryType: '',
    status: '',
    template: '',
    templateId: 0,
    trimbleConnectProjectId: '',
    trimbleConnectTemplate: '',
    trimbleProjectId: '',
    trimbleUserId: 0,
    type: undefined,
    projectAppStoreItemUrl: '',
};
