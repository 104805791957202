import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DisplayType} from '../project-app-store-list-item/project-app-store-list-item.types';

@Component({
    selector: 'app-project-app-store-item-header',
    templateUrl: './project-app-store-item-header.component.html',
    styleUrls: ['./project-app-store-item-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectAppStoreItemHeaderComponent {
    @Input() appTitle!: string;
    @Input() appSubtitle!: string;
    @Input() appDisplayType!: DisplayType;
}
