import {Component, forwardRef, HostBinding, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {rangeFacets} from '../../model/rangefacets';
import KvwNumbers from 'api/models/kvw-numbers';
import {filterValueTranslations} from 'api/models/asset/project';

@Component({
    selector: 'app-active-filter-list',
    templateUrl: './active-filter-list.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ActiveFilterListComponent),
            multi: true
        }
    ]
})
export class ActiveFilterListComponent implements ControlValueAccessor {
    @HostBinding('class')
    classes = 'flex flex-wrap gap-2 mb-4';

    @Input() kvwNumbers: KvwNumbers;

    filterValueTranslations = filterValueTranslations;

    favorite = false;
    filter: ReadonlyMap<string, string[]>;

    onChange: (value: any) => void = () => {};
    onTouch: () => void = () => {};

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    async writeValue(newValue: any): Promise<void> {
        const {favorite, ...filter} = newValue;
        this.favorite = !!favorite;
        this.filter = filter;
    }

    removeFavorite() {
        this.favorite = false;
        this.emitNewValue();
    }

    removeOption(key: string, value: string) {
        const group = this.filter[key];
        const index = group.indexOf(value);
        if (index !== -1) {
            group.splice(index, 1);
        }
        this.emitNewValue();
    }

    removeRangeOption(key: string) {
        const group = this.filter[key];
        group.splice(0, group.length);
        this.emitNewValue();
    }

    emitNewValue() {
        this.onTouch();
        this.onChange({favorite: this.favorite, ...this.filter});
    }

    isDateFilter(filterKey): boolean {
        const filter = rangeFacets.find(facet => facet.key === filterKey);
        return filter && filter.dataType === 'MONTH';
    }
}
