<app-saved-filter-list type="project"></app-saved-filter-list>
<div class="mb-7" *ngIf="(favoriteProjectsPaginator.content$|async)?.length > 0">
    <div class="pb-4 flex items-center">
        <div class="font-medium text-base mr-auto rounded py-1">
            Favoriete projecten
        </div>
        <a [routerLink]="['/projects/search']" [queryParams]="{favorite: true}" queryParamsHandling="merge"
           class="text-primary font-medium ring-2 ring-transparent focus:ring-primary focus:outline-none py-4 rounded">
            Alle favorieten
        </a>
    </div>
    <app-project-tile-grid
        [projects]="favoriteProjectsPaginator.content$|async"
        [threeColumns]="!isOnMobile"
    ></app-project-tile-grid>
</div>
<div class="relative">
    <div class="pb-4 flex items-center">
        <app-export-dropdown *ngIf="!isOnMobile" [total]="totalProjectItems" titleText="Alle Projecten" table="project">
        </app-export-dropdown>
        <app-pagination-sort *ngIf="gridView$|async;" class="ml-auto" [paginator]="allProjectsPaginator"
                             [sortOptions]="projectSortOptions" [setSortFromQueryParams]="true" [startOption]="'recent'">
        </app-pagination-sort>
        <app-results-view-switch class="mobile:hidden" [class.ml-auto]="(gridView$|async) === false"></app-results-view-switch>
        <app-pagination class="mobile:hidden" [paginator]="allProjectsPaginator"></app-pagination>
    </div>
    <ng-container *ngIf="allProjectsPaginator.content$|async as projects">
        <ng-container *ngIf="(gridView$|async) || isOnMobile; else listView">
            <app-project-tile-grid
                [projects]="projects"
                [isLoading]="allProjectsPaginator.isLoading"
            ></app-project-tile-grid>
        </ng-container>
        <ng-template #listView>
            <app-project-list [projects]="projects"></app-project-list>
        </ng-template>
    </ng-container>

    <div class="mt-4 flex items-end flex-col">
        <app-pagination [paginator]="allProjectsPaginator"></app-pagination>
    </div>

    <span *ngIf="allProjectsPaginator.isLoading"
          class="fixed top-96 left-1/2 transform -translate-x-1/2"
          inlineSVG="/assets/icons/loader.svg"></span>
</div>
