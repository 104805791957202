<div class="flex mobile:flex-col rounded desktop:hover:shadow-tile ring-2 ring-transparent border border-neutral-3 cursor-pointer">
    <div class="flex-none w-60 mobile:w-full p-1">
        <button class="block w-full relative project-tile-ratio rounded-l bg-neutral-4 overflow-hidden
                        ring-2 ring-transparent group"
                (click)="onItemClicked($event, project, 'project')">
            <ng-container *ngIf="project | projectImage; then projectImage else defaultImage"></ng-container>
            <ng-template #projectImage>
                <img appImageFade [src]="project | projectImage"
                     class="absolute inset-0 w-full object-cover">
                <div class="absolute inset-x-0 bottom-0 px-6 pb-4 flex flex-col justify-end text-left
             h-24 bg-gradient-to-b from-transparent to-dark">
                    <div class="flex items-center">
                        <span class="text-base font-medium text-white">{{project.naam}}</span>
                        <app-is-favorite [project]="project"></app-is-favorite>
                    </div>
                    <span class="text-xs font-medium text-white text-opacity-80 truncate">{{project.bedrijf}}</span>
                </div>
            </ng-template>
            <ng-template #defaultImage>
                <div class="absolute inset-0 bottom-18 bg-neutral-4">
            <span class="top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2 object-contain"
                  inlineSVG="/assets/icons/thumbnail.svg"></span>
                </div>
                <div class="absolute inset-x-0 bottom-0 px-6 pb-4 flex flex-col justify-end text-left h-18 bg-white">
                    <div class="flex items-center">
                        <span class="text-base font-medium text-dark truncate">{{project.naam}}</span>
                        <app-is-favorite [project]="project"></app-is-favorite>
                    </div>
                    <span class="text-xs font-medium text-neutral-1 truncate">{{project.bedrijf}}</span>
                </div>
            </ng-template>
            <app-is-selected *ngIf="(selectionMode$|async) === 'project'" class="absolute top-2 left-2 group-hover:visible" [project]="project"
                             [ngClass]="selectionService.projectIsSelected(project) ? 'visible' : 'invisible'">
                <div unchecked class="cursor-pointer px-1 bg-white hover:bg-neutral-3 rounded"
                     (click)="addProjectToSelection($event, (project))">
                    <span inlineSVG="/assets/icons/checkbox.svg"></span>
                </div>
                <div checked class="cursor-pointer bg-primary text-white rounded"
                     (click)="removeProjectFromSelection($event, (project))">
                    <span inlineSVG="/assets/icons/checkbox.svg" class="px-1"></span>
                </div>
            </app-is-selected>

        </button>
    </div>
    <div class="flex-grow p-4">
        <div class="text-sm">
            <app-information-item
                labelClasses="w-36"
                label="Opdrachtgever"
                [value]="project.opdrachtgever"
            ></app-information-item>
            <app-information-item
                labelClasses="w-36"
                label="Bedrijf"
                [value]="project.bedrijf"
            ></app-information-item>
            <app-information-item
                labelClasses="w-36"
                label="Locatie"
                [value]="getProjectLocation(project)"
            ></app-information-item>
            <app-information-item
                labelClasses="w-36"
                label="Projectfase"
                [value]="project.operationele_projectstatus"
            ></app-information-item>
            <app-information-item
                labelClasses="w-36"
                label="Werksoort"
                [value]="project.project_type"
            ></app-information-item>
        </div>
    </div>
    <div class="absolute right-6 mt-2">
        <span cdkDragHandle inlineSVG="/assets/icons/icon-16-draganddrop.svg" class="text-neutral-3 hover:text-primary"></span>
        <app-project-card-context-menu class="ml-2" [project]="project"></app-project-card-context-menu>
    </div>
</div>
