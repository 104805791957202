<app-search-result-container [isLoading]="isLoading$|async" [isEmpty]="paginator.isEmpty"
    emptyTitle="Geen projecten gevonden" emptyDescription="Er zijn geen projecten gevonden, probeer het opnieuw via de zoekbalk"
>
    <div class="relative">
        <div class="pb-4 flex items-center">
            <app-export-dropdown *ngIf="!isOnMobile" titleText="Resultaten" table="project" label="Projecten exporteren">
            </app-export-dropdown>
            <a class="ml-auto text-primary font-medium text-right"
               routerLink="/projects/search" queryParamsHandling="preserve">
                Geavanceerd zoeken
            </a>
            <app-results-view-switch class="mobile:hidden">
            </app-results-view-switch>
            <app-pagination class="mobile:hidden ml-2" [paginator]="paginator"></app-pagination>
        </div>
        <ng-container *ngIf="paginator.content$|async as projects">
            <app-project-tile-grid
                *ngIf="(gridView$|async) || isOnMobile; else listView"
                 [isLoading]="paginator.isLoading"
                [projects]="projects"
            >
            </app-project-tile-grid>
            <ng-template #listView>
                <app-project-list [projects]="projects"></app-project-list>
            </ng-template>
        </ng-container>
        <div class="mt-4 flex items-end flex-col">
            <app-pagination [paginator]="paginator"></app-pagination>
        </div>
    </div>
</app-search-result-container>
