import {Component, EventEmitter, forwardRef, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {AppRoleAssignmentService} from '../../services/app-role-assignment.service';
import {catchError, debounceTime, switchMap, tap} from 'rxjs/operators';
import {User} from '@microsoft/microsoft-graph-types';
import {NgOption} from '@ng-select/ng-select';

@Component({
    selector: 'app-multi-select-users',
    templateUrl: './multi-select-users.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MultiSelectUsersComponent),
        multi: true
    }]
})
export class MultiSelectUsersComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() label = '';
    @Input() labelDescription = '';
    @Input() readonly = false;
    @Output() add = new EventEmitter<User>();
    @Output() remove = new EventEmitter<User>();

    private onChange: (items: User[]) => void;
    private onTouched: () => void;
    private currentValue: User[];
    private subscriptions: Subscription[] = [];

    control = new UntypedFormControl();
    loading = false;
    onInput = new BehaviorSubject<string>('');
    onFilter = new BehaviorSubject<string>('');

    users$ = this.onInput.pipe(
        debounceTime(200),
        tap(() => this.loading = true),
        switchMap(term => {
            return this.appRoleAssignmentService.searchUsers(term).pipe(
                catchError(() => of([]))
            );
        }),
        tap(() => this.loading = false),
    );

    constructor(
        @Inject('AppRoleAssignmentService') private appRoleAssignmentService: AppRoleAssignmentService
    ) {
    }

    @Input() set filter(filter: string) {
        if (this.currentValue) {
            this.control.setValue(
                this.currentValue.filter(it => it.displayName.toLowerCase().includes(filter.toLowerCase())),
                {emitEvent: false}
            );
        }
    }

    ngOnInit() {
        this.subscriptions.push(
            this.control.valueChanges.subscribe(users => this.onChange?.(users))
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions = [];
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    writeValue(users: User[]) {
        this.currentValue = users;
        this.control.setValue(users, {emitEvent: true});
    }

    onAdd(user: User) {
        this.add.emit(user);
    }

    onRemove(option: NgOption) {
        this.remove.emit(this.currentValue.find(it => it.id === (option.value as User).id));
    }
}
