import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import type {ApplicationInsights} from '@microsoft/applicationinsights-web';

@Injectable({
    providedIn: 'root'
})
export class ApplicationInsightsService {
    private appInsights: Promise<ApplicationInsights> = null;

    constructor() {
        if (environment.searchInstrumentationKey !== null) {
            this.appInsights = import('../utils/lazy-application-insights')
                .then(module => {
                    const instance = module.newApplicationInsights(environment.searchInstrumentationKey);
                    instance.loadAppInsights();
                    instance.trackPageView();

                    return instance;
                }).catch(error => {
                    console.warn('Failed to initialize appInsights', error);
                    this.appInsights = null;
                    throw error;
                });
        }
    }

    async trackKaartenserviceOpen() {
        if (this.appInsights !== null) {
            (await this.appInsights).trackEvent({name: 'Kaartenservice.Open'});
        }
    }

    async trackKaartenserviceStart() {
        if (this.appInsights !== null) {
            (await this.appInsights).trackEvent({name: 'Kaartenservice.Start'});
        }
    }

    async trackSearch(id: string, index: string, query: string, favorite: boolean, count: number) {
        if (this.appInsights !== null) {
            (await this.appInsights).trackEvent({name: 'Search'}, {
                /* eslint-disable @typescript-eslint/naming-convention */
                SearchServiceName: environment.searchServiceName,
                SearchId: id,
                IndexName: index,
                QueryTerms: query,
                ResultCount: count,
                Favorite: favorite
                /* eslint-enable @typescript-eslint/naming-convention */
            });
        }
    }

    async trackClick(searchId: string, docId: string, docRank: number) {
        if (this.appInsights !== null) {
            (await this.appInsights).trackEvent({name: 'Click'}, {
                /* eslint-disable @typescript-eslint/naming-convention */
                SearchServiceName: environment.searchServiceName,
                SearchId: searchId,
                ClickedDocId: docId,
                Rank: docRank
                /* eslint-enable @typescript-eslint/naming-convention */
            });
        }
    }
}
