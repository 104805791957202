<div class="project-app-store-item-header"
     [class.project-app-store-item-header--primary]="appDisplayType === 'primary'"
     [class.project-app-store-item-header--secondary]="appDisplayType === 'secondary'"
     [class.project-app-store-item-header--other]="appDisplayType === 'other'"
>
    <div class="project-app-store-item-logo">
        <div class="project-app-store-item-logo__asset">
            <span inlineSVG="/assets/icons/icon-bim.svg"></span>
        </div>
    </div>

    <div class="project-app-store-item-header__content">
        <div class="project-app-store-item-header__content__title">{{ appTitle | titlecase }}</div>
        <div class="project-app-store-item-header__content__subtitle">{{ appSubtitle }}</div>
    </div>
</div>
