import {Component, OnDestroy, OnInit} from '@angular/core';
import {distinctUntilChanged, map, pairwise, withLatestFrom} from 'rxjs/operators';
import {merge, of, Subscription} from 'rxjs';
import {ApiService} from '../../services/api.service';
import {SearchStateService} from '../../services/search-state.service';
import {SearchFacetsService} from '../../services/search-facets.service';
import {CallbackPaginator} from '../../utils/paginator/callback-paginator';
import {SearchFilterResponseWithParams} from '../../utils/paginator/abstract-paginator';
import {SearchCompany} from '../../model/search-company';
import {companySortOptions} from 'src/app/model/sort/company-sort-options';
import {Company} from 'api/models/asset/company';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {SearchStatePaginator} from '../../utils/paginator/search-state-paginator';
import {ScreenService} from '../../services/screen.service';

@Component({
    selector: 'app-companies-search',
    templateUrl: './companies-search.component.html'
})
export class CompaniesSearchComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    companySortOptions = companySortOptions;
    isOnMobile = false;
    gridView$ = this.searchStateService.gridView$;

    allResultsCompaniesPaginator = new SearchStatePaginator<Company>(
        this.searchStateService,
        (page, pageSize, query, favorite, sort, filter) => {
            return this.apiService.filter<Company>('company', '', query, favorite, sort, 0, 100000, filter);
        }
    );

    paginator = new CallbackPaginator<SearchCompany, any, any>(
        this.searchStateService.searchState$.pipe(
            map(({page, pageSize, query, favorite, sort, filter}) => {
                return ({page, pageSize, query, favorite, sort, filter});
            }),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
        ),
        (page, pageSize, query, favorite, sort, filter) => {
            return this.apiService.filter<SearchCompany>(
                'company',
                '',
                query,
                favorite,
                sort,
                page,
                pageSize,
                filter
            );
        },
        page => this.searchStateService.setPage(page),
        pageSize => this.searchStateService.setPageSize(pageSize),
        sort => this.searchStateService.setTableSort({
            field: sort, direction: 'ASC'
        })
    );

    constructor(
        private apiService: ApiService,
        private searchStateService: SearchStateService,
        private searchFacetsService: SearchFacetsService,
        private mapboxService: MapboxService,
        private screenService: ScreenService
    ) {
    }

    ngOnInit() {
        this.subscriptions.push(this.allResultsCompaniesPaginator.responseWithParams$.pipe(
            withLatestFrom(this.screenService.screen$,  (response, screen) => ({response, screen})),
        ).subscribe(({response, screen})  => {
            this.searchStateService.pointResults$.next(response.value.map(item => ({
                ...item, title: item.naam, id: item.kvw_nummer
            })));
            const filteredResults = (response.value.filter(result => result.geog !== null)).map(result => result.geog.coordinates);
        }));

        this.subscriptions.push(merge<SearchFilterResponseWithParams<Company>>(
            of(null),
            this.paginator.responseWithParams$
        ).pipe(pairwise()).subscribe(([previous, current]) => {
            const facetsChanged = previous === null
                || previous.params.query !== current.params.query
                || previous.params.favorite !== current.params.favorite;
            this.searchFacetsService.facets$.next({facets: current.facets, facetsChanged});
        }));

        this.subscriptions.push(
            this.screenService.screen$.subscribe(screen => this.isOnMobile = screen === 'mobile')
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];

        // Remove additional point results on closing of results page
        this.searchStateService.pointResults$.next([]);
    }
}
