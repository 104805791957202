import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Project} from 'api/models/asset/project';
import {SelectionService} from '../../services/selection.service';
import {Router} from '@angular/router';
import {SelectionHelper} from '../is-selected/selection-helper';
import {hasCommercialProjectName} from '../../utils/project-name';

@Component({
    selector: 'app-project-tile',
    templateUrl: './project-tile.component.html'
})
export class ProjectTileComponent extends SelectionHelper {
    @Input() project: Project;
    @Output() clicked = new EventEmitter<Project>();
    selectionMode$ = this.selectionService.selectionMode$;
    hasCommercialProjectName = hasCommercialProjectName;

    constructor(
        protected selectionService: SelectionService,
        protected router: Router) {
        super(selectionService, router);
    }

    getProjectNameTooltipText(project: Project): string {
        return `Dit project staat ook bekend als: ${project.naam}`;
    }
}
