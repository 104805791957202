import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MapStyle} from '../../services/mapbox/map-styles';
import {MapConfigService} from '../../services/mapbox/map-config.service';
import {BuildingColorMode} from 'api/models/building-color-mode';
import {MaatvoeringLayerMode} from 'api/models/maatvoering-layer-mode';
import {
    defaultLayerOrder,
    MapBuildingLayerKeysToControl,
    MapConfig,
    MapConfigLayers,
    MapLayer,
    mapLayers
} from '../../model/map-config';
import {filter, map, pluck, throttle} from 'rxjs/operators';
import {SaveStates} from '../../model/save-states';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {VwuiModalService} from '@recognizebv/vwui-angular';
import {LayerInformationModalComponent} from '../../components/layer-information-modal/layer-information-modal.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {UserPreferencesService} from '../../services/user-preferences.service';
import {RadioButtonOption} from '../../model/radio-button-option';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-layers',
    templateUrl: './layers.component.html',
    styleUrls: ['./layer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LayersComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    readonly layers: MapLayer[] = mapLayers.filter(it => it.layerKey !== 'bgt');
    readonly buildingMode = BuildingColorMode;
    readonly maatvoeringMode = MaatvoeringLayerMode;
    readonly mapConfig$ = this.mapConfigService.asObservable();
    readonly anyLayerActive$ = this.mapConfig$.pipe(
        map(config => Object.keys(config.layers).filter(layer => config.layers[layer].active).length > 0)
    );
    readonly buildingModeOptions: RadioButtonOption[] = [
        {label: 'Geen info', value: BuildingColorMode.NONE},
        {label: 'Energielabels', value: BuildingColorMode.ENERGYLABEL},
        {label: 'Bouwjaar', value: BuildingColorMode.BOUWJAAR},
    ];
    saveState$ = new BehaviorSubject<SaveStates>(SaveStates.INITIAL);
    saveStateFeedback = '';

    get saveStates(): typeof SaveStates {
        return SaveStates;
    }

    get getLayerOrder() {
        return this.userPreferencesService.asObservable().pipe(
            map(preferences => preferences
                ? {...preferences, layerOrder: preferences.layerOrder || []}
                : {layerOrder: defaultLayerOrder}
            ),
            pluck('layerOrder')
        );
    }

    constructor(private mapConfigService: MapConfigService,
                private modalService: VwuiModalService,
                private userPreferencesService: UserPreferencesService,
                private mapboxService: MapboxService,
                private toastService: ToastrService
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.getLayerOrder.pipe(
                filter(layerOrder => layerOrder !== [])
            ).subscribe((layerOrder) => {
                this.layers.sort((a, b) => {
                    return layerOrder.indexOf(a.layerKey) - layerOrder.indexOf(b.layerKey);
                });
            })
        );
        this.saveState$.pipe(
            throttle((saveState) => {
                if (saveState === SaveStates.SAVED) {
                    return interval(3000);
                }
                return interval(0);
            })).subscribe((saveState: SaveStates) => {
            switch (saveState) {
                case SaveStates.INITIAL:
                    this.saveStateFeedback = '';
                    break;
                case SaveStates.SAVING:
                    this.saveStateFeedback = 'Wijzigingen opslaan...';
                    break;
                case SaveStates.SAVED:
                    this.saveState$.next(SaveStates.INITIAL);
                    this.saveStateFeedback = 'Wijzigingen opgeslagen.';
                    break;
                case SaveStates.FAILED:
                    this.saveStateFeedback = 'Wijzigingen niet opgeslagen...';
                    break;
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    async selectMapStyle(mapStyle: MapStyle) {
        await this.mapConfigService.selectMapStyle(mapStyle);
        await this.savePreferences();
    }

    async setLayerActive(layerKey: keyof MapConfigLayers, active: boolean) {
        if (layerKey === 'buildingLayer') {
            for (const layerKeyToControl of MapBuildingLayerKeysToControl) {
                await this.mapConfigService.updateLayer(layerKeyToControl as keyof MapConfigLayers, {active});
            }
        }

        await this.mapConfigService.updateLayer(layerKey, {active});
        await this.savePreferences();
        if (!this.mapboxService.checkZoomRange()) {
            this.toastService.info('Niet alle kaartlagen zijn zichtbaar, zoom in om die te tonen.');
        }
    }

    async setLayerOpacity(layerKey: keyof MapConfigLayers, opacity: number) {
        if (layerKey === 'buildingLayer') {
            for (const layerKeyToControl of MapBuildingLayerKeysToControl) {
                await this.mapConfigService.updateLayer(layerKeyToControl as keyof MapConfigLayers, {opacity, active: true});
            }
        }
        await this.mapConfigService.updateLayer(layerKey, {opacity, active: true});
        await this.savePreferences();
    }

    async setBuildingColorMode(mode: BuildingColorMode) {
        await this.mapConfigService.updateLayer('buildingLayer', {mode});
        await this.savePreferences();
    }

    async setMaatvoeringMode(mode: MaatvoeringLayerMode) {
        await this.mapConfigService.updateLayer('maatvoering', {mode});
        await this.savePreferences();
    }

    async disableAllLayers(mapConfig: MapConfig) {
        const configLayers = mapConfig.layers;
        const layers: Partial<MapConfigLayers> = {};
        for (const key in configLayers) {
            if (configLayers.hasOwnProperty(key)) {
                layers[key] = {
                    ...mapConfig.layers[key],
                    active: false
                };
            }
        }

        await this.mapConfigService.update({
            layers: layers as MapConfigLayers
        });
    }

    async savePreferences() {
        this.saveState$.next(SaveStates.SAVING);
        return await this.mapConfigService.saveToPreferences().then(() => {
            this.saveState$.next(SaveStates.SAVED);
        }).catch(e => {
            this.saveState$.next(SaveStates.FAILED);
            console.error('Saving could not happen', e);
        });
    }



    async openLayerInfoModal($event, layer: MapLayer) {
        $event.stopPropagation();
        this.modalService.open(LayerInformationModalComponent, {
            data: {...layer},
            modalClass: 'modal-lg',
            closeOnBackdropClick: false,
        });
    }

    drop(event: CdkDragDrop<string[]>) {
        const waitForItemsMoved = new Promise<void>((resolve) => {
            moveItemInArray(this.layers, event.previousIndex, event.currentIndex);
            resolve();
        });
        this.saveState$.next(SaveStates.SAVING);
        waitForItemsMoved.finally(() => {
            const layerOrder = this.layers.map(layer => layer.layerKey);
            this.userPreferencesService.patch({layerOrder}).toPromise().then(() => {
                this.saveState$.next(SaveStates.SAVED);
            }).catch(e => {
                this.saveState$.next(SaveStates.FAILED);
                console.error('Saving could not happen', e);
            });
        });
    }
}
