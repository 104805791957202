import {Component, OnDestroy, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {delay, filter, finalize, map, pluck, switchMap, take, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {Project} from 'api/models/asset/project';
import {UntypedFormControl} from '@angular/forms';
import {SaveStates} from '../../model/save-states';
import {Collection} from 'api/models/collection';
import {SearchFilterResponse} from '../../model/search-response';
import {CollectionsService} from '../../services/collections.service';
import {SearchStateService} from '../../services/search-state.service';
import {MobileService} from '../../services/mobile.service';
import {RouterHistoryService} from '../../services/router-history.service';
import {detailPageGoBack} from '../../utils/navigation';

@Component({
    selector: 'app-companies-detail',
    templateUrl: './collections-detail.component.html'
})
export class CollectionsDetailComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    refresh$ = new BehaviorSubject<void>(null);
    saveState$ = new BehaviorSubject<SaveStates>(SaveStates.INITIAL);
    loading = new BehaviorSubject<boolean>(false);
    query$ = new BehaviorSubject<string>('');
    queryControl = new UntypedFormControl();
    collection$ = combineLatest([this.refresh$, this.activatedRoute.paramMap]).pipe(
        tap(() => this.loading.next(true)),
        switchMap(([_, paramMap]) => {
            this.loadingCollection = true;
            return this.apiService.getCollection(paramMap.get('collectionId'))
                .pipe(
                    finalize(() => this.loadingCollection = false)
                );
        })
    );
    projects$: Observable<SearchFilterResponse<Project>> = this.collectionsService.refreshCollections$.pipe(
        switchMap(_ => combineLatest([this.collection$, this.query$])),
        switchMap(([collection, query]) => {
            this.loadingProjects = true;
            return this.apiService.filter<Project>('project', '', query,
                false, '', 0, collection.items.length, {id: collection.items})
                .pipe(
                    map((response) => {
                        const order = {};
                        collection.items.forEach((a, i) => {
                            order[a] = i;
                        });
                        response.value.sort((a, b) => {
                            return order[a.id] - order[b.id];
                        });
                        return response;
                    }),
                    finalize(() => this.loadingProjects = false)
                );
        })
    );
    projects: Project[] = [];
    collection: Collection;
    isOnMobile = false;
    loadingCollection = true;
    loadingProjects = true;
    saveStates = SaveStates;
    suggestions$ = this.queryControl.valueChanges.pipe(
        filter(it => it !== ''),
        switchMap(
            query => this.apiService.filter<Project>(
                'project', '', query,
                false, '', 0, this.collection.items.length, {id: this.collection.items}
            ).pipe(
                pluck('value')
            )
        )
    );

    goBack = detailPageGoBack(this.router, this.routerHistory, ['/collections']);

    constructor(
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private collectionsService: CollectionsService,
        private mapboxService: MapboxService,
        private mobileService: MobileService,
        private router: Router,
        private routerHistory: RouterHistoryService,
        private searchStateService: SearchStateService,
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.saveState$.pipe(
                filter(saveState => saveState === SaveStates.SAVED),
                delay(3000)
            ).subscribe(() => {
                this.saveState$.next(SaveStates.INITIAL);
            }),
            this.projects$.subscribe(projects => {
                this.searchStateService.setFilter({id: projects.value.map(it => it.id)}, true);
                this.projects = projects.value;
            }),
            this.collection$.subscribe(collection => {
                this.collection = collection;
                this.collectionsService.currentCollection = collection;
            }),
            this.mobileService.isOnMobile$.subscribe(onMobile => this.isOnMobile = onMobile)
        );
        this.mobileService.navbarEnabled$.next(false);
    }

    ngOnDestroy() {
        this.searchStateService.setFilter({});
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
        this.mobileService.navbarEnabled$.next(true);
    }

    onSearchClear() {
        this.queryControl.reset();
    }

    async loadMap() {
        if (!this.mapboxService.mapLoaded$.value) {
            await this.mapboxService.mapLoaded$.pipe(filter(it => it), take(1)).toPromise();
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        const waitForItemsMoved = new Promise<void>((resolve) => {
            moveItemInArray(this.projects, event.previousIndex, event.currentIndex);
            resolve();
        });
        waitForItemsMoved.finally(() => {
            this.saveCollection();
        });
    }

    async saveCollection(): Promise<void> {
        this.saveState$.next(SaveStates.SAVING);
        const collection: Collection = {
            ...this.collection,
            items: this.projects.map(projects => projects.id)
        };
        return await this.apiService.postCollection(collection).toPromise()
            .then((savedCollection) => {
                this.collection = savedCollection;
            })
            .catch(e => {
                this.saveState$.next(SaveStates.FAILED);
                console.error('Saving could not happen', e);
            }).finally(() => {
                this.refresh$.next();
                this.saveState$.next(SaveStates.SAVED);
            });
    }

    handleSearchProject(query: string) {
        this.query$.next(query);
    }
}
