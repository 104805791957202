<button
    type="button"
    class="inline-flex relative h-12 border border-neutral-3 rounded text-primary font-medium items-center shadow-button
            desktop:hover:bg-neutral-4 active:bg-neutral-3 active:shadow-none outline-0 disabled:opacity-75"
    [class.icon-button--loading]="loading"
    [class.cursor-not-allowed]="disabled"
    [ngClass]="iconOnly ? 'px-3' : 'px-6'"
    [disabled]="disabled || loading"
    [tooltip]="tooltip"
    (click)="onClick($event)"
>
    <span *ngIf="loading; else showContent" inlineSVG="/assets/icons/spinner.svg"></span>
    <ng-template #showContent>
        <span
            *ngIf="icon"
            [inlineSVG]="icon"
            [class.mr-4]="!iconOnly"
        ></span>
    </ng-template>
    <span class="text-m" *ngIf="!iconOnly"><ng-content></ng-content>{{label}}</span>
    <span class="absolute flex items-center content-center -top-1 -right-1 bg-primary rounded-full h-3.5 px-1 text-xxs text-white font-bold"
          *ngIf="badgeCount !== null && badgeCount > 0"
    >{{badgeCount}}</span>
</button>
