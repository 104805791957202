import {Component} from '@angular/core';
import {SearchVerblijfsobject} from '../../model/search-verblijfsobject';
import {SearchStatePaginator} from '../../utils/paginator/search-state-paginator';
import {SearchStateService} from '../../services/search-state.service';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-search-verblijfsobjecten',
  templateUrl: './search-verblijfsobjecten.component.html'
})
export class SearchVerblijfsobjectenComponent {
    gridView$ = this.searchStateService.gridView$;

    paginator = new SearchStatePaginator<SearchVerblijfsobject>(
        this.searchStateService,
        (page, pageSize, query, favorite, sort, filter) => {
            return this.apiService.filter<SearchVerblijfsobject>(
                'verblijfsobject',
                '',
                query,
                favorite,
                sort,
                page,
                pageSize,
                filter
            );
    });
    isLoading$ = this.searchStateService.loadingSearchResults$;

  constructor(
      private searchStateService: SearchStateService,
      private apiService: ApiService
  ) { }

}
