import {Component, OnDestroy, OnInit} from '@angular/core';
import {filter, finalize, map, pluck, shareReplay, switchMap, take} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {Company, companyCorrespondenceAddressString, companyVestigingAddressString} from 'api/models/asset/company';
import {RouterHistoryService} from '../../services/router-history.service';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {MapConfigService} from '../../services/mapbox/map-config.service';
import {Subscription} from 'rxjs';
import {SearchStateService} from '../../services/search-state.service';
import {Project} from 'api/models/asset/project';
import {MobileService} from '../../services/mobile.service';
import {PointResult} from '../../model/search-response';

@Component({
    selector: 'app-companies-detail',
    templateUrl: './companies-detail.component.html'
})
export class CompaniesDetailComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    company$ = this.activatedRoute.paramMap.pipe(
        switchMap(paramMap => {
            this.loadingCompany = true;
            return this.apiService.getMetadata<Company>('company', paramMap.get('companyId'))
                .pipe(
                    map(response => response.asset),
                    finalize(() => this.loadingCompany = false)
                );
        }),
        shareReplay(1)
    );
    companyDetails$ = this.company$.pipe(
        map(company => {
            return [
                {label: 'Naam', value: company.naam},
                {label: 'Handelsnaam', value: company.handelsnamen},
                {label: 'KVW-nummer', value: company.kvw_nummer},
                {label: 'KVK-nummer', value: company.kvk_nummer},
                {label: 'KVK-vestigingsnummer', value: company.kvk_vestigingsnummer},
                {label: 'Is hoofdvestiging', value: company.is_hoofdvestiging ? 'Ja' : 'Nee'},
                {label: 'Divisie naam', value: company.divisie_naam},
                {
                    label: 'Vestigingsadres',
                    value: companyVestigingAddressString(company)
                },
                {
                    label: 'Correspondentie-adres',
                    value: companyCorrespondenceAddressString(company)
                },
            ];
        })
    );
    loadingCompany = true;
    loadingProjects = true;
    projectsExpanded = true;
    projects$ = this.company$.pipe(
        switchMap(company => {
            this.loadingProjects = true;
            return this.apiService.filter<Project>('project', '', '',
                false, '-aangemaakt_op', 0, 10, {kvw_nummer: [company.kvw_nummer]}).pipe(
                finalize(() => this.loadingProjects = false)
            );
        })
    );
    buildingsLayerWasNotActive = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private router: Router,
        private routerHistory: RouterHistoryService,
        private mapboxService: MapboxService,
        private mapConfigService: MapConfigService,
        private mobileService: MobileService,
        private searchStateService: SearchStateService
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.mobileService.navbarEnabled$.next(false);
        this.subscriptions.push(
            this.company$.subscribe((company) => {
                this.loadMap().then(async () => {
                    if (company?.geog?.coordinates[1]) {
                        this.mapboxService.map.flyTo({
                            center: [company.geog.coordinates[0], company.geog.coordinates[1]],
                            zoom: 17,
                            pitch: 70,
                            bearing: 0
                        });
                    }

                    const allPoints = this.searchStateService.pointResults$.value.length === 0
                        ? await this.apiService.allCompanies$.pipe(
                            pluck('value'),
                            map(points => points.map(
                                item => ({...item, title: item.naam, id: item.kvw_nummer, type: 'company'} as PointResult)))
                        ).toPromise()
                        : this.searchStateService.pointResults$.value;
                    this.searchStateService.pointResults$.next(
                        allPoints.map(
                            point => point.id === company.kvw_nummer
                                ? {...point, type: 'activeCompany'}
                                : {...point, type: point.type === 'activeCompany' ? 'company' : point.type}
                        )
                    );
                });
            })
        );
    }

    ngOnDestroy() {
        this.mobileService.navbarEnabled$.next(true);
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
        // Remove additional point results on closing of page
        this.searchStateService.pointResults$.next([]);
        if (this.buildingsLayerWasNotActive) {
            this.mapConfigService.updateLayer('buildings', {active: false});
        }
    }

    goToProjects(kvwNummer: string, totalProjects: number) {
        if (totalProjects === 0) {
            return;
        }
        this.router.navigate(
            ['/projects/search'],
            {
                queryParamsHandling: 'merge',
                queryParams: {
                    filter: JSON.stringify({kvw_nummer: [kvwNummer]}),
                    favorite: undefined
                }
            }
        ).catch(e => {
            console.error('search failed', e);
        });
    }

    async loadMap() {
        if (!this.mapboxService.mapLoaded$.value) {
            await this.mapboxService.mapLoaded$.pipe(filter(it => it), take(1)).toPromise();
        }
    }

    goBack() {
        this.router.navigate(['companies'], {
            queryParamsHandling: 'preserve'
        });
    }
}
