import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Project} from 'api/models/asset/project';
import {hasCommercialProjectName} from '../../utils/project-name';

@Component({
    selector: 'app-project-header',
    templateUrl: './project-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectHeaderComponent {
    @Input() project: Project | null = null;
    @Output() goBack = new EventEmitter<void>();
    hasCommercialProjectName = hasCommercialProjectName;
    handleGoBack() {
        this.goBack.emit();
    }

    getProjectNameTooltipText(project: Project): string {
        return `Dit project staat ook bekend als: ${project.naam}`;
    }
}
