import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-icon-button',
    templateUrl: './icon-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent {
    @Input() label: string;
    @Input() icon: string;
    @Input() loading: boolean;
    @Input() disabled = false;
    @Input() badgeCount: number | null = null;
    @Input() iconOnly = false;
    @Input() tooltip = '';
    @Output() buttonPressed = new EventEmitter<MouseEvent>();

    onClick($event: MouseEvent) {
        this.buttonPressed.emit($event);
    }
}
