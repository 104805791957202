import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AbstractPaginator} from '../utils/paginator/abstract-paginator';
import {filter, withLatestFrom} from 'rxjs/operators';
import {projectSortOptions} from '../model/sort/project-sort-options';

@Injectable({
    providedIn: 'root'
})
export class SortService implements OnDestroy {
    private subscriptions: Subscription[] = [];
    private isGrid$ = new BehaviorSubject<boolean>(false);
    private resetSort = false;
    private previousState = '';
    currentState$ = new BehaviorSubject<any>('');
    currentSort$ = new BehaviorSubject<States>(0);
    updateSort$ = new BehaviorSubject<void>(null);
    paginator: AbstractPaginator<any>;
    projectSortOptions = projectSortOptions;
    defaultState = '';

    constructor() {
        this.subscriptions.push(
            this.updateSort$.pipe(
                withLatestFrom(this.currentSort$, this.currentState$, this.isGrid$),
                filter(() => this.paginator !== undefined)).subscribe(
                ([_, sort, state, currentView]) => {
                    sort++;
                    if (currentView && sort > 2 || state !== this.previousState && !currentView) {
                        this.resetSort = false;
                        sort = 1;
                    }
                    switch (sort) {
                        case States.Ascending:
                            state = state.replace('-', '');
                            this.paginator.setSort(state);
                            break;
                        case States.Descending:
                            if (state.includes('-')) {
                                this.paginator.setSort(state);
                            } else {
                                this.paginator.setSort('-' + state);
                            }
                            this.currentState$.next(state);
                            break;
                    }
                    if (sort > 2 && !currentView) {
                        this.currentState$.next(this.defaultState);
                        sort = 0;
                        this.paginator.setSort(this.defaultState);
                    }
                    this.currentSort$.next(sort);
                    this.previousState = state;
                })
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    sortBy(state: string, isGrid: boolean) {
        this.isGrid$.next(isGrid);
        this.currentState$.next(state);
        this.updateSort$.next();
    }

    updateState(isGrid: boolean) {
        this.isGrid$.next(isGrid);
        this.updateSort$.next();
    }
}

enum States {
    Default,
    Ascending,
    Descending
}
