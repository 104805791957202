<div class="mobile:fixed mobile:w-full mobile:left-0 mobile:z-20 bg-white">
    <div class="ml-2 mr-4 py-6 flex space-x-2 items-center border-b border-neutral-3">
        <button (click)="goBack()">
            <svg
                class="transform origin-center w-4 h-4 cursor-pointer"
                inlineSVG="/assets/icons/icon-arrow-left.svg"
            ></svg>
        </button>
        <app-collections-name-input class="flex flex-grow" [collection]="collection"></app-collections-name-input>
        <app-icon-button class="mr-auto" icon="/assets/icons/icon-16-monitor.svg" (buttonPressed)="navigateToPrsPresentation()">
            Presenteren
        </app-icon-button>
    </div>
</div>
<div class="desktop:hidden h-24"></div>
