import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Collection} from 'api/models/collection';
import {Project} from 'api/models/asset/project';

@Component({
    selector: 'app-collections-search-input',
    templateUrl: './collections-search-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CollectionsSearchInputComponent),
            multi: true,
        }
    ]
})
export class CollectionsSearchInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() placeholder = 'Zoeken...';
    @Input() showBackButton = false;
    @Input() suggestions: Project[]|Collection[] = [];
    @Input() suggestionClickable = true;
    @Input() type: 'project'|'collection' = 'collection';
    @Output() clickedOnSuggestion = new EventEmitter<Collection|Project>();
    @Output() submit = new EventEmitter<string>();

    private subscriptions: Subscription[] = [];

    // CurrentValue contains current searched value
    currentValue = '';
    // InputControl contains current typed value which will be written to currentValue on Enter or click on search icon
    inputControl = new UntypedFormControl('');
    showSuggestions = false;
    loading = false;

    ngOnInit(): void {
        this.subscriptions.push(
            this.inputControl.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.showSuggestions = true;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    onChange: (value: string) => void = () => {};
    onTouch: () => void = () => {};

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(obj: string): void {
        this.currentValue = obj;
        this.inputControl.setValue(obj);
    }

    clear() {
        this.inputControl.setValue('');
    }

    commitValue() {
        this.currentValue = this.inputControl.value?.trim();
        this.submit.emit(this.currentValue);
        this.showSuggestions = false;
    }

    clearAndCommit() {
        this.clear();
        this.commitValue();
    }

    search(text: string) {
        this.inputControl.setValue(text);
        this.commitValue();
    }

    handleClickOnSuggestion(suggestion: any) {
        this.clickedOnSuggestion.emit(suggestion);
    }
}
