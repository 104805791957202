<input
    *ngIf="(filterListOpenedSubject|async) && (collapsable$|async)"
    class="w-full border border-neutral-3 px-2 py-1 rounded focus:border-primary text-m focus:shadow-none"
    type="text"
    placeholder="Filter"
    [formControl]="filterControl"
/>
<div *ngFor="let option of (visibleOptions$|async) index as i" class="custom-checkbox" (click)="toggleOption(option, $event)">
    <input [id]="inputIdPrefix + i" type="checkbox" [checked]="option.enabled">
    <label [for]="inputIdPrefix + i">
        <span inlineSVG="/assets/icons/icon-check.svg"></span>
        {{ option.label }}
    </label>
</div>
<button (click)="toggleFilterList()" *ngIf="(collapsable$|async)" class="mr-auto ml-9 text-primary mt-1 font-medium">
    {{(filterListOpenedSubject|async) ? filterControl.value.trim() !== "" ? "Stop filter" : "Toon minder" : "Toon meer"}}
</button>
