import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-information-item',
    templateUrl: './information-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationItemComponent {
    @Input() label = '';

    @Input() set value(v: string | string[] | null | undefined) {
        if (!v || (Array.isArray(v) && v.length === 0)) {
            this.values = ['-'];
        } else if (typeof v === 'string') {
            this.values = [v];
        } else {
            this.values = v;
        }
    }

    @Input() labelClasses: string | string[] = [];

    values: string[] = ['-'];
}
