import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Style } from 'mapbox-gl';
import { ArcGisStyle } from '../../model/arcgis-style';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TileJsonService {
    constructor(private http: HttpClient) {
    }

    async fetchArcgisTileJson(styleName: string): Promise<Style> {
        const styleBaseUrl = environment.arcgisBasemapStyleUrl;
        const style = await this.http.get<ArcGisStyle>(`${styleBaseUrl}/${styleName}?token=${environment.arcgisApiKey}`).toPromise();
        // eslint-disable-next-line max-len
        const glyphUrl = `https://basemaps-api.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer/resources/fonts/{fontstack}/{range}.pbf?token=${environment.arcgisApiKey}`;

        return {
            version: style.version,
            name: styleName,
            sprite: style.sprite,
            glyphs: glyphUrl,
            sources: style.sources,
            layers: style.layers
        };
    }
}
