<div class="py-10 rounded-full">
    <div class="text-center flex items-center flex-col">
        <h1 class="text-darkBlue_2 pt-2 px-2 pb-3 text-2xl font-medium flex-full">Adblocker ontdekt!</h1>
        <span class="w-90">
            Het ziet er naar uit dat er een adblocker actief is. <br> Om de functionaliteiten van de applicatie volledig te <br>benutten, raden we aan om de adblocker in je browser <br> uit te zetten voor deze website.
        </span>
        <button class="mt-10 mb-2 py-3 px-6 text-white rounded bg-gradient-to-r shadow-lg from-primary to-primary-light"
                (click)="vwuiModalRef.close()">
            Sluiten
        </button>
    </div>
</div>

