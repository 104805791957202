import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import { map} from 'rxjs/operators';
import {SearchFilterResponse} from '../../model/search-response';
import {AbstractPaginator} from './abstract-paginator';

export class SimplePaginator<Item, Sort = any, Filter = any> extends AbstractPaginator<Item, Sort, Filter> {
    private page$: BehaviorSubject<number>;
    private pageSize$: BehaviorSubject<number>;
    private query$: BehaviorSubject<string>;
    private favorite$: BehaviorSubject<boolean>;
    private sort$: BehaviorSubject<Sort>;
    private filter$: BehaviorSubject<Filter>;

    constructor(
        loadFunction: (
            page: number,
            pageSize: number,
            query: string,
            favorite: boolean,
            sort: Sort,
            filter: Filter
        ) => Observable<SearchFilterResponse<Item>>,
        initialPageSize: number = 20
    ) {
        const page$ = new BehaviorSubject(0);
        const pageSize$ = new BehaviorSubject(initialPageSize);
        const query$ = new BehaviorSubject('');
        const favorite$ = new BehaviorSubject(false);
        const sort$ = new BehaviorSubject<Sort>(undefined);
        const filter$ = new BehaviorSubject<Filter>(undefined);

        super(
            combineLatest([
                page$,
                pageSize$,
                query$,
                favorite$,
                sort$,
                filter$
            ]).pipe(
                map(([page, pageSize, query, favorite, sort, filter]) => ({
                    page,
                    pageSize,
                    query,
                    favorite,
                    sort,
                    filter
                }))
            ),
            loadFunction
        );

        this.page$ = page$;
        this.pageSize$ = pageSize$;
        this.query$ = query$;
        this.favorite$ = favorite$;
        this.sort$ = sort$;
        this.filter$ = filter$;
    }

    next(): void {
        if (this.hasNext()) {
            this.page$.next(this.currentPage + 1);
        }
    }

    previous(): void {
        if (this.hasPrevious()) {
            this.page$.next(this.currentPage - 1);
        }
    }

    setPageSize(pageSize: number) {
        this.pageSize$.next(pageSize);
    }

    setSort(sort: Sort) {
        this.sort$.next(sort);
        this.page$.next(0);
    }

    setQuery(query: string) {
        this.query$.next(query);
        this.page$.next(0);
    }
}
