<div *ngIf="projects?.length"
     class="grid gap-4 mobile:gap-3"
     [class.loading-blur]="isLoading"
     [ngClass]="threeColumns ? 'grid-cols-3' : 'grid-cols-2'"
>
    <app-project-tile
        *ngFor="let project of projects; let i = index"
        [project]="project"
        [style.grid-row]="!masonryGrid ? tileHeightDefault : (i % 2 === 0) ? tileHeightLarge : tileHeightDefault"
    ></app-project-tile>
    <div *ngIf="projects.length < 2"></div>
    <div *ngIf="projects.length < 3"></div>
</div>
