export const massaModelsData = [
    {
        id: 'nuon',
        projectenportaal: 556,
        name: 'De Ampère',
        location: [4.65814215, 52.12632863],
        identificatie: '0484100000046304',
        BI: 'nuon/G_B_REG_A_Bruto-Inhoud.glb',
        GO: 'nuon/G_B_REG_A_Adressen.glb'
    },
    {
        id: 'onyx',
        projectenportaal: 530,
        name: 'Onyx Victoriapark Eindhoven',
        location: [5.4726223, 51.44073312],
        identificatie: '0772100001022321',
        BI: 'onyx/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'onyx/G_B_REG_A_Adressen.glb'
    },
    {
        id: 'ravenswade',
        projectenportaal: 345,
        name: 'DigiBase Ravenswade Nieuwegein',
        location: [5.118051, 52.05092356],
        identificatie: '0356100000070909',
        BI: 'ravenswade/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'ravenswade/G_B_REG_A_Bruto-Inhoud.glb',
        GO: 'ravenswade/G_B_REG_A_Adressen.glb'
    },
    {
        id: 'geldershuis',
        projectenportaal: 925,
        name: 'Gelders Huis',
        location: [5.91077149, 51.9771071],
        identificatie: '0202100000269030',
        BI: 'geldershuis/G_B_REG_A_Adressen.glb',
        BVO: 'geldershuis/G_B_REG_A_Adressen.glb'
    },
    {
        id: 'flow',
        projectenportaal: 559,
        name: 'Flow Amsterdam',
        location: [4.8699768, 52.39915926],
        identificatie: '0363100012251311',
        BI: 'flow/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'flow/G_B_REG_A_Adressen.glb'
    },
    {
        id: 'trudotoren',
        projectenportaal: 388,
        name: 'Trudo toren Eindhoven',
        location: [5.4583999543736414, 51.44752142625974],
        identificatie: '0772100001022321',
        BI: 'trudotoren/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'trudotoren/G_B_REG_A_Adressen.glb',
        GO: 'trudotoren/G_B_REG_A_Adressen-GO.glb'
    },
    {
        id: 'podium',
        projectenportaal: null,
        name: 'Podium 9 Amersfoort',
        location: [5.4383655, 52.19038848],
        identificatie: '0307100000533647',
        BI: 'podium/G_B_REG_A_Adressen.glb',
        BVO: 'podium/G_B_REG_A_Adressen.glb'
    },
    {
        id: 'blokm',
        projectenportaal: 727,
        name: 'Overhoeks Blok M',
        location: [4.90493119, 52.38710717],
        identificatie: '0363100012252989',
        BI: 'blokm/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'blokm/G_B_REG_A_Bruto-Inhoud.glb'
    },
    {
        id: 'binnendok',
        projectenportaal: 2994,
        name: 'Binnendok NDSM B3',
        location: [4.890031814896738, 52.40264143266453],
        identificatie: '0363100012254172',
        BI: 'binnendok/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'binnendok/G_B_REG_A_Bruto-Inhoud.glb'
    },
    {
        id: 'pontkade',
        projectenportaal: null,
        name: 'Pontkade B9 Fase 3',
        location: [4.893765449848678, 52.40209158577195],
        identificatie: '0363100012252873',
        BI: 'pontkade/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'pontkade/G_B_REG_A_Bruto-Inhoud.glb'
    },
    {
        id: 'blok10',
        projectenportaal: null,
        name: 'Blok 10 NDSM',
        location: [4.887542658581472, 52.40323054902302],
        identificatie: '0363100012253788',
        BI: 'blok10/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'blok10/G_B_REG_A_Bruto-Inhoud.glb'
    },
    {
        id: 'boerderijkavel',
        projectenportaal: null,
        name: 'Boerderijkavel Westergouwe',
        location: [4.676206044149961, 52.006296740666656],
        identificatie: '',
        BI: 'boerderijkavel/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'boerderijkavel/G_B_REG_A_Bruto-Inhoud.glb'
    },
    {
        id: 'blokh',
        projectenportaal: null,
        name: 'Overhoeks Blok H',
        location: [4.9049019151216395, 52.387688396116374],
        identificatie: '0363100012252988',
        BI: 'blokh/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'blokh/G_B_REG_A_Bruto-Inhoud.glb'
    },
    {
        id: 'hotelgrotemarkt',
        projectenportaal: 1913,
        name: 'Hotel Grote Markt Groningen',
        location: [6.56726108015744, 53.2191461452674],
        identificatie: '0014100022192356',
        BI: 'hotelgrotemarkt/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'hotelgrotemarkt/G_B_REG_A_Bruto-Inhoud.glb'
    },
    {
        id: 'vdven_pietervreedeplein',
        projectenportaal: null,
        name: 'Woongebouw Pieter Vreedeplein Tilburg',
        location: [5.088633226017401, 51.55757609821706],
        identificatie: '',
        BI: 'vdven_pietervreedeplein/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'vdven_pietervreedeplein/G_B_REG_A_Bruto-Inhoud.glb'
    },
    {
        id: 'vdven_pakhuiswest',
        projectenportaal: null,
        name: 'Pakhuis West Tilburg',
        location: [5.091615842433111, 51.558489936091256],
        identificatie: '',
        BI: 'vdven_pakhuiswest/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'vdven_pakhuiswest/G_B_REG_A_Bruto-Inhoud.glb'
    },
    {
        id: 'vdven_stadscampus',
        projectenportaal: null,
        name: 'Woongebouw Stadscampus Tilburg',
        location: [5.084642099048374, 51.56167821937514],
        identificatie: '0855100000714738',
        BI: 'vdven_stadscampus/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'vdven_stadscampus/G_B_REG_A_Bruto-Inhoud.glb'
    },
    {
        id: 'vdven_kernwinkelgebiedzuid',
        projectenportaal: null,
        name: 'Kernwinkelgebied Zuid Tilburg',
        location: [5.088751243206196, 51.55640876034659],
        identificatie: '',
        BI: 'vdven_kernwinkelgebiedzuid/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'vdven_kernwinkelgebiedzuid/G_B_REG_A_Bruto-Inhoud.glb'
    },
    {
        id: 'vdven_terrazzo',
        projectenportaal: null,
        name: 'Terrazzo \'s-Hertogenbosch',
        location: [5.2873312697755415, 51.68506208450528],
        identificatie: '0796100001027760',
        BI: 'vdven_terrazzo/G_B_REG_A_Bruto-Inhoud.glb',
        BVO: 'vdven_terrazzo/G_B_REG_A_Bruto-Inhoud.glb'
    }
];

