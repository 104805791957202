import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent {
    @Input() isChecked = false;
    @Input() isHalfChecked = false;
    @Input() isLoading = false;
    @Input() label = '';
    @Output() toggle = new EventEmitter<boolean>();

    handleToggle() {
        if (!this.isLoading) {
            this.toggle.emit(!this.isChecked);
        }
    }
}
