<h2 appTitle class="mb-4">Achtergrondkaart</h2>
<div class="relative overflow-hidden box-border mb-4">
    <div #scrollContainer class="flex space-x-2 overflow-x-scroll hide-scrollbar">
        <button *ngFor="let mapStyle of mapStyles"
                class="flex-none border p-1 bg-white rounded relative"
                [ngClass]="mapStyle.style === activeMapStyle ? 'border-primary' : 'border-white hover:border-neutral-3'"
                (click)="selectStyle(mapStyle)">
            <div class="relative">
                <img class="rounded" alt=""
                     width="107" height="80"
                     [src]="getStyleSrc(mapStyle.style)"
                     [srcset]="getStyleSrcset(mapStyle.style)"
                />
                <div class="map-style-gradient absolute inset-x-0 bottom-0 top-1/2 flex items-center justify-center text-white font-medium text-xs">
                    {{ mapStyle.label }}
                </div>
            </div>
        </button>
    </div>
    <ng-container *ngIf="showScrollRight">
        <div class="absolute w-4 inset-y-0 right-0 bg-gradient-to-l from-white"></div>
        <button
            class="absolute top-8 right-0 w-6 h-6 rounded-full bg-white border border-neutral-2"
            inlineSVG="/assets/icons/navigation-arrow-icon.svg"
            (click)="scroll(true)"
        ></button>
    </ng-container>

    <ng-container *ngIf="showScrollLeft">
        <div class="absolute w-4 inset-y-0 left-0 bg-gradient-to-r from-white"></div>
        <button
            class="absolute top-8 left-0 w-6 h-6 rounded-full bg-white border border-neutral-2 transform rotate-180"
            inlineSVG="/assets/icons/navigation-arrow-icon.svg"
            (click)="scroll(false)"
        ></button>
    </ng-container>
</div>
