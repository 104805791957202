<app-ui-page-header pageTitle="Apps"></app-ui-page-header>
<app-ui-page-content>
    <ng-container *ngIf="showKaartenservice$|async">
        <app-link
            iconUrl="/assets/icons/icon-kaartenservice.svg"
            label="Kaartenservice"
            routerLink="/apps/map-service"
            queryParamsHandling="preserve"
        ></app-link>
    </ng-container>
    <ng-container *appVisibleForFeatureFlag="appFeatureFlags.mapservicev2">
        <app-link
            iconUrl="/assets/icons/icon-kaartenservice.svg"
            label="Kaartenservice V2"
            routerLink="/apps/map-service-v2"
            queryParamsHandling="preserve"
        ></app-link>
    </ng-container>
    <app-link
        *ngIf="showPRSApp$|async"
        iconUrl="/assets/icons/icon-prs.svg"
        label="PRS Beheer"
        (click)="openPRSOverlay()"
    ></app-link>
    <app-link
        iconUrl="/assets/icons/icon-collections.svg"
        label="Mijn Collecties"
        routerLink="/collections"
        queryParamsHandling="preserve"
    ></app-link>
    <ng-container *appVisibleForFeatureFlag="appFeatureFlags.bedrijfsadmin">
        <app-link
            iconUrl="/assets/icons/icon-prs.svg"
            label="Bedrijfsadmin"
            routerLink="/apps/authorization"
            queryParamsHandling="preserve"
        ></app-link>
    </ng-container>
</app-ui-page-content>
