import {ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureFlagService} from './feature-flag.service';
import {FeatureNameKey} from './feature-flag.types';
import {switchMap} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Directive({
    selector: '[appVisibleForFeatureFlag]'
})
export class VisibleForFeatureFlagDirective implements OnInit, OnDestroy {
    @Input() appVisibleForFeatureFlag: FeatureNameKey;

    private featureFlagSubscription: Subscription | undefined;
    private isCreated = false;

    constructor(
        private featureFlagService: FeatureFlagService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (!this.appVisibleForFeatureFlag) {
            throw new Error('Feature flag name is required for appVisibleForFeatureFlag directive.');
        }

        this.featureFlagSubscription = this.featureFlagService
            .userInitialized$.pipe(
                switchMap(() => this.featureFlagService.isFeatureEnabledForUser(this.appVisibleForFeatureFlag)),
            )
            .subscribe(isEnabled => {
                if (isEnabled) {
                    if (!this.isCreated) {
                        this.viewContainer.createEmbeddedView(this.templateRef);
                        this.cd.detectChanges();
                        this.isCreated = true;
                    }
                } else {
                    if (this.isCreated) {
                        this.viewContainer.clear();
                        this.cd.detectChanges();
                        this.isCreated = false;
                    }
                }
            });
    }

    ngOnDestroy() {
        if (this.featureFlagSubscription) {
            this.featureFlagSubscription.unsubscribe();
        }
    }
}
