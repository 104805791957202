import FreeDraw from 'mapbox-gl-draw-freehand-mode/src/';

let polygonID;
let drawMode;


function disableDoubleClickZoom(ctx) {
    setTimeout(() => {
        if (!ctx.map || !ctx.map.doubleClickZoom) {
            return;
        }
        ctx.map.doubleClickZoom.disable();
    }, 0);
}

export const DrawCustomPolygon = {
    ...FreeDraw,
    onSetup(opts) {
        const polygon = this.newFeature({
            type: 'Feature',
            properties: opts ? {...opts} : {},
            geometry: {
                type: 'Polygon',
                coordinates: [[]]
            }
        });
        this.addFeature(polygon);

        this.clearSelectedFeatures();
        disableDoubleClickZoom(this);
        // disable dragPan
        setTimeout(() => {
            if (!this.map || !this.map.dragPan) {
                return;
            }
            this.map.dragPan.disable();
        }, 0);

        this.updateUIClasses({mouse: 'add'});
        this.activateUIButton('Polygon');
        this.setActionableState({
            trash: true
        });
        polygonID = polygon.id;
        drawMode = this;
        document.onkeyup = drawMode.keyboardControls;
        return {
            polygon,
            currentVertexPosition: 0,
            dragMoving: false
        };
    },
    keyboardControls(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            const polygon = drawMode.getFeature(polygonID);
            if (polygon === undefined) {
                drawMode.changeMode('draw_custom_polygon', {}, {silent: true});
            } else {
                drawMode.map.fire('draw.create', {
                    features: [polygon.toGeoJSON]
                });
                drawMode.changeMode('simple_select', {featureIds: [polygonID]});
                document.onkeyup = null;
            }
        }
        if (event.key === 'Escape') {
            drawMode.deleteFeature([polygonID], {silent: true});
            drawMode.changeMode('draw_custom_polygon', {}, {silent: true});
        }
    }
};
