import {Component, Input, OnChanges} from '@angular/core';
import {Collection} from 'api/models/collection';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CollectionsService} from '../../services/collections.service';
import {ApiService} from '../../services/api.service';

@Component({
    selector: 'app-collections-name-input',
    templateUrl: './collections-name-input.component.html'
})
export class CollectionsNameInputComponent implements OnChanges {
    @Input() collection: Collection;
    dropdownOpen = false;
    renameCollectionForm: UntypedFormGroup;
    pendingSubmit = false;

    constructor(private collectionsService: CollectionsService, private apiService: ApiService) {
    }

    onDropdownOpenClick() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    ngOnChanges(): void {
        this.renameCollectionForm = new UntypedFormGroup({
            name: new UntypedFormControl(this.collection.name,
                [Validators.minLength(1),
                    Validators.maxLength(255),
                    Validators.required])
        });
    }

    cancelRenameCollection() {
        this.renameCollectionForm.controls.name.setValue(this.collection.name);
        this.dropdownOpen = false;
    }

    renameCollection() {
        try {
            this.renameCollectionForm.markAllAsTouched();
            if (this.renameCollectionForm && !this.renameCollectionForm.valid && !this.pendingSubmit) {
                return;
            }
            this.pendingSubmit = true;
            const collection = this.collection;
            collection.name = this.renameCollectionForm.value.name;
            this.apiService.postCollection(collection).toPromise().then(() => {
                this.collectionsService.refreshCollections$.next();
            });
        } catch (e) {
            console.error('Create Collection failed', e.message);
        } finally {
            this.pendingSubmit = false;
            this.dropdownOpen = false;
        }
    }

}
