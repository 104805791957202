<ng-container *ngIf="trimbleAuthenticated; else trimbleLogin">
    <ng-select
        placeholder="Zoek een Trimble Connect project"
        [loading]="loading"
        [formControl]="projectControl"
        [items]="projects$ | async"
        bindLabel="name"
        autofocus
    ></ng-select>

    <span *ngIf="projectControl.invalid" class="block mt-2 text-red-600 hover:text-red-700 focus:text-red-700">
        U hebt niet genoeg rechten om bewerkingen te doen binnen dit project.
    </span>
</ng-container>

<ng-template #trimbleLogin>
    <div class="trimble-login-container">
        Klik op de knop hieronder om in te loggen.
        <button class="trimble-login-container__button" aria-label="Trimble Connect login" type="button" (click)="openTrimbleLogin()">
            <span class="mr-2" inlineSVG="/assets/icons/trimble-connect.svg"></span> Trimble login
        </button>
    </div>
</ng-template>
