import {Component} from '@angular/core';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {
    measureMarkers,
    linestring,
    measure,
    measureLines,
    measurePoints,
    measureSource, addMeasureMarkerToMap, createNewPoint
} from '../../services/mapbox/layer/measure';
import {GeoJSONSource, MapMouseEvent} from 'mapbox-gl';
import {Feature, Point} from '@turf/helpers';
import * as GeoJSON from 'geojson';
import length from '@turf/length';
import distance from '@turf/distance';
import {PinnedLocationService} from '../../services/pinned-location.service';

@Component({
    selector: 'app-zoom-controls',
    templateUrl: './zoom-controls.component.html',
})
export class ZoomControlsComponent {
    measureToolEnabled = false;

    constructor(public mapbox: MapboxService,
                private pinnedLocationService: PinnedLocationService) {
    }

    gotoMyLocation() {
        navigator.geolocation.getCurrentPosition((position) => {
            this.mapbox.flyTo({
                center: [position.coords.longitude, position.coords.latitude],
                zoom: 15
            });
        });
    }

    resetRotation() {
        this.mapbox.setBearing(0);
    }

    toggleMeasureTool() {
        if (this.mapbox.map.getLayer('measure-points')) {
            this.disableMeasureTool();
            this.pinnedLocationService.pinnedLocationState$.next('pinning');
        } else {
            this.enableMeasureTool();
            this.pinnedLocationService.pinnedLocationState$.next('disabled');
        }
    }

    disableMeasureTool() {
        this.mapbox.setLayerClickDisabled(false);
        this.measureToolEnabled = false;
        this.mapbox.map.getCanvasContainer().style.cursor = 'auto';
        this.mapbox.map.off('click', this.measureClickHandler);
        this.mapbox.removeUserLayer('measure-points');
        this.mapbox.removeUserLayer('measure-lines');
        this.mapbox.removeUserSource('measureTool');
        measureMarkers.forEach((marker) => {
            marker.remove();
            marker.getElement().remove();
        });
        measureMarkers.splice(0, measureMarkers.length);
        measure.features = [];
        linestring.geometry.coordinates = [];
    }

    enableMeasureTool() {
        this.mapbox.setLayerClickDisabled(true);
        this.measureToolEnabled = true;
        this.mapbox.addUserSource('measureTool', measureSource);
        this.mapbox.addUserLayer(measurePoints);
        this.mapbox.addUserLayer(measureLines);
        this.mapbox.map.on('click', this.measureClickHandler);
        this.mapbox.map.getCanvasContainer().style.cursor = 'crosshair';
    }

    measureClickHandler(e: MapMouseEvent) {
        const map = this as any;
        const features = map.queryRenderedFeatures(e.point, {
            layers: ['measure-points']
        });

        // remove linestring & markers
        if (measure.features.length > 1) {
            measure.features.pop();
            measureMarkers.forEach((marker) => {
                marker.remove();
                marker.getElement().remove();
            });
            measureMarkers.splice(0, measureMarkers.length);
        }

        // If a feature was clicked, remove it from the map
        if (features.length &&
            features[0].properties.id === measure.features[measure.features.length - 1].properties.id) {
            const id = features[0].properties.id;
            measure.features = measure.features.filter((point) => {
                return point.properties.id !== id;
            });
        } else {
            // calculate distance to previous point and starting point
            const distanceToPreviousPoint = (measure.features.length > 0) ?
                distance(measure.features[measure.features.length - 1] as Feature<Point>, [e.lngLat.lng, e.lngLat.lat]) :
                0;
            const distanceToStartPoint = (length(linestring) + distanceToPreviousPoint);
            const newPoint = createNewPoint(
                [e.lngLat.lng, e.lngLat.lat],
                distanceToStartPoint,
                distanceToPreviousPoint,
                (measure.features.length + 1));
            measure.features.push(newPoint);
        }

        if (measure.features.length > 1) {
            linestring.geometry.coordinates = measure.features.map((point: Feature<GeoJSON.Point>,index) => {
                if(index > 0) {
                    addMeasureMarkerToMap(map, point);
                }
                return point.geometry.coordinates;
            });
        }
        measure.features.push(linestring);
        const source = map.getSource('measureTool') as GeoJSONSource;
        source.setData(measure as GeoJSON.FeatureCollection<GeoJSON.Geometry>);

    }

    get locationAvailable() {
        return 'geolocation' in navigator;
    }
}
