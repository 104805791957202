import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-ui-page-header',
    templateUrl: './ui-page-header.component.html',
    styleUrls: ['./ui-page-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiPageHeaderComponent {
    @Input() pageTitle!: string;
    @Input() pageSubtitle?: string;
    @Input() goBackPath?: string;
}
