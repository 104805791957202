import {AnyLayer, VectorSource} from 'mapbox-gl';
import {environment} from '../../../../environments/environment';

export const maatvoeringSource: VectorSource = {
    type: 'vector',
    minzoom: 12,
    maxzoom: 16,
    tiles: [
        `${environment.vectorTilesBlobUrl}/maatvoering/{z}/{x}/{y}.pbf`
    ],
};

export const maatvoeringLayers: AnyLayer[] = [
    {
        id: 'maatvoering',
        type: 'fill',
        source: 'maatvoering',
        'source-layer': 'Maatvoering',
        layout: {},
        paint: {
            'fill-color': '#807870'
        },
        filter: ['has', 'maxhoogte']
    },
    {
        id: 'maatvoering-border',
        type: 'line',
        source: 'maatvoering',
        'source-layer': 'Maatvoering',
        layout: {},
        paint: {
            'line-color': '#403c38'
        },
        filter: ['has', 'maxhoogte']
    },
    {
        id: 'maatvoering-label',
        type: 'symbol',
        source: 'maatvoering',
        'source-layer': 'Maatvoering',
        minzoom: 12,
        layout: {
            'text-field':  ['concat', ['to-string', ['get', 'maxhoogte']], ['literal', 'm']],
            'text-font': ['Arial Unicode MS Regular'],
            'text-letter-spacing': 0.1,
            'text-offset': [0, 0.1],
            'text-size': 14
        },
        paint: {
            'text-halo-color': '#fff',
            'text-halo-blur': 0.5,
            'text-halo-width': 1,
            'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                14,
                0,
                14.5,
                1,
                22,
                1
            ],
            'text-color': '#000'
        },
        filter: ['any', ['has', 'maxhoogte']]
    },
];

export const maatvoeringLayersExtrusion: AnyLayer[] = [
    {
        id: 'maatvoering',
        type: 'fill-extrusion',
        source: 'maatvoering',
        'source-layer': 'Maatvoering',
        layout: {},
        paint: {
            'fill-extrusion-height': ['get', 'maxhoogte'],
            'fill-extrusion-color': '#807870'
        },
        filter: ['has', 'maxhoogte']
    },
    ...maatvoeringLayers.filter(it => it.id !== 'maatvoering' && it.id !== 'maatvoering-border'),
];
