import {Component, OnDestroy, OnInit} from '@angular/core';
import {SimplePaginator} from '../../utils/paginator/simple-paginator';
import {filter, pairwise, withLatestFrom} from 'rxjs/operators';
import {ApiService} from '../../services/api.service';
import {BehaviorSubject, merge, of, Subscription} from 'rxjs';
import {SearchFacetsService} from '../../services/search-facets.service';
import {SearchFilterResponse} from '../../model/search-response';
import {Company} from 'api/models/asset/company';
import {companySortOptions} from '../../model/sort/company-sort-options';
import {SearchStateService} from '../../services/search-state.service';
import {SearchStatePaginator} from '../../utils/paginator/search-state-paginator';
import {SelectionService} from '../../services/selection.service';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {SortService} from '../../services/sort.service';
import {ActivatedRoute} from '@angular/router';
import {ScreenService} from '../../services/screen.service';

@Component({
    selector: 'app-companies-overview',
    templateUrl: './companies-overview.component.html'
})
export class CompaniesOverviewComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    companySortOptions = companySortOptions;
    selectionMode$: BehaviorSubject<string> = this.selectionService.selectionMode$;
    gridView$ = this.searchStateService.gridView$;
    isOnMobile = false;

    favoriteCompaniesPaginator = new SimplePaginator<Company>((page, pageSize, query, favorite, sort, companyFilter) => {
        return this.apiService.filter<Company>('company', '', '', true, sort, page, pageSize, companyFilter);
    }, 3);

    allCompaniesPaginator = new SearchStatePaginator<Company>(
        this.searchStateService,
        (page, pageSize, query, favorite, sort, companyFilter) => {
            return this.apiService.filter<Company>('company', '', query, favorite, sort, page, pageSize, companyFilter);
        }
    );

    allResultsCompaniesPaginator = new SearchStatePaginator<Company>(
        this.searchStateService,
        (page, pageSize, query, favorite, sort, companyFilter) => {
            return this.apiService.allCompanies$;
        }
    );

    constructor(
        private apiService: ApiService,
        private searchFacetsService: SearchFacetsService,
        private searchStateService: SearchStateService,
        private selectionService: SelectionService,
        private mapboxService: MapboxService,
        private sortService: SortService,
        private route: ActivatedRoute,
        private screenService: ScreenService
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(merge<SearchFilterResponse<Company>>(
            of(null),
            this.allCompaniesPaginator.responseWithParams$
            ).pipe(pairwise()).subscribe(([previous, current]) => {
                this.searchFacetsService.facets$.next({
                    facets: current.facets ?? {},
                    facetsChanged: previous === null // TODO: check if other things need to cause facet update
                });
                this.selectionService.selectionMode$.next('company');
            }),
            this.allResultsCompaniesPaginator.responseWithParams$.pipe(
                withLatestFrom(this.screenService.screen$,  (response, screen) => ({response, screen})),
                filter(({response, screen}) => screen === 'desktop')
            ).subscribe(({response}) => {
                this.searchStateService.pointResults$.next(response.value.map(item => ({
                    ...item, title: item.naam, id: item.kvw_nummer
                })));
                const filteredResults = (response.value.filter(result => result.geog !== null)).map(result => result.geog.coordinates);
                this.mapboxService.moveToLocation(filteredResults);
            }),
            this.route.queryParamMap.subscribe(queryParamMap => {
                if (queryParamMap.has('sort')) {
                    const sort = queryParamMap.get('sort') as any;
                    if (companySortOptions.some(option => option.key === sort) ||
                        companySortOptions.some(option => '-' + option.key === sort)) {
                        this.sortService.currentState$.next(sort.toString());
                        if(sort.toString() !== this.sortService.defaultState)
                        {
                            this.sortService.currentSort$.next(1);
                        }
                        if(sort.toString().includes('-') && sort.toString() !== this.sortService.defaultState)
                        {
                            this.sortService.currentSort$.next(2);
                        }
                        this.allCompaniesPaginator.setSort(sort);
                    }
                    else {
                        this.allCompaniesPaginator.setSort('-naam');
                    }
                }
            }),
            this.screenService.screen$.subscribe(screen => this.isOnMobile = screen === 'mobile')
        );
        this.sortService.currentState$.next('');
        this.sortService.paginator = this.allCompaniesPaginator;
        this.sortService.defaultState = '-naam';
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }
}
