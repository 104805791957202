<app-active-filter *ngIf="favorite" class="mb-4 block" (removeOption)="removeFavorite()">
    Favorieten
</app-active-filter>

<ng-container *ngFor="let group of filter|keyvalue">
    <div *ngIf="group.value.length === 1">
        <app-active-filter *ngFor="let option of group.value" (removeOption)="removeOption(group.key, option)">
            {{group.key === 'kvw_number' ? kvwNumbers[group.value] : filterValueTranslations[option] ?? option }}
        </app-active-filter>
    </div>
    <div *ngIf="group.value.length > 1">
        <app-active-filter (removeOption)="removeRangeOption(group.key)">
            <div *ngIf="isDateFilter(group.key); else numberFilter">
                {{ group.value[0] | date }} - {{ group.value[1] | date }}
            </div>
            <ng-template #numberFilter>{{ group.value[0] }} - {{ group.value[1] }}</ng-template>
        </app-active-filter>
    </div>
</ng-container>
