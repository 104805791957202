import {AppStoreItem} from 'api/models/asset/app-store-item';
import {ProjectAppStoreItem} from 'api/models/asset/project-app-store-item';

export type DisplayType = 'primary' | 'secondary' | 'other';

export const DisplayTypeSortOrder = {
    primary: 1,
    secondary: 2,
    other: 3,
} as const;

export interface ProjectAppStoreListItem extends AppStoreItem, ProjectAppStoreItem {
    subtitle: string;
    displayType: DisplayType;
    sortOrder: number;
    projectAppStoreItemUrl: string;
}

export const appTypeTranslation = {
    Primary: 'Informatiesysteem',
    Secondary: 'Extensie'
} as const;

export const secondaryTypeTranslation = {
    TrimbleViewer: 'Trimble Viewer',
    TrimbleExplorer: 'Trimble Verkenner',
    Extension: 'Extensie',
    Addon: 'Overige applicatie',
    VWSearchIFrame: 'VW Search Applicatie',
} as const;
