<div class="z-20 fixed bottom-0 left-0 h-18 px-4 w-full bg-white border-t border-neutral-3 flex items-center justify-around filter drop-shadow-mobile-navbar">
    <ng-container *ngFor="let group of routes">
        <button *ngFor="let route of group" [routerLink]="route.route" queryParamsHandling="merge"
                [queryParams]="queryParams"
                class="flex flex-col space-y-2 justify-center items-center w-6 h-6 rounded-lg"
                routerLinkActive #link="routerLinkActive"
                [ngClass]="link.isActive ? 'text-primary nav-active' :'text-neutral-1'"
                [disabled]="this.infoBarService.overLayOpened"
        >
            <div [inlineSVG]="route.icon"></div>
            <div class="text-xxs font-medium">{{route.tooltip}}</div>
        </button>
    </ng-container>

    <button routerLink="/account" routerLinkActive #link="routerLinkActive"
         class="flex flex-col space-y-2 justify-center items-center focus:outline-none
         text-xxs font-medium relative cursor-pointer"
    >
        <div
            class="flex justify-center items-center w-6 h-6 rounded-full text-white uppercase font-medium"
            [ngClass]="link.isActive ? 'bg-primary' :'bg-neutral-1'"
        >{{ initials$ | async }}</div>
        <div [ngClass]="link.isActive ? 'text-primary' :'text-neutral-1'">Meer</div>
    </button>
</div>
<app-mobile-selection-list></app-mobile-selection-list>
