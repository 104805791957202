import { Component } from '@angular/core';
import {VwuiModalRef} from '@recognizebv/vwui-angular';

@Component({
  selector: 'app-detect-adblock-modal',
  templateUrl: './detect-adblock-modal.component.html'
})
export class DetectAdblockModalComponent {

  constructor(public vwuiModalRef: VwuiModalRef) { }

}
