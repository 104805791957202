import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MapComponent} from './pages/map/map.component';
import {TrimbleOAuthComponent} from './pages/trimble-oauth/trimble-oauth.component';
import {AuthGuard} from './guards/auth.guard';
import {SearchComponent} from './pages/search/search.component';
import {ProjectsComponent} from './pages/projects/projects.component';
import {CompaniesComponent} from './pages/companies/companies.component';
import {AppsComponent} from './pages/apps/apps.component';
import {LayersComponent} from './pages/layers/layers.component';
import {AccountComponent} from './pages/account/account.component';
import {ProjectsDetailComponent} from './pages/projects-detail/projects-detail.component';
import {ProjectsOverviewComponent} from './pages/projects-overview/projects-overview.component';
import {ProjectsSearchComponent} from './pages/projects-search/projects-search.component';
import {VerblijfsobjectDetailComponent} from './pages/verblijfsobject-detail/verblijfsobject-detail.component';
import {CompaniesOverviewComponent} from './pages/companies-overview/companies-overview.component';
import {CompaniesSearchComponent} from './pages/companies-search/companies-search.component';
import {CompaniesDetailComponent} from './pages/companies-detail/companies-detail.component';
import {SearchOverviewComponent} from './pages/search-overview/search-overview.component';
import {SearchAllComponent} from './pages/search-all/search-all.component';
import {SearchProjectsComponent} from './pages/search-projects/search-projects.component';
import {SearchCompaniesComponent} from './pages/search-companies/search-companies.component';
import {SearchVerblijfsobjectenComponent} from './pages/search-verblijfsobjecten/search-verblijfsobjecten.component';
import {SearchWoonplaatsenComponent} from './pages/search-woonplaatsen/search-woonplaatsen.component';
import {LotsDetailComponent} from './pages/lots-detail/lots-detail.component';
import {PandDetailComponent} from './pages/pand-detail/pand-detail.component';
import {CollectionsComponent} from './pages/collections/collections.component';
import {CollectionsDetailComponent} from './pages/collections-detail/collections-detail.component';
import {CollectionsCreateComponent} from './pages/collections-create/collections-create.component';
import {LayersPoiComponent} from './pages/layer-poi/layers-poi.component';
import {RelationsDetailComponent} from './pages/relations-detail/relations-detail.component';
import {SearchRelationsComponent} from './pages/search-relations/search-relations.component';
import {PoiDetailComponent} from './pages/poi-detail/poi-detail.component';
import {RoleGuard} from './guards/role.guard';
import {TeamMemberPageComponent} from './pages/team-member-page/team-member-page.component';
import {AppAuthorizationPageComponent} from './app-role-assignment/pages/app-authorization-page/app-authorization-page.component';
import {Role} from 'api/models/role';
import {ExtensionsMapServiceComponent} from './pages/extensions-map-service/extensions-map-service.component';
import {AppFeatureFlag} from './modules/shared/feature-flag/app-feature-flags';
import {FeatureFlagGuard} from './modules/shared/feature-flag/feature-flag.guard';

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        component: MapComponent,
        children: [
            {
                path: 'search',
                component: SearchComponent,
                data: {
                    id: 'search',
                    sideBarWidth: 913,
                },
                children: [
                    {
                        path: '',
                        component: SearchOverviewComponent
                    },
                    {
                        path: 'all',
                        component: SearchAllComponent
                    },
                    {
                        path: 'projects',
                        component: SearchProjectsComponent
                    },
                    {
                        path: 'companies',
                        component: SearchCompaniesComponent
                    },
                    {
                        path: 'adressen',
                        component: SearchVerblijfsobjectenComponent
                    },
                    {
                        path: 'plaatsen',
                        component: SearchWoonplaatsenComponent
                    },
                    {
                        path: 'relations',
                        component: SearchRelationsComponent
                    }
                ]
            },
            {
                path: 'projects',
                component: ProjectsComponent,
                data: {
                    id: 'projects',
                    sideBarWidth: 1013,
                },
                children: [
                    {
                        path: '',
                        component: ProjectsOverviewComponent
                    },
                    {
                        path: 'search',
                        component: ProjectsSearchComponent
                    }
                ]
            },
            {
                path: 'companies',
                component: CompaniesComponent,
                data: {
                    id: 'companies',
                    sideBarWidth: 913,
                },
                children: [
                    {
                        path: '',
                        component: CompaniesOverviewComponent
                    },
                    {
                        path: 'search',
                        component: CompaniesSearchComponent
                    }
                ]
            },
            {
                path: 'apps',
                component: AppsComponent,
                data: {
                    id: 'apps'
                }
            },
            {
                path: 'layers',
                component: LayersComponent,
                data: {
                    id: 'layers'
                }
            },
            {
                path: 'layers/poi',
                component: LayersPoiComponent
            },
            {
                path: 'account',
                component: AccountComponent,
                data: {
                    id: 'account'
                }
            },
            {
                path: 'apps/authorization',
                component: AppAuthorizationPageComponent,
                canActivate: [FeatureFlagGuard],
                data: {
                    id: 'authorization',
                    sideBarWidth: 1000,
                    blockResize: true,
                    featureFlag: AppFeatureFlag.bedrijfsadmin,
                    fallbackUrl: ['/apps']
                }
            },
            {
                path: 'apps/map-service',
                component: ExtensionsMapServiceComponent,
                canActivate: [RoleGuard],
                data: {
                    id: 'map-service',
                    sideBarWidth: 500,
                    blockResize: true,
                    role: Role.KaartenService,
                    fallbackUrl: ['/apps']
                }
            },
            {
                path: 'apps/map-service-v2',
                loadChildren: () => import('./modules/features/map-request/map-request.module').then(m => m.MapRequestModule),
                canActivate: [FeatureFlagGuard],
                data: {
                    id: 'map-service',
                    sideBarWidth: 500,
                    blockResize: true,
                    featureFlag: AppFeatureFlag.mapservicev2,
                    fallbackUrl: ['/apps']
                }
            },
            {
                path: 'projects/:projectID' ,
                component: ProjectsDetailComponent,
                data: {
                    id: 'project',
                    sideBarWidth: 600,
                    blockResize: true
                }
            },
            {
                path: 'collections/create/:collection' ,
                component: CollectionsCreateComponent,
                data: {
                    id: 'collection'
                }
            },
            {
                path: 'collections',
                component: CollectionsComponent,
                data: {
                    id: 'collections',
                    sideBarWidth: 913,
                }
            },
            {
                path: 'collections/:collectionId',
                component: CollectionsDetailComponent,
                data: {
                    id: 'collection',
                    sideBarWidth: 913,
                    blockResize: true
                }
            },
            {
                path: 'projects/:projectID/:tab' ,
                component: ProjectsDetailComponent,
                data: {
                    id: 'project',
                    sideBarWidth: 600,
                    blockResize: true
                }
            },
            {
                path: 'verblijfsobject/:verblijfsobjectId',
                component: VerblijfsobjectDetailComponent,
                data: {
                    id: 'verblijfsobject',
                    sideBarWidth: 600,
                    blockResize: true
                }
            },
            {
                path: 'companies/:companyId',
                component: CompaniesDetailComponent,
                data: {
                    id: 'company',
                    sideBarWidth: 600,
                    blockResize: true
                }
            },
            {
                path: 'lots/:lotId',
                component: LotsDetailComponent,
                data: {
                    id: 'lot',
                    sideBarWidth: 600,
                    blockResize: true
                }
            },
            {
                path: 'relations/:id',
                component: RelationsDetailComponent,
                data: {
                    id: 'id',
                    sideBarWidth: 600,
                    blockResize: true
                }
            },
            {
                path: 'pand/:identificatie',
                component: PandDetailComponent,
                data: {
                    id: 'pand',
                    sideBarWidth: 600,
                    blockResize: true
                }
            },
            {
                path: 'poi/:id',
                component: PoiDetailComponent,
                data: {
                    id: 'poi',
                    sideBarWidth: 600,
                    blockResize: true
                }
            },
            {
                path: 'project-team-member/:upn',
                component: TeamMemberPageComponent,
                data: {
                    id: 'team-member',
                    sideBarWidth: 300,
                    blockResize: true
                }
            }
        ]
    },
    {
        path: 'trimble/oauth',
        component: TrimbleOAuthComponent
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
