import * as mapboxgl from 'mapbox-gl';
import { EventData, GeoJSONSource, MapLayerMouseEvent } from 'mapbox-gl';
import * as GeoJSON from 'geojson';

// Expand cluster leaves on click (spiderify) using mapbox marker or a circle layer
// Expanded cluster reset on click and on zoom
// See options below to customize behaviour

// Known bugs
// Double clicking on cluster zoom and spiderify zoom - 1

// Based on Mapbox cluster example https://docs.mapbox.com/mapbox-gl-js/example/cluster/1
// Spiral credits
// http://jsfiddle.net/gjowrfcd/1/
// http://jsfiddle.net/uh1rLvj2/
// Circle credits
// https://stackoverflow.com/questions/24273990/calculating-evenly-spaced-points-on-the-perimeter-of-a-circle
// Spiderify credit
// https://github.com/FranckKe/mapbox-gl-js-cluster-spiderify

const MAX_LEAVES_TO_SPIDERIFY = 255; // Max leave to display when spiderify to prevent filling the map with leaves
const CIRCLE_TO_SPIRAL_SWITCHOVER = 10; // When below number, will display leave as a circle. Over, as a spiral

const CIRCLE_OPTIONS = {
    distanceBetweenPoints: 128
};

const SPIRAL_OPTIONS = {
    rotationsModifier: 200, // Higher modifier = closer spiral lines
    distanceBetweenPoints: 128, // Distance between points in spiral
    radiusModifier: 50000, // Spiral radius
    lengthModifier: 500 // Spiral length modifier
};

const SPIDER_LEGS_LAYER_NAME = 'spider-legs';
const SPIDER_LEAVES_LAYER_NAME = 'spider-leaves';

export function spiderifyCluster(
    map: mapboxgl.Map,
    source: GeoJSONSource,
    cluster: GeoJSON.Feature<GeoJSON.Point>,
    onClick: (event: MapLayerMouseEvent & EventData) => void
) {
    const spiderlegsCollection: GeoJSON.Feature<GeoJSON.Geometry, { [p: string]: any }>[] = [];
    const spiderLeavesCollection: GeoJSON.Feature<GeoJSON.Geometry, { [p: string]: any }>[] = [];
    const clusterId = cluster.properties.cluster_id;
    const clusterCoordinates = cluster.geometry.coordinates as [number, number];

    source.getClusterLeaves(
        clusterId,
        MAX_LEAVES_TO_SPIDERIFY,
        0,
        (error, features) => {
            if (error) {
                console.error('Cluster does not exists on this zoom');
                return;
            }

            const leavesCoordinates = generateLeavesCoordinates(features.length);

            const clusterXY = map.project(clusterCoordinates);

            // Generate spiderlegs and leaves coordinates
            features.forEach((feature, index) => {
                const spiderLeafLatLng = map.unproject([
                    clusterXY.x + leavesCoordinates[index].x,
                    clusterXY.y + leavesCoordinates[index].y
                ]);

                spiderLeavesCollection.push({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [spiderLeafLatLng.lng, spiderLeafLatLng.lat]
                    },
                    properties: feature.properties
                });

                spiderlegsCollection.push({
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: [
                            clusterCoordinates,
                            [spiderLeafLatLng.lng, spiderLeafLatLng.lat]
                        ]
                    },
                    properties: feature.properties
                });
            });

            map.addLayer({
                id: SPIDER_LEGS_LAYER_NAME,
                type: 'line',
                source: {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: spiderlegsCollection
                    }
                },
                paint: {
                    'line-width': 3,
                    'line-color': 'rgba(128, 128, 128, 0.5)'
                }
            }, 'search-result-cluster');

            map.addLayer({
                id: SPIDER_LEAVES_LAYER_NAME,
                type: 'symbol',
                source: {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: spiderLeavesCollection
                    }
                },
                layout: {
                    'icon-allow-overlap': true,
                    'icon-anchor': 'bottom',
                    'icon-image': ['get', 'icon'],
                    'icon-size': 0.75,
                    'text-allow-overlap': true,
                    'text-anchor': 'bottom',
                    'text-field': ['get', 'title'],
                    'text-font': ['Arial Unicode MS Regular'],
                    'text-letter-spacing': 0.1,
                    'text-offset': [0, -4],
                    'text-padding': 0,
                    'text-size': 14,
                },
                paint: {
                    'text-color': 'rgb(33, 33, 33)',
                    'text-halo-color': 'rgb(255, 255, 255)',
                    'text-halo-width': 1,
                    'text-halo-blur': 0.5,
                }
            });

            map.on('click', SPIDER_LEAVES_LAYER_NAME, (event) => {
                onClick(event);
            });
        }
    );
}

export function removeSpiderifyCluster(map: mapboxgl.Map) {
    removeSourceAndLayer(map, SPIDER_LEGS_LAYER_NAME);
    removeSourceAndLayer(map, SPIDER_LEAVES_LAYER_NAME);
}

function generateLeavesCoordinates(nbOfLeaves: number): { x: number; y: number }[] {
    // Position cluster's leaves in circle if below threshold, spiral otherwise
    return (nbOfLeaves < CIRCLE_TO_SPIRAL_SWITCHOVER)
        ? generateEquidistantPointsInCircle(nbOfLeaves)
        : generateEquidistantPointsInSpiral(nbOfLeaves);
}

function generateEquidistantPointsInCircle(totalPoints: number): { x: number; y: number }[] {
    const points = [];
    const theta = (Math.PI * 2) / totalPoints;
    let angle = theta;
    for (let i = 0; i < totalPoints; i++) {
        angle = theta * i;
        points.push({
            x: CIRCLE_OPTIONS.distanceBetweenPoints * Math.cos(angle),
            y: CIRCLE_OPTIONS.distanceBetweenPoints * Math.sin(angle)
        });
    }
    return points;
}

function generateEquidistantPointsInSpiral(totalPoints: number): { x: number; y: number }[] {
    const points = [{ x: 0, y: 0 }];
    // Higher modifier = closer spiral lines
    const rotations = totalPoints * SPIRAL_OPTIONS.rotationsModifier;
    const distanceBetweenPoints = SPIRAL_OPTIONS.distanceBetweenPoints;
    const radius = totalPoints * SPIRAL_OPTIONS.radiusModifier;
    // Value of theta corresponding to end of last coil
    const thetaMax = rotations * 2 * Math.PI;
    // How far to step away from center for each side.
    const awayStep = radius / thetaMax;
    for (
        let theta = distanceBetweenPoints / awayStep;
        points.length <= totalPoints + SPIRAL_OPTIONS.lengthModifier;
    ) {
        points.push({
            x: Math.cos(theta) * (awayStep * theta),
            y: Math.sin(theta) * (awayStep * theta)
        });
        theta += distanceBetweenPoints / (awayStep * theta);
    }
    return points.slice(1, totalPoints + 1);
}

function removeSourceAndLayer(map: mapboxgl.Map, id: string) {
    if (map.getLayer(id) != null) { map.removeLayer(id); }
    if (map.getSource(id) != null) { map.removeSource(id); }
}
