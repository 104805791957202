import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Collection} from 'api/models/collection';

@Component({
    selector: 'app-collection-list',
    templateUrl: './collection-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionListComponent {
    @Input() collections: Collection[];

    constructor(private router: Router) {}

    navigateToCollection(collection: Collection) {
        this.router.navigate(
            ['/collections/', collection.id],
            {queryParamsHandling: 'preserve'}
        );
    }
}
