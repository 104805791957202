<button class="flex flex-col w-full h-full rounded bg-white overflow-hidden desktop:hover:shadow-tile relative group
        ring-2 ring-transparent focus:ring-primary focus:outline-none border border-neutral-3 relative mobile:shadow"
        (click)="onItemClicked($event, company, 'company')">
    <ng-container *ngIf="company.entiteit_logo_url; then projectImage else defaultImage"></ng-container>
    <ng-template #projectImage>
        <div class="h-24 flex-grow-0 flex-shrink-0 w-max max-w-full px-6">
            <img appImageFade *ngIf="company.entiteit_logo_url; else defaultImage" [src]="company.entiteit_logo_url" class="py-3 max-h-24 object-scale-down">
        </div>
    </ng-template>
    <ng-template #defaultImage>
        <div class="absolute h-24 w-full bg-neutral-4 -ml-6"></div>
        <div class="h-24 flex-grow-0 flex-shrink-0 w-max max-w-full flex mx-auto px-6">
            <img src="/assets/icons/thumbnail.svg" class="object-scale-down">
        </div>
    </ng-template>
    <div class="w-screen -ml-6 border-b"></div>
    <div class="block border-top desktop:py-4 mobile:py-2 text-left px-6 w-full">
        <div class="flex justify-between">
            <div class="font-medium">{{ company.naam }}</div>
            <app-is-favorite class="inline-block transform -translate-y-0.5" [company]="company"></app-is-favorite>
        </div>
        <div class="leading-6 mt-3 desktop:min-h-18">
            {{ company.vestiging_straat }} {{company.vestiging_huisnummer}}{{company.vestiging_huisnummertoevoeging}}<br>
            {{ company.vestiging_postcode }} {{company.vestiging_plaats }}
        </div>
    </div>
    <div class="mt-1 text-primary font-medium leading-6 flex flex-wrap w-full px-6 mt-auto">
        <a *ngIf="company.hoofd_telefoonnummer" class="font-medium flex items-center space-x-1 mb-2 mr-2"
           [ngClass]="{'border-r border-neutral-4 pr-2': company.email || company.domainnaam}"
           [href]="renderPhoneNumber(company.hoofd_telefoonnummer)"
        >
            <span inlineSVG="/assets/icons/icon-16-phone.svg"></span>
        </a>
        <a *ngIf="company.email" class="font-medium flex items-center space-x-1 mb-2 mr-2"
           [ngClass]="{'border-r border-neutral-4 pr-2': company.domainnaam}"
           [href]="'mailto:' + company.email" tabindex="-1"
        >
            <span inlineSVG="/assets/icons/icon-16-mail.svg"></span>
        </a>
        <a *ngIf="company.domainnaam" class="font-medium flex items-center space-x-1 mb-2"
           [href]="company.domainnaam|https" tabindex="-1" target="_blank"
        >
            <span inlineSVG="/assets/icons/icon-16-website.svg"></span>
        </a>
    </div>
    <app-is-selected *ngIf="(selectionMode$|async) === 'company'" (click)="$event.stopPropagation();"
                     class="mobile:hidden absolute top-2 left-2 group-hover:visible" [company]="company"
                     [ngClass]="selectionService.companyIsSelected(company) ? 'visible' : 'invisible'">
        <div unchecked class="cursor-pointer px-1 border-primary border rounded hover:bg-neutral-3 rounded"
             (click)="addCompanyToSelection($event, (company))">
            <span inlineSVG="/assets/icons/checkbox.svg"></span>
        </div>
        <div checked class="cursor-pointer bg-primary border rounded border-primary rounded"
             (click)="removeCompanyFromSelection($event, (company))">
            <span inlineSVG="/assets/icons/checkbox.svg" class="px-1"></span>
        </div>
    </app-is-selected>
    <app-company-tile-context-menu class="absolute top-2 right-2" [company]="company"></app-company-tile-context-menu>
</button>
