/* eslint-disable quote-props */

export const stabuChapters = {
    '00': 'Algemeen',
    '01': 'Voor het werk geldende voorwaarden',
    '05': 'Bouwplaatsvoorzieningen',
    '06': 'Door de aannemer aan te leveren documenten',
    '10': 'Stut- en sloopwerk',
    '12': 'Grondwerk',
    '14': 'Buitenriolering en drainage',
    '15': 'Terreinverhardingen',
    '17': 'Terreininrichting',
    '20': 'Funderingspalen en damwanden',
    '21': 'Betonwerk',
    '22': 'Metselwerk',
    '23': 'Vooraf vervaardigde steenachtige elementen',
    '24': 'Ruwbouwtimmerwerk',
    '25': 'Metaalconstructiewerk',
    '26': 'Bouwkundige kanaalelementen',
    '31': 'Systeembekledingen',
    '32': 'Trappen en balustraden',
    '33': 'Dakbedekkingen',
    '34': 'Beglazing',
    '35': 'Natuur- en kunststeen',
    '36': 'Voegvulling',
    '37': 'Na-isolatie',
    '38': 'Gevelschermen',
    '40': 'Stukadoorswerk',
    '41': 'Tegelwerk',
    '42': 'Dekvloeren en vloersystemen',
    '43': 'Metaal- en kunststofwerk',
    '44': 'Plafond- en wandsystemen',
    '45': 'Afbouwtimmerwerk',
    '46': 'Schilderwerk',
    '47': 'Binneninrichting',
    '50': 'Dakgoten en hemelwaterafvoeren',
    '51': 'Binnenriolering',
    '52': 'Waterinstallaties',
    '53': 'Sanitair',
    '54': 'Brandbestrijdingsinstallaties',
    '55': 'Gasinstallaties',
    '56': 'Perslucht- en vacuuminstallaties',
    '57': 'Technische inrichting',
    '60': 'Verwarmingsinstallaties',
    '61': 'Ventilatie- en luchtbehandelingsinstallaties',
    '62': 'Koelinstallaties',
    '68': 'Regelinstallaties',
    '70': 'Elektrotechnische installaties',
    '75': 'Communicatie- en beveiligingsinstallaties',
    '78': 'Gebouwenbeheersystemen',
    '80': 'Liftinstallaties',
    '81': 'Roltrappen en rolpaden',
    '82': 'Hijs- en hefinstallaties',
    '83': 'Goederentransport- en -distributies',
    '84': 'Gevelonderhoudinstallaties',
};
