import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html'
})
export class EmptyStateComponent {
    @HostBinding('class') classes = 'flex flex-col items-center';
    @Input() title = 'Geen resultaten gevonden';
    @Input() message = 'Er zijn geen resultaten gevonden';
}
