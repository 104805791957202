import {Injectable} from '@angular/core';
import { map } from 'rxjs/operators';
import {ScreenService} from './screen.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MobileService {
    private scrollToTopSubject = new BehaviorSubject<void>(null);
    isOnMobile$ = this.screenService.screen$.pipe(
        map(screen => screen === 'mobile')
    );

    // used to hide navbar on certain pages like detailed pages
    navbarEnabled$ = new BehaviorSubject(true);

    scrollToTop$ = this.scrollToTopSubject.asObservable();

    constructor(private screenService: ScreenService) {
    }

    scrollToTop() {
        this.scrollToTopSubject.next();
    }
}
