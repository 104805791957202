import {Component} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {VwuiModalRef} from '@recognizebv/vwui-angular';

@Component({
    selector: 'app-disclaimer-modal',
    templateUrl: './disclaimer-modal.component.html'
})
export class DisclaimerModalComponent {

    disclaimer$ = this.apiService.getDisclaimer();

    constructor(
        public vwuiModalRef: VwuiModalRef,
        private apiService: ApiService
    ) {}

    close() {
        this.vwuiModalRef.close();
    }

}
