<button class="mr-auto rounded group-hover:visible bg-white border border-neutral-3 hover:bg-neutral-4 h-6 w-6"
        [class.invisible]="!dropdownOpen"
        cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="onClick($event)"
        inlineSVG="/assets/icons/icon-16-more-horizontal.svg">
</button>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="dropdownOpen"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    [cdkConnectedOverlayPositions]="connectedPositions"
    (overlayOutsideClick)="onOutsideOverlayClick()"
    (detach)="onOutsideOverlayClick()">
    <div class="bg-white border-neutral-3 border rounded z-10 mt-1">
        <div class="px-2 py-4">
            <div class="text-xs mb-2 ml-4 text-neutral-1">
                Acties
            </div>
            <div>
                <app-tile-context-menuitem (clicked)="openNameChange()">
                    Naam wijzigen
                </app-tile-context-menuitem>
                <app-tile-context-menuitem (clicked)="openRemoveCollection()">
                    Collectie verwijderen
                </app-tile-context-menuitem>
                <app-tile-context-menuitem (clicked)="openShareCollection()">
                    Collectie delen
                </app-tile-context-menuitem>
                <app-tile-context-menuitem (clicked)="openPrsPresentation()">
                    Collectie presenteren
                </app-tile-context-menuitem>
            </div>
        </div>
    </div>
</ng-template>
