import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import * as configcat from 'configcat-js';
import {FeatureFlagUserInfo, FeatureNameKey} from './feature-flag.types';
import {environment} from '../../../../environments/environment';
import {filter, switchMap} from 'rxjs/operators';
import {AppFeatureFlag} from './app-feature-flags';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {
    private configCatUser?: configcat.User;
    private configCatLogger = configcat.createConsoleLogger(environment.configCatLogLevel);
    private configCatClient? = environment.configCatKey === null ? null : configcat.getClient(
        environment.configCatKey,
        configcat.PollingMode.AutoPoll,
        {
            logger: this.configCatLogger,
            dataGovernance: configcat.DataGovernance.EuOnly,
            setupHooks: (client) => {
                client.on('configChanged', () => this.configChanged$.next());
            }
        }
    );

    private configChanged$ = new BehaviorSubject<void>(undefined);
    public userInitialized$ = new BehaviorSubject(false);

    public initFeatureFlagUser({id, email, custom}: FeatureFlagUserInfo) {
        if (this.userInitialized$.value) {
            throw new Error('Feature flag service already initialized with user.');
        }

        this.configCatUser = new configcat.User(
            id,
            email,
            undefined,
            custom
        );

        this.userInitialized$.next(true);
    }

    public isFeatureEnabledForUser(featureName: FeatureNameKey): Observable<boolean> {
        return combineLatest([this.userInitialized$, this.configChanged$]).pipe(
            filter(([initialized]) => initialized),
            switchMap(([initialized]) =>
                initialized ? this.configCatClient?.getValueAsync(
                    AppFeatureFlag[featureName], false, this.configCatUser
                ) ?? of(true) : of(false))
        );
    }
}
