<app-saved-filter-list type="company"></app-saved-filter-list>
<div class="mb-6" *ngIf="(favoriteCompaniesPaginator.content$|async)?.length > 0">
    <div class="pb-4 flex items-center">
        <div class="font-medium text-base mr-auto rounded py-1">
            Favoriete bedrijven
        </div>
        <a [routerLink]="['/companies/search']" [queryParams]="{favorite: true}" queryParamsHandling="merge"
           class="text-primary font-medium ring-2 ring-transparent focus:ring-primary focus:outline-none px-2 py-4 rounded">
            Alle favorieten
        </a>
    </div>
    <app-company-tile-grid *ngIf="favoriteCompaniesPaginator.content$|async as companies" [companies]="companies"></app-company-tile-grid>
</div>
<div class="relative">
    <div class="pb-4 flex items-center">
        <app-export-dropdown *ngIf="!isOnMobile" titleText="Alle bedrijven" table="company">
        </app-export-dropdown>
        <app-pagination-sort *ngIf="gridView$|async;" class="ml-auto mr-2" [paginator]="allCompaniesPaginator"
                             [sortOptions]="companySortOptions" [setSortFromQueryParams]="true" [startOption]="''"></app-pagination-sort>
        <app-results-view-switch class="mobile:hidden" [class.ml-auto]="(gridView$|async) === false">
        </app-results-view-switch>
        <app-pagination class="mobile:hidden" [paginator]="allCompaniesPaginator"></app-pagination>
    </div>
    <ng-container *ngIf="allCompaniesPaginator.content$|async as companies">
        <app-company-tile-grid *ngIf="(gridView$|async) || isOnMobile; else listView" [companies]="companies"></app-company-tile-grid>
        <ng-template #listView>
            <app-company-list [items]="companies"></app-company-list>
        </ng-template>
    </ng-container>

    <div class="mt-4 flex items-end flex-col">
        <app-pagination [paginator]="allCompaniesPaginator"></app-pagination>
    </div>

    <span *ngIf="allCompaniesPaginator.isLoading" class="fixed top-96 left-1/2 transform -translate-x-1/2"
          inlineSVG="/assets/icons/loader.svg"></span>
</div>
