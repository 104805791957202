import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import Timeout = NodeJS.Timeout;

export function bufferTimeLazy<T>(bufferTime: number) {
    const buffer: any[] = [];
    let timeout: Timeout;

    return function(source: Observable<T>) {
        return source.pipe(
            switchMap((event) => {
                buffer.push(event);
                return new Observable<T[]>((subscriber => {
                    if (timeout) {
                        clearTimeout(timeout);
                    }
                    timeout = setTimeout(() => {
                        const prevBuffer = [...buffer];
                        buffer.length = 0; // clear buffer

                        subscriber.next(prevBuffer);
                    }, bufferTime);
                }));
            })
        );
    };
}
