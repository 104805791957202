<ng-container *ngIf="(metadata$ | async) as metadata; else empty">
    <ng-container *ngIf="metadata.asset as verblijfsobject">
        <div class="text-base font-medium text-dark truncate">
            <div class="ml-6 py-8 w-5 h-5">
                <span class="bg-white rounded mr-2 cursor-pointer" inlineSVG="/assets/icons/icon-arrow-left.svg"
                      (click)="this.location.back()"></span>
                {{ verblijfsobject | verblijfsobjectAddress }},
                {{ verblijfsobject.postcode}} {{ verblijfsobject.woonplaatsNaam }}
            </div>
        </div>

        <div class="mx-6 border-b border-neutral-3">
            <app-collapse collapseTitle="Adresgegevens">
                <div class="pb-6">
                    <div class="flex align-center items-center mt-1">
                        <span class="flex-half text-neutral-1 font-medium leading-6">Straat</span>
                        <span>{{ verblijfsobject.openbareruimteNaam }}</span>
                    </div>
                    <div class="flex align-center items-center mt-1">
                        <span class="flex-half text-neutral-1 font-medium leading-6">Huisnummer</span>
                        <span>{{ verblijfsobject.huisnummer }} {{ verblijfsobject.huisnummertoevoeging }}</span>
                    </div>
                    <div class="flex align-center items-center mt-1">
                        <span class="flex-half text-neutral-1 font-medium leading-6">Postcode</span>
                        <span>{{ verblijfsobject.postcode }}</span>
                    </div>
                    <div class="flex align-center items-center mt-1">
                        <span class="flex-half text-neutral-1 font-medium leading-6">Woonplaats</span>
                        <span>{{ verblijfsobject.woonplaatsNaam }}</span>
                    </div>
                </div>
            </app-collapse>
        </div>

        <div class="mx-6 border-b border-neutral-3">
            <app-collapse collapseTitle="Verblijfsobject">
                <div class="pb-6">
                    <div class="flex align-center items-center mt-1">
                        <span class="flex-half text-neutral-1 font-medium leading-6">Oppervlakte</span>
                        <span>{{ verblijfsobject.oppervlakte }} m²</span>
                    </div>
                    <div class="flex align-center items-center mt-1">
                        <span class="flex-half text-neutral-1 font-medium leading-6">Gebruiksdoel</span>
                        <span>{{ verblijfsobject.gebruiksdoel | titlecase }}</span>
                    </div>
                    <div class="flex align-center items-center mt-1">
                        <span class="flex-half text-neutral-1 font-medium leading-6">Documentdatum</span>
                        <span>{{ verblijfsobject.documentdatum | date}}</span>
                    </div>
                    <div class="flex align-center items-center mt-1">
                        <span class="flex-half text-neutral-1 font-medium leading-6">Documentnummer</span>
                        <span>{{ verblijfsobject.documentnummer }}</span>
                    </div>
                    <div class="flex align-center items-center mt-1">
                        <span class="flex-half text-neutral-1 font-medium leading-6">BAG-id</span>
                        <span>{{ verblijfsobject.identificatie.replace('NL.IMBAG.Verblijfsobject.', '') }}</span>
                    </div>
                </div>
            </app-collapse>
        </div>

        <div class="mx-6 border-b border-neutral-3">
            <app-collapse collapseTitle="Energielabel">
                <div class="pb-6">
                    <div *ngIf="metadata.energyLabel as energyLabel; else noEnergyLabel">
                        <div class="flex align-center items-center mt-1">
                            <span class="flex-half text-neutral-1 font-medium leading-6">Energielabel</span>
                            <span
                                [class]="'energy-label energy-label--' + energyLabel.energyClass.split('+').join('P')">{{ energyLabel.energyClass }}</span>
                        </div>
                        <div class="flex align-center items-center mt-1">
                            <span class="flex-half text-neutral-1 font-medium leading-6">Energieprestatieindex</span>
                            <span>{{ energyLabel.energyPerformanceIndex }}</span>
                        </div>
                        <div class="flex align-center items-center mt-1">
                            <span class="flex-half text-neutral-1 font-medium leading-6">Opnamedatum</span>
                            <span>{{ energyLabel.recordingDate | date:'d MMMM y' }}</span>
                        </div>
                        <div class="flex align-center items-center mt-1">
                            <span class="flex-half text-neutral-1 font-medium leading-6">Meting geldig tot</span>
                            <span>{{ energyLabel.measurementValidUntil | date:'d MMMM y' }}</span>
                        </div>
                        <div class="flex align-center items-center mt-1">
                            <span class="flex-half text-neutral-1 font-medium leading-6">Gebouwklasse</span>
                            <span>{{ energyLabel.buildingClass }}</span>
                        </div>
                        <div class="flex align-center items-center mt-1">
                            <span class="flex-half text-neutral-1 font-medium leading-6">Woningtype</span>
                            <span>{{ energyLabel.buildingType }}</span>
                        </div>
                        <div class="flex align-center items-center mt-1">
                            <span class="flex-half text-neutral-1 font-medium leading-6">Woningsubtype</span>
                            <span>{{ energyLabel.buildingSubType }}</span>
                        </div>
                    </div>
                </div>

                <ng-template #noEnergyLabel>
                    <div class="text-sm">
                        Dit adres heeft geen energielabelgegevens.
                    </div>
                </ng-template>
            </app-collapse>
        </div>
    </ng-container>
</ng-container>

<ng-template #empty>
    <div *ngIf="loading" class="m-6 flex justify-center">
        <vwui-spinner></vwui-spinner>
    </div>
    <div *ngIf="!loading" class="text-base font-medium text-dark truncate">
        <div class="ml-6 py-8 w-5 h-5">
            <span class="bg-white rounded mr-2 cursor-pointer" inlineSVG="/assets/icons/icon-arrow-left.svg"
                  (click)="this.location.back()"></span>
            Adres niet gevonden
        </div>
    </div>
</ng-template>
