import mapboxgl, {AnyLayer} from 'mapbox-gl';
import FeatureService from 'mapbox-gl-arcgis-featureserver';

export const natura2000SourceKey = 'natura2000';

export const initNatura2000FeatureService = (map: mapboxgl.Map): FeatureService => {
    return new FeatureService(natura2000SourceKey, map, {
        url: `https://services.arcgis.com/kE0BiyvJHb5SwQv7/arcgis/rest/services/N2000_VHR_service/FeatureServer/0`,
        useStaticZoomLevel: true,
        minZoom: 7,
    }) as FeatureService;
};

export const natura2000Layers: AnyLayer[] = [
    {
        id: 'natura2000',
        type: 'fill',
        source: natura2000SourceKey,
        layout: {},
        minzoom: 6,
        paint: {
            'fill-color': [
                'match',
                [
                    'get',
                    'BESCHERMIN'
                ],
                ['HR'],
                '#e6e600',
                ['VR'],
                '#bee6ff',
                ['VR+HR'],
                '#d2ff73',
                ['HR groeve'],
                '#ffa753',
                '#B8ADA2'
            ]
        }
    },
];
