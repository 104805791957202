import { Color, DataTexture, Shader, UniformsUtils } from 'three';

export const bag3dShaderFragmentShader =  `
varying float batchid;
	uniform float selectedBatchId;
	uniform float highlightedBatchId;
	uniform float maxBatchid;
	uniform vec3 selectedColor;
	uniform vec3 highlightColor;
	uniform vec3 baseColor;
	uniform sampler2D dataTexture;
	#define STANDARD
	#ifdef PHYSICAL
		#define REFLECTIVITY
		#define CLEARCOAT
		#define TRANSMISSION
	#endif
	uniform vec3 diffuse;
	uniform vec3 emissive;
	uniform float roughness;
	uniform float metalness;
	uniform float opacity;
	#ifdef TRANSMISSION
		uniform float transmission;
	#endif
	#ifdef REFLECTIVITY
		uniform float reflectivity;
	#endif
	#ifdef CLEARCOAT
		uniform float clearcoat;
		uniform float clearcoatRoughness;
	#endif
	#ifdef USE_SHEEN
		uniform vec3 sheen;
	#endif
	varying vec3 vViewPosition;
	#ifndef FLAT_SHADED
		varying vec3 vNormal;
		#ifdef USE_TANGENT
			varying vec3 vTangent;
			varying vec3 vBitangent;
		#endif
	#endif
	#include <common>
	#include <packing>
	#include <dithering_pars_fragment>
	#include <color_pars_fragment>
	#include <uv_pars_fragment>
	#include <uv2_pars_fragment>
	#include <map_pars_fragment>
	#include <alphamap_pars_fragment>
	#include <aomap_pars_fragment>
	#include <lightmap_pars_fragment>
	#include <emissivemap_pars_fragment>
	#include <transmissionmap_pars_fragment>
	#include <bsdfs>
	#include <cube_uv_reflection_fragment>
	#include <envmap_common_pars_fragment>
	#include <envmap_physical_pars_fragment>
	#include <fog_pars_fragment>
	#include <lights_pars_begin>
	#include <lights_physical_pars_fragment>
	#include <shadowmap_pars_fragment>
	#include <bumpmap_pars_fragment>
	#include <normalmap_pars_fragment>
	#include <clearcoat_pars_fragment>
	#include <roughnessmap_pars_fragment>
	#include <metalnessmap_pars_fragment>
	#include <logdepthbuf_pars_fragment>
	#include <clipping_planes_pars_fragment>
	void main() {
		#include <clipping_planes_fragment>
		
				vec4 diffuseColor;
				vec4 colorFromDataTexture = texture2D(dataTexture, vec2(floor(batchid + 0.5) / maxBatchid, 0.5));
				if (abs(batchid - selectedBatchId) < 0.5) {
					diffuseColor = vec4( selectedColor, (1.0+opacity)/1.8 );
				} else if (abs(batchid - highlightedBatchId) < 0.5) {
					diffuseColor = vec4( highlightColor, (1.0+opacity)/1.8 );
				} else {
					if (colorFromDataTexture.rgb == vec3(0.0)) {
						// If the color from the DataTexture is black, set the diffuse color to red
						diffuseColor = vec4(1.0, 0.0, 0.0, 1.0);
					} 
					else if (colorFromDataTexture.a == 0.0) {
						discard;
						// diffuseColor = vec4(colorFromDataTexture.rgb, colorFromDataTexture.a);
					}
					else {
						// Otherwise, use the color from the DataTexture
						diffuseColor = vec4(colorFromDataTexture.rgb, opacity);
					}
				}
				
		ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
		vec3 totalEmissiveRadiance = emissive;
		#ifdef TRANSMISSION
			float totalTransmission = transmission;
		#endif
		#include <logdepthbuf_fragment>
		#include <map_fragment>
		#include <color_fragment>
		#include <alphamap_fragment>
		#include <alphatest_fragment>
		#include <roughnessmap_fragment>
		#include <metalnessmap_fragment>
		#include <normal_fragment_begin>
		#include <normal_fragment_maps>
		#include <clearcoat_normal_fragment_begin>
		#include <clearcoat_normal_fragment_maps>
		#include <emissivemap_fragment>
		#include <transmissionmap_fragment>
		#include <lights_physical_fragment>
		#include <lights_fragment_begin>
		#include <lights_fragment_maps>
		#include <lights_fragment_end>
		#include <aomap_fragment>
		vec3 outgoingLight = reflectedLight.directDiffuse + 
			reflectedLight.indirectDiffuse + 
			reflectedLight.directSpecular + 
			reflectedLight.indirectSpecular + 
			totalEmissiveRadiance;
		#ifdef TRANSMISSION
			diffuseColor.a *= mix( saturate( 1. - totalTransmission + 
				linearToRelativeLuminance( reflectedLight.directSpecular + reflectedLight.indirectSpecular ) 
			), 1.0, metalness );
		#endif
		if (colorFromDataTexture.a <= 0.0) {
			discard;
		}
		else{
			gl_FragColor = vec4( outgoingLight, diffuseColor.a );
		}
		#include <tonemapping_fragment>
		#include <encodings_fragment>
		#include <fog_fragment>
		#include <premultiplied_alpha_fragment>
		#include <dithering_fragment>
	}
	`;

	export const bag3dShaderVertexShader =  `
	attribute float _batchid;
	varying float batchid;
	#define STANDARD
	varying vec3 vViewPosition;
	#ifndef FLAT_SHADED
	varying vec3 vNormal;
	#ifdef USE_TANGENT
	varying vec3 vTangent;
	varying vec3 vBitangent;
	#endif
	#endif
	#include <common>
	#include <uv_pars_vertex>
	#include <uv2_pars_vertex>
	#include <displacementmap_pars_vertex>
	#include <color_pars_vertex>
	#include <fog_pars_vertex>
	#include <morphtarget_pars_vertex>
	#include <skinning_pars_vertex>
	#include <shadowmap_pars_vertex>
	#include <logdepthbuf_pars_vertex>
	#include <clipping_planes_pars_vertex>
	void main() {

		#include <uv_vertex>
		batchid = _batchid;
		
	#include <uv2_vertex>
	#include <color_vertex>
	#include <beginnormal_vertex>
	#include <morphnormal_vertex>
	#include <skinbase_vertex>
	#include <skinnormal_vertex>
	#include <defaultnormal_vertex>
	#ifndef FLAT_SHADED
	vNormal = normalize( transformedNormal );
	#ifdef USE_TANGENT
	vTangent = normalize( transformedTangent );
	vBitangent = normalize( cross( vNormal, vTangent ) * tangent.w );
	#endif
	#endif
	#include <begin_vertex>
	#include <morphtarget_vertex>
	#include <skinning_vertex>
	#include <displacementmap_vertex>
	#include <project_vertex>
	#include <logdepthbuf_vertex>
	#include <clipping_planes_vertex>
	vViewPosition = - mvPosition.xyz;
	#include <worldpos_vertex>
	#include <shadowmap_vertex>
	#include <fog_vertex>
}`;

	export const batchIdShader = (
		shader: Shader,
		dataTexture: DataTexture,
		pandDefaultColor: Color,
		pandHighlightedColor: Color,
		pandSelectedColor: Color) => {

        const newShader = { ...shader };

        newShader.uniforms = {
            // custom uniforms:
            ...UniformsUtils.clone(shader.uniforms),
            selectedBatchId: { value: - 1 },
            highlightedBatchId: { value: - 1 },
            baseColor: { value: pandDefaultColor },
            highlightColor: { value: pandHighlightedColor },
            selectedColor: { value: pandSelectedColor },
            ignoredBatchId: { value: 9999 },
            dataTexture: { value: dataTexture },
            maxBatchid: { value: 0 },

        };
        newShader.vertexShader = bag3dShaderVertexShader;

        newShader.fragmentShader =  bag3dShaderFragmentShader;

        return newShader;
    };
