import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map, scan, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {SearchStateService} from '../../services/search-state.service';
import {SearchFacetsInfo, SearchFacetsService} from '../../services/search-facets.service';
import {FavoriteService} from '../../services/favorite.service';
import {UserFavorite} from 'api/models/user-favorite';
import {SaveFilterModalComponent} from '../save-filter-modal/save-filter-modal.component';
import {VwuiModalRef, VwuiModalService} from '@recognizebv/vwui-angular';
import {valueChangesWithInitialValue} from '../../utils/form-control';
import {ScreenService} from 'src/app/services/screen.service';
import KvwNumbers from 'api/models/kvw-numbers';
import {SearchResultType} from 'api/models/search-preference';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {facetGroup, facetLabel, facetOptionLabel} from '../../utils/facet-translations';

export interface FacetSelectOption {
    value: string;
    label: string;
    enabled?: boolean;
}

export interface SimpleFacet {
    type: string;
    groupName: string;
    key: string;
    options: FacetSelectOption[];
}

export interface RangeFacet extends SimpleFacet {
    dataType: 'MONTH'|'NUMBER';
    min: number;
    max: number;
    valuePrefix: string;
    valueSuffix: string;
    subject: string;
}

export type Facet = SimpleFacet | RangeFacet;

export interface FacetsWithForm {
    group: UntypedFormGroup;
    facets: Facet[];
}

@Component({
    selector: 'app-search-filter',
    templateUrl: './search-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchFilterComponent),
            multi: true,
        }
    ]
})
export class SearchFilterComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @HostBinding('class')
    classes = '';

    @Input() kvwNumbers: KvwNumbers;

    @Input() set type(value: UserFavorite['type']) {
        this.type$.next(value);
    }

    private subscriptions: Subscription[] = [];
    private lastGroupSubscription: Subscription = null;

    private collapsedFacetGroups = new Set<string>(['Kenmerken', 'Contract']);

    type$ = new BehaviorSubject<UserFavorite['type']>(null);
    vwuiModalRef: VwuiModalRef;

    favoriteControl = new UntypedFormControl(false);
    favoriteCount$ = combineLatest([
        this.type$,
        this.favoriteService.favorites$
    ]).pipe(
        filter(([type]) => type !== null),
        map(([type, favorites]) => favorites.filter(item => item.type === type).length)
    );

    facetsByGroup: {[key: string]: Facet[]} = {};
    facets$: Observable<FacetsWithForm> = this.searchFacetsService.facets$.pipe(
        scan<SearchFacetsInfo, FacetsWithForm>((previousFacets, currentFacets) => {
            if (previousFacets === null || currentFacets.facetsChanged) {
                const group = new UntypedFormGroup({});
                const options = Object.keys(currentFacets.facets).map(key => {
                    group.addControl(key, new UntypedFormControl([]));
                    return ({
                        key,
                        type: 'SIMPLE',
                        groupName: this.facetGroup(key),
                        options: currentFacets.facets[key].map(it => ({
                            value: it.value,
                            label: `${this.facetOptionLabel(key, it.value)} (${it.count})`
                        }))
                    });
                });
                const rangeFacetsControls = Object.keys(currentFacets.rangeFacets ?? {})
                    .filter(key => currentFacets.rangeFacets[key].subject === this.type$.value)
                    .map(key => currentFacets.rangeFacets[key]);
                rangeFacetsControls.map(facet => {
                    group.addControl(facet.key, new UntypedFormControl([]));
                    return facet;
                });
                options.push( ...rangeFacetsControls);
                if (this.lastGroupSubscription) {
                    this.lastGroupSubscription.unsubscribe();
                }
                this.lastGroupSubscription = this.searchStateService.searchState$.pipe(
                    map(it => it && it.filter || {}),
                    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
                ).subscribe(it => {
                    group.patchValue(it, {emitEvent: false});
                });
                return {group, facets: options};
            } else {
                // Update previous facets with new count, this is needed to keep options with 0 as the count after filtering
                const updatedOptions = previousFacets.facets
                    .filter(facet => facet.type === 'SIMPLE')
                    .map(({key, options}) => {
                        const newOptions = currentFacets.facets[key] || [];
                        return {
                            key,
                            type: 'SIMPLE',
                            groupName: this.facetGroup(key),
                            options: options.map(option => {
                                const matchingNewOption = newOptions.find(it => it.value === option.value);
                                const count = matchingNewOption?.count || 0;
                                return {
                                    value: option.value,
                                    label: `${this.facetOptionLabel(key, option.value)} (${count})`
                                };
                            })
                        };
                    });
                return {
                    group: previousFacets.group,
                    facets: [
                        ...updatedOptions,
                        ...Object.keys(currentFacets.rangeFacets)
                            .filter(key => currentFacets.rangeFacets[key].subject === this.type$.value)
                            .map(key => currentFacets.rangeFacets[key])
                    ]
                };
            }
        }, null),
        tap(it => ['Algemeen', 'Kenmerken', 'Contract', 'Top'].forEach(
            group => this.facetsByGroup[group] = this.filterFacetsByGroup(it.facets, group).filter(facet => {
                return facet.type !== 'SIMPLE' || facet.options.length > 0;
            })
        )),
        shareReplay({bufferSize: 1, refCount: true}),

    );

    facetGroups$: Observable<string[]> = this.type$.pipe(
        map((type) => {
            if(type === 'project') {
                return ['Algemeen', 'Kenmerken', 'Contract'];
            } else {
                return ['Algemeen'];
            }
        })
    );

    filterValue$ = combineLatest([
        this.facets$,
        valueChangesWithInitialValue(this.favoriteControl)
    ]).pipe(
        switchMap(([facet]) => valueChangesWithInitialValue<any>(facet.group)),
        shareReplay(1)
    );

    activeFilterCount$ = this.filterValue$.pipe(
        map(filterValue => Object.keys(filterValue).filter(key => filterValue[key].length > 0).length),
        shareReplay(1)
    );

    dropdownOpen = false;
    isOnMobile = false;


    constructor(
        private searchStateService: SearchStateService,
        private searchFacetsService: SearchFacetsService,
        private favoriteService: FavoriteService,
        private modalService: VwuiModalService,
        private elementRef: ElementRef,
        private screenService: ScreenService,
        private apiService: ApiService,
        private route: ActivatedRoute,
        private toastrService: ToastrService
    ) {
    }

    @HostListener('body:click', ['$event'])
    onDocumentClick(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.dropdownOpen = false;
        }
    }

    onChange: (value: any) => void = () => {
    };
    onTouch: () => void = () => {
    };

    ngOnInit(): void {
        this.subscriptions.push(
            this.filterValue$.subscribe(value => {
                const cleanedValue: { [key: string]: string[] } = {
                    favorite: this.favoriteControl.value
                };
                Object.keys(value).forEach(key => {
                    if (value[key] && value[key].length > 0) {
                        cleanedValue[key] = value[key];
                    }
                });

                this.onTouch();
                this.onChange(cleanedValue);
            }),
            this.screenService.screen$.subscribe(screen => this.isOnMobile = screen === 'mobile')
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
        if (this.lastGroupSubscription) {
            this.lastGroupSubscription.unsubscribe();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    async writeValue(obj: any): Promise<void> {
        this.favoriteControl.patchValue(obj.favorite, {emitEvent: false});
        const currentFacets = await this.facets$.pipe(take(1)).toPromise();
        currentFacets.group.patchValue(obj, {emitEvent: false});
    }

    filterFacetsByGroup(facets: Facet[], groupName: string) {
        return facets.filter(facet => facet.groupName === groupName);
    }

    facetOptionLabel(key: string, value: string): string {
        return facetOptionLabel(key, value, this.kvwNumbers);
    }

    facetGroup(key: string) {
        return facetGroup(key);
    }

    facetLabel(key: string) {
        return facetLabel(key);
    }

    isFacetGroupCollapsed(key: string) {
        return this.collapsedFacetGroups.has(key);
    }

    toggleFacetGroupCollapsed(key: string) {
        if (this.collapsedFacetGroups.has(key)) {
            this.collapsedFacetGroups.delete(key);
        } else {
            this.collapsedFacetGroups.add(key);
        }
    }

    isFacetOptionListOpenend(key: string) {
        return sessionStorage.getItem(`${key}-listOpened`) === 'open';
    }

    storeFacetOptionListOpened(key: string, opened: boolean) {
        const sessionKey = `${key}-listOpened`;
        if (opened) {
            sessionStorage.setItem(sessionKey, 'open');
        } else {
            sessionStorage.removeItem(sessionKey);
        }
    }

    getFacetOptionListFilter(key) {
        return sessionStorage.getItem(`${key}-filter`) ?? '';
    }

    storeFacetOptionListFilter(key: string, filterKey: string) {
        sessionStorage.setItem(`${key}-filter`, filterKey);
    }

    clearFilters(facets: FacetsWithForm) {
        this.favoriteControl.patchValue(false, {emitEvent: false});
        Object.keys(facets.group.controls).forEach(
            key => facets.group.controls[key].reset([])
        );
    }

    openSaveFilterModal() {
        if(!this.vwuiModalRef) {
            this.vwuiModalRef = this.modalService.open(SaveFilterModalComponent, {
                closeOnBackdropClick: false
            });

            this.subscriptions.push(this.vwuiModalRef.afterClosed.subscribe(async data => {
                this.vwuiModalRef = undefined;
                if (data) {
                    try {
                        await this.apiService.postSearchPreference({
                            name: data,
                            table: this.type$.value as SearchResultType,
                            query: this.route.snapshot.queryParamMap.get('query') || '',
                            favorite: this.route.snapshot.queryParamMap.get('favorite') === 'true',
                            filter: JSON.parse(this.route.snapshot.queryParamMap.get('filter') || '{}')
                        }).toPromise();
                        this.toastrService.success('Filter successvol opgeslagen');
                    } catch (error) {
                        switch (error.status) {
                            case 409:
                                this.toastrService.error('Filternaam is al in gebruik');
                                break;
                            default:
                                throw error;
                        }
                    }
                }
            }));
        }
    }
}
