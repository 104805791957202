import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {SearchFacet} from '../model/search-facet';
import {RangeFacet} from '../components/search-filter/search-filter.component';

export interface SearchFacetsInfo {
    facets: {[key: string]: SearchFacet[]};
    rangeFacets?: {[key: string]: RangeFacet};
    facetsChanged: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class SearchFacetsService {
    readonly facets$: Subject<SearchFacetsInfo> = new Subject();
}
