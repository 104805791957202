import {BuildingColorMode} from 'api/models/building-color-mode';
import {Style} from 'mapbox-gl';
import {MaatvoeringLayerMode} from 'api/models/maatvoering-layer-mode';

export interface MapFilter {
    layerId: string;
    filter: any[];
}

export type MapLayerMode = BuildingColorMode | MaatvoeringLayerMode;

export type MapLayerConfig = {
    active: boolean;
    opacity: number;
    mode?: MapLayerMode;
};

export type MapMaatvoeringLayerConfig = MapLayerConfig & {
    mode: MaatvoeringLayerMode;
};

export type MapBuildingLayerConfig = MapLayerConfig & {
    mode: BuildingColorMode;
};

export type MapLayerConfigType = MapLayerConfig | MapBuildingLayerConfig | MapMaatvoeringLayerConfig;

export interface MapConfigLayers {
    buildingLayer: MapBuildingLayerConfig;
    lot: MapLayerConfig;
    bgt: MapLayerConfig;
    bestemmingsplan: MapLayerConfig;
    maatvoering: MapMaatvoeringLayerConfig;
    natura2000: MapLayerConfig;
    buildings: MapLayerConfig;
    opentopo: MapLayerConfig;
    leefbaarometer: MapLayerConfig;
    geluidkaart: MapLayerConfig;
    groenkaart: MapLayerConfig;
    boomhoogte: MapLayerConfig;
    risicokaart: MapLayerConfig;
    poi: MapLayerConfig;
    bag3d: MapLayerConfig;
    vwprojects: MapLayerConfig;
}

export interface MapConfig {
    style: Style;
    styleName: string;
    layers: MapConfigLayers;
    layerOrder: string[];
    filters: MapFilter[];
}

export const MapBuildingLayerKeysToControl = ['buildings', 'bag3d'];

export const defaultMapConfigLayers: MapConfigLayers = {
    buildingLayer: {
        active: false,
        opacity: 1,
        mode: BuildingColorMode.NONE
    },
    lot: {active: false, opacity: 1},
    bgt: {active: false, opacity: 1},
    bestemmingsplan: {active: false, opacity: 1},
    maatvoering: {active: false, opacity: 0.3, mode: MaatvoeringLayerMode.NONE},
    natura2000: {active: false, opacity: 1},
    buildings: {active: false, opacity: 1},
    opentopo: {active: false, opacity: 1},
    leefbaarometer: {active: false, opacity: 1},
    geluidkaart: {active: false, opacity: 1},
    groenkaart: {active: false, opacity: 1},
    boomhoogte: {active: false, opacity: 1},
    risicokaart: {active: false, opacity: 1},
    poi: {active: false, opacity: 1},
    bag3d: {active: false, opacity: 1},
    vwprojects: {active: false, opacity: 1}
};

export const MapConfigLayerIds: {[key in keyof MapConfigLayers]: string} = {
    lot: 'A',
    bgt: 'B',
    bestemmingsplan: 'C',
    maatvoering: 'D',
    natura2000: 'E',
    buildings: 'F',
    opentopo: 'G',
    leefbaarometer: 'H',
    geluidkaart: 'I',
    groenkaart: 'J',
    boomhoogte: 'K',
    risicokaart: 'L',
    poi: 'N',
    bag3d: 'O',
    vwprojects: 'P',
    buildingLayer: 'Q'
};

export interface MapConfig {
    style: Style;
    styleName: string;
    layers: MapConfigLayers;
    layerOrder: string[];
    filters: MapFilter[];
}

export interface MapLayer {
    layerKey: keyof MapConfigLayers;
    title: string;
    description: string;
    longDescription: string;
    imageFileName?: string;
    sourceName?: string;
    sourceUrl?: string;
    dataType?: string;
    lastUpdate?: string;
}

export const defaultLayerOrder: string[] = [
    'buildingLayer',
    'vwprojects',
    'lot',
    'bestemmingsplan',
    'maatvoering',
    'natura2000',
    'leefbaarometer'
];

export const mapLayers: MapLayer[] = [
    {
        layerKey: 'buildingLayer',
        title: 'Gebouwen',
        description: 'Toont de gebouwen.',
        longDescription: 'Toont de gebouwen',
        imageFileName: '3dbuildings',
        sourceName: 'BAG',
        sourceUrl: 'https://www.pdok.nl/introductie/-/article/basisregistratie-adressen-en-gebouwen-ba-1',
        dataType: '3D Vector'
    },
    {
        layerKey: 'vwprojects',
        title: 'VW Projecten',
        description: 'Toont 3D massamodellen.',
        longDescription: 'Met deze kaartlaag worden de 3D modellen van VolkerWessels projecten getoond. ' +
            'De modellen worden gegenereerd op basis van BIM modellen uit het PMS (Trimble Connect). ' +
            'De modellen tonen een hoog detail niveau om projecten ook vindbaar te maken aan de hand van ' +
            'de fysieke project locatie. De bestaande BAG-panden van de VW projecten worden met deze ' +
            'kaartlaag vervangen door de 3D massa modellen.',
        imageFileName: '3dmassamodellen',
        sourceName: 'Trimble connect',
        sourceUrl: 'https://connect.trimble.com/',
        dataType: 'BIM model',
        lastUpdate: 'Realtime'
    },
    {
        layerKey: 'lot',
        title: 'Percelen',
        description: 'Toont de globale ligging van een kadastraal perceel.',
        longDescription: 'De Kadastrale kaart toont de globale ligging van een kadastraal perceel in zijn omgeving. ' +
            'Op de kaart zie je onder meer de kadastrale perceelgrenzen, perceelnummers en de belangrijkste ' +
            'bebouwing. Je kunt van de Kadastrale kaart geen maten afleiden.',
        imageFileName: 'lots',
        sourceName: 'PDOK',
        sourceUrl: 'https://www.pdok.nl/introductie/-/article/basisregistratie-kadaster-brk-',
        dataType: 'Vector'
    },
    {
        layerKey: 'bgt',
        title: 'BGT laag',
        description: 'Toont een gedetaileerde kaart.',
        longDescription: 'De Basisregistratie Grootschalige Topografie (BGT) is een gedetailleerde digitale kaart van ' +
            'Nederland. In de BGT worden objecten zoals gebouwen, wegen, water, spoorlijnen en groen op eenduidige ' +
            'manier vastgelegd.',
        imageFileName: 'bgt',
        sourceName: 'Kadaster',
        sourceUrl: 'http://www.kadaster.nl ',
    },
    {
        layerKey: 'bestemmingsplan',
        title: 'Bestemmingsplan',
        description: 'Toont gebruiks- en de bouwmogelijkheden.',
        longDescription: 'Het bestemmingsplan is een juridisch bindend document voor zowel de overheid als burgers ' +
            'en bedrijven. In een bestemmingsplan worden de gebruiks- en de bouwmogelijkheden vastgelegd voor een ' +
            'gebied.',
        imageFileName: 'bestemmingsplan',
        sourceName: 'Bestemmingsplannen',
        sourceUrl: 'https://www.ruimtelijkeplannen.nl/web-roo/roo/over',
        dataType: 'Vector'
    },
    {
        layerKey: 'maatvoering',
        title: 'Maximum bouwhoogte',
        description: 'Toont de maximale bouwhoogte of goothoogte.',
        longDescription: 'De gegevens bestaan uit BAG-panden en een deelselectie van BAG-gegevens van deze panden en ' +
            'de zich daarin bevindende verblijfsobjecten. Ook de ligplaatsen en standplaatsen zijn hierin opgenomen ' +
            'met een deelselectie van BAG-gegevens.',
        imageFileName: 'maatvoering',
        sourceName: 'Planinformatie',
        sourceUrl: 'https://www.ruimtelijkeplannen.nl/web-roo/roo/over',
        dataType: 'Vector'
    },
    {
        layerKey: 'natura2000',
        title: 'Natura 2000',
        description: 'Toont beschermde natuurgebieden.',
        longDescription: 'Natura 2000 is het samenhangend netwerk van beschermde natuurgebieden in de Europese Unie ' +
            'bestaande uit Vogelrichtlijn- en Habitatrichtlijngebieden. Natura 2000-gebieden zijn een samenvoeging ' +
            'van beide en de daarin gelegen beschermde natuurmonumenten. In Nederland gaat het om 163 gebieden ' +
            'waarvan drie mariene gebieden in de Exclusieve Economische Zone (EEZ) op de Noordzee ' +
            '(incl. een voorgenomen Vogelrichtlijngebied in de EEZ). De Vogelrichtlijngebieden zijn merendeels ' +
            'aangewezen in de periode 1986-2000. De Habitatrichtlijngebieden worden aangewezen met de aanwijzing ' +
            'van de betreffende Natura 2000-gebieden. Deze service bevat de grenzen van de definitief aangewezen ' +
            'gebieden en de grenzen conform het ontwerp-besluit van de meeste overige gebieden. Voor enkele ' +
            'Habitatrichtlijngebieden waarvoor nog geen ontwerp-besluiten zijn gepubliceerd, bevat deze service de ' +
            'grenzen zoals die aan de Europese Commissie gemeld zijn.',
        imageFileName: 'natura2000',
        sourceName: 'PDOK',
        sourceUrl: 'https://www.pdok.nl/introductie/-/article/natura-2000',
        dataType: 'Raster'
    },
    // {
    //     layerKey: 'opentopo',
    //     title: 'Opentopo',
    //     description: '',
    //     longDescription: ''
    // },
    // {
    //     layerKey: 'leefbaarometer',
    //     title: 'Leefbaarometer',
    //     description: 'Toont de leefbaarheidssituatie.',
    //     longDescription: 'Met behulp van de Leefbaarometer kan de leefbaarheid in alle bewoonde wijken, buurten ' +
    //         'en straten in Nederland gemonitord worden. De Leefbaarometer laat zien hoe de situatie van de ' +
    //         'leefbaarheid er is en hoe die zich in de afgelopen jaren heeft ontwikkeld. Hiermee biedt de ' +
    //         'Leefbaarometer basismateriaal voor beleidsvoorbereiding, -bijsturing en -evaluatie voor verschillende ' +
    //         'partijen.',
    //     imageFileName: 'leefbaarometer',
    //     sourceName: 'Leefbaarometer',
    //     sourceUrl: 'https://www.leefbaarometer.nl/',
    //     dataType: 'Raster'
    // },
    // {
    //     layerKey: 'geluidkaart',
    //     title: 'Geluidkaart',
    //     description: '',
    //     longDescription: ''
    // },
    // {
    //     layerKey: 'groenkaart',
    //     title: 'Groenkaart',
    //     description: '',
    //     longDescription: ''
    // },
    // {
    //     layerKey: 'boomhoogte',
    //     title: 'Boomhoogte',
    //     description: '',
    //     longDescription: ''
    // },
    // {
    //     layerKey: 'risicokaart',
    //     title: 'Risicokaart',
    //     description: '',
    //     longDescription: ''
    // },
];
