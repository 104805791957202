import mapboxgl, {CircleLayer, GeoJSONSourceRaw, LineLayer, LngLatLike, Marker} from 'mapbox-gl';
import {Feature, FeatureCollection} from '@turf/helpers';
import * as GeoJSON from 'geojson';

export const measure: FeatureCollection<GeoJSON.Point | GeoJSON.LineString> = {
    type: 'FeatureCollection',
    features: []
};

export function createNewPoint(coords: any,
                               distanceToStart: number,
                               distanceToPrevious: number,
                               index: number): Feature<GeoJSON.Point> {
    return {
        type: 'Feature',
            geometry: {
        type: 'Point',
            coordinates: coords
        },
        properties: {
            id: String(new Date().getTime()),
                distanceToStart,
                distanceToPrevious,
                pointNumber: index
        }
    };
}

export function addMeasureMarkerToMap(map: mapboxgl.Map, point: Feature<GeoJSON.Point>) {
    const el = document.createElement('div');
    el.className = 'measure-tool-marker';
    el.setAttribute('marker-id', point.id as string);
    el.innerHTML = getMarkerTemplate(point);
    const marker = new mapboxgl.Marker(el)
        .setLngLat([point.geometry.coordinates[0], point.geometry.coordinates[1]] as LngLatLike)
        .addTo(map);
    measureMarkers.push(marker);
}

function getMarkerTemplate(point: Feature<GeoJSON.Point>): string {
    const distanceToPreviousInMeters: number = +point.properties.distanceToPrevious * 1000;
    const distanceToStartInMeters: number = +point.properties.distanceToStart * 1000;

    return `<span class="blue">+ ${distanceToPreviousInMeters.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })} m</span><br>
                <span>= ${distanceToStartInMeters.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })} m</span>`;
}

// Used to draw a line between points
export const linestring: Feature<GeoJSON.LineString> = {
    type: 'Feature',
    geometry: {
        type: 'LineString',
        coordinates: []
    },
    properties: []
};

export const measureSource: GeoJSONSourceRaw = {
    type: 'geojson',
    data: {
        type: 'FeatureCollection',
        features: []
    }
};

export const measurePoints: CircleLayer = {
    id: 'measure-points',
    type: 'circle',
    source: 'measureTool',
    paint: {
        'circle-radius': 8,
        'circle-color': '#008cbc',
        'circle-stroke-color': '#FFF',
        'circle-stroke-width': 4
    },
    filter: ['in', '$type', 'Point']
};
export const measureLines: LineLayer = {
    id: 'measure-lines',
    type: 'line',
    source: 'measureTool',
    layout: {
        'line-cap': 'round',
        'line-join': 'round'
    },
    paint: {
        'line-color': '#008cbc',
        'line-width': 4,
        'line-dasharray': [1, 3]
    },
    filter: ['in', '$type', 'LineString']
};
export const measureMarkers: Marker[] = [];
