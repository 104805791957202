<div class="flex align-center mt-1">
    <div [class.cursor-pointer]="clickable" [class.hover:bg-neutral-4]="clickable" (click)="handleClick()" class="flex w-full border-b border-neutral-3 pt-4 pb-4 pl-2">
        <div class="w-14 text-white rounded flex items-center justify-center mr-6">
            <img *ngIf="thumbnail" alt="" [src]="thumbnail" class="rounded">
            <span *ngIf="!thumbnail"  class="max-w-14" inlineSVG="/assets/icons/thumbnail.svg"
                  [setSVGAttributes]="{width: 40, height: 40}"></span>
        </div>
        <div class="flex py-2 items-center w-full">
            <div><span class="font-medium block">{{label}}</span></div>
            <span *ngIf="clickable" class="ml-auto" inlineSVG="/assets/icons/navigation-arrow-icon.svg"></span>
        </div>
    </div>
</div>
