import {Component, OnDestroy, OnInit} from '@angular/core';
import {SimplePaginator} from '../../utils/paginator/simple-paginator';
import {Project} from 'api/models/asset/project';
import {filter, map, pairwise, pluck, withLatestFrom} from 'rxjs/operators';
import {ApiService} from '../../services/api.service';
import {merge, of, Subscription} from 'rxjs';
import {SearchFacetsService} from '../../services/search-facets.service';
import {PointResult, SearchFilterResponse} from '../../model/search-response';
import {projectSortOptions} from 'src/app/model/sort/project-sort-options';
import {SearchStateService} from '../../services/search-state.service';
import {SearchStatePaginator} from '../../utils/paginator/search-state-paginator';
import {SelectionService} from '../../services/selection.service';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {SortService} from '../../services/sort.service';
import {ActivatedRoute} from '@angular/router';
import {MobileService} from '../../services/mobile.service';

@Component({
    selector: 'app-projects-overview',
    templateUrl: './projects-overview.component.html'
})
export class ProjectsOverviewComponent<Sort> implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    projectSortOptions = projectSortOptions;
    isOnMobile = false;
    totalProjectItems = 0;

    gridView$ = this.searchStateService.gridView$;

    favoriteProjectsPaginator = new SimplePaginator<Project>((page, pageSize, query, favorite, sort, projectFilter) => {
        return this.apiService.filter('project', '', '', true, sort, page, pageSize, projectFilter);

    }, 3);

    allProjectsPaginator = new SearchStatePaginator<Project>(
        this.searchStateService,
        (page, pageSize, query, favorite, sort, projectFilter) => {
            return this.apiService.filter<Project>('project', '', query, favorite, sort, page, pageSize, projectFilter);
        }
    );

    allProjectsPointResults$ = this.apiService.allProjects$.pipe(
        pluck('value'),
        withLatestFrom(this.mobileService.isOnMobile$,  (points, isOnMobile) => ({points, isOnMobile})),
        filter(({points, isOnMobile}) => !isOnMobile),
        map(({points}) => points.map(item => ({...item, title: item.naam, id: item.id, type: 'project'} as PointResult)))
    );

    constructor(
        private apiService: ApiService,
        private searchFacetsService: SearchFacetsService,
        private searchStateService: SearchStateService,
        private selectionService: SelectionService,
        private sortService: SortService,
        private mapboxService: MapboxService,
        private mobileService: MobileService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(merge<SearchFilterResponse<Project>>(
            of(null),
            this.allProjectsPaginator.responseWithParams$
            ).pipe(pairwise()).subscribe(([previous, current]) => {
                this.totalProjectItems = current.count;
                this.searchFacetsService.facets$.next({
                    facets: current.facets,
                    facetsChanged: previous === null, // TODO: check if other things need to cause facet update
                    rangeFacets: current.rangeFacets
                });
                this.selectionService.selectionMode$.next('project');
            }),
            this.allProjectsPointResults$.subscribe(points => {
                this.searchStateService.pointResults$.next(points);
                const filteredResults = (points.filter(result => result.geog !== null)).map(result => result.geog.coordinates);
                this.mapboxService.moveToLocation(filteredResults);
            }),
            this.route.queryParamMap.subscribe(queryParamMap => {
                if (queryParamMap.has('sort')) {
                    const sort = queryParamMap.get('sort') as any;
                    if (projectSortOptions.some(option => option.key === sort) ||
                        projectSortOptions.some(option => '-' + option.key === sort)) {
                        this.sortService.currentState$.next(sort.toString());
                        if(sort.toString() !== this.sortService.defaultState)
                        {
                            this.sortService.currentSort$.next(1);
                        }
                        if(sort.toString().includes('-') && sort.toString() !== this.sortService.defaultState)
                        {
                            this.sortService.currentSort$.next(2);
                        }
                        this.allProjectsPaginator.setSort(sort);
                    }
                    else {
                        this.allProjectsPaginator.setSort('-aangemaakt_op');
                    }
                }
            }),
            this.mobileService.isOnMobile$.subscribe(isOnMobile => {
                this.isOnMobile = isOnMobile;
                this.favoriteProjectsPaginator.setPageSize(isOnMobile ? 2 : 3);
            })
        );
        this.sortService.currentState$.next('');
        this.sortService.paginator = this.allProjectsPaginator;
        this.sortService.defaultState = '-aangemaakt_op';
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }
}
