<div class="app-modal-header">
    <vwui-modal-header>
        <div class="mobile:flex mobile:items-center">
            <div
                class="desktop:hidden p-4"
                inlineSVG="/assets/icons/icon-arrow-left.svg"
                [setSVGAttributes]="{width: 16, height: 16}"
                (click)="close()"
            ></div>
            <div><ng-content></ng-content></div>
        </div>
    </vwui-modal-header>
</div>
