<div *ngIf="projects$ | async as projects; else placeholder" class="flex">
    <div *ngFor="let project of projects.slice(0, 3)"
        @fadeInAnimation
        [class.bg-primary]="!(project | projectImage)"
        class="relative w-8 h-8 rounded flex items-center justify-center -mx-1 cursor-pointer"
    >
        <img *ngIf="project | projectImage; else defaultImage"
            [src]="project | projectImage"
            alt=""
            class="absolute inset-0 w-full object-cover rounded"
        >
        <ng-template #defaultImage>
            <span [setSVGAttributes]="{width: 16, height: 16}"
                class="absolute left-auto right-auto text-white rounded"
                inlineSVG="/assets/icons/icon-16-folder.svg"
            ></span>
        </ng-template>
    </div>
    <div *ngIf="projects.length > 3"
        class="relative w-8 h-8 rounded flex items-center justify-center -mx-1 cursor-pointer bg-neutral-4"
    >
        <span class="absolute left-auto right-auto text-xs text-dark rounded">+{{ projects.length - 3 }}</span>
    </div>
</div>

<ng-template #placeholder>
    <div class="relative w-8 h-8 rounded flex items-center justify-center -mx-1 cursor-pointer bg-primary">
        <span [setSVGAttributes]="{width: 16, height: 16}"
              class="absolute left-auto right-auto text-white rounded"
              inlineSVG="/assets/icons/icon-16-folder.svg"
        ></span>
    </div>
</ng-template>
