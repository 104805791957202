import {SelectionService} from '../../services/selection.service';
import {Router} from '@angular/router';
import {Company} from 'api/models/asset/company';
import {Project, projectImage} from 'api/models/asset/project';
import {Relation} from 'api/models/asset/relation';

export class SelectionHelper {
    selectionMode$ = this.selectionService.selectionMode$;

    constructor(
        protected selectionService: SelectionService,
        protected router: Router) {
    }

    onItemClicked(event: MouseEvent, item: Company | Project | Relation, type: 'company' | 'project' | 'relation') {
        if (type === 'project') {
            this.projectClicked(event, item as Project);
        }
        if (type === 'company') {
            this.companyClicked(event, item as Company);
        }
        if (type === 'relation') {
            this.relationClicked(event, item as Relation);
        }
    }

    projectClicked(event: MouseEvent, project: Project) {
        this.gotoItemDetail(event, project, 'project');
    }

    companyClicked(event: MouseEvent, company: Company) {
        this.gotoItemDetail(event, company, 'company');
    }

    relationClicked(event: MouseEvent, relation: Relation) {
        this.gotoItemDetail(event, relation, 'relation');
    }

    toggleCompanySelected(event: MouseEvent, company: Company) {
        if (this.selectionService.companyIsSelected(company)) {
            this.removeCompanyFromSelection(event, company);
        } else {
            this.addCompanyToSelection(event, company);
        }
    }

    toggleProjectSelected(event, project) {
        if (this.selectionService.projectIsSelected(project)) {
            this.removeProjectFromSelection(event, project);
        } else {
            this.addProjectToSelection(event, project);
        }
    }

    addProjectToSelection(event: MouseEvent, project: Project) {
        event.stopPropagation();
        this.selectionService.addSelected({
            id: project.id,
            type: 'project',
            icon: projectImage(project)
        });
    }

    removeProjectFromSelection(event: MouseEvent, project: Project) {
        event.stopPropagation();
        this.selectionService.removeSelected({
            type: 'project',
            id: project.id,
            icon: projectImage(project)
        });
    }

    addCompanyToSelection(event: MouseEvent, company: Company) {
        event.stopPropagation();
        this.selectionService.addSelected({
            type: 'company',
            id: company.kvw_nummer,
            icon: company.entiteit_logo_url
        });
    }

    removeCompanyFromSelection(event: MouseEvent, company: Company) {
        event.stopPropagation();
        this.selectionService.removeSelected({
            type: 'company',
            id: company.kvw_nummer,
            icon: company.entiteit_logo_url
        });
    }

    gotoItemDetail(event: MouseEvent, item: Company | Project | Relation, type: 'company' | 'project' | 'relation') {
        if (type === 'company') {
            const company = item as Company;
            this.router.navigate(['/companies/', company.kvw_nummer], {
                queryParamsHandling: 'preserve'
            });
        }
        if (type === 'project') {
            const project = item as Project;
            this.router.navigate(['/projects/', project.id], {
                queryParamsHandling: 'preserve'
            });
        }
        if (type === 'relation') {
            const relation = item as Relation;
            this.router.navigate(['/relations/', relation.relation_id], {
                queryParamsHandling: 'preserve'
            });
        }
    }
}
