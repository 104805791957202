export class FileUtil {

    static downloadBlobAsFile(blob: Blob, filename: string) {
        // Blob download link
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a') as any;

        a.href = url;
        a.download = filename;

        // Add link to DOM as firefox wont allow clicks on elements not in the DOM.
        a.style.display = 'none';
        document.body.appendChild(a);

        a.click();

        setTimeout(() => {
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }, 100);
    }

    static dataURItoBlob(dataURI: string, sliceSize: number = 512) {
        // convert base64 to raw binary data held in a string
        const byteCharacters = atob(dataURI.split(',')[1]);
        // separate out the mime component
        const contentType = dataURI.split(',')[0].split(':')[1].split(';')[0];

        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    }
}
