import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {ScreenService} from '../../services/screen.service';
import {Subscription} from 'rxjs';
import {SearchRelation} from '../../model/search-relation';
import {Router} from '@angular/router';
import {Relation} from 'api/models/asset/relation';
import {SortService} from '../../services/sort.service';

@Component({
  selector: 'app-relation-list',
  templateUrl: './relation-list.component.html'
})
export class RelationListComponent implements OnInit, OnDestroy {
    @Input() items: SearchRelation[] = [];
    @Input() addEmptyStateRow = false;
    private subscriptions: Subscription[] = [];
    isOnMobile = false;
    constructor(public mapbox: MapboxService,
                public screenService: ScreenService,
                public router: Router,
                public sortService: SortService) {
    }

    ngOnInit() {
        this.subscriptions.push(
            this.screenService.screen$.subscribe(screen => {
                this.isOnMobile = screen === 'mobile';
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }

    onItemClicked(item: Relation) {
        this.router.navigate(['/relations', item.relation_id], {
            queryParamsHandling: 'merge'
        });
    }
}
