<button class="mr-auto rounded group-hover:visible bg-white border border-neutral-3 hover:bg-neutral-4 h-6 w-6"
        [class.invisible]="dropdownOpen"
        cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="onClick($event)"
        inlineSVG="/assets/icons/icon-16-more-horizontal.svg">
</button>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="dropdownOpen"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    [cdkConnectedOverlayPositions]="connectedPositions"
    (overlayOutsideClick)="onOutsideOverlayClick()"
    (detach)="onOutsideOverlayClick()">
    <div class="bg-white border-neutral-3 border rounded z-10 mt-1">
        <div class="px-2 py-4">
            <div class="text-xs mb-2 ml-4 text-neutral-1">
                Acties
            </div>
            <div>
                <div (click)="removeProjectFromCollection()" class="leading-6 text-dark py-2 px-4 cursor-pointer hover:bg-neutral-4 rounded">
                    Project verwijderen
                </div>
            </div>
        </div>
    </div>
</ng-template>
