import {BehaviorSubject, Observable, combineLatest} from 'rxjs';
import {SearchFilterResponse} from '../../model/search-response';
import {debounceTime, finalize, map, pairwise, shareReplay, startWith, switchMap, tap} from 'rxjs/operators';
import {isFilterActive} from '../filter';
import {mergeFacetCollection} from '../facets';

export interface PaginatorLoadParams<Sort, Filter> {
    page: number;
    pageSize: number;
    query: string;
    favorite: boolean;
    sort: Sort;
    filter: Filter;
}

export interface SearchFilterResponseWithParams<Item, Sort = any, Filter = any>  extends SearchFilterResponse<Item> {
    params: PaginatorLoadParams<Sort, Filter>;
}

export abstract class AbstractPaginator<Item, Sort = any, Filter = any> {
    private loadingCountInternal$ = new BehaviorSubject(0);

    totalPages = 1;
    totalElements = 0;
    currentPage = 0;
    isEmpty = false;

    currentPageFirstIndex = 0;
    currentPageLastIndex = 0;

    searchId: string;
    searchIndex: string;

    readonly responseWithParams$: Observable<SearchFilterResponseWithParams<Item, Sort, Filter>> = this.loadParams$.pipe(
        debounceTime(100),
        // query once without filter if query has changed to make sure we have all facet options
        startWith<PaginatorLoadParams<Sort, Filter>, PaginatorLoadParams<Sort, Filter>>(null),
        pairwise(),
        switchMap(([previous, current]) => {
            const facetsChanged = previous === null
                || previous.query !== current.query
                || previous.favorite !== current.favorite;

            if (facetsChanged && current.filter && isFilterActive(current.filter)) {
                // The filter may be missing in the facet results of the filterless loadData() call
                // It will be present in the filtered loadData() call
                // To ensure all facets are present _with_ the filter, we merge the facets from both calls
                return combineLatest([
                    this.loadData({...current, pageSize: 1, filter: {} as Filter}),
                    this.loadData(current)
                ]).pipe(map(([allResults, filteredResults]) => {
                    return { ...filteredResults, facets: mergeFacetCollection(allResults.facets, filteredResults.facets) };
                }));
            } else {
                return this.loadData(current);
            }
        }),
        tap(response => {
            this.setProperties(response);
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    readonly content$: Observable<Item[]> = this.responseWithParams$.pipe(
        map(response => response.value),
    );
    readonly count$: Observable<number> = this.responseWithParams$.pipe(
        map(response => response.count),
    );

    protected constructor(
        public readonly loadParams$: Observable<PaginatorLoadParams<Sort, Filter>>,
        protected loadFunction: (
            page: number,
            pageSize: number,
            query: string,
            favorite: boolean,
            sort: Sort,
            filter: Filter
        ) => Observable<SearchFilterResponse<Item>>,
    ) {
    }

    get isLoading() {
        return this.loadingCountInternal$.value > 0;
    }

    get isLoading$() {
        return this.loadingCountInternal$.asObservable();
    }

    hasNext() {
        return this.currentPage + 1 < this.totalPages;
    }

    hasPrevious() {
        return this.currentPage > 0;
    }

    private loadData(params: PaginatorLoadParams<Sort, Filter>) {
        const {page, pageSize, query, favorite, sort, filter} = params;
        // Optimistic index
        const offset = (page * pageSize);
        this.currentPageFirstIndex = 1 + offset;
        this.currentPageLastIndex = offset + pageSize;
        //
        this.loadingCountInternal$.next(this.loadingCountInternal$.value + 1);
        return this.loadFunction(page, pageSize, query, favorite, sort, filter).pipe(
            finalize(() => {
                this.loadingCountInternal$.next(this.loadingCountInternal$.value - 1);
            }),
            map(response => ({...response, params}))
        );
    }

    private setProperties(response: SearchFilterResponseWithParams<Item, Sort, Filter>) {
        const {page, pageSize} = response.params;
        const offset = (page * pageSize);
        this.totalPages = Math.ceil(Math.max(1,response.count) / pageSize);
        this.totalElements = response.count;
        this.currentPage = page;

        this.isEmpty = response.value.length === 0;

        this.currentPageFirstIndex = this.isEmpty ? 0 : 1 + offset;
        this.currentPageLastIndex = this.isEmpty ? 0 : response.value.length + offset;

        this.searchId = response.searchId;
        this.searchIndex = response.searchIndex;
    }

    abstract setPageSize(pageSize: number);
    abstract setSort(sort: Sort);

    abstract next(): void;
    abstract previous(): void;
}
