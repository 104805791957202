import {Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import { parseISO } from 'date-fns';
import {BehaviorSubject, merge, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {RangeFacet} from '../search-filter/search-filter.component';

export interface Ui5SliderElement extends HTMLElement {
    startValue: number;
    endValue: number;
    disable: boolean;
    labelInterval: number;
    max: number;
    min: number;
    showTickmarks: boolean;
    showTooltip: boolean;
    step: number;
}

@Component({
    selector: 'app-search-filter-select-range',
    templateUrl: './search-filter-select-range.component.html',
    styleUrls: ['./search-filter-select-range.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchFilterSelectRangeComponent),
            multi: true
        }
    ]
})
export class SearchFilterSelectRangeComponent implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {
    @Input() group!: RangeFacet;

    private subscriptions: Subscription[] = [];

    private inputWithoutChangeTimeout: number | undefined;
    private updateLabelTimeout: number | undefined;
    private months: string[] = ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];

    minValue: number | undefined;
    maxValue: number | undefined;

    startValue: number | undefined;
    endValue: number | undefined;

    startLabel: string | number | undefined;
    endLabel: string | number | undefined;

    inputBlur = new BehaviorSubject(null);
    inputForm = new UntypedFormGroup({
        startValue: new UntypedFormControl(),
        endValue: new UntypedFormControl()
    });


    ngOnInit() {
        this.subscriptions.push(merge(
            this.inputForm.valueChanges.pipe(debounceTime(1000)),
            this.inputBlur
        ).subscribe(() => {
            const {startValue, endValue} = this.inputForm.value;
            if (typeof startValue === 'number' && typeof endValue === 'number') {
                this.updateValue(startValue, endValue);
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(it => it.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges) {
        this.minValue = this.group.min;
        this.maxValue = this.group.max;

        if (this.maxValue === this.minValue) {
            this.maxValue += 1;
        }

        if (this.group.dataType === 'MONTH') {
            const steps = (1 + this.group.max - this.group.min) * 12 - 1;
            this.maxValue = this.group.min + steps;
        }

        if (this.startValue === undefined) {
            this.startValue = this.minValue;
        }
        if (this.endValue === undefined) {
            this.endValue = this.maxValue;
        }

        this.updateLabel(this.startValue, this.endValue);
    }

    onSliderInput(event: Event) {
        const element = event.target as Ui5SliderElement;
        this.updateLabel(element.startValue, element.endValue);
        this.onTouched();

        // We need to set a timeout to trigger onChange on a delay because the slider doesnt emit change events when its used with keyboard
        clearTimeout(this.inputWithoutChangeTimeout);
        this.inputWithoutChangeTimeout = window.setTimeout(() => this.onSliderChange(event), 1000);
    }

    onSliderChange(event: Event) {
        clearTimeout(this.inputWithoutChangeTimeout);
        this.onTouched();
        const element = event.target as Ui5SliderElement;
        this.updateValue(element.startValue, element.endValue);
    }

    registerOnChange(fn: (value: (number|string)[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(obj: unknown[]): void {
        const [startValue, endValue] = obj;

        if (startValue === undefined && endValue === undefined) {
            this.startValue = this.minValue;
            this.endValue = this.maxValue;
        } else if (this.group.dataType === 'MONTH') {
            if (typeof startValue === 'string' && typeof endValue === 'string') {
                this.startValue = this.monthFilterValueToValue(startValue);
                this.endValue = this.monthFilterValueToValue(endValue);
            } else {
                console.error(`Unsupported start/endValue type, expected string got '${typeof startValue}' / '${typeof endValue}'`);
            }
        } else {
            if (typeof startValue === 'number' && typeof endValue === 'number') {
                this.startValue = startValue;
                this.endValue = endValue;
            } else {
                console.error(`Unsupported start/endValue type, expected number got '${typeof startValue}' / '${typeof endValue}'`);
            }
        }

        if (this.startValue !== undefined && this.endValue !== undefined) {
            this.updateLabel(this.startValue, this.endValue);
        }
    }

    private onChange: (value: (number|string)[]) => void = () => {};
    private onTouched: () => void = () => {};

    private updateValue(rawStartValue: number, rawEndValue: number) {
        const startValue = Math.min(rawStartValue, rawEndValue);
        const endValue = Math.max(rawStartValue, rawEndValue);

        this.startValue = startValue;
        this.endValue = endValue;

        this.updateLabel(startValue, endValue);

        if (startValue === this.minValue && endValue === this.maxValue) {
            this.onChange([]);
        } else if (this.group.dataType === 'MONTH') {
            this.onChange([
                this.monthValueToFilterValue(startValue),
                this.monthValueToFilterValue(endValue)
            ]);
        } else if (this.group.dataType === 'NUMBER') {
            this.onChange([startValue, endValue]);
        }
    }

    private updateLabel(startValue: number, endValue: number) {
        clearTimeout(this.updateLabelTimeout);
        this.updateLabelTimeout = window.setTimeout(() => {
            this.inputForm.patchValue({startValue, endValue}, {emitEvent: false});
            if (this.group.dataType === 'MONTH') {
                this.startLabel = this.monthValueToLabel(startValue);
                this.endLabel = this.monthValueToLabel(endValue);
            } else {
                this.startLabel = startValue;
                this.endLabel = endValue;
            }
        }, 5);
    }

    private monthValueToLabel(value: number) {
        const [year, month] = this.monthValueToYearMonth(value);
        return `${this.months[month]} ${year}`;
    }

    private monthValueToFilterValue(value: number) {
        const [year, month] = this.monthValueToYearMonth(value);
        return (new Date(year, month)).toISOString();
    }

    private monthValueToYearMonth(value: number): [number, number] {
        const minValue = this.minValue || 0;
        const relativeValue = value - minValue;
        const year = minValue + Math.floor(relativeValue / 12);
        const month = relativeValue % 12;
        return [year, month];
    }

    private monthFilterValueToValue(filterValue: string): number {
        const minValue = this.minValue || 0;
        const date = parseISO(filterValue);
        if (isNaN(date.getTime())) {
            throw Error(`Failed to parse month filterValue '${filterValue}')`);
        }
        const year = date.getFullYear();
        const month = date.getMonth();
        const value = minValue + ((year - minValue) * 12) + month;

        if(year < minValue) {
            return minValue;
        } else if (this.maxValue && value > this.maxValue) {
            return this.maxValue;
        } else {
            return value;
        }
    }
}
