
export function cleanObject<T>(object: T): T {
    const cleanedValue = {};

    Object.keys(object).forEach(key => {
        if (object[key] !== undefined) {
            cleanedValue[key] = object[key];
        }
    });

    return cleanedValue as T;
}
