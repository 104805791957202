<ng-container *ngIf="collection$|async as collection; else empty">
    <app-collection-header [collection]="collection"></app-collection-header>
</ng-container>

<ng-template #empty>
    <div *ngIf="loadingCollection" class="m-6 flex justify-center">
        <vwui-spinner></vwui-spinner>
    </div>
    <div *ngIf="!loadingCollection" class="text-base font-medium text-dark truncate">
        <div class="ml-6 py-8 w-5 h-5">
            <span class="bg-white rounded mr-2 cursor-pointer" inlineSVG="/assets/icons/icon-arrow-left.svg"
                  (click)="goBack()"></span>
            Collectie niet gevonden
        </div>
    </div>
</ng-template>

<div class="mx-4 py-4 border-b border-neutral-3">
    <app-collections-search-input
        class="flex-grow"
        placeholder="Zoeken door projecten binnen de collectie"
        type="project"
        [formControl]="queryControl"
        [suggestions]="suggestions$|async"
        [suggestionClickable]="false"
        (submit)="handleSearchProject($event)"
    ></app-collections-search-input>
</div>

<div class="relative p-4">
    <div class="pb-4 flex items-center">
        <app-export-dropdown titleText="Projecten in de collectie" table="project">
        </app-export-dropdown>
    </div>
    <ng-container *ngIf="projects">
        <div class="grid grid-cols-1 gap-2"
             [class.loading-blur]="loadingProjects || (saveState$|async) !== saveStates.INITIAL"
             cdkDropList (cdkDropListDropped)="drop($event)">
            <app-project-card
                cdkDrag
                *ngFor="let project of projects"
                [project]="project"
                [cdkDragDisabled]="isOnMobile"
            ></app-project-card>
        </div>
    </ng-container>
    <div *ngIf="loadingProjects" class="m-6 flex justify-center">
        <vwui-spinner></vwui-spinner>
    </div>
</div>
<ng-container *ngIf="!loadingProjects && projects.length === 0">
    <div class="text-center flex items-center flex-col mt-16">
        <span inlineSVG="/assets/icons/box-illustration.svg"></span>
        <span class="mt-6 font-medium text-lg text-darkBlue_2">Geen resultaten gevonden</span>
        <span class="mt-4 text-neutral-1 w-1/2">Er zijn nog geen projecten met deze naam.</span>
    </div>
</ng-container>
