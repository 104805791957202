export const latLngRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export function isLatLng(value: string) {
    return latLngRegex.test(value);
}

export function strToLatLngObject(value: string): {lat: number, lng: number}|null {
    const matches = value.match(latLngRegex)
    if (matches !== null) {
        return {
            lat: +matches[1],
            lng: +matches[4]
        }
    }

    return null;
}