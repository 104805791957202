import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {Project} from 'api/models/asset/project';
import {AbstractTileContextMenuComponent} from './abstract-tile-context-menu.component';
import {FavoriteService} from '../../services/favorite.service';
import {Overlay} from '@angular/cdk/overlay';
import {SelectionService} from '../../services/selection.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-project-tile-context-menu',
    templateUrl: './tile-context-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectTileContextMenuComponent extends AbstractTileContextMenuComponent {
    @Input() project: Project;

    constructor(
        protected changeDetectionRef: ChangeDetectorRef,
        protected overlay: Overlay,
        protected selectionService: SelectionService,
        protected router: Router,
        private favoriteService: FavoriteService,
    ) {
        super(changeDetectionRef, overlay, selectionService, router);
    }

    onAddFavorite(event: MouseEvent, project: Project) {
        event.stopPropagation();
        this.favoriteService.addFavorite({
            type: 'project',
            id: project.id
        });
        this.onOutsideOverlayClick();
    }

    onRemoveFavorite(event: MouseEvent, project: Project) {
        event.stopPropagation();
        this.favoriteService.removeFavorite({
            type: 'project',
            id: project.id
        });
        this.onOutsideOverlayClick();
    }

}
