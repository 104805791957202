import {Pipe, PipeTransform} from '@angular/core';
import {AssetType} from '../model/search-result-type';

@Pipe({
    name: 'assetTypeRoute'
})
export class AssetTypeRoutePipe implements PipeTransform {
    transform(type: AssetType): string {
        switch (type) {
            case 'project':
                return '/projects';
            case 'company':
                return '/companies';
            case 'verblijfsobject':
                return '/verblijfsobject';
            case 'relation':
                return '/relations';
            default:
                console.warn(`No detail page for asset type ${type}`);
        }
    }
}
