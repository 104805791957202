import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VisibleForFeatureFlagDirective} from './visible-for-feature-flag.directive';
import {FeatureFlagService} from './feature-flag.service';


@NgModule({
    declarations: [
        VisibleForFeatureFlagDirective
    ],
    imports: [
        CommonModule
    ],
    providers: [
        FeatureFlagService
    ],
    exports: [
        VisibleForFeatureFlagDirective
    ]
})
export class FeatureFlagModule {
}
