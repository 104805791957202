import mapboxgl, { CustomLayerInterface } from 'mapbox-gl';
import {
    ShaderLib,
    ShaderMaterial,
    UniformsUtils,
    Shader,
    Color,
    DoubleSide,
    Vector3,
    Box3,
    PlaneGeometry,
    MeshBasicMaterial,
    Mesh
} from 'three';

import { environment } from '../../../../environments/environment';
import { Subscription, fromEvent, Subject } from 'rxjs';
import { ApiService } from '../../api.service';


import { Glb } from 'api/models/glb';

export class MassaModel implements CustomLayerInterface {
    private defaultColor = new Color(0x008cbc).convertSRGBToLinear();//new Color(0xb7bcc3).convertSRGBToLinear();
    private highlightedColor = new Color(0x32A3C9).convertSRGBToLinear();//new Color(0x1abad1).convertSRGBToLinear();
    private selectedColor = new Color(0x008cbc).convertSRGBToLinear();//new Color(0x008cbc).convertSRGBToLinear();


    private filteredBagPandIds = [];

    private tb: any;
    private map: mapboxgl.Map;
    private subscriptions: Subscription[] = [];
    private controller: AbortController = new AbortController();
    private loadedTilesIndex = {};
    private expiredTilesIndex = {};
    private fetching = false;
    private rotating = false;
    private opacity = 1;

    public minZoom = 14;
    public maxZoom = 24;

    public glbs: Glb[];
    public onSelected$ = new Subject<mapboxgl.MapLayerMouseEvent & mapboxgl.EventData>();
    public onContextMenu$ = new Subject<mapboxgl.MapLayerMouseEvent & mapboxgl.EventData>();

    public bagFilter$ = new Subject<any>();
    public isOn = false;

    readonly type: 'custom' = 'custom';
    readonly renderingMode: '3d' = '3d';

    constructor(
        public readonly id: string,
        private apiService: ApiService,
    ) {
    }

    onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext) {
        this.tb = (window as any).tb;
        this.map = map;
        this.tb.setLayerZoomRange(this.id, this.minZoom, this.maxZoom);
        this.loadModels();
        this.map.on('moveend', this.loadModels);
        this.map.on('rotatestart', this.onRotateStart);
        this.map.on('rotateend', this.onRotateEnd);
    }

    onRemove(map: mapboxgl.Map, gl: WebGLRenderingContext) {
        this.map.off('moveend', this.loadModels);
        this.map.off('rotatestart', this.onRotateStart);
        this.map.off('rotateend', this.onRotateEnd);
        const index = this.tb.zoomLayers.indexOf(this.id);
        if (index !== -1) { this.tb.zoomLayers.splice(index, 1); }

        this.refreshPandFilter(false);
        this.clear();
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render(gl: WebGLRenderingContext, matrix: number[]) {
    }

    toggleOn() {
        this.isOn = true;
        const index = this.tb.zoomLayers.indexOf(this.id);
        if (index === -1) { this.tb.zoomLayers.push(this.id); }
        this.tb.toggleLayer(this.id, true);

        this.loadModels();
        this.refreshPandFilter(true);
    }

    toggleOff() {
        const index = this.tb.zoomLayers.indexOf(this.id);
        if (index !== -1) { this.tb.zoomLayers.splice(index, 1); }
        this.refreshPandFilter(false);
        this.clear();
        this.tb.toggleLayer(this.id, false);


        this.isOn = false;
        if (this.fetching) {
            this.controller.abort();
            this.fetching = false;
        }
    }

    clear() {
        this.tb.clear(this.id, false);
        this.loadedTilesIndex = {};
    }

    onRotateStart = () => {
        this.rotating = true;
    };
    onRotateEnd = () => {
        this.rotating = false;
    };
    onSelectedChange(e: any) {
        const projectId = e.detail?.userData?.projectenportaal;
        if (projectId !== undefined) {
            e.target.click.features = [{
                id: projectId,
                type: 'Feature',
                properties: {
                    obj: e.detail,
                    id: projectId,
                    searchType: 'project'
                }
            }];
            e.target.click.layer = this.id;
            this.onSelected$.next(e.target.click);
        }
    }

    onContextMenu(e: any) {
        const projectId = e.detail?.userData?.projectenportaal;
        if (this.rotating) {
            return;
        }
        if (projectId !== undefined) {
            e.target.click.features = [{
                id: projectId,
                type: 'Feature',
                properties: {
                    obj: e.detail,
                    id: projectId
                }
            }];
            e.target.click.layer = this.id;
            this.onContextMenu$.next(e.target.click);
        } else {
            e.target.click.features = [];
            e.target.click.layer = this.id;
            this.onContextMenu$.next(e.target.click);
        }

    }


    loadModels = () => {

        const zoom = this.map.getZoom();
        if (zoom >= this.minZoom && zoom <= this.maxZoom && this.map.getLayoutProperty(this.id, 'visibility') === 'visible') {
            if (this.fetching) {
                this.controller.abort();
                this.fetching = false;
            }
            const bbx = this.map.getBounds();
            const url = environment.geoserverUrl +
                '/Digibase/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Digibase%3Avw_project_massa_model' +
                '&outputFormat=application%2Fjson&srsName=EPSG%3A4326' +
                `&BBOX=${bbx.getWest()},${bbx.getSouth()},${bbx.getEast()},${bbx.getNorth()},EPSG:4326`;

            this.controller = new AbortController();
            const { signal } = this.controller;
            this.fetching = true;
            fetch(url, { signal })
                .then(response => response.json())
                .then(tileset => {
                    Object.assign(this.expiredTilesIndex, this.loadedTilesIndex);

                    tileset.features.forEach((m: { properties: any }) => {
                        if (!this.loadedTilesIndex[m.properties.project_id]) {
                            this.apiService.getProjectGlb(m.properties.project_id).toPromise().then(glb => {
                                m.properties.url = glb.url;
                                this.addModel(this.id, m.properties);
                            }).then(() => {
                                this.loadedTilesIndex[m.properties.project_id] = true;
                                m.properties.bag_id_removed?.split(',').forEach((bagid) => {
                                    this.filteredBagPandIds.push(bagid);
                                    this.bagFilter$.next({ bagid, add: true });
                                });
                            });
                        }
                        this.expiredTilesIndex[m.properties.project_id] = false;
                    });
                }).then(() => {
                    if (this.tb.world.children.filter(obj => obj.layer === this.id).length > 10) {
                        for (const model of this.tb.world.children.filter(obj => obj.layer === this.id).slice(0, 5)) {
                            if (this.expiredTilesIndex[model.uuid]) {
                                this.tb.remove(model);
                                this.loadedTilesIndex[model.uuid] = false;
                                this.expiredTilesIndex[model.uuid] = true;
                            }
                        }
                    }
                    // make sure the lod 1 layer extrusion is set to 0
                    this.fetching = false;
                    this.refreshPandFilter();
                })
                .catch(() => { });
        }
        this.tb.repaint();
    };

    public refreshPandFilter(add: boolean = true) {
        this.filteredBagPandIds.forEach((bagid: string) => {
            this.bagFilter$.next({ bagid, add });
        });
    }

    private batchIdHighlightShaderMixin(shader: Shader) {
        const newShader = { ...shader };
        newShader.uniforms = {
            selectedBatchId: { value: - 1 },
            highlightedBatchId: { value: - 1 },
            highlightColor: { value: this.highlightedColor },
            selectedColor: { value: this.selectedColor },
            baseColor: { value: this.defaultColor },
            ...UniformsUtils.clone(shader.uniforms),
        };

        newShader.vertexShader = `
        attribute float _batchid;
        varying float batchid;
        ${newShader.vertexShader.replace(
            /#include <uv_vertex>/,
            `
            #include <uv_vertex>
            batchid = _batchid;
            `)}`;
        newShader.fragmentShader = `
        varying float batchid;
        uniform float selectedBatchId;
        uniform float highlightedBatchId;
        uniform vec3 selectedColor;
        uniform vec3 highlightColor;
        uniform vec3 baseColor;
        ${newShader.fragmentShader.replace(
            /vec4 diffuseColor = vec4\( diffuse, opacity \);/,
            `
        vec4 diffuseColor =
            abs( batchid - selectedBatchId ) < 0.5 ?
            vec4( selectedColor, (1.0+opacity)/1.8 ) :
            abs( batchid - highlightedBatchId ) < 0.5 ?
            vec4( highlightColor, (1.0+opacity)/1.8 ) :
            vec4( baseColor, opacity );
        `)}`;
        return newShader;

    }

    private addModel(layerId: string, properties: Glb) {
        const options = {
            obj: properties.url,
            type: 'gltf',
            scale: { x: 1, y: 1, z: 1 },
            units: 'meters',
            rotation: { x: 90, y: 180, z: 0 }, //default rotation
            anchor: 'none',
            identiticatie: properties.bag_id,
            projectenportaal: properties.project_id,
            bbox: false,
        };
        // make sure we check for unique id when adding buildings
        if (this.tb.world.children.some(o => o.uuid === properties.project_id)) {
            return;
        }

        this.tb.loadObj(options, (model: any) => {
            model.traverse((child: any) => {
                if (child instanceof Mesh && child.geometry.type === 'BufferGeometry') {
                    child.material = new ShaderMaterial({
                        ...this.batchIdHighlightShaderMixin(ShaderLib.phong),
                        transparent: false,
                        flatShading: true,
                        extensions: {
                            derivatives: true,
                        },
                        lights: true,
                        side: DoubleSide
                    });
                    child.material.uniforms.shininess.value = 1;
                    const boundingBox = new Box3();
                    const offset = 100;

                    boundingBox.setFromPoints([
                        new Vector3(model.bottomLeft.x - offset, model.bottomLeft.y - offset, model.bottomLeft.z - 10),
                        new Vector3(model.bottomRight.x + offset, model.bottomRight.y - offset, model.bottomRight.z - 10),
                        new Vector3(model.topLeft.x - offset, model.topLeft.y + offset, model.topLeft.z - 10),
                        new Vector3(model.topRight.x + offset, model.topRight.y + offset, model.topRight.z - 10)
                    ]);

                    const width = boundingBox.max.x - boundingBox.min.x;
                    const height = boundingBox.max.y - boundingBox.min.y;

                    // Create a PlaneGeometry with the dimensions of the bounding box
                    const geometry = new PlaneGeometry(width, height);
                    // Rotate the geometry to face downwards
                    geometry.rotateX(Math.PI);
                    // Create a material with the desired color
                    const material = new MeshBasicMaterial({ color: new Color(1, 1, 0), transparent: true, opacity: 0 }); // RGB for yellow
                    // Create a mesh with the geometry and material
                    const rectangle = new Mesh(geometry, material);
                    // Position the rectangle at the center of the bounding box
                    rectangle.position.set(
                        (boundingBox.min.x + boundingBox.max.x) / 2,
                        (boundingBox.min.y + boundingBox.max.y) / 2,
                        boundingBox.min.z // Assuming the rectangle is on the same plane as the bounding box
                    );
                    rectangle.raycast = function() { };
                    // Add the rectangle to the model (assuming model is already defined)
                    model.add(rectangle);

                }

            });


            model.userData.batchid = -1;
            this.subscriptions.push(fromEvent<Event>(model, 'SelectedChange').subscribe((event: Event) => {
                this.onSelectedChange(event);
            }));
            this.subscriptions.push(fromEvent<Event>(model, 'ContextMenu').subscribe((event: Event) => {
                this.onContextMenu(event);
            }));
            model.setCoords([properties.threebox_X, properties.threebox_Y]);
            model.uuid = properties.project_id;
            model.layer = layerId;
            this.tb.add(model, layerId);
            return model;
        });
    }
}

