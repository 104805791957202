import {Component, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Component({
    selector: 'app-resize-handle',
    template: '',
    styleUrls: ['./resize-handle.component.scss']
})
export class ResizeHandleComponent {
    @Output() resizeChange = new EventEmitter<number>();
    @Output() resizeEnd = new EventEmitter<number>();

    @HostBinding('class.bg-opacity-25')
    resizing = false;

    private lastX = 0;

    @HostListener('document:pointermove', ['$event'])
    onDocumentPointerMove(ev: PointerEvent) {
        if (this.resizing) {
            this.resizeChange.emit(ev.clientX);
            this.lastX = ev.clientX;
        }
    }

    @HostListener('document:pointerup')
    onDocumentPointerUp() {
        this.onPointerCancel();
    }

    @HostListener('pointerdown')
    onPointerDown() {
        this.resizing = true;
        document.body.classList.add('resizing');
    }

    @HostListener('pointerup')
    onPointerCancel() {
        if (this.resizing) {
            this.resizing = false;
            document.body.classList.remove('resizing');
            this.resizeEnd.emit(this.lastX);
        }
    }
}
