import {FillLayer} from 'mapbox-gl';

export const woonplaatsFill: FillLayer = {
    id: 'wpl',
    type: 'fill',
    source: 'woonplaats',
    layout: {},
    paint: {
        'fill-color': 'rgba(0, 140, 188, 0.3)',
        'fill-outline-color': 'rgba(0, 0, 0, 0.4)',
    }
};
