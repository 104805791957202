import {Component} from '@angular/core';
import {VwuiModalRef} from '@recognizebv/vwui-angular';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
})
export class ModalHeaderComponent {
    constructor(private vwuiModalRef: VwuiModalRef) {
    }

    close() {
        this.vwuiModalRef.close();
    }
}
