import { Injectable } from '@angular/core';
import { ProjectColumnConfigItem } from '../model/project-column-config-item';

@Injectable({
  providedIn: 'root'
})
export class ProjectColumnConfigService {
    constructor() { }

    get config(): ProjectColumnConfigItem[] | undefined {
        if (localStorage.projectColumnConfig) {
            return JSON.parse(localStorage.projectColumnConfig);
        }
    }

    set config(config: ProjectColumnConfigItem[]) {
        localStorage.projectColumnConfig = JSON.stringify(config);
    }

    isConfigApplicable(otherConfig: ProjectColumnConfigItem[]): boolean {
        if (this.config == null) {
            return false;
        }
        // This ensure we don't mess with the config should we change columns in the future
        const persistedNames = this.config.map(c => c.name);
        const currentNames = otherConfig.map(c => c.name);
        // Compare arrays by value
        return persistedNames.every((name, i) => currentNames[i] === name);
    }
}
