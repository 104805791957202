<div class="form-group mb-5">
    <label *ngIf="label" class="form-label">
        {{label}}
        <span
            *ngIf="labelDescription"
            class="bg-primary rounded-full inline-block w-4 h-4 text-center text-white ml-1"
            style="font-size: 12px;"
            [tooltip]="labelDescription"
        >?</span>
    </label>
    <ng-select
        bindLabel="displayName"
        [loading]="loading"
        [formControl]="control"
        [typeahead]="onInput"
        [items]="users$ | async"
        [multiple]="true"
        [readonly]="readonly"
        [hideSelected]="true"
        [clearable]="false"
        (add)="onAdd($event)"
        (remove)="onRemove($event)"
    >
        <ng-template ng-option-tmp let-item="item">
            <div>
                <div>{{item.displayName}}</div>
                <div class="text-xs font-medium">{{item.mail}}</div>
            </div>
        </ng-template>

        <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label" [tooltip]="item.mail" [tooltipAppendToBody]="true">
                {{item.displayName}}
            </span>
            <span class="ng-value-icon right" (click)="!readonly && clear(item)">×</span>
        </ng-template>
    </ng-select>
</div>
