import {MapConfigLayerIds, MapConfigLayers, MapLayerConfig} from '../model/map-config';

export interface EncodeableLayerConfig extends MapLayerConfig {
    active: boolean;
    opacity: number;
    mode?: number;
}

const layerNameRegex = /([a-zA-Z0-9]+)/;
const decodeRegex = /([a-zA-Z0-9]+)_(\d+)([Y|N])(\d*)/;
const layerIdDecodeRegex = /([A-Z])(\d+)([Y|N])(\d*)/;

function encodeLayer(name: string, layer: EncodeableLayerConfig) {
    if (!layerNameRegex.test(name)) {
        throw new Error(`Unsupported layer name '${name}'`);
    }
    const opacity = (layer.opacity * 10).toString();
    const active = layer.active ? 'Y' : 'N';
    const mode = layer.mode === undefined ? '' : layer.mode.toString();
    const layerId = MapConfigLayerIds[name];
    return layerId + opacity + active + mode;
}

function decodeLayer(str: string): [string, EncodeableLayerConfig] {
    let match = layerIdDecodeRegex.exec(str);
    if (match === null) {
        match = decodeRegex.exec(str);
        if (match === null || !(MapConfigLayerIds[match[1]])) {
            throw new Error(`Failed to parse layer ${str}`,);
        }
        match[1] = MapConfigLayerIds[match[1]];
    }

    const config: EncodeableLayerConfig = {
        opacity: (parseInt(match[2], 10) / 10),
        active: match[3] === 'Y',
    };
    if (match[4] !== '') {
        config.mode = parseInt(match[4], 10);
    }

    const layerName = Object.keys(MapConfigLayerIds).find(key => MapConfigLayerIds[key] === match[1]);
    if (!layerName) {
        throw new Error(`Failed to parse layer ${str}`,);
    }

    return [layerName, config];
}

export function compress(obj: MapConfigLayers): string {
    return Object.entries(obj)
        .map(([key, value]) => encodeLayer(key, value))
        .join('~');
}

export function decompress(str: string): MapConfigLayers {
    try {
        return str.split('~')
            .map(layer => decodeLayer(layer))
            .reduce((configLayers, [key, config]) => {
                configLayers[key] = config;
                return configLayers;
            }, {} as MapConfigLayers);
    } catch (e) {
        console.error('Failed to decompress', e);
        return {} as MapConfigLayers;
    }
}
