import {Component} from '@angular/core';
import {filter, finalize, switchMap, take} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {detailPageGoBack} from '../../utils/navigation';
import {RouterHistoryService} from '../../services/router-history.service';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {ArcgisService} from '../../services/arcgis.service';
import {Observable} from 'rxjs';
import {Lot} from 'api/models/asset/lot';

@Component({
    selector: 'app-lots-detail',
    templateUrl: './lots-detail.component.html'
})
export class LotsDetailComponent {
    lot$: Observable<Lot> = this.activatedRoute.paramMap.pipe(
        switchMap(paramMap => {
            this.loading = true;
            return this.arcgisService.getLotMetaData(paramMap.get('lotId'))
                .pipe(finalize(() => this.loading = false));
        }),
    );
    loading = false;

    goBack = detailPageGoBack(this.router, this.routerHistory,  ['/search']);

    constructor(
        private activatedRoute: ActivatedRoute,
        private mapboxService: MapboxService,
        private router: Router,
        private routerHistory: RouterHistoryService,
        private arcgisService: ArcgisService,
    ) {
    }

    async loadMap() {
        if (!this.mapboxService.mapLoaded$.value) {
            await this.mapboxService.mapLoaded$.pipe(filter(it => it), take(1)).toPromise();
        }
    }
}
