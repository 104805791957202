import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, take, withLatestFrom} from 'rxjs/operators';
import {MapboxService} from './mapbox/mapbox.service';
import {BehaviorSubject,  Subscription} from 'rxjs';
import {LngLat, LngLatLike} from 'mapbox-gl';

@Injectable({
    providedIn: 'root'
})
export class PinnedLocationService implements OnDestroy{
    private subscriptions: Subscription[] = [];
    public coordinates$ = new BehaviorSubject<LngLat>(undefined);
    readonly pinnedLocationState$ = new BehaviorSubject<'init' | 'pinning' | 'pinned' | 'disabled'>('init');
    public windowLocation$ = new BehaviorSubject<'center' | 'right'>('center');

    constructor(
        private activatedRoute: ActivatedRoute,
        private mapboxService: MapboxService,
        private ngZone: NgZone,
        private router: Router
    ) {
        this.loadMap().then(() => {
            this.init();
        });
    }

    async loadMap() {
        if (!this.mapboxService.mapLoaded$.value) {
            await this.mapboxService.mapLoaded$.pipe(filter(it => it), take(1)).toPromise();
        }
    }

    ngOnDestroy(): void {
        if(this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    private async init() {
        this.subscriptions.push(
            this.activatedRoute.queryParamMap.pipe(
                withLatestFrom(this.pinnedLocationState$),
                filter(([_, pinnedlocationState]) => pinnedlocationState === 'init'),
                take(1),
            ).subscribe(([queryParam, _]) => {
                    if (queryParam.get('marker_location') !== null) {
                        const lngLat = queryParam.get('marker_location').split(',').map(x => parseFloat(x));
                        const markerLocation: LngLatLike = [lngLat[1], lngLat[0]];
                        this.mapboxService.addLocationPin(markerLocation);
                        this.coordinates$.next(new LngLat(lngLat[1], lngLat[0]));
                        this.pinnedLocationState$.next('pinned');
                    } else {
                        this.pinnedLocationState$.next('pinning');
                    }
                }
            ),
            this.pinnedLocationState$.pipe(
                filter(state => state ===  'disabled')
            ).subscribe(() => {
                this.mapboxService.removeLocationPin().finally(() => {
                    this.router.navigate([], {
                        queryParams: {
                            marker_location: null
                        },
                        queryParamsHandling: 'merge'
                    });
                });
            }),
            this.mapboxService.mapLayerClickPrioritized$.pipe(
                withLatestFrom(this.pinnedLocationState$),
                filter(([event, pinnedLocationState]) => event.layer === null && pinnedLocationState !== 'disabled'),
            ).subscribe(([event, pinnedLocationState]) => {
                this.ngZone.run(() => {
                    switch (pinnedLocationState) {
                        case 'pinning':
                            const {lat, lng} = event.lngLat;
                            this.coordinates$.next(event.lngLat);
                            this.router.navigate([], {
                                queryParamsHandling: 'merge',
                                queryParams: {
                                    marker_location: `${lat},${lng}`
                                },
                                relativeTo: this.activatedRoute
                            }).then(() => {
                                this.mapboxService.addLocationPin(event.lngLat);
                                this.pinnedLocationState$.next('pinned');
                            });
                            break;
                        case 'pinned':
                            this.mapboxService.removeLocationPin().then(() => {
                                this.router.navigate([], {
                                    queryParams: {
                                        marker_location: null
                                    },
                                    queryParamsHandling: 'merge'
                                }).then(() => {
                                    this.pinnedLocationState$.next('pinning');
                                });
                            });
                            break;
                    }
                });
            })
        );
    }

}
