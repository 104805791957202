import {ChangeDetectorRef, Directive, Input} from '@angular/core';
import {ConnectedPosition, Overlay} from '@angular/cdk/overlay';
import {Project} from 'api/models/asset/project';
import {Company} from 'api/models/asset/company';
import {Collection} from 'api/models/collection';
import {SelectionHelper} from '../is-selected/selection-helper';
import {SelectionService} from '../../services/selection.service';
import {Router} from '@angular/router';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractTileContextMenuComponent extends SelectionHelper {
    @Input() variant: 'list'|'grid' = 'grid';

    project: Project;
    collections: Collection;
    company: Company;

    scrollStrategy = this.overlay.scrollStrategies.close();
    dropdownOpen = false;
    connectedPositions: ConnectedPosition[] = [
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
        }
    ];
    selectionMode$ = this.selectionService.selectionMode$;

    protected constructor(
        protected changeDetectionRef: ChangeDetectorRef,
        protected overlay: Overlay,
        protected selectionService: SelectionService,
        protected router: Router
    ) {
        super(selectionService, router);
    }

    onClick(event: MouseEvent) {
        event.stopPropagation();
        this.dropdownOpen = !this.dropdownOpen;
        this.changeDetectionRef.detectChanges();
    }

    onOutsideOverlayClick() {
        // Schedule so closing dropdown occurs after potential onDropdownOpenClick event
        setTimeout(() => {
            this.dropdownOpen = false;
            this.changeDetectionRef.detectChanges();
        }, 0);
    }

    toggleSelected($event, subject) {
        if(subject.type === 'project') {
            this.toggleProjectSelected($event, subject);
        }
        else if(subject.type === 'company') {
            this.toggleCompanySelected($event, subject);
        }
        else {
            throw new Error('Selected item is not a project or company');
        }
    }

    onAddFavorite(event: MouseEvent, item: any) {
        throw new Error('onAddFavorite not implemented');
    }
    onRemoveFavorite(event: MouseEvent, item: any) {
        throw new Error('onRemoveFavorite not implemented');
    }
}
