<div class="px-6 pt-8">
    <div class="flex items-center mb-2">
        <h1 class="text-dark">{{modalConfig.data.title}}</h1>
        <button class="ml-auto text-neutral-1 w-5 h-5" inlineSVG="/assets/icons/icon-24-close.svg"
                (click)="close()"></button>
    </div>
    <div class="text-neutral mb-4">
        <p>{{modalConfig.data.longDescription}}</p>
    </div>

    <div class="flex gap-5 items-center border-neutral-3 mb-9">
        <div *ngIf="modalConfig.data.imageFileName" class="flex-shrink flex-half">
            <img *ngIf="modalConfig.data.imageFileName"
                 class=" transform object-cover"
                 [src]="getImageSourceUrls(modalConfig.data.imageFileName)[0]"
                 [srcset]="getImageSourceUrls(modalConfig.data.imageFileName).join(', ')">
        </div>
        <div class="flex-shrink flex-half">
            <h3 class="text-dark">Brongegevens</h3>
            <div *ngIf="modalConfig.data.sourceName" class="flex align-center mt-1">
                <span class="flex-half text-neutral-1 font-medium leading-6">Bron</span>
                <span>{{ modalConfig.data.sourceName }} </span>
            </div>
            <div *ngIf="modalConfig.data.sourceUrl" class="flex align-center mt-1">
                <span class="flex-half text-neutral-1 font-medium leading-6">Link naar bron</span>
                <span><a [href]="modalConfig.data.sourceUrl" class="text-primary">{{getUrlHostName(modalConfig.data.sourceUrl)}}</a></span>
            </div>
            <div *ngIf="importDate$ | async as lastUpdate" class="flex align-center mt-1">
                <span class="flex-half text-neutral-1 font-medium leading-6">Laatste update</span>
                <span>{{lastUpdate}}</span>
            </div>
            <div *ngIf="modalConfig.data.dataType" class="flex align-center mt-1">
                <span class="flex-half text-neutral-1 font-medium leading-6">Type data</span>
                <span>{{modalConfig.data.dataType}}</span>
            </div>
        </div>

    </div>

    <div class="flex items-center mb-9">
        <button class="ml-auto font-medium text-neutral" (click)="close()">Sluiten</button>
    </div>
</div>
