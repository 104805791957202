import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Company} from 'api/models/asset/company';
import {SelectionService} from '../../services/selection.service';
import {Router} from '@angular/router';
import {SelectionHelper} from '../is-selected/selection-helper';

@Component({
    selector: 'app-company-tile',
    templateUrl: './company-tile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CompanyTileComponent extends SelectionHelper {
    @Input() company: Company;
    @Output() clicked = new EventEmitter<Company>();
    selectionMode$ = this.selectionService.selectionMode$;

    constructor(
        protected selectionService: SelectionService,
        protected router: Router) {
        super(selectionService, router);
    }

    renderPhoneNumber(phone: string): string {
        const phoneNumber = phone
            .replace(/ /g, '');
        return `tel:${phoneNumber}`;
    }

    onTileClicked(event: MouseEvent, company: Company) {
        if(this.selectionMode$.value === 'company') {
            this.toggleCompanySelected(event, company);
        } else {
            this.router.navigate(['/companies/', company.kvw_nummer] ,{
                queryParamsHandling: 'preserve'
            });
            this.clicked.emit(company);
        }
    }
}
