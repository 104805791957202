import {Component, OnDestroy, OnInit} from '@angular/core';
import {distinctUntilChanged, map, pairwise, withLatestFrom} from 'rxjs/operators';
import {merge, of, Subscription} from 'rxjs';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute} from '@angular/router';
import {SearchStateService} from '../../services/search-state.service';
import {SearchProject} from '../../model/search-project';
import {Project} from 'api/models/asset/project';
import {SearchFacetsService} from '../../services/search-facets.service';
import {CallbackPaginator} from '../../utils/paginator/callback-paginator';
import {SearchFilterResponseWithParams} from '../../utils/paginator/abstract-paginator';
import {projectSortOptions} from '../../model/sort/project-sort-options';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {SelectionService} from '../../services/selection.service';
import {SearchStatePaginator} from '../../utils/paginator/search-state-paginator';
import {ScreenService} from 'src/app/services/screen.service';
import {MobileService} from '../../services/mobile.service';

@Component({
    selector: 'app-projects-search',
    templateUrl: './projects-search.component.html'
})
export class ProjectsSearchComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    projectSortOptions = projectSortOptions;
    isOnMobile = false;
    gridView$ = this.searchStateService.gridView$;
    totalProjectItems = 0;

    allResultsProjectsPaginator = new SearchStatePaginator<Project>(
        this.searchStateService,
        (page, pageSize, query, favorite, sort, filter) => {
            return this.apiService.filter<Project>('project', '', query, favorite, sort, 0, 10000, filter);
        }
    );
    paginator = new CallbackPaginator<SearchProject, any, any>(
        this.searchStateService.searchState$.pipe(
            map(({page, pageSize, query, favorite, sort, filter}) => {
                return ({page, pageSize, query, favorite, sort, filter});
            }),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
        ),
        (page, pageSize, query, favorite, sort, filter) => {
            return this.apiService.filter<SearchProject>(
                'project',
                '',
                query,
                favorite,
                sort,
                page,
                pageSize,
                filter
            );
        },
        page => this.searchStateService.setPage(page),
        pageSize => this.searchStateService.setPageSize(pageSize),
        sort => this.searchStateService.setTableSort({
            field: sort, direction: 'ASC'
        })
    );

    constructor(
        private apiService: ApiService,
        private route: ActivatedRoute,
        private searchStateService: SearchStateService,
        private searchFacetsService: SearchFacetsService,
        private selectionService: SelectionService,
        private mapboxService: MapboxService,
        private mobileService: MobileService,
        private screenService: ScreenService
    ) {
    }

    ngOnInit() {
        this.mobileService.navbarEnabled$.next(false);
        this.subscriptions.push(this.allResultsProjectsPaginator.responseWithParams$.pipe(
                withLatestFrom(this.screenService.screen$,  (response, screen) => ({response, screen})),
            ).subscribe(({response, screen}) => {
                this.searchStateService.pointResults$.next(response.value.map(item => ({
                    ...item, title: item.naam
                })));
                const filteredResults = (response.value.filter(result => result.geog !== null)).map(result => result.geog.coordinates);
            }),
            merge<SearchFilterResponseWithParams<Project>>(
                of(null),
                this.paginator.responseWithParams$
            ).pipe(pairwise()).subscribe(([previous, current]) => {
                this.totalProjectItems = current.count;
                const facetsChanged = previous === null
                    || previous.params.query !== current.params.query
                    || previous.params.favorite !== current.params.favorite;
                this.searchFacetsService.facets$.next({facets: current.facets, facetsChanged, rangeFacets: current.rangeFacets});
            }),
            this.screenService.screen$.subscribe(screen => this.isOnMobile = screen === 'mobile')
        );
        this.selectionService.selectionMode$.next('project');
    }

    ngOnDestroy() {
        this.mobileService.navbarEnabled$.next(true);
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];

        // Remove additional point results on closing of results page
        this.searchStateService.pointResults$.next([]);
    }

}
