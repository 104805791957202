import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-tile-context-menuitem',
    templateUrl: './tile-context-menuitem.component.html'
})
export class TileContextMenuitemComponent {
    @Output() clicked = new EventEmitter<void>();

    handleClick(event: MouseEvent) {
        event.stopPropagation();
        this.clicked.emit();
    }
}
