import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'searchHighlight'
})
export class SearchHighlightPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(text: any, highlightText: string): any {
        if (!highlightText) {
            return text;
        }

        const safeValue = this.sanitizer.sanitize(SecurityContext.HTML, text);
        const highlightRegex = highlightText
            .replace(/[\\^$*+?.()|[\]{}]/g, '\\$&')
            .split(' ')
            .filter(it => it.trim() !== '')
            .join('|');
        const safeHighlightRegex = this.sanitizer.sanitize(SecurityContext.HTML, highlightRegex);

        const replacedText = !safeHighlightRegex ? safeValue : safeValue
            .replace(new RegExp(safeHighlightRegex, 'gi'), match => `<span class="search-highlight">${match}</span>`)
            // Fix spaces not showing when 2 spans are next to each other
            .replace(/<\/span>(\s+)<span/gi, (match, spaces) => `${spaces}</span><span`);

        return this.sanitizer.bypassSecurityTrustHtml(replacedText);
    }
}
