import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {ApiService} from './api.service';
import {
    catchError,
    debounceTime,
    distinctUntilChanged, finalize,
    map,
    retry,
    shareReplay,
    switchMap,
    tap
} from 'rxjs/operators';
import {Point} from '../model/point';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SelectableMetadataItem} from '../model/metadata';
import {PointResult, SearchFilterResponse, SearchResponse} from '../model/search-response';
import {AssetType, SearchMode, SearchResultType} from '../model/search-result-type';
import {ApplicationInsightsService} from './application-insights.service';
import {Asset} from 'api/models/asset/metadata';

export type ViewType = 'list' | 'grid';

export interface TableResultState<T = any> {
    query: string;
    filter: { [key: string]: string[] };
    result: SearchFilterResponse<T>;
    table: SearchResultType;
    favorite: boolean;
    page: number;
}

export interface TableSort {
    direction: 'DESC' | 'ASC';
    field: string;
}

export interface SearchState {
    query: string;
    center: string;
    id: string | null;
    type: Asset['type'] | null;
    tab: number;
    item: string | null;
    table: SearchResultType | null;
    filter: { [key: string]: string[] } | null;
    sort: string | null;
    favorite: null | true;
    page: number | null;
    pageSize: number;
    viewType: ViewType | null;
}

const getEmptyState: () => SearchState = () => ({
    query: null,
    center: null,
    id: null,
    type: null,
    tab: 0,
    item: null,
    table: null,
    prevTable: null,
    filter: null,
    sort: null,
    favorite: null,
    page: null,
    pageSize: DEFAULT_PAGESIZE,
    viewType: 'list'
});

export const emptyResults: SearchResponse & { empty: true } = {
    verblijfsobject: {value: [], count: 0, searchId: null, searchIndex: null},
    company: {value: [], count: 0, searchId: null, searchIndex: null},
    project: {value: [], count: 0, searchId: null, searchIndex: null},
    woonplaats: {value: [], count: 0, searchId: null, searchIndex: null},
    relation: {value: [], count: 0, searchId: null, searchIndex: null},
    empty: true
};

export const DEFAULT_PAGESIZE = 20;

@Injectable({
    providedIn: 'root'
})
export class SearchStateService {
    private assetCoordinatesInternal$ = new BehaviorSubject<Point>(null);
    private loadingSearchResultsSubject = new BehaviorSubject<boolean>(false);

    readonly assetCoordinates$ = this.assetCoordinatesInternal$.asObservable();
    readonly loadingSearchResults$ = this.loadingSearchResultsSubject.asObservable();

    readonly gridView$ = this.activatedRoute.queryParamMap.pipe(
        map(params => params.get('view') !== 'list')
    );

    readonly searchState$: Observable<SearchState> = this.activatedRoute.queryParams.pipe(
        map<Params, SearchState>(params => this.queryParamsToSearchState(params)),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        shareReplay({bufferSize: 1, refCount: true}),
    );

    readonly assetMetadataItem$ = this.activatedRoute.queryParams.pipe(
        map<Params, SelectableMetadataItem>(params => params.item && JSON.parse(params.item)),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        shareReplay({bufferSize: 1, refCount: true}),
    );

    readonly mode$: Observable<SearchMode> = this.searchState$.pipe(map(it => {
        if (it.type && it.id) {
            return 'result';
        } else if (it.table) {
            return it.table;
        } else if (it.query || it.favorite) {
            return 'search';
        }
    }));

    readonly searchResults$: Observable<SearchResponse> = this.searchState$.pipe(
        distinctUntilChanged((a, b) => a.query === b.query),
        debounceTime(250),
        switchMap(({query, center, favorite}) => {
            if (query) {
                this.loadingSearchResultsSubject.next(true);
                return this.apiService.search(query || '', center, favorite).pipe(
                    retry(2),
                    catchError(err => {
                        console.error('Search failed', err);
                        return of(emptyResults);
                    }),
                    tap(searchResult => this.trackSearchResponse(searchResult, query, favorite)),
                    finalize(() => this.loadingSearchResultsSubject.next(false))
                );
            } else {
                return of(emptyResults);
            }
        }),
        shareReplay({bufferSize: 1, refCount: true})
    );

    readonly pointResults$ = new BehaviorSubject<PointResult[]>([]);

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private apiService: ApiService,
                private applicationInsightsService: ApplicationInsightsService
    ) {
    }

    clearSearchState() {
        this.updateRouteQueryParams(getEmptyState());
    }

    setSearchQuery(query: string, favorite: boolean) {
        this.updateRouteQueryParams({...getEmptyState(), query, favorite: favorite ? true : null});
    }

    setFavoriteEnabled(favorite: boolean) {
        this.updateRouteQueryParams({favorite: favorite ? true : null});
    }

    setPage(page: number | null) {
        this.updateRouteQueryParams({page});
    }

    setPageSize(pageSize: number | null) {
        this.updateRouteQueryParams({pageSize});
    }

    setViewType(viewType: ViewType | null) {
        this.updateRouteQueryParams({viewType});
    }

    setResultMode(query: string, type: AssetType, id: string, coordinates?: Point, searchId?: string, docId?: string, docRank?: number) {
        if (coordinates) {
            this.assetCoordinatesInternal$.next(coordinates);
        }
        if (searchId) {
            this.applicationInsightsService.trackClick(searchId, docId, docRank);
        }
        this.updateRouteQueryParams({...getEmptyState(), query, type, id});
    }

    setTableMode(table: SearchResultType, query?: string, favorite?: boolean) {
        const prevTable = this.activatedRoute.snapshot.queryParams.prevTable;
        const emptyState: Partial<SearchState> = prevTable && prevTable === table ? {} : getEmptyState();

        if (query !== undefined) {
            emptyState.query = query;
        } else {
            // Keep previous query
            delete emptyState.query;
        }

        if (favorite !== undefined) {
            emptyState.favorite = favorite ? true : null;
        } else {
            delete emptyState.favorite;
        }

        // Keep viewType if same table
        if (this.activatedRoute.snapshot.queryParams.table === table) {
            delete emptyState.viewType;
        }

        this.updateRouteQueryParams({...emptyState, table});
    }

    collapseTable() {
        this.updateRouteQueryParams({table: 'collapsed', prevTable: this.activatedRoute.snapshot.queryParams.table});
    }

    setSelectedTab(tab: number) {
        this.updateRouteQueryParams({tab});
    }

    setSelectedItem(item: SelectableMetadataItem) {
        this.updateRouteQueryParams({item: JSON.stringify(item)});
    }

    setFilter(filters: { [key: string]: string[] }, replaceUrl = false) {
        this.updateRouteQueryParams({
            page: null,
            filter: JSON.stringify(filters)
        }, replaceUrl);
    }

    setTableFilter(table: SearchResultType, filters: { [key: string]: string[] }) {
        const currentTable = this.activatedRoute.snapshot.queryParams.table;
        if (currentTable === table) {
            this.updateRouteQueryParams({
                page: null,
                filter: JSON.stringify(filters)
            });
        }
    }

    setTableSort(sort: TableSort) {
        this.updateRouteQueryParams({sort: sort.direction === 'ASC' ? sort.field : `-${sort.field}`});
    }

    queryParamsToSearchState(queryParams: Params): SearchState {
        const {query, location, id, type, tab, item, table, filter, sort, favorite, page, pageSize, viewType} = queryParams;

        return {
            query, id, type, tab, item, table, sort, viewType,
            page: +page || 0,
            pageSize: +pageSize || DEFAULT_PAGESIZE,
            center: location,
            favorite: favorite === 'true' ? true : null,
            filter: filter ? JSON.parse(filter) : null
        };
    }

    private updateRouteQueryParams(params: Params, replaceUrl = false) {
        const currentParams = this.activatedRoute.snapshot.queryParams;
        const hasNewParams = Object.entries(params).some(([key, value]) => currentParams[key] !== value);

        // Navigating to the same URL a second time can result in navigations being cancelled,
        // which messes with the router history. This guard ensures this method can always be
        // called without worrying about those side effects.
        if (hasNewParams) {
            return this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: {...currentParams, ...params},
                replaceUrl
            });
        }
    }

    private trackSearchResponse(response: SearchResponse, query: string, favorite: boolean) {
        for (const key of Object.getOwnPropertyNames(response)) {
            const item: SearchResponse[keyof SearchResponse] = response[key as keyof SearchResponse];
            if (item.searchId) {
                this.applicationInsightsService.trackSearch(
                    item.searchId, item.searchIndex, query, favorite, item.count
                );
            }
        }
    }

    private trackFilterResponse(response: SearchFilterResponse, query: string, favorite: boolean) {
        if (response.searchId) {
            this.applicationInsightsService.trackSearch(
                response.searchId, response.searchIndex, query, favorite, response.count
            );
        }
    }
}
