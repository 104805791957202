/**
 * This method decorator expects the class to have a property 'ngZone'
 */
export function runOutsideAngular(): MethodDecorator {
    return function(target: any, key: string, descriptor: any) {
        const originalMethod = descriptor.value;

        descriptor.value = function(...args: any[]) {
            return this.ngZone.runOutsideAngular(() => originalMethod.apply(this, args));
        };

        return descriptor;
    };
}
