<ng-container *ngIf="(company$|async ) as company; else empty">
    <div class="ml-2 mr-6 flex items-center">
        <div class="p-4 cursor-pointer"
            inlineSVG="/assets/icons/icon-arrow-left.svg"
            [setSVGAttributes]="{width: 16, height: 16}"
            (click)="goBack()"
        ></div>
        <div appTitle="lg" class="truncate">{{ company.naam }}</div>
    </div>

    <div class="mx-6 mt-8 mobile:mt-0">
        <app-company-tile class="block desktop:w-80 mb-4" [company]="company"></app-company-tile>

        <ng-container *ngIf="(projects$|async) as projects; else projectsSpinner">
            <div class="flex items-center pt-3 pb-4 cursor-pointer border-t border-neutral-3">
                <div class="flex cursor-pointer" (click)="projectsExpanded = !projectsExpanded">
                    <div class="transform origin-center"
                        [ngClass]="projectsExpanded ? 'rotate-90' : '-rotate-90'"
                        inlineSVG="/assets/icons/navigation-arrow-icon.svg"
                    ></div>
                    <span class="font-medium text-base ml-4">Recente Projecten</span>
                </div>
                <span class="font-medium text-base text-primary ml-auto" (click)="goToProjects(company.kvw_nummer, projects.count)">
                    Alle {{projects.count}} projecten
                </span>
            </div>
            <div [hidden]="!projectsExpanded">
                <div *ngIf="projects.value.length === 0">Projecten niet beschikbaar</div>
                <div *ngFor="let project of projects.value" class="flex my-1 cursor-pointer"
                     [routerLink]="['/projects/', project.id]"
                     queryParamsHandling="preserve">
                    <div class="flex align-center flex-nowrap items-center">
                        <div class="relative w-8 h-8 rounded flex items-center justify-center"
                             [class.bg-primary]="!(project | projectImage)">
                            <img *ngIf="project | projectImage; else defaultImage" alt=""
                                 [src]="project | projectImage"
                                 class="absolute inset-0 w-full object-cover rounded">
                            <!--
                                We _should_ be using thumbnail images here, but for unknown reasons suffixing ?size=thumbnail
                                no longer works. We use full size images instead, which definitely works, but is obviously
                                not ideal in terms of performance and bandwith used.
                            -->
                            <ng-template #defaultImage>
                                <span class="absolute left-auto right-auto text-white"
                                      inlineSVG="/assets/icons/icon-project.svg" [setSVGAttributes]="{width: 16, height: 16}">
                                </span>
                            </ng-template>
                        </div>
                        <span class="ml-4">{{ project.naam }}</span>
                        <app-is-favorite [project]="project"></app-is-favorite>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #projectsSpinner>
            <div *ngIf="loadingProjects" class="m-6 flex justify-center">
                <vwui-spinner></vwui-spinner>
            </div>
        </ng-template>

        <app-collapse collapseTitle="Details" [leftIndent]="false">
            <app-information-item
                *ngFor="let item of companyDetails$ | async"
                [label]="item.label"
                [value]="item.value"
            ></app-information-item>
        </app-collapse>
    </div>
</ng-container>

<ng-template #empty>
    <div *ngIf="loadingCompany" class="m-6 flex justify-center">
        <vwui-spinner></vwui-spinner>
    </div>
    <div *ngIf="!loadingCompany" class="pt-20 px-4">
        <app-empty-state
            title="Geen bedrijf gevonden"
            message="Er kon geen bedrijf gevonden worden, probeer het opnieuw via de zoekbalk."
        ></app-empty-state>
    </div>
</ng-template>
