<vwui-modal-header>
        Filter opslaan
</vwui-modal-header>
<vwui-modal-body>
    <label [for]="'filterName'" class="block font-medium pb-3 mt-2 text-neutral-1">Naam van het filter</label>
    <vwui-input inputId="filterName" type="text" placeholder="Naam" [formControl]="nameControl" (keydown.enter)="save()"></vwui-input>
</vwui-modal-body>
<vwui-modal-footer>
    <button (click)="close()" class="ml-auto px-6 py-3 text-neutral-1 font-medium">Annuleren</button>
    <button (click)="save()" class="px-6 py-3 text-white font-medium rounded
            bg-gradient-to-r from-primary to-primary-light">
        Filter opslaan
        <span inlineSVG="/assets/icons/spinner.svg" [class.d-none]="!loading"></span>
    </button>
</vwui-modal-footer>
