import {Component, OnInit} from '@angular/core';
import {filter, finalize, switchMap, take} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {Verblijfsobject} from 'api/models/asset/verblijfsobject';
import {Location} from '@angular/common';
import {MapboxService} from '../../services/mapbox/mapbox.service';

@Component({
    selector: 'app-verblijfsobject-detail',
    templateUrl: './verblijfsobject-detail.component.html'
})
export class VerblijfsobjectDetailComponent implements OnInit {
    metadata$ = this.activatedRoute.paramMap.pipe(
        switchMap(paramMap => {
            this.loading = true;
            return this.apiService.getMetadata<Verblijfsobject>('verblijfsobject', paramMap.get('verblijfsobjectId'))
                .pipe(finalize(() => this.loading = false));
        }),
    );
    loading = true;

    constructor(
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private mapboxService: MapboxService,
        public location: Location
    ) {
    }

    ngOnInit(): void {
        this.loadMap().then(() => {
            this.metadata$.subscribe((verblijfsobject) => {
                this.mapboxService.map.flyTo({
                    center: [verblijfsobject.asset.geog.coordinates[0], verblijfsobject.asset.geog.coordinates[1]],
                    zoom: 17,
                    pitch: 50,
                    bearing: 0
                });
            });
        });
    }

    async loadMap() {
        if (!this.mapboxService.mapLoaded$.value) {
            await this.mapboxService.mapLoaded$.pipe(filter(it => it), take(1)).toPromise();
        }
    }
}
