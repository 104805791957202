import {VWConcept} from './vw-concept';
import {Sustainability} from './sustainability';
import {SearchPoint} from './searchpoint';
import {Prs} from '../prs';

export const projectTypeTranslation: {[key: string]: string} = {
    Demolition: 'Sloop',
    Expansion: 'Uitbreiding',
    Maintenance: 'Onderhoud',
    NewConstruction: 'Nieuwbouw',
    Renovation: 'Renovatie',
    Restoration: 'Restauratie',
    Transformation: 'Herbestemming / Transformatie',
    Other: 'Overige / Diverse en aanverwanten'
};

export const filterValueTranslations: {[key: string]: string} = {
    'Aanwezig': 'PRS aanwezig', // TODO fix this in the backend
    'Voorlopig Ontwerp': 'Materialen paspoort aanwezig'
};

export interface Project {
    type: 'project';
    id: string;
    identificatie: string;
    naam: string;
    project_type: string;
    pandref: number;
    straat: string;
    huisnummer: string;
    postcode: string;
    plaats: string;
    bedrijf: string;
    cluster_regionaam: string;
    opdrachtgever: string;
    afbeeldingURL: string;
    startdatum: string;
    einddatum:string;
    gebouwclassificatie: string;
    gebouwsubclassificatie: string[];
    vwconcept: VWConcept;
    duurzaamheidsclassificatie: Sustainability;
    aangemaakt_op: string;
    geog: SearchPoint;
    project_status: string;
    operationele_projectstatus: string;
    prs?: Prs;
    hasPrs?: string;
    kvw_nummer? : string;
    bouwcombinatie_kvw_numbers : string[];
    commercial_project_name?: string;
    mca_phase?: string;
    mca_created_on?: string;
    mca_matrix_url?: string;
    prs_primaryPhoto?: string;
    prs_id?: number;
}

export interface ProjectTeamMember {
    id?: number;
    name: string;
    nummer: string;
    role: string;
    upn: string;
    businessPhones?: string[];
    companyName?: string;
    projectId: number;
}

export interface ProjectTeam {
    projectId: number;
    team: ProjectTeamMember[];
}

export function projectImage(project: Project): string | undefined {
    return project.prs?.primaryPhoto ?? project.prs_primaryPhoto ?? project.afbeeldingURL;
}

export interface ProjectApplication {
    projectId: number;
    administration?: string;
    applicationName?: string;
    applicationNumber?: string;
    navApplicationNumber?: string;
    url?: string;
    type?: string;
}

export interface ProjectRelation {
    project_id: string;
    relation_id: string;
    relation_name: string;
    role: string;
    stabu_code_largest_stabu: string;
    invoiced_amount_largest_stabu: number;
    invoiced_amount: number;
}
