import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {
    ProjectClassification,
    ProjectProjectClassification
} from 'api/models/asset/project-classification';

@Injectable({
    providedIn: 'root'
})
export class ProjectClassificationService {
    projectClassifications$: BehaviorSubject<ProjectClassification[]> = new BehaviorSubject([]);

    constructor(private http: HttpClient) {
    }

    init() {
        this.getAllProjectClassifications().subscribe(
            projectClassifications => this.projectClassifications$.next(projectClassifications));
    }

    getAllProjectClassifications(): Observable<ProjectClassification[]> {
        return this.http.get<ProjectClassification[]>(`${environment.azureBaseUrl}/project-classification`);
    }

    getProjectProjectClassificationsByProjectId(projectId: number): Observable<ProjectProjectClassification[]> {
        return this.http.get<ProjectProjectClassification[]>(
            `${environment.azureBaseUrl}/project-project-classification/${projectId}`);
    }
}
