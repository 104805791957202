import atlas from 'azure-maps-rest';
import SearchPoiResult = atlas.Models.SearchPoiResult;

export interface PoiCategory {
    id: number;
    name: string;
    icon: string;
    minZoomLevel: number;
    maxZoomLevel: number;
}

export interface PoiGroup {
    name: string;
    categories: PoiCategory[];
    catIds?: number[];
}

export interface  PoiGroupMap {
    [key: string]: PoiGroup;
}

export interface SearchPoiResultCustom extends SearchPoiResult {
    icon: string;
}

export function getPOICategoryTree(): PoiGroup[] {
    return [
        {
            name: 'Mobiliteit',
            categories: [
                {id: 7309, name: 'Oplaadpunt voor Elektrische Auto\'s', icon: 'charging-station', minZoomLevel: 13, maxZoomLevel: 18},
                {id: 7311, name: 'Tankstation', icon: 'fuel', minZoomLevel: 12, maxZoomLevel: 18},
            ]
        },
        {
            name: 'Zorg',
            categories: [
                {id: 7321, name: 'Ziekenhuis', icon: 'hospital-JP', minZoomLevel: 13, maxZoomLevel: 18},
                {id: 7391, name: 'Eerste Hulp', icon: 'hospital-JP', minZoomLevel: 13, maxZoomLevel: 18},
                {id: 9956, name: 'Spoedeisende Hulp', icon: 'hospital-JP', minZoomLevel: 14, maxZoomLevel: 18},
            ]
        },
        {
            name: 'Veiligheid',
            categories: [
                {id: 7322, name: 'Politiebureau', icon: 'police', minZoomLevel: 13, maxZoomLevel: 18},
                {id: 7392, name: 'Brandweer', icon: 'fire-station', minZoomLevel: 13, maxZoomLevel: 18},
            ]
        },
        {
            name: 'Kinderopvang',
            categories: [
                {id: 7372003, name: 'Kinderdagverblijf', icon: 'ranger-station', minZoomLevel: 15, maxZoomLevel: 18},
            ]
        },
        {
            name: 'Onderwijs',
            categories: [
                {id: 7372, name: 'School', icon: 'college', minZoomLevel: 15, maxZoomLevel: 18},
                {id: 7372006, name: 'Middelbare School', icon: 'college', minZoomLevel: 15, maxZoomLevel: 18},
                {id: 7372007, name: 'Bovenbouw Middelbare School', icon: 'college', minZoomLevel: 15, maxZoomLevel: 18},
                {id: 7377, name: 'Hogeschool/Universiteit', icon: 'college', minZoomLevel: 14, maxZoomLevel: 18},
                {id: 7372004, name: 'Kleuterschool', icon: 'ranger-station', minZoomLevel: 15, maxZoomLevel: 18},
                {id: 7372005, name: 'Basisschool', icon: 'ranger-station', minZoomLevel: 15, maxZoomLevel: 18},
                {id: 7372013, name: 'Speciaal Onderwijs', icon: 'college-JP', minZoomLevel: 15, maxZoomLevel: 18},
            ]
        },
        {
            name: 'Treinvervoer',
            categories: [
                {id: 7380, name: 'Treinstation', icon: 'rail', minZoomLevel: 12, maxZoomLevel: 18},
                {id: 7380002, name: 'Internationaal Treinstation', icon: 'rail', minZoomLevel: 12, maxZoomLevel: 18},
                {id: 7380003, name: 'Nationaal Treinstation', icon: 'rail', minZoomLevel: 12, maxZoomLevel: 18},
                {id: 7380004, name: '(Voor)stedelijk Treinstation', icon: 'rail', minZoomLevel: 12 , maxZoomLevel: 18},
            ]
        },
        {
            name: 'Openbaar vervoer',
            categories: [
                {id: 9942002, name: 'Bushalte', icon: 'bus', minZoomLevel: 15, maxZoomLevel: 18},
                {id: 9942, name: 'Halte Openbaar Vervoer', icon: 'bus', minZoomLevel: 15, maxZoomLevel: 18},
                {id: 9942004, name: 'Tramhalte', icon: 'rail-light', minZoomLevel: 15, maxZoomLevel: 18},
                {id: 7380005, name: 'Metrostation', icon: 'rail-light', minZoomLevel: 13, maxZoomLevel: 18},
            ]
        },
        {
            name: 'Lucht vervoer',
            categories: [
                {id: 7383, name: 'Luchthaven', icon: 'airport', minZoomLevel: 12, maxZoomLevel: 18},
                {id: 7383002, name: 'Burgerluchthaven', icon: 'airport', minZoomLevel: 12, maxZoomLevel: 18},
                {id: 7383005, name: 'Vliegveld', icon: 'airport', minZoomLevel: 12, maxZoomLevel: 18},
            ]
        }
    ];
}
