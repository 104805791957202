import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-share-button',
    templateUrl: './share-button.component.html'
})
export class ShareButtonComponent {
    bsModalRef: BsModalRef;

    constructor(private toastrService: ToastrService) {
    }

    copyUrlToClipBoard() {
        document.execCommand('copy');
        navigator.clipboard.writeText((window.location.href)).then(
            () => this.toastrService.info('Link is gekopieerd naar het klembord.')
        );
    }
}
