import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {FeatureFlagService} from './feature-flag.service';
import {FeatureNameKey} from './feature-flag.types';
import {AppFeatureFlag} from './app-feature-flags';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagGuard implements CanActivate {
    constructor(private router: Router,
                private toastrService: ToastrService,
                private featureFlagService: FeatureFlagService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if(!AppFeatureFlag[route.data.featureFlag]) {
            throw Error('Valid feature flag must be set in route data when using FeatureFlagGuard');
        }
        return this.featureFlagService.isFeatureEnabledForUser(route.data.featureFlag).pipe(
            map(hasFeatureFlag => hasFeatureFlag ? true : this.accessDenied(route.data.fallbackUrl, route.data.featureFlag))
        );
    }

    accessDenied(fallbackUrl: string[], featureFlag: FeatureNameKey) {
        this.toastrService.info(`Je hebt de benodigde feature flag: '${featureFlag}' niet`);
        return this.router.createUrlTree(fallbackUrl || ['/']);
    }
}
