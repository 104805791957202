<div *ngIf="group" class="mx-1">
    <ui5-range-slider
        [min]="minValue"
        [max]="maxValue"
        [startValue]="startValue"
        [endValue]="endValue"
        (change)="onSliderChange($event)"
        (input)="onSliderInput($event)">
    </ui5-range-slider>
    <div class="-mt-4 sm:flex justify-between items-center" *ngIf="group.dataType === 'NUMBER'; else staticLabel" [formGroup]="inputForm">
        <div class="min-w-0 flex-1 flex items-center px-3 py-2 border border-neutral-3 rounded ring-neutral-3 focus-within:ring-2">
            <span *ngIf="group.valuePrefix" class="mr-1">{{ group.valuePrefix }}</span>
            <input
                class="flex-1 min-w-0 appearance-none"
                formControlName="startValue"
                type="number"
                (blur)="inputBlur.next(null)"
                [min]="minValue" [max]="maxValue"
            >
            <span *ngIf="group.valueSuffix" class="ml-1">{{ group.valueSuffix }}</span>
        </div>
        <div class="flex-none px-2"> tot </div>
        <div class="min-w-0 flex-1 flex items-center px-3 py-2 border border-neutral-3 rounded ring-neutral-3 focus-within:ring-2">
            <span *ngIf="group.valuePrefix" class="mr-1">{{ group.valuePrefix }}</span>
            <input
                class="flex-1 min-w-0 appearance-none px-1"
                formControlName="endValue"
                type="number"
                (blur)="inputBlur.next(null)"
                [min]="minValue" [max]="maxValue"
            >
            <span *ngIf="group.valueSuffix" class="ml-1">{{ group.valueSuffix }}</span>
        </div>
    </div>
    <ng-template #staticLabel>
        <div class="-mt-6 flex justify-between">
            <div class="border-neutral-3 rounded">{{ startLabel | formatAttributeValue:group.valuePrefix:group.valueSuffix }}</div>
            <div class="border-neutral-3 rounded">{{ endLabel | formatAttributeValue:group.valuePrefix:group.valueSuffix }}</div>
        </div>
    </ng-template>
</div>
