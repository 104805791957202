<div [class.hidden]="isOnMobile" class="fixed-container" id="mapbox-container"></div>

<app-selection-list></app-selection-list>

<app-layer-legend *ngIf="!isOnMobile"></app-layer-legend>

<div class="fixed-container pointer-events-none-self">
    <button class="start_feedback_guide_mening absolute right-0 top-1/2 bg-orange px-4 py-3 rounded-t
        text-white font-medium text-base shadow
        transform -rotate-90 origin-center translate-x-1/3">
        Start de tour
    </button>
    <div class="zoom-controls-container">
        <app-zoom-controls></app-zoom-controls>
    </div>
</div>

<app-sidebar class="z-10" [class.hidden]="activatedRoute.firstChild === null && !isOnMobile">
    <router-outlet></router-outlet>
</app-sidebar>
<app-navbar *ngIf="!isOnMobile" class="z-20"></app-navbar>
<app-mobile-navbar *ngIf="isOnMobile && mobileNavbarEnabled$|async"></app-mobile-navbar>

<div *ngIf="!isOnMobile" class="fixed-container app-container">
    <!--
Overlay container component to show ERP info or for example Trimble viewer
-->
    <app-overlay-container></app-overlay-container>
</div>
