import {Component} from '@angular/core';
import { VwuiModalRef} from '@recognizebv/vwui-angular';
import {UntypedFormControl, Validators} from '@angular/forms';

@Component({
    selector: 'app-save-filter-modal',
    templateUrl: './save-filter-modal.component.html'
})
export class SaveFilterModalComponent {
    nameControl = new UntypedFormControl('', Validators.required);
    loading = false;

    constructor(
        private modalRef: VwuiModalRef
    ) {
    }

    close() {
        this.modalRef.close();
    }

    async save() {
        if (!this.nameControl.valid || this.loading) {
            return;
        }

        this.modalRef.close(this.nameControl.value);
    }
}
