import {Router} from '@angular/router';
import {RouterHistoryService} from '../services/router-history.service';

export function detailPageGoBack(
    router: Router,
    routerHistory: RouterHistoryService,
    fallbackCommands: string[],
) {
    return () => {
        const history = [...routerHistory.history$.getValue()];
        history.reverse();

        const currentPath = history[0].url.split('?')[0];

        for (const item of history) {
            const itemPath = item ? item.url.split('?')[0] : null;
            if (itemPath && itemPath !== currentPath) {
                window.history.back();
                return;
            }
        }

        // fallback
        router.navigate(fallbackCommands, {queryParamsHandling: 'preserve'});
    };
}
