<div>
    <div class="flex items-center my-4 cursor-pointer" (click)="open = !open">
        <div class="w-8 flex items-center">
            <div class="transform origin-center"
                 [ngClass]="open ? 'rotate-90' : '-rotate-90'"
                 inlineSVG="/assets/icons/navigation-arrow-icon.svg"
            ></div>
        </div>
        <div class="font-medium text-base">{{collapseTitle}}</div>
    </div>
    <div [hidden]="!open" class="text-sm" [class.desktop:ml-8]="leftIndent">
        <ng-content></ng-content>
    </div>
</div>
