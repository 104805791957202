/**
 * @author peterqliu / https://github.com/peterqliu
 * @author jscastro / https://github.com/jscastro76
 */
import {utils} from "../utils/utils.js";
import Objects from "./objects.js";
import {Color} from "three";
import {GLTFLoader} from "./loaders/GLTFLoader.js";
import {DRACOLoader} from "./loaders/DRACOLoader";

const gltfLoader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.1/');
dracoLoader.preload();
gltfLoader.setDRACOLoader(dracoLoader);

export default function loadObj(options, cb, promise) {

	if (options === undefined) return console.error("Invalid options provided to loadObj()");
	options = utils._validate(options, Objects.prototype._defaults.loadObj);
	let loaded = false;

	const modelComplete = (m) => {
		console.log("Model complete!", m);
		if (--remaining === 0) loaded = true;
	}
	var loader;
	if (!options.type) { options.type = 'mtl'; };
	//[jscastro] support other models
	switch (options.type) {
		case "mtl":
            throw new Error('loader disabled, please edit loadObj.js to re-enable')
			//loader = objLoader;
			break;
		case "gltf":
		case "glb":
			// [jscastro] Support for GLTF/GLB
			loader = gltfLoader;
			break;
		case "fbx":
		    throw new Error('loader disabled, please edit loadObj.js to re-enable')
			//loader = fbxLoader;
			break;
		case "dae":
            throw new Error('loader disabled, please edit loadObj.js to re-enable')
            // loader = daeLoader;
			break;
	}

	if (options.mtl) {
        throw new Error('mtl option disabled, please edit loadObj.js to re-enable')
    }
	// materialLoader.load(options.mtl, loadObject, () => (null), error => {
	// 	console.warn("No material file found for SymbolLayer3D model " + m);
	// });
    loadObject();

	function loadObject(materials) {

		if (materials && options.type == "mtl") {
			materials.preload();
			loader.setMaterials(materials);
		}

		loader.load(options.obj, obj => {
			// assign the userData from the glb to the scene because they are ignored otherwise
			Object.assign(obj.scene.userData,obj.userData)
			//[jscastro] MTL/GLTF/FBX models have a different structure
			let animations = [];
			switch (options.type) {
				case "mtl":
					obj = obj.children[0];
					break;
				case "gltf":
				case "glb":
				case "dae":
					animations = obj.animations;
					obj = obj.scene;
					break;
				case "fbx":
					animations = obj.animations;
					break;
			}
			obj.animations = animations;
			

			// This enables backwards compatibility with older models and enables future changes to not break the environment
			if (obj.userData.version !== undefined){
				// if the version is >1 overwrite the scale and units attributes
				if (obj.userData.version === 2 && obj.userData.modelType === 'MassaModel'){
					options.scale = obj.userData.scale
					options.units = 'scene'
					options.version = obj.userData.version
				}
			}

			// [jscastro] options.rotation was wrongly used
			const r = utils.types.rotation(options.rotation, [0, 0, 0]);
			const s = utils.types.scale(options.scale, [1, 1, 1]);
			obj.rotation.set(r[0], r[1], r[2]);
			obj.scale.set(s[0], s[1], s[2]);
			// [jscastro] normalize specular/metalness/shininess from meshes in FBX and GLB model as it would need 5 lights to illuminate them properly
			if (options.normalize) { normalizeSpecular(obj); }
			obj.name = "model";
			let userScaleGroup = Objects.prototype._makeGroup(obj, options);
			Objects.prototype._addMethods(userScaleGroup);
			//[jscastro] calculate automatically the pivotal center of the object
			userScaleGroup.setAnchor(options.anchor);
			//[jscastro] override the center calculated if the object has adjustments
			userScaleGroup.setCenter(options.adjustment);
			//[jscastro] if the object is excluded from raycasting
			userScaleGroup.raycasted = options.raycasted;
			//[jscastro] return to cache
			promise(userScaleGroup);
			//[jscastro] then return to the client-side callback
			cb(userScaleGroup);
			// [jscastro] initialize the default animation to avoid issues with skeleton position
			userScaleGroup.idle();

		}, () => (null), error => {
				console.error("Could not load model file: " + options.obj + " \n " + error.stack);
				promise("Error loading the model");
		});

	};

	//[jscastro] some FBX/GLTF models have too much specular effects for mapbox
	function normalizeSpecular(model) {
		model.traverse(function (c) {

			if (c.isMesh) {
				//c.castShadow = true;
				let specularColor;
				if (c.material.type == 'MeshStandardMaterial') {

					if (c.material.metalness) { c.material.metalness *= 0.1; }
					if (c.material.glossiness) { c.material.glossiness *= 0.25; }
					specularColor = new Color(12, 12, 12);

				} else if (c.material.type == 'MeshPhongMaterial') {
					c.material.shininess = 0.1;
					specularColor = new Color(20, 20, 20);
				}
				if (c.material.specular && c.material.specular.isColor) {
					c.material.specular = specularColor;
				}
				//c.material.needsUpdate = true;

			}

		});
	}

}
