import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ContactRequest} from 'api/models/contact-request';
import {ContactAttachmentSasResponse} from 'api/models/contact-attachment-sas-response';

@Injectable({
    providedIn: 'root'
})
export class ContactService {
    constructor(private http: HttpClient) {
    }

    post(body: ContactRequest) {
        return this.http.post(`${environment.azureBaseUrl}/contact`, body);
    }

    getAttachmentSas(mimeType: string) {
        return this.http.post<ContactAttachmentSasResponse>(`${environment.azureBaseUrl}/contact-attachment-sas`, {
            mimeType
        });
    }
}
