<button class="w-full h-full rounded bg-white overflow-hidden desktop:hover:shadow-tile relative group
        ring-2 rounded ring-transparent focus:ring-primary focus:outline-none border border-neutral-3 p-1 relative"
        (click)="onTileClicked()">
    <div *ngIf="projects$ | async as projects; else imagePlaceholder" @fadeInAnimation
         class="rounded imageGrid w-12 grid gap-2 w-full flex-col flex-wrap justify-center items-center"
         [class.firstGridChild]="projects.length === 3"
         [class.grid-cols-1]="projects.length <= 2"
         [class.grid-rows-2]="projects.length !== 1"
         [class.grid-row-1]="projects.length === 1"
         [class.grid-cols-2]="projects.length > 2">
        <ng-container *ngFor="let project of projects.slice(0, 3)">
            <ng-container *ngIf="project | projectImage; else defaultImage">
                <div class="relative w-full overflow-hidden"
                     [ngClass]="projects.length !== 1 ? 'h-20' : 'h-40'">
                    <img appImageFade [src]="project | projectImage"
                         class="absolute w-full object-cover object-center">
                </div>
            </ng-container>
            <ng-template #defaultImage>
                <div class="relative bg-neutral-4 w-full items-center justify-center"
                     [ngClass]="projects.length !== 1 ? 'h-20' : 'h-40'">
                    <div class="absolute inset-0 bg-neutral-4">
                        <span class="top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2 object-contain"
                              inlineSVG="/assets/icons/thumbnail.svg"></span>
                    </div>
                </div>
            </ng-template>
        </ng-container>
        <div class="relative col-start-2 w-full h-full bg-neutral-3 flex items-center justify-center overflow-hidden"
             *ngIf="projects.length > 3">
            <span class="absolute left-auto right-auto text-xs text-dark rounded">
                +{{ projects.length - 3 }}
            </span>
        </div>
    </div>
    <ng-template #imagePlaceholder>
        <div class="rounded imageGrid bg-neutral-4 w-12 w-full h-40 relative">
            <span class="top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2 object-contain"
                  inlineSVG="/assets/icons/thumbnail.svg"></span>
        </div>
    </ng-template>
    <div class="px-6 pb-4 flex flex-col justify-end text-left h-18 bg-white">
        <div class="flex items-center">
            <span class="text-base font-medium text-dark truncate">{{theCollection.name}}</span>
        </div>
        <span class="text-xs font-medium text-neutral-1 truncate">
            Aangemaakt op:  {{ theCollection.aangemaakt_op | date:'shortDate' }}
        </span>
    </div>
    <app-collections-tile-context-menu class="mobile:hidden absolute top-2 right-2" [collection]="theCollection">
    </app-collections-tile-context-menu>
</button>
<style>
    .firstGridChild :first-child {
        grid-column: 1 / -1;
    }
</style>
