export async function detectAdblock(): Promise<boolean> {
    const flaggedURL = 'https://adblockanalytics.com';

    if (window.fetch) {
        const request = new Request(flaggedURL, {
            method: 'HEAD',
            mode: 'no-cors',
        });
        try {
            await fetch(request);
            return false;
        } catch (e) {
            return true;
        }
    } else {
        return new Promise<boolean>((resolve) => {
            const http = new XMLHttpRequest();
            http.open('HEAD', flaggedURL, false);
            try {
                http.send();
                resolve(false);
            } catch (err) {
                resolve(true);
            }
        });
    }
}
