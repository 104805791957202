<div class="px-6 flex items-center h-20 justify-between">
    <div class="text-lg font-medium text-dark">
        <span class="text-primary" *ngIf="total">{{total}}</span> projecten exporteren
    </div>
    <div class="flex">
        <button class="font-medium text-m text-primary mr-4 disabled:opacity-50" [disabled]="(filters$ | async)?.length === 8" (click)="openSaveExportParameterModal()">Filters opslaan
        </button>
        <vwui-btn-icon (click)="close()" class="modal-close" icon="icon-24-close"></vwui-btn-icon>
    </div>
</div>
<vwui-modal-body>
    <p class="mb-4">Geef hieronder aan welke parameters geëxporteerd moeten worden.</p>
    <form [formGroup]="form" class="flex flex-wrap">
        <div class="mt-2 w-3/6">
            <vwui-checkbox [checked]="checked" [indeterminate]="indeterminate" (vwuiChecked)="toggleCheck($event)">
                Alle
            </vwui-checkbox>
        </div>
        <ng-container *ngFor="let parameter of parameters">
            <div class="mt-2 w-3/6">
                <vwui-checkbox [formControl]="asFormControl(form.get(parameter.key))">
                    {{parameter.title}}
                </vwui-checkbox>
            </div>
        </ng-container>
    </form>
    <div class="flex flex-wrap gap-2 mt-8 grid grid-cols-4" *ngIf="(filters$ | async)?.length > 0">
        <div
            class="group relative flex px-3 py-2 border border-neutral-3 rounded cursor-pointer hover:shadow-button"
            *ngFor="let filter of filters$|async" (click)="activateExportPreference(filter)">
            <div class="text-primary cursor-pointer mr-2"
                 inlineSVG="/assets/icons/icon-16-filter.svg">
            </div>
            <div class="relative flex-1 truncate pr-4">
                {{ filter.name }}
                <div
                    class="absolute right-0 inset-y-0 cursor-pointer text-neutral-1 hover:text-dark invisible group-hover:visible mobile:visible bg-white"
                    inlineSVG="/assets/icons/icon-16-bin.svg" (click)="deleteExportPreference($event, filter)">
                </div>
            </div>
        </div>
    </div>
</vwui-modal-body>
<vwui-modal-footer>
    <button (click)="close()">Annuleren</button>
    <button class="ml-6 py-3 px-6 text-white rounded bg-gradient-to-r from-primary to-primary-light" (click)="save()">
        Exporteren
    </button>
</vwui-modal-footer>

