import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AppRole, AppRoleAssignment, User} from '@microsoft/microsoft-graph-types';
import {Observable} from 'rxjs';

export interface AppRoleAssignmentService {
    getUserAppRoles(): Observable<AppRole[]>;

    getAppRoleAssignments(): Observable<AppRoleAssignment[]>;

    searchUsers(term: string): Observable<User[]>;

    createAppRoleAssignment(principalId: string, appRoleId: string): Observable<any>;

    deleteAppRoleAssignment(roleAssignmentId: string): Observable<any>;
}

@Injectable()
export class AppRoleAssignmentServiceImpl implements AppRoleAssignmentService {
    constructor(
        private http: HttpClient
    ) {
    }

    getUserAppRoles() {
        return this.http.get<AppRole[]>(`${environment.azureBaseUrl}/app-roles`);
    }

    getAppRoleAssignments() {
        return this.http.get<AppRoleAssignment[]>(`${environment.azureBaseUrl}/app-role-assignments`);
    }

    searchUsers(search: string) {
        return this.http.get<User[]>(
            `${environment.azureBaseUrl}/company-users`,
            {params: {search}}
        );
    }

    createAppRoleAssignment(principalId: string, appRoleId: string) {
        return this.http.post(
            `${environment.azureBaseUrl}/app-role-assignments`,
            {
                principalId,
                appRoleId
            }
        );
    }

    deleteAppRoleAssignment(roleAssignmentId: string) {
        return this.http.delete(`${environment.azureBaseUrl}/app-role-assignments/${roleAssignmentId}`);
    }
}
