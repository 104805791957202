import {Pipe, PipeTransform} from '@angular/core';
import {projectImage} from 'api/models/asset/project';

@Pipe({
    name: 'projectImage'
})
export class ProjectImagePipe implements PipeTransform {
    transform(value: any): string | undefined {
        if (!value) {
            return undefined;
        }

        return projectImage(value);
    }
}
