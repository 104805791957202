import mapboxgl, {AnyLayer} from 'mapbox-gl';
import FeatureService from 'mapbox-gl-arcgis-featureserver';
import {environment} from '../../../../environments/environment';

const baseUrl = environment.featureServices.baseUrl;
const projectionEndpoint = `${baseUrl}/arcgis/rest/services/Utilities/Geometry/GeometryServer/project`;

const enkelbestemmingFeatureServerConfig = {
    url: `${baseUrl}/arcgis/rest/services/IMRO/Ruimtelijke_plannen/FeatureServer/11`,
    useStaticZoomLevel: true,
    minZoom: 7,
    projectionEndpoint,
};

const dubbelbestemmingFeatureServerConfig = {
    url: `${baseUrl}/arcgis/rest/services/IMRO/Ruimtelijke_plannen/FeatureServer/10`,
    useStaticZoomLevel: true,
    minZoom: 7,
    projectionEndpoint
};

const bouwvlakFeatureServerConfig = {
    url: `${baseUrl}/arcgis/rest/services/IMRO/Ruimtelijke_plannen/FeatureServer/3`,
    useStaticZoomLevel: true,
    minZoom: 7,
    projectionEndpoint
};

export const enkelbestemmingSourceKey = 'bestemmingsplan-enkelbestemming';
export const dubbelbestemmingSourceKey = 'bestemmingsplan-dubbelbestemming';
export const bouwvlakSourceKey = 'bestemmingsplan-bouwvlak';

export const initEnkelbestemmingSource = (map: mapboxgl.Map): FeatureService => {
    return new FeatureService(enkelbestemmingSourceKey, map, enkelbestemmingFeatureServerConfig) as FeatureService;
};

export const initDubbelbestemmingSource = (map: mapboxgl.Map): FeatureService => {
    return new FeatureService(dubbelbestemmingSourceKey, map, dubbelbestemmingFeatureServerConfig) as FeatureService;
};

export const initBouwvlakSource = (map: mapboxgl.Map): FeatureService => {
    return new FeatureService(bouwvlakSourceKey, map, bouwvlakFeatureServerConfig) as FeatureService;
};

export const enkelbestemmingLayers: AnyLayer[] = [
    {
        id: 'Enkelbestemming',
        type: 'fill',
        source: enkelbestemmingSourceKey,
        layout: {},
        minzoom: 16,
        paint: {
            'fill-color': [
                'match',
                [
                    'get',
                    'bestemmingshoofdgroep'
                ],
                ['agrarisch'],
                '#ebf0d2',
                ['agrarisch met waarden'],
                '#d2e1a5',
                ['bedrijf'],
                '#b45fd2',
                ['bedrijventerrein'],
                '#c8a0d7',
                ['bos'],
                '#64aa2d',
                ['centrum'],
                '#ffc8be',
                ['cultuur en ontspanning'],
                '#ff3c82',
                ['detailhandel'],
                '#ffa096',
                ['dienstverlening'],
                '#f091be',
                ['gemengd'],
                '#ffbe87',
                ['groen'],
                '#28c846',
                ['horeca'],
                '#ff6923',
                ['kantoor'],
                '#ebc3d7',
                ['maatschappelijk'],
                '#dc9b78',
                ['natuur'],
                '#82a591',
                ['overig'],
                '#ebe1eb',
                ['recreatie'],
                '#b9d746',
                ['sport'],
                '#82c846',
                ['tuin'],
                '#c8d76e',
                ['verkeer'],
                '#cdcdcd',
                ['water'],
                '#afcde1',
                ['wonen'],
                '#ffff00',
                ['woongebied'],
                '#FFFFB3',
                '#FF00FF'
            ]
        }
    },
];

export const dubbelbestemmingLayers: AnyLayer[] = [
    {
        id: 'Dubbelbestemming',
        type: 'fill',
        source: dubbelbestemmingSourceKey,
        layout: {},
        minzoom: 16,
        paint: {
            'fill-pattern': [
                'match',
                [
                    'get',
                    'bestemmingshoofdgroep'
                ],
                ['leiding'],
                'blokjes',
                ['waarde'],
                'cross',
                ['waterstaat'],
                'slinger',
                ''
            ],
        }
    },
    {
        id: 'DubbelBestemming-border',
        type: 'line',
        source: dubbelbestemmingSourceKey,
        layout: {},
        minzoom: 16,
        paint: {'line-color': '#000000'}
    }
];

export const bouwvlakLayers: AnyLayer[] = [
    {
        id: 'Bouwvlak',
        type: 'line',
        source: bouwvlakSourceKey,
        layout: {},
        minzoom: 16,
        paint: {'line-color': '#000000'}
    },
];
