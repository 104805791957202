import {Component, ElementRef, Inject, Input, ViewChild} from '@angular/core';
import {ConnectedPosition, Overlay} from '@angular/cdk/overlay';
import {AbstractPaginator} from '../../utils/paginator/abstract-paginator';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html'
})
export class PaginationComponent {
    @ViewChild('dropdownButton') dropdownButton?: ElementRef<HTMLButtonElement>;
    @Input() paginator: AbstractPaginator<any>;
    @Input() pageSizes: number[] = [20, 50, 100];

    dropdownOpen = false;

    scrollStrategy = this.overlay.scrollStrategies.close();
    dropdownHeight = 170; // in pixels

    get connectedPositions(): ConnectedPosition[] {
        // Check if the dropdown menu is expected to exceed the bottom of the page.
        // If it does, render it above the button, rather than below.
        if (this.dropdownButton != null) {
            const yPosition = this.dropdownButton.nativeElement.getBoundingClientRect().y;
            const pageHeight = this.document.body.getBoundingClientRect().height;
            if (pageHeight - yPosition < this.dropdownHeight) {
                return [{
                    originX: 'center',
                    originY: 'top',
                    overlayX: 'center',
                    overlayY: 'bottom'
                }];
            }
        }
        return [{
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top'
        }];
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private overlay: Overlay
    ) { }

    onDropdownOpenClick() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    onOutsideOverlayClick() {
        // Schedule so closing dropdown occurs after potential onDropdownOpenClick event
        setTimeout(() => this.dropdownOpen = false, 0);
    }

    onOptionClick(size: number) {
        this.paginator.setPageSize(size);
        this.dropdownOpen = false;
    }
}
