<app-project-header [project]="project" (goBack)="goBack()"></app-project-header>

<div *ngIf="metadata?.bim || metadata?.rcIndoor || metadata?.rcOutdoor || metadata?.fields"
     class="flex w-full text-center desktop:px-6 desktop:mt-2.5">
    <a class="p-3 mobile:px-6 desktop:hover:text-primary cursor-pointer" (click)="activeTab = 'project'"
       [ngClass]="activeTab === 'project' ? ['text-primary', 'border-primary', 'border-b-2'] : ['text-neutral-1', 'border-b']"
    >
        Project
    </a>
    <a *ngIf="(metadata?.bim || metadata?.rcIndoor || metadata?.rcOutdoor || metadata?.fields) && !isOnMobile"
       class="p-3 desktop:hover:text-primary cursor-pointer" (click)="activeTab = 'project_dossier'"
       [ngClass]="activeTab === 'project_dossier' ? ['text-primary', 'border-primary', 'border-b-2'] : ['text-neutral-1', 'border-b']"
    >
        Project dossier
    </a>
    <a class="p-3 mobile:px-6 desktop:hover:text-primary cursor-pointer"
       *ngIf="project.prs?.id !== null"
       (click)="activeTab = 'project_referentie_sheet'"
       [ngClass]="activeTab === 'project_referentie_sheet' ? ['text-primary', 'border-primary', 'border-b-2'] : ['text-neutral-1', 'border-b']">
        Project referentie
    </a>
    <span class="flex-grow border-b"></span>
</div>
<div class="mx-6">
    <ng-container *ngIf="project; else noProject">
        <ng-container *ngIf="activeTab === 'project'">
            <app-collapse collapseTitle="Projectinformatie">
                <app-information-item label="Opdrachtgever" [value]="project.opdrachtgever"></app-information-item>
                <app-information-item label="Onderneming" [value]="companies$ | async"></app-information-item>
                <app-information-item label="Locatie" [value]="getProjectLocation(project)"></app-information-item>
                <app-information-item label="Project omvang"
                                      [value]="projectBuildingClassification$|async"></app-information-item>

                <ng-container *ngFor="let projectClassification of projectClassificationValuesToShow$ | async">
                    <app-information-item
                        [label]="projectClassification.kenmerk"
                        [value]="getProjectClassificationValuesByDefinition(projectClassification.kenmerk) | async">
                    </app-information-item>
                </ng-container>

                <div class="overflow-hidden" *ngIf="getProjectPercentage() as percentage">
                    <div
                        class="ml-auto mr-auto mb-1 mt-6 mx-2  h-6 text-darkBlue text-xs flex font-medium">
                        <span *ngIf="project.startdatum">{{ project.startdatum | date:'LLLL yyyy' }} </span>
                        <span class="ml-auto"
                              *ngIf="project.einddatum">{{ project.einddatum | date:'LLLL yyyy' }}</span>
                    </div>
                    <div class="ml-auto mr-auto h-6 bg-neutral-4 rounded">
                        <div class="h-full bg-primary rounded-l text-right flex relative"
                             [style.width]="percentage + '%'"
                             [class.rounded-r]="percentage === 100">
                        <span *ngIf="percentage !== 100" [class.invisible]="percentage <= 30"
                              class="ml-auto text-white text-xs mt-1 mr-2">
                            {{ currentDate | date:'LLLL yyyy' }}
                        </span>
                            <span *ngIf="percentage !== 100"
                                  class="transform -translate-y-1 border-r-4 border-primary h-8"></span>
                            <span [class.invisible]="percentage > 30"
                                  class="absolute left-full text-primary text-xs mt-1 ml-2 whitespace-nowrap">
                            {{ currentDate | date:'LLLL yyyy' }}
                        </span>
                        </div>
                    </div>
                    <div class="ml-auto mr-auto mt-1 mx-2 h-6 text-neutral-2 text-xs flex justify-between font-medium">
                        <span>Start bouw</span>
                        <span>Opleverdatum</span>
                    </div>
                </div>
            </app-collapse>

            <app-collapse *ngIf="project.prs?.projectRelations" collapseTitle="Ontwerpteam">
                <ng-container *ngIf="project.prs?.projectRelations.length; else noRelations">
                    <app-project-relation-card
                        *ngFor="let projectRelation of project.prs.projectRelations"
                        [projectRelation]="projectRelation"
                    ></app-project-relation-card>
                </ng-container>
                <ng-template #noRelations>
                    Ontwerpteam niet beschikbaar
                </ng-template>
            </app-collapse>

            <app-collapse *ngIf="projectTeam$|async as projectTeam" collapseTitle="Projectteam VolkerWessels">
                <ng-container *ngIf="projectTeam.team.length; else noTeamMembers">
                    <app-contact-card
                        *ngFor="let projectTeamMember of projectTeam.team"
                        [projectTeamMember]="projectTeamMember"
                    ></app-contact-card>
                </ng-container>
                <ng-template #noTeamMembers>
                    Projectteam VolkerWessels niet beschikbaar
                </ng-template>
            </app-collapse>

            <app-collapse *ngIf="projectRelationsChapters$|async as chapters" collapseTitle="Leveranciers en onderaannemers">
                <ng-container *ngIf="chapters.length; else noProjectRelations">
                    <app-collapse [open]="false" *ngFor="let chapter of chapters" [collapseTitle]="chapter.label">
                        <div *ngFor="let projectRelation of filterDuplicatesByRelationName(chapter.projectRelations)"
                             class="cursor-pointer"
                             (click)="goToRelation(projectRelation.relation_id)">{{ projectRelation.relation_name }}
                        </div>
                    </app-collapse>
                </ng-container>
                <ng-template #noProjectRelations>
                    Leveranciers en onderaannemers niet beschikbaar
                </ng-template>
            </app-collapse>

        </ng-container>

        <app-collapse *ngIf="project && project.prs?.id && activeTab === 'project_referentie_sheet'"
                      collapseTitle="Projectreferentie">
            <app-information-item label="Aangemaakt" [value]="project.prs.createdAt|date"></app-information-item>
            <app-information-item label="Aangemaakt door" [value]="project.prs.createdBy"></app-information-item>
            <app-information-item label="Laatste wijziging" [value]="project.prs.updatedAt|date"></app-information-item>
            <app-information-item label="Laatste wijziging door" [value]="project.prs.updatedBy"></app-information-item>
            <div class="flex align-center mt-2 space-x-2">
                <vwui-button id="open-prs" block="" class="flex-1 is-primary" (click)="openOverlay(project.prs, 'prs')">
                    Referentie bekijken
                </vwui-button>
            </div>
        </app-collapse>
        <app-collapse *ngIf="activeTab === 'project_dossier' && !isOnMobile" collapseTitle="Project views">
            <div *ngIf="trimbleAuthenticated && (loadingTrimbleViews || loadingTrimbleApplications)"
                 class="text-primary">
                <span inlineSVG="/assets/icons/spinner.svg" class="w-4 ml-auto"></span>
            </div>

            <ng-container *ngIf="trimbleAuthenticated; else trimbleLogin">
                <ng-container
                    *ngFor="let trimbleView of (trimbleViews$ | async)">
                    <app-project-view-link
                        label="3D informatiemodellen"
                        [thumbnail]="'/assets/icons/icon-bim.svg'"
                        (clicked)="openOverlay(trimbleView, 'trimbleView')"
                    ></app-project-view-link>
                </ng-container>

                <ng-container *ngIf="((trimbleApplications$ | async)?.length > 0 &&
                                        (trimbleViews$ | async)?.length === 0 &&
                                        !trimbleViewAccesDenied)">
                    <ng-container
                        *ngFor="let projectApplication of (trimbleApplications$ | async)">
                        <app-project-view-link
                            label="3D informatiemodellen"
                            [thumbnail]="'/assets/icons/icon-bim.svg'"
                            (clicked)="openOverlay(projectApplication, 'projectApplication')"
                        ></app-project-view-link>
                    </ng-container>
                </ng-container>

                <ng-container
                    *ngIf="((trimbleApplications$ |async)?.length > 0 && (trimbleViews$ | async)?.length === 0)">
                    U heeft geen toegang tot dit project in Trimble, neem contact op met de
                    projectleider {{ getProjectLeiderName() | async }}
                </ng-container>

            </ng-container>

            <ng-template #trimbleLogin>
                <div class="trimble-login-container">
                    Klik op de knop hieronder om in te loggen.
                    <button class="trimble-login-container__button" aria-label="Trimble Connect login" type="button"
                            (click)="openTrimbleLogin(project.id)">
                        <span class="mr-2" inlineSVG="/assets/icons/trimble-connect.svg"></span> Trimble login
                    </button>
                </div>
            </ng-template>

            <app-project-view-link
                *ngFor="let bim of metadata.bim"
                [label]="bim.name"
                [thumbnail]="bim.thumbnail"
                (clicked)="openOverlay(bim, 'bim')"
            ></app-project-view-link>
            <app-project-view-link
                *ngFor="let rcOutdoor of metadata.rcOutdoor"
                [label]="rcOutdoor.name"
                [thumbnail]="rcOutdoor.thumbnail"
                (clicked)="openOverlay(rcOutdoor, 'rcOutdoor')"
            ></app-project-view-link>
            <app-project-view-link
                *ngFor="let rcIndoor of metadata.rcIndoor"
                [label]="rcIndoor.name"
                [thumbnail]="rcIndoor.thumbnail"
                (clicked)="openOverlay(rcIndoor, 'rcIndoor')"
            ></app-project-view-link>

            <ng-container
                *ngIf="metadata.bim.length === 0 && metadata.rcOutdoor.length === 0 && metadata.rcIndoor.length === 0 && (trimbleApplications$ | async)?.length === 0">
                Project views niet beschikbaar
            </ng-container>

        </app-collapse>

        <app-collapse *ngIf="activeTab === 'project_dossier' && project.mca_matrix_url && !isOnMobile" collapseTitle="MCA">
            <app-project-view-link
                label="Materialen matrix"
                thumbnail="/assets/icons/icon-bim.svg"
                (clicked)="openMCAModal(project.mca_matrix_url)"
            ></app-project-view-link>
        </app-collapse>

    </ng-container>
    <app-collapse *ngIf="activeTab === 'project_dossier' && !isOnMobile" collapseTitle="Applicaties">
        <div *ngIf="loadingProjectApplications" class="text-primary">
            <span inlineSVG="/assets/icons/spinner.svg" class="w-4 ml-auto"></span>
        </div>
        <app-project-app-store-list [projectId]="project.id" [projectAppStoreItems]="projectAppStoreItems$"></app-project-app-store-list>
    </app-collapse>

    <ng-template #noProject>
        <div *ngIf="loading" class="m-6 flex justify-center">
            <vwui-spinner></vwui-spinner>
        </div>
        <div *ngIf="!loading" class="pt-20 px-4">
            <app-empty-state
                title="Geen project gevonden"
                message="Er kon geen project gevonden worden, probeer het opnieuw via de zoekbalk."
            ></app-empty-state>
        </div>
    </ng-template>
</div>
