import {Injectable} from '@angular/core';
import {fromEvent, of} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, startWith} from 'rxjs/operators';

const QUERIES = new Map([
    ['desktop', '(min-width: 480px) and (orientation: portrait), (min-width: 980px) and (orientation: landscape)'],
    ['mobile', '(max-width: 479.99px) and (orientation: portrait), (max-width: 979.99px) and (orientation: landscape)'],
]);

@Injectable({
    providedIn: 'root'
})
export class ScreenService {
    _screen$ = of('mobile');
    screen$ = fromEvent(window, 'resize')
        .pipe(
            startWith(this.getScreenSize()),
            map(event => this.getScreenSize()),
            distinctUntilChanged(),
            shareReplay(1)
        );

    private getScreenSize(): string {
        const [[newSize = 'never']] = Array.from(QUERIES.entries())
            .filter(([size, mediaQuery]) => window.matchMedia(mediaQuery).matches);
        return newSize;
    }
}
