import {RangeFacet} from '../components/search-filter/search-filter.component';

export const rangeFacets: RangeFacet[] = [{
    type: 'RANGE',
    groupName: 'Algemeen',
    dataType: 'MONTH',
    key: 'startdatum',
    max: 2023,
    min: 2011,
    valuePrefix: '',
    valueSuffix: '',
    options: [],
    subject: 'project'
}, {
    type: 'RANGE',
    groupName: 'Algemeen',
    dataType: 'MONTH',
    key: 'einddatum',
    max: 2023,
    min: 2011,
    valuePrefix: '',
    valueSuffix: '',
    options: [],
    subject: 'project'
}, {
    type: 'RANGE',
    groupName: 'Contract',
    dataType: 'NUMBER',
    key: 'prs_contractAttributes_contractPrice',
    max: 15000000,
    min: 1,
    valuePrefix: '€',
    valueSuffix: '',
    options: [],
    subject: 'project'
}, {
    type: 'RANGE',
    groupName: 'Contract',
    dataType: 'NUMBER',
    key: 'prs_contractAttributes_additionalWork',
    max: 15000000,
    min: 1,
    valuePrefix: '€',
    valueSuffix: '',
    options: [],
    subject: 'project'
}, {
    type: 'RANGE',
    groupName: 'Kenmerken',
    dataType: 'NUMBER',
    key: 'prs_attributes_surfaceBVO',
    max: 60000,
    min: 0,
    valuePrefix: '',
    valueSuffix: 'm²',
    options: [],
    subject: 'project'
}, {
    type: 'RANGE',
    groupName: 'Kenmerken',
    dataType: 'NUMBER',
    key: 'prs_attributes_surfaceVVO',
    max: 55000,
    min: 0,
    valuePrefix: '',
    valueSuffix: 'm²',
    options: [],
    subject: 'project'
}, {
    type: 'RANGE',
    groupName: 'Kenmerken',
    dataType: 'NUMBER',
    key: 'prs_attributes_surfaceFacade',
    max: 30000,
    min: 0,
    valuePrefix: '',
    valueSuffix: 'm²',
    options: [],
    subject: 'project'
}, {
    type: 'RANGE',
    groupName: 'Kenmerken',
    dataType: 'NUMBER',
    key: 'prs_attributes_locationSize',
    max: 12000,
    min: 0,
    valuePrefix: '',
    valueSuffix: 'm²',
    options: [],
    subject: 'project'
}, {
    type: 'RANGE',
    groupName: 'Kenmerken',
    dataType: 'NUMBER',
    key: 'prs_attributes_buildingLayersAboveGround',
    max: 30,
    min: 1,
    valuePrefix: '',
    valueSuffix: '',
    options: [],
    subject: 'project'
}, {
    type: 'RANGE',
    groupName: 'Kenmerken',
    dataType: 'NUMBER',
    key: 'prs_attributes_buildingLayersUnderGround',
    max: 3,
    min: 0,
    valuePrefix: '',
    valueSuffix: '',
    options: [],
    subject: 'project'
}, {
    type: 'RANGE',
    groupName: 'Kenmerken',
    dataType: 'NUMBER',
    key: 'prs_attributes_demolitionSize',
    max: 12000,
    min: 0,
    valuePrefix: '',
    valueSuffix: 'm²',
    options: [],
    subject: 'project'
}, {
    type: 'RANGE',
    groupName: 'Kenmerken',
    dataType: 'NUMBER',
    key: 'prs_attributes_remediateSize',
    max: 12000,
    min: 0,
    valuePrefix: '',
    valueSuffix: 'm²',
    options: [],
    subject: 'project'
}, {
    type: 'RANGE',
    groupName: 'Kenmerken',
    dataType: 'NUMBER',
    key: 'prs_attributes_readyForConstructionSize',
    max: 12000,
    min: 0,
    valuePrefix: '',
    valueSuffix: 'm²',
    options: [],
    subject: 'project'
}, {
    type: 'RANGE',
    groupName: 'Kenmerken',
    dataType: 'NUMBER',
    key: 'prs_attributes_readyToLiveSize',
    max: 12000,
    min: 0,
    valuePrefix: '',
    valueSuffix: 'm²',
    options: [],
    subject: 'project'
}, {
    type: 'RANGE',
    groupName: 'Kenmerken',
    dataType: 'NUMBER',
    key: 'prs_attributes_maintanceContractYears',
    max: 20,
    min: 1,
    valuePrefix: '',
    valueSuffix: 'jaar',
    options: [],
    subject: 'project'
}
];
